<div class="page-facturation">
	<div class="page-facturation__container">
		<div class="page-facturation__header">
			<div class="page-facturation__titre">
				<h1>Facturation</h1>
				<small>{{ordoActiveCountMessage}}</small>
			</div>
			<div class="page-facturation__filtres">
				<div class="selection-filtre">
					<lua-filtre-groupe [filters]="facturationfilters" (filterSelected)="changeFilter($event)"
						[defaultFilter]="defaultFilter">
					</lua-filtre-groupe>
				</div>
				<form [formGroup]="filtreForm" [class]="{'full-width': isMobile}">
					<lua-filter-date-picker *ngIf="defaultFilter !== 'FACTURÉES'" label="Date de facturation" fullWidth="isMobile"
						(valueChange)="onValueChange($event)" formControlName="dateFacturation" 
						[max]="maxDate">
					</lua-filter-date-picker>
				</form>
				<lua-filtres
				 	*ngIf="!isMobile && defaultFilter !== 'FACTURÉES'" 
					[fullWidth]="false" 
					[selectedFilters]="filterInfirmiersSelected" 
					(click)="openMenuFiltreInfirmier($event)" 
					label="Infirmier"
					[color]="colorFilter">
				</lua-filtres>		
				<lua-recherche  [class]="{'full-width': isMobile}" [fullWidth]="isMobile" [searchText]="searchedValue" label="Rechercher" (onValueChange)="filterOrdonnances($event)"
					placeholder="Rechercher">
				</lua-recherche>	
			</div>
		</div>
		<div class="page-facturation__body">
			<ng-container *ngIf="showOrdonnanceList()">
				<ng-container *ngFor="let ordonnance of filteredOrdonnance  | slice:0:displayCount; let i = index">
					<di-carte-ordonnance [ordonnance]="ordonnance" tagColor="Info" progressBarColor="Indigo" [patient]="ordonnance.patient"
						buttonIconName="more_vert" [modeFacturation]="true" [onFacturerClick]="handleFacturer">
					</di-carte-ordonnance>
				</ng-container>
				<lua-action-secondaire *ngIf="displayCount < ordonnances.length" class="show-more-btn-container"
					libelle="Afficher les factures suivantes" [fullWidth]="false" (click)="showMore()"></lua-action-secondaire>
			</ng-container>
			<ng-container *ngIf="!showOrdonnanceList()">
				<lua-message-aucune-donnee [mainText]="MainTextNoResult" iconName="contract"
					[subText]="SubTextNoResult"></lua-message-aucune-donnee>
			</ng-container>
		</div>
	</div>
</div>