import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DateHelper, FileHelper } from '@osapp/helpers';
import { IContact, IGalleryFile } from '@osapp/model';
import { DmsFile } from '@osapp/modules/dms/model/DmsFile';
import { LongGuidBuilder } from '@osapp/modules/guid/models/long-guid-builder';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ITraitement } from '../../../../../model/ITraitement';
import { Traitement } from '../../../../../model/Traitement';
import { EOriginePrescription } from '../../../../../modules/ordonnances/models/eorigine-prescription.enum';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { TraitementService } from '../../../../../services/traitement.service';
import { DeviceService } from '../../../shared/services/device.service';
import { LoaderService } from '../../../shared/services/loader.service';
import { MedecinService } from '../../../shared/services/medecin.service';
import { PanneauService } from '../../../shared/services/panneau.service';
import { UploadFileService } from '../../../shared/services/uploadFile.service';
import { PanneauChoixMedecinComponent } from '../panneau-choix-medecin/panneau-choix-medecin.component';
import { SeanceService } from '../../../shared/services/seance.service';

@Component({
	selector: 'di-form-ordonnance',
	templateUrl: './form-ordonnance.component.html',
	styleUrls: ['./form-ordonnance.component.scss'],
})
export class FormOrdonnanceComponent extends DestroyableComponentBase implements OnInit, OnChanges {

	@Input() public ordonnance?: ITraitement;
	@Input() public ordonnanceOrigine?: Traitement;
	@Input() public patient?: IPatient;
	@Input() public medecin?: IContact;
	@Input() public mode?: string;
	@Input() public forceMobile?: boolean = false;
	@Output() updateOrCreateOrdonnance: EventEmitter<ITraitement> = new EventEmitter<ITraitement>();

	public model: ITraitement;

	public ordoForm: FormGroup;
	public isADC: boolean = false;
	public isALD: boolean = false;
	public prescripteur: IContact;
	public texteSecondaireIsADC: string = "Accident de droit commun";
	public texteSecondaireIsALD: string = "Soins conforme au protocole d'affection de longue durée";

	public listDoc: IGalleryFile[] = [];
	public listNewFile: File[] = [];
	private readonly guidBuilder = new LongGuidBuilder();
	public errorMessage: string;
	public isError: boolean = false;

	public isMobile: boolean = false;

	public origineOptions = [
		{ label: "T (Médecin traitant)", value: EOriginePrescription.medTraitant },
		{ label: "O (Médecin correspondant)", value: EOriginePrescription.medCorrespondant },
		{ label: "P (Autre situation parcours de soin)", value: EOriginePrescription.autreSituationParcours },
		{ label: "S (Hors parcours de soin)", value: EOriginePrescription.horsParcoursDeSoin },
		{ label: "A (Renouvellement adapté)", value: EOriginePrescription.renouvellementAdapte },
		{ label: "I (Renouvellement identique)", value: EOriginePrescription.renouvellementIdentique }
	];

	constructor(
		private router: Router,
		private fb: FormBuilder,
		private svcPanneau: PanneauService,
		private svcMedecin: MedecinService,
		private svcContacts: ContactsService,
		private svcOrdonnance: TraitementService,
		private svcUploadFile: UploadFileService,
		private svcDevice: DeviceService,
		private svcLoader : LoaderService,
		private svcSeance: SeanceService
	) {
		super()
	}

	ngOnInit() {
		this.svcMedecin.selectMedecin$.pipe(takeUntil(this.destroyed$)).subscribe(
			(medecinId: string) => {
				this.svcContacts.getContact(medecinId).pipe(
					tap((medecin: IContact) => {
						this.prescripteur = medecin;
					}),
					takeUntil(this.destroyed$)
				).subscribe()
			}
		);

		this.isMobile = this.forceMobile;
		if (!this.forceMobile) {
			this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
				this.isMobile = flag;
			});
		}
		this.initForm();
	}

	ngOnChanges() {
	}

	initForm() {
		if (this.ordonnance)
			this.model = this.ordonnance
		this.ordoForm = this.fb.group({
			originePrescription: [this.ordonnanceOrigine ? EOriginePrescription.renouvellementAdapte : this.ordonnance?.originePrescription ?? EOriginePrescription.medTraitant],
			prescriptionDate: [this.ordonnanceOrigine ? DateHelper.toDateUrl(new Date()) : this.ordonnance?.prescriptionDate ? DateHelper.toDateUrl(this.ordonnance.prescriptionDate as Date) : DateHelper.toDateUrl(new Date()), Validators.required],
			description: [this.ordonnanceOrigine ? this.ordonnanceOrigine?.description : this.ordonnance?.description ?? ""],
			commentaire: [this.ordonnanceOrigine ? this.ordonnanceOrigine?.commentaire : this.ordonnance?.commentaire ?? ""],
			adcDate: [this.ordonnanceOrigine && this.ordonnanceOrigine.isAdc && this.ordonnanceOrigine.adcDate ?  new Date(this.ordonnanceOrigine?.adcDate) : this.ordonnance?.adcDate && this.ordonnance.isAdc ? new Date(this.ordonnance?.adcDate) : '', []]
		});
		this.initSwitchValue();
		this.initMedecinValue();
		this.initDocValue();
	}

	private initSwitchValue() {
		if(this.ordonnanceOrigine)
		{
			this.isALD = this.ordonnanceOrigine?.isAld ?? false;
			this.isADC = this.ordonnanceOrigine?.isAdc ?? false;
		}else
		{
			this.isALD = this.ordonnance?.isAld ?? false;
			this.isADC = this.ordonnance?.isAdc ?? false;
		}


	}

	private initDocValue() {
		if(this.ordonnanceOrigine && this.ordonnanceOrigine.documents)
		{
			this.listDoc = [];
		}
		if (this.ordonnance && this.ordonnance.documents) {
			this.listDoc = this.ordonnance.documents.map((doc : IGalleryFile) => 
			{
				doc.isNew = false;
				return doc;
			});
		}
	}

	private initMedecinValue() {
		if (this.medecin) {
			this.prescripteur = this.medecin;
			return;
		}
	
		const contactId = this.getPrescripteurContactId();
		if (contactId) {
			this.loadPrescripteur(contactId);
		}
	}

	private getPrescripteurContactId(): string | undefined {
		if (this.ordonnanceOrigine?.prescripteurContactId) {
			return this.ordonnanceOrigine.prescripteurContactId;
		}
		if (this.ordonnance?.prescripteurContactId) {
			return this.ordonnance.prescripteurContactId;
		}
		return undefined;
	}

	private loadPrescripteur(contactId: string): void {
		this.svcContacts.getContact(contactId).pipe(
			tap((medecin: IContact) => {
				this.prescripteur = medecin;
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	toggleIsALD(isALD: boolean) {
		this.isALD = isALD;

	}
	toggleIsADC(isADC: boolean) {
		this.isADC = isADC;
	}

	public openPanneauMedecin(): void {
		if(this.mode === 'add'){
			this.svcPanneau.open("Prescripteur", PanneauChoixMedecinComponent, {patient: this.patient})
		}else{
			this.svcPanneau.open("Prescripteur", PanneauChoixMedecinComponent, { ordonnance: this.ordonnance })
		}
	}

	handleAnnule() {
		this.router.navigate(['ordonnances']);
	}

	public async addAttestation(file: File) {
		if (file) {
			if (file.size > 1000 * 1024) {
				try {
					const newFile: File = await FileHelper.resizeImageAnakn(file);
					this.listNewFile.push(newFile);
				} catch (error) {
					this.isError = true;
					console.error('Error resizing file:', error);
				}
			} else if (file.size > 250 * 1024 && !file.type.includes('image/')) {
				this.isError = true;
				this.errorMessage = "Le fichier sélectionné est trop volumineux (250Ko maximum)";
			}
			else {
				this.isError = false;
				this.listNewFile.push(file);
			}
		}
	}

	public deleteNewFile(event: File): void {
		this.listNewFile = [];
	}

	public deleteOldFile(event: IGalleryFile): void {
		const index = this.listDoc.findIndex(file =>
			file.guid === event.guid
		);

		if (index !== -1) {
			this.listDoc.splice(index, 1);
		}
	}

	handleClickSaveBtn() {
		if (this.ordoForm.valid) {
			const formValues = this.ordoForm.value;
			let formOrdo = {}
			if (this.model) {
				formOrdo = { ...this.model }
			} else {
				formOrdo = {
					_id: ''
				}
			}
			formOrdo = {
				...formOrdo,
				...formValues
			}
			this.saveOrdonnance(formOrdo);
		}
	}

	private saveOrdonnance(OrdoValue: any) {
		let ordonnance: ITraitement;
		if (OrdoValue._id === '') {
			this.svcOrdonnance.createTraitement(this.patient).pipe(
				tap((ordoRetour: ITraitement) => {
					ordonnance = ordoRetour;
				}),
				takeUntil(this.destroyed$)
			).subscribe();
		}
		else {
			ordonnance = this.model;
		}

		this.listNewFile.map((file: File) => {
			const dmsFile: DmsFile = new DmsFile(file, file.name);
			const galleryFile: IGalleryFile = {
				file: dmsFile,
				isNew: true,
				name: dmsFile.Name,
				description: "",
				guid: this.guidBuilder.build({ withHyphens: false, upperCase: false })
			};
			this.listDoc.push(galleryFile);
		});
		this.listNewFile = [];
		const aldHasChanged: boolean = ordonnance.isAld !== this.isALD;

		if (!ordonnance.documents)
			ordonnance.documents = [];
		ordonnance.documents = this.listDoc;
		ordonnance.prescriptionDate = new Date(OrdoValue.prescriptionDate);
		ordonnance.originePrescription = OrdoValue.originePrescription;
		ordonnance.prescripteurContactId = this.prescripteur?._id;
		ordonnance.isAld = this.isALD;
		ordonnance.isAdc = this.isADC;
		ordonnance.adcDate = this.isADC ? OrdoValue.adcDate : undefined
		ordonnance.description = OrdoValue.description;
		ordonnance.commentaire = OrdoValue.commentaire;
		ordonnance.beginDate = OrdoValue.prescriptionDate;

		if(this.ordonnanceOrigine)
		{
			ordonnance.actes = this.ordonnanceOrigine.actes;
			ordonnance.ordonnanceOrigineId = this.ordonnanceOrigine._id;
		}

		const ordonnanceObj: Traitement = Traitement.createFromData(ordonnance);
		this.svcLoader.showLoader("Sauvegarde des informations sur l'ordonnance en cours ...");
		this.svcOrdonnance.saveTraitementANAKIN(ordonnanceObj).pipe(
			mergeMap((ordo: Traitement) => {
				if (this.listDoc.some((file: IGalleryFile) => file.isNew)) {							
					return this.svcUploadFile.saveFilesWithErrorHandler(ordonnance.documents, ordonnance._id).pipe(
								map( () => {
									ordonnance = ordo;
									return ordo;
								}),
								catchError(error => {
										delete ordo.documents; // Supprime les documents dans l'ordonnance
										const ordonnanceObjRevert = Traitement.createFromData(ordo);
										return this.svcOrdonnance.saveTraitementANAKIN(ordonnanceObjRevert).pipe(
												map((ordoRevert: Traitement) => {
														ordonnance = ordoRevert;
														return ordoRevert;
												})
										);
								})
							);
					} else {
							ordonnance = ordo;
							return of(ordo);
					}
			}),
			switchMap((ordo: Traitement) => {
				if (this.mode !== "edit" || !aldHasChanged) {
					return of(ordo);
				}
				return this.svcSeance.updateTraitementSeances(ordonnanceObj).pipe(map(() => ordo))
			}),
			takeUntil(this.destroyed$),
			finalize(() => {
				this.svcLoader.hideLoader();
			})
		).subscribe(
			(ordo: Traitement) => {
					ordonnance = ordo; // Met à jour ordonnance avec le bon objet (soit ordo, soit ordoRevert)
					this.updateOrCreateOrdonnance.emit(ordonnance);
			},
			(error) => {
				console.error('Erreur lors du processus de sauvegarde', error);
			}
		);
	}
}