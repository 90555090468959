<div class="menu-patient__container">
	<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="edit"
		text="Modifier l'identification" (click)="editPatient()">
	</lua-list-item>
	<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="medical_information"
		text="Lire la carte vitale" (click)="openPanneauSecuriteSociale()">
	</lua-list-item>
	<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="emergency"
		text="Prise en charge spécifique" (click)="openPanneauPriseEnChargeSpecifique()">
	</lua-list-item>
	<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="warning"
		text="Allergies, antécédents..." (click)="openPanneauAllergies()">
	</lua-list-item>
	<lua-divider-horizontal></lua-divider-horizontal>
	<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="directions_car"
		text="Naviguer vers le patient" [isDisabled]="!patient.street || !patient.zipCode || !patient.city"
		(click)="navigatePatient()">
	</lua-list-item>
	<lua-list-item class="list-item" [isDisabled]="!patient.email" iconColor="CouleurPrimaire" textColor="Noir"
		iconName="mail" text="Envoyer un email" (click)="emailPatient()">
	</lua-list-item>
	<lua-list-item class="list-item" iconColor="CouleurPrimaire" [isDisabled]="!patient.phone" textColor=" Noir"
		iconName="phone" text="Téléphoner" (click)="phonePatient()">
	</lua-list-item>
	<lua-divider-horizontal></lua-divider-horizontal>
	<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="person_off"
		text="Patient décédé" (click)="openPanneauDeces()">
	</lua-list-item>
	<lua-list-item class="list-item" iconColor="Rouge" textColor="Rouge" [iconName]="!patient.isArchive ? 'delete' : 'undo'" [text]="!patient.isArchive ? 'Archiver' : 'Annuler l\'archivage du dossier'"
		(click)="archivePatient($event)">
	</lua-list-item>
</div>