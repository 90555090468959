<div #scrollContainer class="page-controle-facturation__container">
	<div class="page-gauche">
		<h1>Contrôle facturation</h1>
		<div class="form-controle">
			<div class="liste-controle">
				<di-separateur-date date="PATIENT"></di-separateur-date>
				<div class="patient-container">
					<ng-container *ngIf="controlesFacturation.patient">
						<di-donnee-controlee [etat]="controlesFacturation.patient.etat"
							[libellePrincipal]="controlesFacturation.patient.libellePrincipal"
							[libelleSecondaire]="controlesFacturation.patient.libelleSecondaire"
							[libelleErreur]="controlesFacturation.patient.libelleErreur" [bold]="controlesFacturation.patient.isBold"
							[onClick]="controlesFacturation.patient.onClick"></di-donnee-controlee>
					</ng-container>
				</div>
				<di-separateur-date date="ORDONNANCE"></di-separateur-date>
				<div class="ordonnance-container">
					<ng-container *ngIf="controlesFacturation.ordonnance">
						<di-donnee-controlee *ngFor="let controleOrdo of controlesFacturation.ordonnance" [etat]="controleOrdo.etat"
							[libellePrincipal]="controleOrdo.libellePrincipal" [libelleSecondaire]="controleOrdo.libelleSecondaire"
							[libelleErreur]="controleOrdo.libelleErreur" [bold]="controleOrdo.isBold"
							[onClick]="controleOrdo.onClick"></di-donnee-controlee>
					</ng-container>
				</div>
				<di-separateur-date date="SECURITE SOCIALE"></di-separateur-date>
				<div class="securite-sociale-container">
					<ng-container *ngIf="controlesFacturation.amo">
						<di-donnee-controlee *ngFor="let controleAmo of controlesFacturation.amo" [etat]="controleAmo.etat"
							[libellePrincipal]="controleAmo.libellePrincipal" [libelleSecondaire]="controleAmo.libelleSecondaire"
							[libelleErreur]="controleAmo.libelleErreur" [switchOnly]="controleAmo.isSwitchOnly"
							[checked]="controleAmo.isChecked" [bold]="controleAmo.isBold" [onClick]="controleAmo.onClick"
							[disabled]="controleAmo.isDisabled" [onToggleSwitch]="controleAmo.onToggleSwitch"></di-donnee-controlee>
					</ng-container>
				</div>
				<di-separateur-date date="MUTUELLE"></di-separateur-date>
				<div class="mutuelle-container">
					<ng-container *ngIf="controlesFacturation.amc">
						<di-donnee-controlee *ngFor="let controleAmc of controlesFacturation.amc" [etat]="controleAmc.etat"
							[libellePrincipal]="controleAmc.libellePrincipal" [libelleSecondaire]="controleAmc.libelleSecondaire"
							[libelleErreur]="controleAmc.libelleErreur" [switchOnly]="controleAmc.isSwitchOnly"
							[checked]="controleAmc.isChecked" [bold]="controleAmc.isBold" [onClick]="controleAmc.onClick"
							[disabled]="controleAmc.isDisabled" [onToggleSwitch]="controleAmc.onToggleSwitch"></di-donnee-controlee>
					</ng-container>
				</div>
				<ng-container *ngIf="isMobile">
					<di-separateur-date *ngIf="controlesFacturation.infirmiers?.length > 0" date="INFIRMIER"></di-separateur-date>
					<div class="infirmiers-container">
						<ng-container *ngIf="controlesFacturation.infirmiers">
							<di-donnee-controlee *ngFor="let controleInf of controlesFacturation.infirmiers" [etat]="controleInf.etat"
								[libellePrincipal]="controleInf.libellePrincipal" [libelleSecondaire]="controleInf.libelleSecondaire"
								[libelleErreur]="controleInf.libelleErreur" [switch]="controleInf.isSwitch"
								[checked]="controleInf.isChecked" [disabled]="controleInf.isDisabled"
								[onToggleSwitch]="controleInf.onToggleSwitch"
								[tag]="controleInf.tag"></di-donnee-controlee>
						</ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="isMobile">
					<di-separateur-date date="SOINS A FACTURER"></di-separateur-date>
					<div class="soins-container">
						<ng-container *ngIf="controlesFacturation.soinsAFacturer">
							<di-donnee-controlee *ngFor="let controleSoins of controlesFacturation.soinsAFacturer"
								[etat]="controleSoins.etat" [libellePrincipal]="controleSoins.libellePrincipal"
								[libelleSecondaire]="controleSoins.libelleSecondaire" [libelleErreur]="controleSoins.libelleErreur"
								[iconEnd]="controleSoins.iconEnd" [onClick]="controleSoins.onClick"></di-donnee-controlee>
						</ng-container>
					</div>				
				</ng-container>
			</div>
			<ng-container *ngIf="isMobile">
				<div class="action-boutons">
					<lua-action-principale libelle="Facturer" [fullWidth]="isMobile" [isDisabled]="facturationStart || !isFacturable"
						(click)="facturer($event)"></lua-action-principale>
					<lua-bouton-texte size="large" [fullWidth]="isMobile" libelle="Annuler"
						(click)="onClickCancel()"></lua-bouton-texte>
				</div>
			</ng-container>
		</div>
	</div>
	<div *ngIf="!isMobile" class="page-droite">
		<di-groupe-liste-seance 
			[seances]="seancesDisplayed" 
			[ordonnance]="ordonnance" 
			[patient]="patient"
			[facturationStart]="facturationStart"
			[isFacturable$]="isFacturable$"
			[amoCouverture]="amoCouverture"
			[amcCouverture]="amcCouverture"
			[amcPatient]="amcPatient"
			[infirmiers]="infirmiers"
			[showSelectionTitulaire]="showSelectionTitulaire"
			[onShowPanneauFacturation]="openPanneauFacturation"
			[targetSeanceId]="targetSeanceId"
			[nomPatientFormatted]="nomPatientFormatted"
			[adressePatientFormatted]="adressePatientFormatted"
			[libelleBtnDeplacement]="libelleBtnDeplacement"
			[openPanneauDeplacement]="openPanneauDeplacement"
			[filtreForm]="filtreForm"
			[onDateFacturationChange]="onDateFacturationChange"
			[filterInfirmiersSelected$]="filterInfirmiersSelected$"
			[openMenuFiltreInfirmier]="openMenuFiltreInfirmier"
			[openMenuFiltreEtat]="openMenuFiltreEtat"
			[colorFilter$]="colorFilter$"
			[filterEtatsSelected]="filterEtatsSelected"
			[factureTarifiee]="factureTarifiee"
			[showWarningTarification]="showWarningTarification"
			>
		</di-groupe-liste-seance>
	</div>
</div>