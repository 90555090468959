<div class="di-majoration" [class.disabled]="disabled">
	<lua-icone 
		color="CouleurPrimaire" 
		[mode]="getIconMode()" 
		[width]="getIconSize()" 
		[height]="getIconSize()"
		[iconName]="getMajorationDetails().iconName" 
		[customIconName]="getMajorationDetails().customIconeName"
		[isIcon]="!!getMajorationDetails().customIconeName">
	</lua-icone>
	<ng-container *ngIf="!icon">
		<p class="label">{{ getMajorationDetails().label }}</p>
		<p *ngIf="price" class="price">{{ priceLabel | number:'1.2-2' }} €</p>
	</ng-container>
</div>