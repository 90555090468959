import { Injectable } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EnumHelper } from '@osapp/helpers/enumHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { IGroupMember } from '@osapp/model/contacts/IGroupMember';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { EPrefix } from '@osapp/model/EPrefix';
import { ICacheData } from '@osapp/model/store/ICacheData';
import { IHydratedGroupMember } from '@osapp/modules/groups/model/IHydratedGroupMember';
import { ISector } from '@osapp/modules/sectors/models/isector';
import { ISite } from '@osapp/modules/sites/models/isite';
import { Site } from '@osapp/modules/sites/models/site';
import { SitesService } from '@osapp/modules/sites/services/sites.service';
import { ContactAddressPipe } from '@osapp/pipes/contactAddress.pipe';
import { ContactsService } from '@osapp/services/contacts.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { GroupsService } from '@osapp/services/groups.service';
import { merge, Observable, of, ReplaySubject } from 'rxjs';
import { map, mapTo, mergeMap, tap, toArray } from 'rxjs/operators';
import { C_PREFIX_TOURNEE } from '../../../app/app.constants';
import { ESeanceStatusIcon } from '../../../model/ESeanceStatusIcon';
import { EStatusSeance } from '../../../model/EStatusSeance';
import { IIdelizyContact } from '../../../model/IIdelizyContact';
import { Seance } from '../../../model/Seance';
import { ISeanceTournee } from '../../../model/seances/ISeanceTournee';
import { SeanceService } from '../../../services/seance.service';
import { PatientsService } from '../../patients/services/patients.service';
import { ETourneeMode } from '../model/ETourneeMode';
import { ITournee } from '../model/ITournee';

@Injectable()
export class TourneesService {

	//#region FIELDS

	/** Sujet qui renvoie le dernier résultat quand on s'abonne pour indiquer le mode de tournée. */
	private moPlanificationModeReplaySubject: ReplaySubject<ETourneeMode> = new ReplaySubject(1);

	//#endregion

	//#region METHODS

	constructor(
		private isvcSeance: SeanceService,
		private isvcContacts: ContactsService,
		private isvcEntityLink: EntityLinkService,
		private isvcGroups: GroupsService,
		private readonly isvcPatients: PatientsService,
		private readonly isvcSites: SitesService,
		private readonly ioContactAddressPipe: ContactAddressPipe<ISite>
	) { }

	/** Récupère les séances pour une date donnée.
	 * @param pdTourneeDate Date de la tournée pour laquelle récupérer les séances associées.
	 */
	public getSeancesFromDate(pdTourneeDate: Date): Observable<Seance[]>;
	/** Récupère les séances dont la date est comprise entre deux dates.
	 * @param pdMinTourneeDate Date minimum de la tournée pour laquelle récupérer les séances associées.
	 * @param pdMinTourneeDate Date maximum de la tournée pour laquelle récupérer les séances associées.
	 */
	public getSeancesFromDate(pdMinTourneeDate: Date, pdMaxTourneeDate: Date): Observable<Seance[]>;
	public getSeancesFromDate(pdTourneeDateOrMinTourneeDate: Date, pdMaxTourneeDate?: Date): Observable<Seance[]> {
		let ldMinTourneeDate: Date;
		let ldMaxTourneeDate: Date;

		if (pdMaxTourneeDate instanceof Date) {
			ldMinTourneeDate = pdTourneeDateOrMinTourneeDate;
			ldMaxTourneeDate = pdMaxTourneeDate;
		}
		else {
			if (isNaN(pdTourneeDateOrMinTourneeDate.getTime())) {
				ldMinTourneeDate = new Date();
				ldMaxTourneeDate = new Date();
			}
			else {
				ldMinTourneeDate = pdTourneeDateOrMinTourneeDate;
				ldMaxTourneeDate = new Date(pdTourneeDateOrMinTourneeDate);
			}
			ldMinTourneeDate.setHours(0, 0, 0, 0);
			ldMaxTourneeDate.setHours(24, 0, 0, 0);
		}

		return this.isvcSeance.getSeances(ldMinTourneeDate, ldMaxTourneeDate, true);
	}

	public getSeanceTourneesFromSeances(paSeances: Seance[]): Observable<ISeanceTournee[]> {
		return this.getIntervenants(paSeances)
			.pipe(
				map((paIntervenants: IGroupMember[]) => paSeances.map((poSeance: Seance) => ({
					seance: poSeance,
					intervenants: this.extractIntervenants(paIntervenants, poSeance),
					patient: this.extractPatient(paIntervenants, poSeance)
				} as ISeanceTournee))),
				mergeMap((paSeanceTournees: ISeanceTournee[]) => this.addCenterName(paSeanceTournees).pipe(mapTo(paSeanceTournees)))
			);
	}

	public addCenterName(paSeancesTournee: ISeanceTournee[]): Observable<boolean> {
		const loSeanceTourneeByPatientId: Map<string, ISeanceTournee[]> = ArrayHelper.groupBy(paSeancesTournee, (poSeanceTournee: ISeanceTournee) => poSeanceTournee.patient.groupMember._id);

		return this.isvcPatients.getPatientsSectorIds(MapHelper.keysToArray(loSeanceTourneeByPatientId)).pipe(
			mergeMap((poSectorIdByPatientId: Map<string, string>) => this.isvcGroups.getGroups(MapHelper.valuesToArray(poSectorIdByPatientId))
				.pipe(
					mergeMap((paGroups: ISector[]) => {
						const loPatientIdBySectorId: Map<string, string> = MapHelper.map(MapHelper.reverseWithKeySelector(poSectorIdByPatientId), (paPatientIds: string[]) => ArrayHelper.getFirstElement(paPatientIds));
						const loGroupsBySiteIds: Map<string, ISector[]> = ArrayHelper.groupBy(paGroups, (poGroup: ISector) => poGroup.siteId);
						loGroupsBySiteIds.get(undefined)?.forEach((poSector: ISector) =>
							loSeanceTourneeByPatientId.get(loPatientIdBySectorId.get(poSector._id))?.forEach((poSeanceTournee: ISeanceTournee) => poSeanceTournee.centerName = poSector.description)
						);
						loGroupsBySiteIds.delete(undefined); // On enlève les groupes sans site.

						return this.isvcSites.getSitesFromIds(MapHelper.keysToArray(loGroupsBySiteIds)).pipe(
							tap((paSites: Site[]) => {
								paSites.forEach((poSite: Site) => loGroupsBySiteIds.get(poSite._id).forEach((poSector: ISector) =>
									loSeanceTourneeByPatientId.get(loPatientIdBySectorId.get(poSector._id))?.forEach((poSeanceTournee: ISeanceTournee) => poSeanceTournee.centerName = this.ioContactAddressPipe.transform(poSite))
								));
							})
						);
					})
				)
			),
			mapTo(true)
		);
	}

	private getIntervenants(paSeances: Seance[]): Observable<IGroupMember[]> {
		const laContactIds: string[] = [];
		const laGroupIds: string[] = [];

		paSeances.forEach((poSeance: Seance) => {
			if (ArrayHelper.hasElements(poSeance.intervenantIds)) {
				poSeance.intervenantIds.forEach((psIntervenantId: string) => {
					if (!StringHelper.isBlank(psIntervenantId)) {
						if (IdHelper.hasPrefixId(psIntervenantId, EPrefix.contact))
							laContactIds.push(psIntervenantId);
						else
							laGroupIds.push(psIntervenantId);
					}
					else
						console.warn("TOUR.S:: intervenant non défini.", poSeance);
				});
			}
			if (!StringHelper.isBlank(poSeance.patientId))
				laContactIds.push(poSeance.patientId);
		});

		return merge(this.isvcContacts.getContactsByIds(laContactIds), this.isvcGroups.getGroups(laGroupIds))
			.pipe(
				toArray(),
				map((paIntervenants: IGroupMember[][]) => ArrayHelper.flat(paIntervenants))
			);
	}

	private extractPatient(paContacts: IGroupMember[], poSeance: Seance): IHydratedGroupMember {
		const loPatientContact: IIdelizyContact = paContacts.find((poContact: IIdelizyContact) => poContact._id === poSeance.patientId) as IIdelizyContact;

		return {
			groupMember: loPatientContact,
			avatar: loPatientContact ? ContactsService.createContactAvatar(loPatientContact) : null
		};
	}

	private extractIntervenants(paIntervenants: IGroupMember[], poSeance: Seance): IHydratedGroupMember[] {
		return paIntervenants
			.filter((poIntervenant: IGroupMember) => poSeance.intervenantIds &&
				poSeance.intervenantIds.some((psIntervenantId: string) => poIntervenant._id === psIntervenantId)
			)
			.map((poIntervenant: IGroupMember) => this.transformContactToHydratedContact(poIntervenant));
	}

	/** Retourne le tableau trié par date de début des 'ISeanceTournee'.
	 * @param paSeanceTournees Tableau à trier.
	 */
	public static sortSeanceTournees(paSeanceTournees: ISeanceTournee[]): ISeanceTournee[] {
		return paSeanceTournees.sort(
			(poItemA: ISeanceTournee, poItemB: ISeanceTournee) => DateHelper.compareTwoDates(poItemA.seance.startDate, poItemB.seance.startDate)
		);
	}

	/** Retourne un tableau contenant tous les icônes de statut de séance possibles. */
	public getAllStatusIcons(): string[] {
		return EnumHelper.getValues(ESeanceStatusIcon);
	}

	/** Récupère le nom de l'icône associé au statut de la séance.
	 * @param poSeanceTournee ISeanceTournee dont il faut récupérer l'icône associé.
	 */
	public static getStatusIcon(poSeanceTournee: ISeanceTournee): string;
	/** Récupère le nom de l'icône associé au statut.
	 * @param peStatus Statut dont il faut récupérer l'icône associé.
	 * @returns L'icône associée au statut : "canceled", "done", "pending" ; les cas "en cours" et "en retard" ne sont pas pris en compte.
	 */
	public static getStatusIcon(peStatus: EStatusSeance): string;
	public static getStatusIcon(poSeanceTourneeOrStatus: ISeanceTournee | EStatusSeance): string {
		let lsStatusIcon: string;
		const lbIsStatusParam: boolean = typeof poSeanceTourneeOrStatus === "number" || poSeanceTourneeOrStatus === undefined;
		const leStatus: EStatusSeance = lbIsStatusParam ?
			poSeanceTourneeOrStatus as EStatusSeance : (poSeanceTourneeOrStatus as ISeanceTournee).seance.status;

		if (leStatus === EStatusSeance.canceled)
			lsStatusIcon = ESeanceStatusIcon.canceled;

		// Si le statut de la séance est 'done' ou 'inProgress' alors elle est terminée et peut être en cours d'export ou déjà exportée.
		else if (leStatus === EStatusSeance.done || leStatus === EStatusSeance.inProgress)
			lsStatusIcon = ESeanceStatusIcon.done;

		else if (leStatus === EStatusSeance.completed)
			lsStatusIcon = ESeanceStatusIcon.completed;

		else if (!lbIsStatusParam &&
			DateHelper.compareTwoDates(new Date(), (poSeanceTourneeOrStatus as ISeanceTournee).seance.endDate) >= 0) {
			lsStatusIcon = ESeanceStatusIcon.late;
		}

		else if (!leStatus || leStatus === EStatusSeance.pending)
			lsStatusIcon = ESeanceStatusIcon.pending;

		return lsStatusIcon;
	}

	/** Récupère le libellé d'un état de séance en fonction de l'icône qui lui est associé.
	 * @param psIcon Nom de l'icône dont il faut récupérer un nom d'état.
	 */
	public static getStatusLabelFromIcon(psIcon: string): string {
		let lsStatusName: string;

		if (psIcon === ESeanceStatusIcon.canceled)
			lsStatusName = "Annulé";
		else if (psIcon === ESeanceStatusIcon.done)
			lsStatusName = "Validé";
		else if (psIcon === ESeanceStatusIcon.pending)
			lsStatusName = "En cours";
		else if (psIcon === ESeanceStatusIcon.completed)
			lsStatusName = "Facturé";
		else
			lsStatusName = "En retard";

		return lsStatusName;
	}

	/** Renvoie un observable indiquant le mode de tournée. */
	public getTourneeModeObservable(): Observable<ETourneeMode> {
		return this.moPlanificationModeReplaySubject.asObservable();
	}

	/** Lève un événement pour notifer les abonnés en quel mode de tournée on passe.
	 * @param peTourneeMode Indique le mode de tournée.
	 */
	public raiseTourneeModeEvent(peTourneeMode: ETourneeMode): void {
		this.moPlanificationModeReplaySubject.next(peTourneeMode);
	}

	/** Transforme un `IGroupMember` en `IHydratedGroupMember`.
	 * @param poIntervenant Intervenant à transformer.
	 */
	public transformContactToHydratedContact(poIntervenant: IGroupMember): IHydratedGroupMember {
		return {
			avatar: poIntervenant ? IdHelper.hasPrefixId(poIntervenant._id, EPrefix.contact) ?
				ContactsService.createContactAvatar(poIntervenant as IContact) : GroupsService.createGroupAvatar(poIntervenant as IGroup)
				: null,
			groupMember: poIntervenant
		} as IHydratedGroupMember;
	}

	/** Transforme un tableau de `IIdelizyContact` en tableau de `IHydratedContact`.
	 * @param paContacts Tableau de contacts à transformer.
	 */
	public transformContactsToHydratedContacts(paContacts: IIdelizyContact[]): IHydratedGroupMember[] {
		return paContacts.map((poContact: IIdelizyContact) => this.transformContactToHydratedContact(poContact));
	}

	/** Crée un objet tournée à partir d'une date.
	 * @param poDate Date de la tournée.
	 */
	public static createTournee(poDate: Date): ITournee {
		return {
			_id: IdHelper.buildId(C_PREFIX_TOURNEE, DateHelper.transform(poDate, "yyyyMMdd")),
			date: poDate,
			$cacheData: { databaseId: "none" } as ICacheData
		} as ITournee;
	}

	/** Récupère la route pour naviguer vers une tournée.
	 * @param poTournee Tournée dont on veut récupérer la route.
	 */
	public static getTourneeRoute(poTournee: ITournee): string {
		return `tournees/${DateHelper.toDateUrl(poTournee.date)}`;
	}

	/** Récupère le nom d'une tournée.
	 * @param poTournee Tournée dont on veut récupérer le nom.
	 */
	public static getTourneeName(poTournee: ITournee): string {
		return `Tournée du ${DateHelper.transform(poTournee.date, ETimetablePattern.dd_MMM_yyyy)}`;
	}

	/** Récupère un objet `Date` depuis l'identifiant d'une tournée.
	 * @param psTourneeId Identifiant de la tournée dans lequel récupérer la date.
	 */
	public static getDateFromTourneeId(psTourneeId: string): Date {
		return DateHelper.parseReverseDate(psTourneeId.replace(C_PREFIX_TOURNEE, ""));
	}

	//#endregion

}