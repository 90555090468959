import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ArrayHelper } from "@osapp/helpers";
import { ConfigData, ESecurityFlag, IHttpError, IWorkspace, UserData } from "@osapp/model";
import { motsDePasses } from "@osapp/modules/password/french_passwords";
import { PasswordService } from "@osapp/modules/password/services/password.service";
import { EPermissionsFlag } from "@osapp/modules/permissions/models/EPermissionsFlag";
import { ISite } from "@osapp/modules/sites/models/isite";
import { SitesService } from "@osapp/modules/sites/services/sites.service";
import { FlagService, SecurityService, WorkspaceService } from "@osapp/services";
import { EMPTY, Observable, throwError } from "rxjs";
import { finalize, map, mergeMap, tap } from "rxjs/operators";
import { AuthenticatorService } from "../../shared/services/authenticator.service";
import { LoaderService } from "../../shared/services/loader.service";

@Injectable({
	providedIn: "root"
})
export class ConnexionService {

	public workspaceId: string = "";

	constructor(
		private svcPassword: PasswordService,
		private svcWorkspace: WorkspaceService,
		private svcSites: SitesService,
		private router: Router,
		private svcAuthenticator: AuthenticatorService,
		private svcSecurity: SecurityService,
		private svcFlag: FlagService,
		private svcLoader: LoaderService

	) {
		ConfigData.appInfo.versionAppErgo = localStorage.getItem("versionAppErgo");
	}

	public waitForConnexionFlags(){
		this.svcFlag.waitForFlags(
			[ESecurityFlag.authenticated, 
				EPermissionsFlag.isLoaded
			], true)
			.pipe(
				tap(() => this.controleSiteWorkspace(this.workspaceId).subscribe()))
			.subscribe();
	}

	public async sendMailRequest(email: string): Promise<IHttpError | void> {
		if (!email) return Promise.resolve({ message: 'Veuillez entrer une adresse email valide.' } as IHttpError);

		try {
			await this.svcPassword.sendResetPasswordEmail(email)
				.pipe(
					tap(() => {
						this.router.navigate(['/confirmationEmail'], { queryParams: { email: email } });
					})
				)
				.toPromise();
			return null;
		} catch (error) {
			return error as IHttpError;
		}
	}

	public async verifToken(token: string): Promise<IHttpError | void> {

		if (!token) this.router.navigate(['/lienExpire']);

		try {
			const result = await this.svcPassword.verifToken(token)
				.pipe(
					tap((res: boolean) => {
						if (res)
							this.router.navigate(['/lienExpire']);
					})
				)
				.toPromise();
			return null;
		} catch (error) {
			return error as IHttpError;
		}
	}


	public isValidCriters(password: string, isReset: boolean): string[] | null {
		const validationErrors: string[] = [];

		const regexNumber: RegExp = /\d/;
		const regexMajuscule: RegExp = /[A-Z]/;
		const regexMinuscule: RegExp = /[a-z]/;
		const regexSize: RegExp = /^[^\s]{8,}$/;
		const regexSpecialCar: RegExp = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|]/;

		if (password.match(regexNumber) !== null) {
			validationErrors.push('notNumberValid');
		}
		if (password.match(regexMajuscule) !== null) {
			validationErrors.push('notMajusculeValid');
		}
		if (password.match(regexMinuscule) !== null) {
			validationErrors.push('notMinusculeValid');
		}
		if (password.match(regexSize) !== null) {
			validationErrors.push('notSizeValid');
		}
		if (password.match(regexSpecialCar) !== null) {
			validationErrors.push('notSpecialCarValid');
		}
		if (password.toLowerCase().includes(ConfigData.appInfo.login.toLowerCase())) {
			if (!isReset) {
				validationErrors.push('notDifLoginValid');
			}
		}
		if (!motsDePasses.includes(password)) {
			validationErrors.push('notCourantValid');
		}

		return validationErrors;
	}

	private hasMaxTwoOccurrencesOfEachLetter(mot: string): boolean {
		for (let i = 0; i < mot.length - 2; i++) {
			if (mot[i] === mot[i + 1] && mot[i] === mot[i + 2]) {
				return false;
			}
		}
		return true;
	}

	public hasError(errors: string[], errorCode: string): boolean {
		return !errors.includes(errorCode);
	}

	public selectWorkspace(workspaceId: string){
		this.workspaceId = workspaceId;
		this.svcSecurity.setAuthenticationStatus(true);
	}


	public controleSiteWorkspace(workspaceId: string): Observable<ISite | Error> {
		let lsDefaultSiteId: string;
		this.svcLoader.showLoader("Chargement de l'espace de travail...", "Blanc");
		return this.svcWorkspace.getWorkspaceDocument(workspaceId).
			pipe(
				tap((poWorkspace: IWorkspace) => {
					lsDefaultSiteId = poWorkspace.defaultSiteId;
					UserData.modules = poWorkspace.modules ?? [];
				}),
				mergeMap(() => this.svcSites.getUserSiteIds()),
				map((cabinetlistId: string[]) => lsDefaultSiteId ? ArrayHelper.unique([lsDefaultSiteId, ...cabinetlistId]) : cabinetlistId),
				mergeMap((cabinetlistId: string[]) => {
					if (cabinetlistId.length === 0) {
						return throwError(new Error('Aucun cabinet pour ce workspace.'));
					}

					if (cabinetlistId.length === 1) {
						const cabinetId: string = ArrayHelper.getFirstElement(cabinetlistId);
						return this.svcSites.getSite(cabinetId).pipe(
							tap((poSite: ISite) => {
								UserData.currentSite = poSite;
								UserData.currentSite.isDefaultSite = poSite._id === lsDefaultSiteId;
								this.svcAuthenticator.setAuthenticationStatus(true);
								this.router.navigate(["/home"]);
							})
						);
					}

					if (cabinetlistId.length > 1) {
						this.router.navigate(['/selectionCabinet'], { queryParams: { cabinetlistId: cabinetlistId } });
						return EMPTY;
					}

					return throwError(new Error('Chemin inattendu'));
				}),
				finalize(() => {
					this.svcLoader.hideLoader();
				})
			);
	}
}