<div class="form-ordonnances__container">
	<form [formGroup]="ordoForm" (ngSubmit)="handleClickSaveBtn()" class="form-ordo-form">
		<div class="form-ordo-body">
			<div class="form-ordonnances__body" [ngClass]="{'form-ordonnance_mobile': isMobile}">
				<div class="container_right" [ngClass]="{'container_mobile': isMobile}">
					<h2>Prescripteur</h2>
					<div class="list-item">
						<di-bouton-panneau [large]="true" [dotted]="!prescripteur" placeHolder="Sélectionnez le prescripteur"
							(click)="openPanneauMedecin()" [hasContent]="!!prescripteur">
							<di-ligne-contact *ngIf="!!prescripteur" [contact]="prescripteur" [selectable]="false">
							</di-ligne-contact>
						</di-bouton-panneau>
					</div>
					<div class="form-ordonnance">
						<h2>Ordonnance</h2>
						<div class="inputs">
							<lua-input [fullWidth]="true" placeholder="Date de prescription" label="Date de prescription" type="date"
								formControlName="prescriptionDate"></lua-input>
							<lua-select label="Origine" [options]="origineOptions" formControlName="originePrescription"></lua-select>
							<div class="switch">
								<lua-switch (clickSwitch)="toggleIsALD($event)" label="ALD" [isChecked]="isALD"
									[texteSecondaire]="texteSecondaireIsALD"></lua-switch>
							</div>
							<div class="switch">
								<lua-switch (clickSwitch)="toggleIsADC($event)" label="ADC" [isChecked]="isADC"
									[texteSecondaire]="texteSecondaireIsADC"></lua-switch>
							</div>
							<lua-input *ngIf="isADC" [fullWidth]="true" placeholder="Date de ADC" label="Date de ADC" type="date"
								formControlName="adcDate"></lua-input>
						</div>
					</div>
					<div>
						<h2>Description (optionnel)</h2>
						<lua-input label="Description de l'ordonnance (optionnel)" formControlName="description"
							placeholder="Description de l'ordonnance (optionnel)" [fullWidth]="true"></lua-input>
					</div>
				</div>
				<div class="container_left" [ngClass]="{'container_mobile': isMobile}">
					<div>
						<h2>Commentaire facturation (optionnel)</h2>
						<lua-input [multiline]="true" label="Commentaire pour la facturation (optionnel)" formControlName="commentaire"
							placeholder="Commentaire pour la facturation (optionnel)" [fullWidth]="true"></lua-input>
					</div>
					<div>
						<h2>Scan de l'ordonnance</h2>
						<div class="scan-container">
							<ng-container *ngFor="let image of this.listDoc">
								<di-carte-photo [photoFile]="image" [edit]="true" (delete)="deleteOldFile($event)"></di-carte-photo>
							</ng-container>
							<ng-container *ngFor="let image of this.listNewFile">
								<di-carte-photo [photoFile]="image" [edit]="true" (delete)="deleteNewFile($event)"></di-carte-photo>
							</ng-container>
							<lua-alerte *ngIf="isError" [filled]="true" [fullWidth]="true" type="error" iconName="info"
								[message]="errorMessage"></lua-alerte>
							<di-input-file title="Ajouter le scan de l’ordonnance"
								(inputClick)="addAttestation($event)"></di-input-file>
						</div>
					</div>
					
				</div>
			</div>
			<div class="bouttons">
				<lua-action-principale [isDisabled]="!ordoForm.valid"
					[libelle]="mode === 'add' ? 'Choix des actes' : 'Enregistrer'" endIcon="arrow_forward"
					(click)="handleClickSaveBtn()"></lua-action-principale>
				<lua-bouton-texte [fullWidth]="true" libelle="Annuler" (click)="handleAnnule()"></lua-bouton-texte>
			</div>
		</div>
	</form>
</div>