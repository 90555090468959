import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { EMPTY, Observable, combineLatest, concat, defer, merge } from 'rxjs';
import { endWith, ignoreElements, map, mergeMap, takeWhile, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { ENetworkFlag } from '../../../model/application/ENetworkFlag';
import { Database } from '../../../model/store/Database';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { IStoreReplicationResponse } from '../../../model/store/IStoreReplicationResponse';
import { IStoreReplicationToLocalResponse } from '../../../model/store/IStoreReplicationToLocalResponse';
import { IStoreReplicationToServerResponse } from '../../../model/store/IStoreReplicationToServerResponse';
import { FlagService } from '../../../services/flag.service';
import { Store } from '../../../services/store.service';
import { SyncDmsService } from '../../dms/services/syncDms.service';
import { EDatabaseSyncStatus } from '../../store/model/EDatabaseSyncStatus';
import { IResetDatabasesResult } from '../../store/model/IResetDatabasesResult';
import { ISyncronizationEvent } from '../../store/model/isyncronization-event';
import { IDatabaseGroupingConfiguration } from '../model/IDatabaseGroupingConfiguration';
import { IDatabaseSyncStatus } from '../model/IDatabaseSyncStatus';
import { IDmsSyncConfig } from '../model/IDmsSyncConfig';

export const DATABASES_GROUPING_CONFIG = new InjectionToken<IDatabaseGroupingConfiguration[]>("DATABASES_GROUPING_CONFIG");
export const DMS_SYNC_CONFIG = new InjectionToken<IDmsSyncConfig>("DMS_SYNC_CONFIG");

@Injectable()
export class DatabaseSynchroService {

	//#region FIELDS

	/** Tableau des configurations des bases de données par défaut. */
	private static readonly defaultDatabasesGroupingConfigs: IDatabaseGroupingConfiguration[] = [
		{
			roles: [EDatabaseRole.workspace, EDatabaseRole.userContext],
			title: "Espace de travail"
		},
	];

	/** Configuration des bases de données de synchro du DMS par défaut. */
	private static readonly defaultDmsSyncConfig: IDmsSyncConfig = {
		title: "Documents"
	};

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcSyncDms: SyncDmsService,
		private readonly isvcFlag: FlagService,
		@Inject(DATABASES_GROUPING_CONFIG) @Optional() private iaDatabasesGroupingConfigs?: IDatabaseGroupingConfiguration[],
		@Inject(DMS_SYNC_CONFIG) @Optional() private ioDmsSyncConfig?: IDmsSyncConfig
	) { }

	/** Récupère un tableau de configuration des bases de données. */
	public getDatabasesGroupingConfigs(): IDatabaseGroupingConfiguration[] {
		return this.iaDatabasesGroupingConfigs ?? DatabaseSynchroService.defaultDatabasesGroupingConfigs;
	}

	/** Récupère la configuration des bases de données de synchro du DMS. */
	public getDmsSyncConfig(): IDmsSyncConfig {
		return this.ioDmsSyncConfig ?? DatabaseSynchroService.defaultDmsSyncConfig;
	}

	/** Récupère un tableau de toutes les bases de données initialisées par le `Store`. */
	public getDatabases(): Database[] {
		return MapHelper.valuesToArray(this.isvcStore.getDatabases());
	}

	public static getSyncIcon(peStatus: EDatabaseSyncStatus): string {
		switch (peStatus) {
			case EDatabaseSyncStatus.upToDate:
				return "sync-checkmark";

			case EDatabaseSyncStatus.obsolete:
				return "sync-alert";

			case EDatabaseSyncStatus.synchronizing:
				return "sync-loading-circle";

			case EDatabaseSyncStatus.error:
				return "sync-close";
		};
	}

	public static getCommonStatus(paDatabasesSyncStatus: EDatabaseSyncStatus[]): EDatabaseSyncStatus {
		if (paDatabasesSyncStatus.includes(EDatabaseSyncStatus.synchronizing))
			return EDatabaseSyncStatus.synchronizing;
		if (paDatabasesSyncStatus.includes(EDatabaseSyncStatus.error))
			return EDatabaseSyncStatus.error;
		if (paDatabasesSyncStatus.includes(EDatabaseSyncStatus.obsolete))
			return EDatabaseSyncStatus.obsolete;

		return EDatabaseSyncStatus.upToDate;
	}

	public static areDatabasesStatusEquals(poDatabaseStatusA: IDatabaseSyncStatus, poDatabaseStatusB: IDatabaseSyncStatus): boolean {
		return poDatabaseStatusA?.title === poDatabaseStatusB?.title &&
			poDatabaseStatusA?.description === poDatabaseStatusB?.description &&
			poDatabaseStatusA?.status === poDatabaseStatusB?.status;
	}

	public getSyncStatus(paRoles: EDatabaseRole[]): Observable<EDatabaseSyncStatus> {
		const laDatabases: Database[] = this.getDatabases().filter((poDatabase: Database) => ArrayHelper.hasElements(ArrayHelper.intersection(poDatabase.roles, paRoles)));

		return combineLatest(laDatabases.map((poDatabase: Database) => poDatabase.syncStatus$)).pipe(
			map((paDatabasesSyncStatus: EDatabaseSyncStatus[]) => DatabaseSynchroService.getCommonStatus(paDatabasesSyncStatus))
		);
	}

	public getDatabasesCommonStatus(): Observable<EDatabaseSyncStatus> {
		return combineLatest([this.getSyncStatus(this.getGroupingConfigsRoles()), this.getDmsSyncStatus()]).pipe(
			map((paDatabasesSyncStatus: EDatabaseSyncStatus[]) => DatabaseSynchroService.getCommonStatus(paDatabasesSyncStatus)),
		);
	}

	/** Récupère le statut de synchronisation des documents du DMS (documents en attente de téléchargement ou téléversement). */
	public getDmsSyncStatus(): Observable<EDatabaseSyncStatus> {
		return this.isvcSyncDms.getPendingDownloadAndUploadDocs(true)
			.pipe(map((paPendingDocs: IStoreDocument[]) => ArrayHelper.hasElements(paPendingDocs) ? EDatabaseSyncStatus.obsolete : EDatabaseSyncStatus.upToDate));
	}

	/** Synchronise une base (montant et descendant) et un observable qui retourne un texte représentant l'avancement de la synchronisation.
	 * @param poDatabase
	 */
	public syncDatabase(poDatabase: Database): Observable<string> {
		if (poDatabase.hasLocalInstance() && poDatabase.hasRemoteInstance()) {
			return this.isvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)
				.pipe(
					tap(() => {
						poDatabase.isSynchroFromServerOnError = false;
						poDatabase.isSynchroToServerOnError = false;
					}),
					mergeMap(() => {
						const loReplicationToServer$: Observable<IStoreReplicationToServerResponse> = defer(() => {
							return this.isvcStore.replicateToServer(poDatabase.id);
						});

						const loReplicationToToLocal$: Observable<IStoreReplicationToLocalResponse> = defer(() => {
							return this.isvcStore.replicateToLocal(poDatabase.id);
						});

						return concat(
							this.execReplication$(loReplicationToServer$, poDatabase.isSynchronizingToServer$, 1),
							this.execReplication$(loReplicationToToLocal$, poDatabase.isSynchronizingFromServer$, 2)
						)
					})
				);
		}
		return EMPTY;
	}

	private execReplication$(
		poReplicationToServer$: Observable<IStoreReplicationResponse>,
		poIsSynchronizingToServer$: Observable<ISyncronizationEvent>,
		pnStepIndex: number
	): Observable<string> {
		return merge(
			poReplicationToServer$.pipe(ignoreElements(), endWith({ loaded: 100, total: 100 } as ISyncronizationEvent)), // On force l'état à 100% à la fin.
			poIsSynchronizingToServer$
		).pipe(
			takeWhile((poEvent: ISyncronizationEvent) => !poEvent || poEvent.loaded !== poEvent.total, true),
			map((poEvent: ISyncronizationEvent) => `Étape ${pnStepIndex}/2 : ${this.isvcStore.getProgressPercentageString(poEvent)}`)
		);
	}

	/** Récupère un tableau des rôles des configurations des bases de données. */
	public getGroupingConfigsRoles(): EDatabaseRole[] {
		const laRoles: EDatabaseRole[] = [];
		this.getDatabasesGroupingConfigs().forEach((poConfig: IDatabaseGroupingConfiguration) => laRoles.push(...poConfig.roles));
		return laRoles;
	}

	/** Récupère un tableau des bases de données qui ont au moins un rôle assigné dans les configurations des bases de données. */
	public getGroupingConfigsDatabases(): Database[] {
		return this.getDatabases().filter((poDatabase: Database) => ArrayHelper.hasElements(ArrayHelper.intersection(poDatabase.roles, this.getGroupingConfigsRoles())));
	}

	/** Optimise/Réinitialise des bases de données (réinitialisation après avoir remonté les modifs sur le serveur).
	 * @returns Bases de données optimisées.
	 * @throws
	 * - `NoOnlineReliableNetworkError` si pas de connexion internet,
	 * - `NoDatabaseLocalInstanceError` si pas d'instance locale pour une base de données,
	 * - `NoDatabaseRemoteInstanceError` si pas d'instance distante pour une base de données,
	 * - `autre erreur.
	 */
	public resetDatabases(): Observable<IResetDatabasesResult> {
		return this.isvcStore.resetDatabases(this.isvcStore.getDatabasesToOptimize());
	}

	//#endregion

}