import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'lua-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css'],
})
export class CheckboxComponent implements OnInit {
  @Input() iconName = '';
  @Input() disabled = false;
  @Input() label = '';
  @Input() checked = true;
  @Input() position: 'R' | 'L' = 'L';
  @Output() checkedChange = new EventEmitter<boolean>();

  isHovered = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  onMouseEnter() {
    this.isHovered = true;
  }

  onMouseLeave() {
    this.isHovered = false;
  }

  onCheckboxChange() {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
    this.cdr.detectChanges();
  }
}
