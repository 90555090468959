import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ArrayHelper, NumberHelper } from "@osapp/helpers";
import { IDayRepetition } from "@osapp/modules/event-markers/models/iday-repetition";
import { C_RANGE_REPETITION_TYPE } from "@osapp/modules/event-markers/models/range-repetition";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { forkJoin, Observable } from "rxjs";
import { finalize, map, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { Acte } from "../../../../../model/Acte";
import { EMajorationType } from "../../../../../model/EMajorationType";
import { EStatusSeance } from "../../../../../model/EStatusSeance";
import { IDeplacementByProfession } from "../../../../../model/IDeplacementByProfession";
import { Majoration } from "../../../../../model/Majoration";
import { IPatient } from "../../../../../modules/patients/model/IPatient";
import { Deplacement } from "../../../../../modules/traitement/model/Deplacement";
import { EIndemniteType } from "../../../../../modules/traitement/model/EIndemniteType";
import { Indemnite } from "../../../../../modules/traitement/model/Indemnite";
import { IdlApplicationService } from "../../../../../services/idlApplicationService.service";
import { IndemniteService } from "../../../../../services/indemnite.service";
import { StoredSeance } from "../../../../models/StoredSeance";
import { EEtatActe } from "../../enums/EEtatActe";
import { EMoments } from "../../enums/EMoments";
import { DeviceService } from "../../services/device.service";
import { LoaderService } from "../../services/loader.service";
import { SeanceService } from "../../services/seance.service";
import { SeanceRealisationService } from "../../services/seance-realisation.service";
import { EPlace } from "apps/idl/src/model/EPlace";
import { PatientsService } from "apps/idl/src/modules/patients/services/patients.service";

@Component({
	selector: "di-soins-realises",
	templateUrl: "./soins-realises.component.html",
	styleUrls: ["./soins-realises.component.scss"],
})
export class SoinsRealisesComponent extends DestroyableComponentBase implements OnInit, OnDestroy {
	@Input() public seance?: StoredSeance;
	@Input() public patient?: IPatient;
	@Input() index?: number;
	@Input() updateConcurrentSeances: (seancesConcurrentes: StoredSeance[]) => void;
	@Input() cancel: () => void;

	public isMobile: boolean = false;
	public ikForm: FormGroup;
	public horairePrecise: string = "";
	public total: number;
	public majorationsList: Majoration[] = [];
	public indemnitesForfaitairesList: Indemnite[] = [];
	public selectedMajos: Majoration[] = [];
	public selectedIndemnites: Indemnite[] = [];
	public showAllMajorations = true;
	public showAllDeplacement = true;
	public IKSelected: Indemnite;
	public abattement: number = 0;
	public deplacement: Deplacement;
	public selectedOption: string = 'IK';
	public deplacementByLC: IDeplacementByProfession[];
	public isIkChecked: boolean = false;

	public concurrentSeances: StoredSeance[] = [];
	public displayedActes: Acte[] = [];
	// Seance utilisée pour calculer les abattement d'actes de plusieurs séances
	public seanceActesSelectionnes: StoredSeance;
	// Seance utilisée pour stocker les actes sans abattement
	public seanceActesNonSelectionnes: StoredSeance;
	// True si aucun acte de la séance principale n'est sélectionné
	public confirmDisabled: boolean = true;
	public isLoaded: boolean = false;

	constructor(
		private svcDevice: DeviceService,
		private svcIndemnite: IndemniteService,
		private fb: FormBuilder,
		private svcApplication: IdlApplicationService,
		private svcSeance: SeanceService,
		private svcLoader: LoaderService,
		private svcSeanceRealisation: SeanceRealisationService,
		private svcPatient: PatientsService
	) {
		super();
	}

	ngOnInit() {
		this.svcDevice.isMobile$.pipe(
			takeUntil(this.destroyed$)
		).subscribe((flag: boolean) => {
			this.isMobile = flag;
		});

		this.svcLoader.showLoader("Chargement des soins...");
		this.initializeForm();

		this.initializeDisplayedActes().pipe(
			take(1),
			switchMap(() => forkJoin({
				deplacements: this.svcSeanceRealisation.getDeplacementsByProfession(),
				indemnites: this.svcSeanceRealisation.initializeIndemnitesForfaitairesList(this.seance.startDate),
				majorations: this.svcSeanceRealisation.initializeMajorationsList()
			})),
			tap(({ deplacements, indemnites, majorations }) => {
				this.deplacementByLC = deplacements;
				this.indemnitesForfaitairesList = indemnites;
				this.majorationsList = majorations;
			}),
			tap(() => {
				this.setDefaultIK();
				this.initializeSelectedMajorations();
				this.initializeSelectedIndemnities();
				this.calculateTotal();
			}),
			finalize(() => {
				this.svcLoader.hideLoader();
				this.isLoaded = true;
			})
		).subscribe();
	}

	private setDefaultIK(): void {
		this.IKSelected = this.svcSeanceRealisation.getIkIndemnite(this.patient);
		this.IKSelected.disabled = !this.svcSeanceRealisation.isIKEligible(this.seanceActesSelectionnes);
		this.selectedOption = this.IKSelected.type;
		this.ikForm.get('distance')?.setValue(this.IKSelected.distance);
		this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
		this.deplacement = this.svcSeanceRealisation.getDeplacement(this.IKSelected);
		this.abattement = this.svcSeanceRealisation.getAbattement(this.deplacement);
	}

	// Tri les actes affichés 
	private sortDisplayedActes(): void {
		let indexAbattement = 1;
		this.displayedActes
			.filter((acte: Acte) => this.seanceActesSelectionnes.actes.some(
				(seanceActe: Acte) => seanceActe.guid === acte.guid
			))
			.sort((a: Acte, b: Acte) => b.price - a.price)
			.forEach((acte: Acte) => {
				acte.indexAbattement = indexAbattement++;
			});

		this.displayedActes.sort((a: Acte, b: Acte) =>
			b.priceCoefficient - a.priceCoefficient ||
			a.guid.localeCompare(b.guid) ||
			a.seanceId.localeCompare(b.seanceId)
		);
	}

	// Initialise les champs du form avec les valeurs par défaut
	private initializeForm(): void {
		let hours: string;
		let minutes: string;
		const moment: IDayRepetition = this.seance.moment;
		if (this.seance.status !== EStatusSeance.done && moment?.type === C_RANGE_REPETITION_TYPE) {
			hours = moment.from.hours.toString();
			// Issue 2708: changement de l'heure du matin de 6h à 8h
			if (hours === "6") hours = "8";
			minutes = moment.from.minutes.toString();
		} else {
			const dateSeance: Date = new Date(this.seance.startDate);
			hours = dateSeance.getHours().toString();
			minutes = dateSeance.getMinutes().toString();
		}
		hours = hours.padStart(2, "0");
		minutes = minutes.padStart(2, "0");
		this.horairePrecise = `${hours}:${minutes}`;
		this.ikForm = this.fb.group({
			distance: [0],
			montant: [0],
			heure: [this.horairePrecise],
		});
	}

	// Récupère les séances concurrentes pour afficher les actes
	public initializeDisplayedActes(): Observable<StoredSeance[]> {
		const startDate = new Date(this.seance.startDate);
		return this.svcSeance.selectSeancesByDateMomentAndPatient(
			startDate,
			StoredSeance.determineMoment(this.seance.moment ?? startDate) as EMoments,
			this.seance.patientId
		).pipe(
			map((seances: StoredSeance[]) => {
				seances = seances.filter((seance: StoredSeance) =>
					this.seance.concurrentSeanceId
						? seance.concurrentSeanceId === this.seance.concurrentSeanceId
						: seance.concurrentSeanceId === undefined
				);

				seances.forEach((seance: StoredSeance) => {
					seance.actes = seance.actes.map((acte: Acte) => {
						const clonedActe = new Acte(acte);
						clonedActe.seanceId = seance._id;
						return clonedActe;
					});
				});
				this.concurrentSeances = seances.filter(seance => seance._id != this.seance._id);
				return seances;
			}),
			switchMap((seances: StoredSeance[]) => {
				this.seanceActesSelectionnes = Object.assign({}, this.seance);
				this.seanceActesNonSelectionnes = Object.assign({}, this.seance);
				this.seanceActesSelectionnes.actes = [];
				this.seanceActesNonSelectionnes.actes = [];
				seances.forEach((seance: StoredSeance) => {
					seance.actes.forEach((acte: Acte) => {
						if ((this.seance.status !== EStatusSeance.done && acte.seanceId === this.seance._id) || acte.etat === EEtatActe.done) {
							this.seanceActesSelectionnes.actes.push(acte);
							acte.etat = EEtatActe.done;
						} else {
							this.seanceActesNonSelectionnes.actes.push(acte);
						}
					})
				})
				this.setConfirmDisabled();
				return this.initializeAbattement();
			})
		);
	}

	// Calcul l'abattement des actes de chaque séance
	private initializeAbattement(): Observable<StoredSeance[]> {
		return this.svcSeanceRealisation.calculateSeanceAbatement([this.seanceActesSelectionnes], this.patient).pipe(
			tap((seances: StoredSeance[]) => {
				const seance: StoredSeance = ArrayHelper.getFirstElement(seances);
				this.displayedActes = [];
				this.concurrentSeances.forEach((seance: StoredSeance) => {
					seance.actes = [];
				});
				this.seance.actes = [];

				// Met à jour la liste des actes des séances concurrents avec l'abattement
				this.seanceActesSelectionnes = seance;
				[...this.seanceActesSelectionnes.actes, ...this.seanceActesNonSelectionnes.actes].forEach((acte: Acte) => {
					if (acte.seanceId === this.seance._id)
						this.seance.actes.push(acte)
					else
						this.concurrentSeances.find((seance: StoredSeance) => seance._id === acte.seanceId)?.actes.push(acte);
				});

				// Met à jour la liste des actes affichés dans le panneau
				this.displayedActes.push(...this.seanceActesSelectionnes.actes);
				this.displayedActes.push(...this.seanceActesNonSelectionnes.actes);
				if (this.seance.status === EStatusSeance.done) {
					this.displayedActes.forEach((acte: Acte) => {
						if (acte.etat === EEtatActe.not_done)
							acte.etat = EEtatActe.to_be_done;
					});
				}
				this.sortDisplayedActes();
			})
		)
	}

	// True si l'indemnite fait partie de celles sélectionnées
	public isSelectedInListIndem(indem: Indemnite): boolean {
		if (!ArrayHelper.hasElements(this.selectedIndemnites)) return false
		return this.selectedIndemnites.some(deplacement => deplacement.type === indem.type)
	}

	// True si la majoration fait partie de celles sélectionnées
	public isSelectedInListMajo(majo: Majoration): boolean {
		if (!ArrayHelper.hasElements(this.selectedMajos)) return false
		return this.selectedMajos.some(majoration => majoration.type === majo.type)
	}

	// Appelé au clic sur un type d'IK
	selectOption(option: string) {
		// On garde seulement les déplamcents qui ne sont pas de type IK car on va recalculer l'IK puis l'ajouter de nouveau
		this.selectedIndemnites = this.selectedIndemnites.filter((indem: Indemnite) => !indem.isIKType);
		this.IKSelected.type = this.svcIndemnite.getType(option);
		this.selectedOption = option;
		this.deplacement.sectorType = this.svcIndemnite.getSecteur(option);
		this.abattement = this.svcSeanceRealisation.getAbattement(this.deplacement);
		this.IKSelected.id = "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType;
		this.deplacement.sectorPrice = this.svcIndemnite.getDeplacementTarif(
			this.deplacementByLC.find(deplacement => deplacement.id === "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType)
		);
		this.svcIndemnite.setIndemnitePrice(this.deplacement, this.IKSelected, this.deplacementByLC);
		this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
		this.selectedIndemnites.push(this.IKSelected);
		this.calculateTotal();
	}

	// Appelé lorsqu'on saisi une distance réelle pour l'IK
	public onInputChange(distance: number) {
		// On garde seulement les déplamcents qui ne sont pas de type IK car on va recalculer l'IK puis l'ajouter de nouveau
		this.selectedIndemnites = this.selectedIndemnites.filter(indem => !indem.isIKType);
		this.deplacement.distance = distance;
		this.IKSelected.distance = distance;
		this.IKSelected.label = `(${distance}km)`;
		this.abattement = this.svcSeanceRealisation.getAbattement(this.deplacement);
		this.deplacement.sectorPrice = this.svcIndemnite.getDeplacementTarif(
			this.deplacementByLC.find(deplacement => deplacement.id === "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType)
		);
		this.svcIndemnite.setIndemnitePrice(this.deplacement, this.IKSelected, this.deplacementByLC);
		this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
		this.selectedIndemnites.push(this.IKSelected)
		this.calculateTotal();
	}

	private initializeSelectedMajorations(): void {
		this.selectedMajos = this.svcSeanceRealisation.getSelectedMajorations(this.seanceActesSelectionnes, this.patient, this.majorationsList, this.horairePrecise)
	}

	public onHoraireChange(event: Event) {
		this.initializeSelectedMajorations();
		this.initializeSelectedIndemnities();
		this.calculateTotal();
	}

	private initializeSelectedIndemnities(): void {
		this.selectedIndemnites = this.svcSeanceRealisation.getSelectedIndemnities(this.seanceActesSelectionnes, this.patient, this.indemnitesForfaitairesList);
		this.IKSelected.disabled = !this.svcSeanceRealisation.isIKEligible(this.seanceActesSelectionnes);
		this.isIkChecked = this.selectedIndemnites.some((indemnite: Indemnite) => indemnite.isIKType) && !this.IKSelected.disabled; 
	}

	// Appelé au clic sur la checkbox IK
	toggleIKSelected(): void {
		if(this.IKSelected.disabled) return;
		// Si la config de l'IK était affiché, alors on supprime l'IK de la liste des indemnités sélectionnées
		if (this.isIkChecked) {
			this.selectedIndemnites = this.selectedIndemnites.filter((indem: Indemnite) => !indem.isIKType)
		} else {
			// Sinon on rajoute le dernier type d'IK coché ou IK plaine si il n'y a pas eu d'IK coché précédement
			this.selectedIndemnites.push(this.IKSelected)
		}
		this.isIkChecked = !this.isIkChecked;
		this.calculateTotal();
	}

	toggleMajorations() {
		this.showAllMajorations = !this.showAllMajorations;
	}

	toggleCheckMajoration(event: Event, majo: Majoration) {
		// Vérifier les incompatibilités entre Dim et N1/N2
		const isSundayOrHoliday = majo.type === EMajorationType.SundayAndHolyday;
		const isNightFirstHalf = majo.type === EMajorationType.NightFirstHalf;
		const isNightSecondHalf = majo.type === EMajorationType.NightSecondHalf;

		// Retirer N1 ou N2 si Dim est sélectionné
		if (isSundayOrHoliday && (this.selectedMajos.some(a => a.type === EMajorationType.NightFirstHalf) ||
			this.selectedMajos.some(a => a.type === EMajorationType.NightSecondHalf))) {
			this.selectedMajos = this.selectedMajos.filter(a => ![EMajorationType.NightFirstHalf, EMajorationType.NightSecondHalf].includes(a.type));
		}
		// Retirer Dim si N1 ou N2 est sélectionné
		else if ((isNightFirstHalf || isNightSecondHalf) && this.selectedMajos.some(a => a.type === EMajorationType.SundayAndHolyday)) {
			this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.SundayAndHolyday);
		}

		// Gérer les incompatibilités entre MAU, MCI
		const isMau = majo.type === EMajorationType.Mau;
		const isMci = majo.type === EMajorationType.Mci;

		if (isMau) {
			// Désélectionner MIE et MCI si MAU est sélectionné
			this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.Mci);
		} else if (isMci) {
			// Désélectionner MAU si MCI est sélectionné
			this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.Mau);
		}

		// Toggle la sélection de la majoration
		if (this.selectedMajos?.some(a => a.id === majo.id)) {
			this.selectedMajos = this.selectedMajos.filter(a => a.id !== majo.id); // Retirer si déjà sélectionné
		} else {
			this.selectedMajos.push(majo); // Ajouter si pas sélectionné
		}

		// Calculer le total après modification de la sélection
		this.calculateTotal();
	}

	toggleDeplacement() {
		this.showAllDeplacement = !this.showAllDeplacement;
	}

	toggleCheckDeplacement(event: Event, depla: Indemnite) {
		if (depla.type === EIndemniteType.IFD && this.selectedIndemnites.some(a => a.type === EIndemniteType.IFI)) {
			this.selectedIndemnites = this.selectedIndemnites.filter(a => a.type !== EIndemniteType.IFI);
		}
		else if (depla.type === EIndemniteType.IFI && this.selectedIndemnites.some(a => a.type === EIndemniteType.IFD)) {
			this.selectedIndemnites = this.selectedIndemnites.filter(a => a.type !== EIndemniteType.IFD);
		}
		if (this.selectedIndemnites?.some(a => a.id === depla.id)) {
			this.selectedIndemnites = this.selectedIndemnites.filter(a => a.id !== depla.id);
		} else {
			this.selectedIndemnites.push(depla);
		}
		if (depla.type === EIndemniteType.IFI && this.selectedMajos.some(a => a.type === EMajorationType.Mau)) {
			this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.Mau);
		}
		this.calculateTotal();
	}

	updateActesStatus = (actes: Acte[]): void => {
		let allNotDone = true;
		actes.forEach((acte: Acte) => {
			const isDone = this.seanceActesSelectionnes.actes.some(
				selectedActe => selectedActe.guid === acte.guid && selectedActe.seanceId === acte.seanceId
			);
			acte.etat = isDone ? EEtatActe.done : EEtatActe.not_done;
			if (acte.etat !== EEtatActe.not_done) {
				allNotDone = false;
			}
		});
		if (allNotDone && this.concurrentSeances.length > 0) {
			actes.forEach((acte: Acte) => {
				acte.etat = EEtatActe.to_be_done;
			});
		}
	};

	public majSeance(): StoredSeance[] {
		this.updateActesStatus(this.seance.actes);
		this.concurrentSeances.forEach((seanceChild: StoredSeance) => {
			this.updateActesStatus(seanceChild.actes);
		})
		const heureRealisation = this.ikForm.get('heure')?.value;
		const IK: Indemnite = this.selectedIndemnites.find((indemnite: Indemnite) => indemnite.isIKType);
		//Mettre a jour mon patient si IK change seulement si c'est a domicile
		if (IK && this.patient.lastIkUpdate !== IK && this.seance.mePlace == EPlace.home && IK.distance) {
			this.patient.lastIkUpdate = IK;
			this.svcPatient.savePatientAnakin(this.patient).subscribe();
		}
		return this.svcSeanceRealisation.setSeancesDone(this.seance, heureRealisation, this.selectedMajos, this.selectedIndemnites, this.patient, this.concurrentSeances);
	}

	private majSeanceDelete() {
		this.seance = this.svcSeanceRealisation.setSeanceToBeDone(this.seance);
		this.concurrentSeances = this.concurrentSeances.map(seance =>
			this.svcSeanceRealisation.setSeanceToBeDone(seance)
		);
	}

	handleClickConfirm() {
		const updatedSeance: StoredSeance[] = this.majSeance();
		if (this.updateConcurrentSeances)
			this.updateConcurrentSeances(updatedSeance)
	}

	handleClickDelete() {
		this.majSeanceDelete();
		if (this.updateConcurrentSeances)
			this.updateConcurrentSeances([this.seance, ...this.concurrentSeances])
	}

	handleClickAnnule() {
		if (this.cancel)
			this.cancel();
	}

	handleCheckboxChange(event: Event, acte: Acte) {
		if (this.seanceActesSelectionnes.actes?.some(a => a.guid === acte.guid && a.seanceId === acte.seanceId)) {
			this.seanceActesSelectionnes.actes = this.seanceActesSelectionnes.actes.filter(a => a.guid !== acte.guid || a.seanceId !== acte.seanceId);
			acte.taxAllowance = 1;
			this.seanceActesNonSelectionnes.actes.push(acte);
		} else {
			this.seanceActesSelectionnes.actes.push(acte);
			this.seanceActesNonSelectionnes.actes = this.seanceActesNonSelectionnes.actes.filter(a => a.guid !== acte.guid || a.seanceId !== acte.seanceId);
		}
		this.setConfirmDisabled();
		this.initializeAbattement().pipe(
			tap(_ => {
				this.initializeSelectedMajorations();
				this.initializeSelectedIndemnities();
				this.calculateTotal();
			})
		).subscribe();
	}

	calculateTotal() {
		const acteTotal = this.seanceActesSelectionnes.actes.reduce((sum, acte) => sum + (acte?.price || 0), 0);
		const majorationTotal = this.selectedMajos.reduce((sum, majo) => sum + (majo?.price || 0), 0);
		const indemnityTotal = this.selectedIndemnites.reduce((sum, indem) => sum + (indem?.price || 0), 0);

		this.total = acteTotal + majorationTotal + indemnityTotal;
	}

	setConfirmDisabled(): void {
		this.confirmDisabled = !this.seanceActesSelectionnes.actes.some((acte: Acte) => acte.seanceId === this.seance._id);
	}
}
