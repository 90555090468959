import { Site } from '../../modules/sites/models/site';
import { WorkspaceFilter } from '../workspaces/WorkspaceFilter';
import { IUser } from './IUser';

export abstract class UserData {

	/** Utilisateur courant. */
	public static current?: IUser;
	/** Filtre des workspaces. */
	public static workspaceFilters: Array<WorkspaceFilter> = [];
	/** Site sur lequel l'utilisateur est connecté. */
	public static currentSite?: Site & { isDefaultSite?: boolean };

	public static modules?: string[];
}