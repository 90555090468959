import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: "di-bouton-surface",
	templateUrl: "./bouton-surface.component.html",
	styleUrls: ["./bouton-surface.component.scss"],
})
export class BoutonSurfaceComponent implements OnInit {
	@Input()
	public mode: "filled" | "outlined" = "filled";

	@Input()
	public title = "";

	@Input()
	public subtitle = "";

	@Input()
	public fullWidth = true;

	@Output()
	public onClick = new EventEmitter<void>();

	@Input() iconName?: string;

	@Input() customIconName?: string;

	@Input() selectionnable: boolean = false;
	@Input() selected: boolean = false;
	@Input() colorIcone?: string;

	ngOnInit(): void {
		if (this.customIconName) {
			this.iconName = '';
		}
	}

	getColorIcon() {
		return this.mode === "filled" ? "CouleurPrimaire" : "Blanc";
	}

	handleClick() {
		this.onClick.emit();
	}
}
