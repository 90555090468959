
import { IAuthentication } from '@osapp/model/IAuthentication';
import { IAppInfo } from '@osapp/model/application/IAppInfo';
import { EAuthenticationAction } from '@osapp/model/authenticator/EAuthenticationAction';
import { IBackgroundTask } from '@osapp/model/backgroundTask/IBackgroundTask';
import { ContactEntityBuilder } from '@osapp/model/entities/ContactEntityBuilder';
import { GroupEntityBuilder } from '@osapp/model/entities/GroupEntityBuilder';
import { SiteEntityBuilder } from '@osapp/model/entities/SiteEntityBuilder';
import { EFormEngine } from '@osapp/model/forms/EFormEngine';
import { Credentials } from '@osapp/model/security/Credentials';
import { EUpdateProvider } from '@osapp/model/update/EUpdateProvider';
import { IUpdateConfig } from '@osapp/model/update/IUpdateConfig';
import { DmsDocumentEntityBuilder } from '@osapp/modules/dms/model/DmsDocumentEntityBuilder';
import { C_ADMINISTRATORS_ROLE_ID, C_SECTORS_ROLE_ID } from '@osapp/modules/permissions/services/permissions.service';
import { EntityBuilder } from '@osapp/services/EntityBuilder';
import { ApplicationService } from '@osapp/services/application.service';
import { DomainService } from '@osapp/services/domain.service';
import { ISecurityConfig } from '@osapp/services/security/ISecurityConfig';
import { C_ACCES_RETROCESSION_ROLE_ID, C_AGREMENT_ROLE_ID, C_DESMOS_PERMISSION_ID, C_DESMOS_ROLE_ID } from './app/app.constants';
import { APP_CORE_DATABASE_PREFIX, APP_ID, environment } from './environments/environment';
import { DemandePreparationMedicamentsEntityBuilder } from './model/DemandePreparationMedicamentsEntityBuilder';
import { ActeEntityBuilder } from './modules/actes/ActeEntityBuilder';
import { OrdonnanceEntityBuilder } from './modules/ordonnances/models/OrdonnanceEntityBuilder';
import { TransmissionRapportEntityBuilder } from './modules/patients/TransmissionRapportEntityBuilder';
import { PatientEntityBuilder } from './modules/patients/model/PatientEntityBuilder';
import { ConstantesEntityBuilder } from './modules/patients/model/constantes/ConstantesEntityBuilder';
import { InjectionsEntityBuilder } from './modules/patients/model/constantes/InjectionsEntityBuilder';
import { PlanningRHEntityBuilder } from './modules/planning-rh/model/PlanningRHEntityBuilder';
import { TourneeEntityBuilder } from './modules/tournees/model/TourneeEntityBuilder';
import { TraitementEntityBuilder } from './modules/traitement/model/TraitementEntityBuilder';
import { IdlApplicationService } from './services/idlApplicationService.service';
import { IdlDomainService } from './services/idlDomain.service';

const defaultHearbeatIntervalMilliseconds = 60000;

export const appInfo: IAppInfo = {
	appId: APP_ID,
	formEngine: EFormEngine.formly,
	deviceId: "123",
	appName: "DESMOS Infirmiers",
	supportEmail: "sav@sephira.fr",
	supportUrl: "https://healthcare-orisha.my.site.com/login",
	firstName: "",
	lastName: "",
	login: "",
	applicationDatabaseId: `${APP_CORE_DATABASE_PREFIX}local_application_storage`,
	applicationServiceProvider: { provide: ApplicationService, useClass: IdlApplicationService },
	domainServiceProvider: { provide: DomainService, useClass: IdlDomainService },
	useLinks: true,
	unlockRequired: environment.unlockRequired,
	coreDatabasePrefix: APP_CORE_DATABASE_PREFIX
};

export * from './environments/environment';

export const authentication: IAuthentication = {
	type: "disable",
	hearbeatIntervalMilliseconds: defaultHearbeatIntervalMilliseconds,
	appCredentials: environment.appCredentials,
	credentials: new Credentials(),
	authenticatorParams: {
		_id: "",
		loginEditEnabled: true,
		newAccountEnabled: false,
		authenticationAction: EAuthenticationAction.route,
		imgEnabled: true,
		backgroundStyles: "auth",
		login: environment.credentials ? environment.credentials.login : '',
		password: environment.credentials ? environment.credentials.password : '',
		thumbnailPicture: 'img/logo-desmos-infirmiers.png',
		thumbnailIcon: 'img/desmos-infirmiers-icon.png',
		selectSite: true,
		demoEnabled: true,
		infosEnabled: true,
		initUserContactAfterAuth: true
	}
};

export const backgroundTask: IBackgroundTask = {
	execTasksIntervalMs: 30000,
	maxRepeatTask: 10
};

export const update: IUpdateConfig = {
	android: {
		updateCheckIntervalMinutes: 30,
		updateProvider: EUpdateProvider.calaoStore,
		updatesApiUrl: `${environment.cloud_url}${environment.cloud_api_apps_suffix}updates`
	},
	ios: {
		updateCheckIntervalMinutes: 30,
		updateProvider: EUpdateProvider.calaoStore,
		updatesApiUrl: `${environment.cloud_url}${environment.cloud_api_apps_suffix}updates`,
	}
};



export const entityBuilders: EntityBuilder<any>[] = [
	new ContactEntityBuilder(),
	new GroupEntityBuilder(),
	new TourneeEntityBuilder(),
	new PatientEntityBuilder(),
	new TraitementEntityBuilder(),
	new DemandePreparationMedicamentsEntityBuilder(),
	new TransmissionRapportEntityBuilder(),
	new ConstantesEntityBuilder(),
	new InjectionsEntityBuilder(),
	new ActeEntityBuilder(),
	new PlanningRHEntityBuilder(),
	new OrdonnanceEntityBuilder(),
	new DmsDocumentEntityBuilder(),
	new SiteEntityBuilder()
];

export const security: ISecurityConfig = {
	builtInRoles: {
		administrators: {
			id: C_ADMINISTRATORS_ROLE_ID,
			label: "Administrateur"
		},
		[C_SECTORS_ROLE_ID]: {
			id: C_SECTORS_ROLE_ID,
			label: "Secteur"
		},
		[C_DESMOS_ROLE_ID]: {
			id: C_DESMOS_ROLE_ID,
			label: "Desmos"
		},
		[C_AGREMENT_ROLE_ID]: {
			id: C_AGREMENT_ROLE_ID,
			label: "Agrément"
		}
	},
	permissions: {
		default: {
			contacts: {
				create: true,
				edit: true,
				delete: true,
				read: true,
				modeAgrement: false
			},
			patients: {
				create: true,
				edit: true,
				delete: true,
				read: true,
				modeAgrement: false
			}
		},
		[C_ADMINISTRATORS_ROLE_ID]: {
			parameters: {
				create: true,
				edit: true,
				delete: true,
				read: true
			}
		},
		[C_ACCES_RETROCESSION_ROLE_ID]: {
			retrocession: {
				read: true
			}
		},
		[C_DESMOS_ROLE_ID]: {
			[C_DESMOS_PERMISSION_ID]: {
				create: true,
				edit: true,
				delete: true,
				read: true
			}
		},
		[C_AGREMENT_ROLE_ID]: {
			contacts: {
				create: true,
				edit: true,
				delete: true,
				read: true,
				modeAgrement: true
			},
		}
	}
};
