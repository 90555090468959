import { Directive, ElementRef, Input, Renderer2, HostListener } from '@angular/core';

@Directive({
    selector: '[tooltip]'
})
export class TooltipDirective {
    @Input('tooltip') tooltipText = '';
    private tooltipElement!: HTMLElement;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    @HostListener('mouseenter') onMouseEnter() {
        if (!this.tooltipText) return;

        this.createTooltip();
        this.setPosition();
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.destroyTooltip();
    }

    private createTooltip() {
        this.tooltipElement = this.renderer.createElement('div');
        this.tooltipElement.textContent = this.tooltipText;

        // Tooltip styles
        this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
        this.renderer.setStyle(this.tooltipElement, 'background', '#2B184E');
        this.renderer.setStyle(this.tooltipElement, 'color', 'white');
        this.renderer.setStyle(this.tooltipElement, 'padding', '5px 8px');
        this.renderer.setStyle(this.tooltipElement, 'border-radius', '4px');
        this.renderer.setStyle(this.tooltipElement, 'font-size', '12px');
        this.renderer.setStyle(this.tooltipElement, 'white-space', 'nowrap');
        this.renderer.setStyle(this.tooltipElement, 'z-index', '1000');

        // Add the arrow element
        const arrow = this.renderer.createElement('div');
        this.renderer.setStyle(arrow, 'position', 'absolute');
        this.renderer.setStyle(arrow, 'width', '0');
        this.renderer.setStyle(arrow, 'height', '0');
        this.renderer.setStyle(arrow, 'border-left', '6px solid transparent');
        this.renderer.setStyle(arrow, 'border-right', '6px solid transparent');
        this.renderer.setStyle(arrow, 'border-bottom', '6px solid #2B184E');
        this.renderer.setStyle(arrow, 'top', '-6px'); 
        this.renderer.setStyle(arrow, 'left', '50%');
        this.renderer.setStyle(arrow, 'transform', 'translateX(-50%)');

        this.renderer.appendChild(this.tooltipElement, arrow);
        this.renderer.appendChild(document.body, this.tooltipElement);
    }

    private setPosition() {
        const hostRect = this.el.nativeElement.getBoundingClientRect();
        const tooltipRect = this.tooltipElement.getBoundingClientRect();

        const top = hostRect.bottom + window.scrollY ; 
        const left = hostRect.left + window.scrollX + (hostRect.width - tooltipRect.width) / 2; 

        this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
        this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
    }

    private destroyTooltip() {
        if (this.tooltipElement) {
            this.renderer.removeChild(document.body, this.tooltipElement);
            this.tooltipElement = undefined!;
        }
    }
}
