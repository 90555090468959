<div class="liste-contacts-container">
	<div *ngIf="displayHeader" class="liste-contacts-entete">
		<div class="liste-contacts-titre">
			<h1>Contacts</h1>
			<small>{{getNbContactsList()}}</small>
		</div>
		<div class="btn-ajout-contact">
			<lua-action-secondaire libelle="Contact" [avecIcone]="true" nomIcone="add" size="large" [fullWidth]="false"
				positionIcone="L" (click)="handleClickAddContact($event)">
			</lua-action-secondaire>
		</div>
	</div>
	<div class="liste-contacts-recherche">
		<lua-recherche [searchText]="rechercheValue" placeHolderText="Nom Prénom Finess RPPS ADELI" label="Rechercher"
			(onValueChange)="filterContacts($event)">
		</lua-recherche>
	</div>
	<div class="liste-contacts-liste">
		<ng-container *ngIf="showContactsList()">
			<div class="liste-contacts-repertoire" *ngFor="let letter of organizedLetters">
				<lua-separateur-groupe [label]="letter"></lua-separateur-groupe>
				<ng-container *ngFor="let contact of organizedContacts[letter]">
					<di-ligne-contact (clickLine)="handleLineClick($event)" [id]="contact._id"
						[selected]="selectedContactId === contact._id" [withTooltip]="true" [contact]="contact">
					</di-ligne-contact>
					<div class="liste-contacts-separateur"></div>
				</ng-container>
			</div>
		</ng-container>
		<ng-container *ngIf="!showContactsList()">
			<div>
				<lua-message-aucune-donnee (onClick)="handleClickAddContact($event)" [mainText]="mainTextAucunContact"
					iconName="call" [subText]="sousTexteAucunContact" buttonLabel="Nouveau contact"></lua-message-aucune-donnee>
			</div>
		</ng-container>
	</div>
</div>