import { IControleBase } from "./IControleBase";
import { IControleFacturation } from "./IControleFacturation";

export class ControleFacturation implements IControleFacturation {
    infirmiers: IControleBase[] = [];
    soinsAFacturer: IControleBase[] = [];
    amc: IControleBase[] = [];
    amo: IControleBase[] = [];
    patient: IControleBase;
    ordonnance: IControleBase[] = [];

    constructor() {
       this.initControle();
    }

		public initControle(){
			this.amc = [];
			this.amo = [];
			this.patient = null;
			this.ordonnance = [];
			this.infirmiers = [];
		}
}