import { AfterViewChecked, Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { NB_ITEM_TO_DISPLAY } from 'apps/idl/src/anakin/anakin.constants';
import { Traitement } from '../../../../../model/Traitement';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { StoredSeance } from 'apps/idl/src/anakin/models/StoredSeance';
import { CarteSeanceComponent } from '../seance/carte-seance.component';
import { ESeancesListMode } from '../../enums/ESeancesListMode';

@Component({
  selector: 'di-liste-seance',
  templateUrl: './liste-seances-component.html',
  styleUrls: ['./liste-seances-component.scss']
})
export class ListeSeancesComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChildren(CarteSeanceComponent) carteSeanceList: QueryList<CarteSeanceComponent>;

  @Input() ordonnance: Traitement;
  @Input() patient: IPatient;
  @Input() visibleGroupedSeances?: { date: string, seances: StoredSeance[] }[];
  @Input() pagination: boolean = false;
  @Input() targetSeanceId?: string;
  @Input() withBoutonEtat: boolean = false;
  @Input() mode: ESeancesListMode = ESeancesListMode.DETAILED

  public displayCount?: number;   // Nombre de séances à afficher
  public nbTotalSeances?: number; // Nombre total de séances
  public displayedGroupedSeances: { date: string, seances: StoredSeance[] }[] = []; // Séances groupées affichées
  private seanceScrolledTo: string = "";

  ngOnInit(): void {
    this.displayCount = this.pagination ? NB_ITEM_TO_DISPLAY : 0;
    this.nbTotalSeances = this.getTotalSeances();
    this.displayedGroupedSeances = [];
    this.updateDisplayedSeances();
  }

  ngAfterViewChecked(): void {
    // Timeout nécessaire pour pouvoir scroll jusqu'à la séance ciblée
    if (this.targetSeanceId && this.seanceScrolledTo !== this.targetSeanceId) {
      setTimeout(() => {
        this.scrollToSeance(this.targetSeanceId);
        this.seanceScrolledTo = this.targetSeanceId;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['visibleGroupedSeances'] && !changes['visibleGroupedSeances'].firstChange) {
      this.seanceScrolledTo = "";
      this.displayedGroupedSeances = [];
      this.nbTotalSeances = this.getTotalSeances();
      this.updateDisplayedSeances();
    }
  }

  scrollToSeance(targetId: string): void {
    const targetComponent = this.carteSeanceList.toArray().find((carteSeance: CarteSeanceComponent) => carteSeance.seance._id === targetId);
    if (targetComponent && targetComponent.seanceElement) {
      const targetElement = targetComponent.seanceElement.nativeElement;
      targetElement.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }

  // Compte le nombre total de séances
  getTotalSeances(): number {
    if (!this.pagination) return 0;

    return this.visibleGroupedSeances?.reduce((total, group) => {
      const seancesCount = group.seances ? group.seances.length : 0;
      return total + seancesCount;
    }, 0) || 0;
  }

  // Met à jour les séances affichées en fonction de la limite
  updateDisplayedSeances() {
    let totalDisplayed = 0;

    for (const group of this.visibleGroupedSeances) {
      if (totalDisplayed >= this.displayCount) break;

      const remainingToDisplay = this.displayCount - totalDisplayed;
      const seancesToDisplay = group.seances.slice(0, Math.max(0, remainingToDisplay));

      // Vérifie si le groupe est déjà dans la liste affichée
      const existingGroup = this.displayedGroupedSeances.find(g => g.date === group.date);
      if (existingGroup) {
        // Ajoute uniquement les nouvelles séances qui ne sont pas déjà dans le groupe
        seancesToDisplay.forEach(seance => {
          const alreadyExists = existingGroup.seances.some(s => s._id === seance._id);
          if (!alreadyExists) {
            existingGroup.seances.push(seance);
          }
        });
      } else {
        // Sinon, ajoute tout le groupe avec les séances à afficher
        this.displayedGroupedSeances.push({
          date: group.date,
          seances: seancesToDisplay
        });
      }

      totalDisplayed += seancesToDisplay.length;
    }
  }



  // Afficher plus de séances (par pas de NB_ITEM_TO_DISPLAY)
  showMore() {
    this.displayCount += NB_ITEM_TO_DISPLAY;
    this.updateDisplayedSeances();
  }

}