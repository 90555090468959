<div class="di-ligne-pause" (click)="openPanneauInterruptionTraitement()">
	<div class="left-container">
		<strong class="title">EN PAUSE
			<ng-container *ngIf="seance.dateFinPause">
				(jusqu'au {{formattedDateFinPause}})
			</ng-container>
		</strong>
		<small *ngIf="seance.commentairePause" class="comment">{{seance.commentairePause}}</small>
	</div>
	<div class="right-container">
		<lua-icone color="CouleurPrimaire" iconName="pause_circle" mode="outlined"></lua-icone>
	</div>
</div>
