import { Injectable } from '@angular/core';

@Injectable()
export abstract class SSNHelper {

    public static formatSSN(ssn: string): string {
        if(!ssn) return "";

        // On autorise les caractères alphanumériques
        ssn = ssn.toUpperCase().replace(/[^0-9A-B]/g, '');

        let numericPart = ssn.slice(0, 5) + ssn.slice(7);
        numericPart = numericPart.replace(/\D/g, '');

        let departmentPart = ssn.slice(5, 7);
        if (!/^\d{2}$|^2[A-B]$/.test(departmentPart)) {
            departmentPart = departmentPart.replace(/\D/g, '');
        }

        ssn = numericPart.slice(0, 5) + departmentPart + numericPart.slice(5);
        ssn = ssn.substring(0, 15);

        const parts = [];
        parts.push(ssn.slice(0, 1)); // Sexe (1 chiffre)
        parts.push(ssn.slice(1, 3)); // Année de naissance (2 chiffres)
        parts.push(ssn.slice(3, 5)); // Mois de naissance (2 chiffres)
        parts.push(ssn.slice(5, 7)); // Département (2 chiffres ou "2A" / "2B")
        parts.push(ssn.slice(7, 10)); // Commune (3 chiffres)
        parts.push(ssn.slice(10, 13)); // Ordre de naissance (3 chiffres)
        parts.push(ssn.slice(13)); // Clé de contrôle (2 chiffres)
        
        return parts.filter(Boolean).join(' ');
    }

    /** Calcule la clé SSN d'un numéro de sécurité sociale */
    public static getSSNKey(ssn: string): string {
        ssn = ssn.replace(/\s+/g, '');
        let ssnPrefix: string = ssn.slice(0, 13);
        // Traite le cas Corse 2A = 19 2B = 18
        ssnPrefix = ssnPrefix.replace(/2[Aa]/g, "19").replace(/2[Bb]/g, "18");
        return (97 - (+ssnPrefix % 97)).toString().padStart(2, "0");
    }

    // Vérifie la validité d'un SSN : longueur + clé
    public static verifySSN(ssn: string): boolean {
        if (!ssn) return false;

        const cleanSSN = ssn.replace(/\s+/g, '');

        const ssnRegex = /^[1-2][0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-9][0-9]|2[AB])([0-9]{6})([0-9]{2})$/i;
        if (!ssnRegex.test(cleanSSN)) {
            return false;
        }

        const expectedKey = this.getSSNKey(cleanSSN.substring(0, 13));
        const actualKey = cleanSSN.substring(13, 15);
        return expectedKey === actualKey;
    }
}