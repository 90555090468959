<div class="di-groupe-liste-seance">
	<div class="cf-entete-seances">
		<div class="cf-entete__infos-actions">
			<div class="cf-entete__infos">
				<h1>{{nomPatientFormatted}}</h1>
				<small>{{adressePatientFormatted}}</small>
				<ng-container *ngIf="seances.length === 0">
					<strong>Aucune séance</strong>
				</ng-container>
				<ng-container *ngIf="seances.length !== 0">
					<strong>{{seances.length}} séance{{seances.length > 1 ? 's' : '' }}</strong>
				</ng-container>
			</div>
			<div class="cf-entete__actions">
				<ng-container *ngIf="!isMobile">
					<lua-action-secondaire 
						[libelle]="libelleBtnDeplacement" 
						[avecIcone]="true" 
						nomIcone="directions_car" 
						size="large" 
						[fullWidth]="false"
						positionIcone="L" 
						(click)="openPanneauDeplacement()">
					</lua-action-secondaire>
					<lua-action-secondaire 
						[libelle]="libelleBtnToutRealiser" 
						[avecIcone]="true" 
						[nomIcone]="isToutRealiser ? 'check_circle' : 'circle'" 
						size="large" 
						[fullWidth]="false"
						positionIcone="L" 
						(click)="handleClickRealiser()">
					</lua-action-secondaire>
				</ng-container>
				<ng-container *ngIf="isMobile">
					<lua-bouton-icone
					iconName="directions_car"
					mode="outlined"
					(onClick)="openPanneauDeplacement()">
					</lua-bouton-icone>
					<lua-bouton-icone
					[iconName]="isToutRealiser ? 'check_box' : 'check_box_outline_blank'"
					mode="outlined"
					(onClick)="handleClickRealiser()">
					</lua-bouton-icone>
				</ng-container>
			</div>
		</div>
		<div class="cf-entete__filtres">
			<div class="filtre-seances">
				<form [formGroup]="filtreForm">
					<lua-filter-date-picker 
						label="Facturer jusqu'au" 
						[fullWidth]="isMobile"
						(valueChange)="onDateFacturationChange($event)"
						formControlName="dateFacturation" 
						[min]="ordonnance?.prescriptionDate"
						[max]="maxDateFacturation">
					</lua-filter-date-picker>
				</form>
				<lua-filtres
					[fullWidth]="isMobile" 
					[selectedFilters]="filterInfirmiersSelected" 
					(click)="openMenuFiltreInfirmier($event)" 
					label="Infirmier"
					[color]="colorFilter">
				</lua-filtres>	
				<ng-container *ngIf="!isMobile">
					<lua-filtres
						[fullWidth]="false" 
						[selectedFilters]="filterEtatsSelected" 
						(click)="openMenuFiltreEtat($event)" 
						label="Etat des séances">
					</lua-filtres>
				</ng-container>
			</div>
			<lua-filtre-groupe
				*ngIf="!isMobile"
				class="filtre_view-type"
				[filters]="seancesListOptions"
				[filterIcons]="boutonIconSeancesListOptions"
    			[defaultFilter]="seancesListMode"
				[showLibelle]="false"
				(filterSelected)="onSeancesListModeSelected($event)"
			></lua-filtre-groupe>	
		</div>
		<div *ngIf="ordonnance?.commentaire || (patient && !patient.lastIkUpdate)" class="cf-entete__alertes">
			<lua-alerte 
				*ngIf="patient && !patient.lastIkUpdate"				 
				[filled]="true" 
				[fullWidth]="true" 
				type="warning" 
				iconName="directions_car"
				message="Aucune indemnité kilométrique renseignée">
			</lua-alerte>
			<lua-alerte 
				*ngIf="ordonnance?.commentaire"
				[filled]="false" 
				[fullWidth]="true" 
				type="info" 
				iconName="info"
				[message]="ordonnance?.commentaire">
			</lua-alerte>
		</div>
	</div>
	<div class="flex-container">
		<div class="centered-container">
			<div class="flex-centered-container">
				<div class="container__content">
					<div class="di-liste-seance__container">
						<ng-container *ngIf="seances?.length > 0">
							<di-liste-seance 
								[visibleGroupedSeances]="groupedSeances" 
								[ordonnance]="ordonnance" 
								[patient]="patient" 
								[pagination]="true"
								[targetSeanceId]="targetSeanceId"
								[withBoutonEtat]="!isMobile"
								[mode]="seancesListMode">
							</di-liste-seance>
							<ng-container *ngIf="!isMobile" >
								<div class="alerte-tarification" *ngIf="showWarningTarification">
									<lua-alerte
										[fullWidth]="true"
										type="warning"
										[message]="warningTarificationMessage">
									</lua-alerte>
								</div>
								<div class="montants-tarification">
									<div class="zone-secondaire part-amo">
										<ng-container *ngIf="!factureTarifiee">
											<small>{{amoCouverture?.label || 'Part AMO'}}</small>
											<lua-loader-circulaire *ngIf="afficherLoaderTarif" [size]="35" color="Indigo"></lua-loader-circulaire>
											<strong *ngIf="!afficherLoaderTarif">-</strong>
										</ng-container>
										<ng-container *ngIf="factureTarifiee">
											<small>{{factureTarifiee.labelAMO || 'Part AMO'}}</small>
											<strong *ngIf="amoCouverture">{{factureTarifiee.totalPartAMO | priceFormat:2 }} €</strong>
											<strong *ngIf="!amoCouverture">-</strong>
										</ng-container>
									</div>
									<div class="zone-secondaire part-amc">
										<ng-container *ngIf="!factureTarifiee">
											<small>{{amcCouverture?.label || 'Part AMC'}}</small>
											<lua-loader-circulaire *ngIf="afficherLoaderTarif && amcCouverture" [size]="35" color="Indigo"></lua-loader-circulaire>
											<strong *ngIf="!afficherLoaderTarif || !amcCouverture">-</strong>
										</ng-container>
										<ng-container *ngIf="factureTarifiee">
											<small>{{factureTarifiee.labelAMC || 'Part AMC'}}</small>
											<strong *ngIf="amcCouverture">{{factureTarifiee.totalPartAMC| priceFormat:2 }} €</strong>
											<strong *ngIf="!amcCouverture">-</strong>
										</ng-container>
									</div>
									<div class="zone-secondaire part-patient">
										<small>Patient</small>
										<strong *ngIf="factureTarifiee">{{factureTarifiee.totalPartPatient| priceFormat:2 }} €</strong>
										<ng-container *ngIf="!factureTarifiee">
											<lua-loader-circulaire *ngIf="afficherLoaderTarif" [size]="35" color="Indigo"></lua-loader-circulaire>
											<strong *ngIf="!afficherLoaderTarif">-</strong>
										</ng-container> 
									</div>
								</div>
							</ng-container>							
							<div class="action-boutons">
								<lua-action-principale 
									[libelle]="libelleBtnFacturer" 
									[fullWidth]="true" 
									[isDisabled]="facturationStart || !isFacturable"
									(click)="facturer($event)">
								</lua-action-principale>
								<lua-bouton-texte 
									size="large" 
									[fullWidth]="true" 
									libelle="Annuler"
									(click)="onClickCancel()">
								</lua-bouton-texte>
							</div>				
						</ng-container>

						<ng-container *ngIf="!seances || seances.length === 0">
							<lua-message-aucune-donnee 
								mainText="Aucune séances à facturer"
								iconName="request_quote"
								subText="Essayez de modifier les filtres" 
								buttonLabel="">
							</lua-message-aucune-donnee>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
