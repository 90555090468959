<div class="entete-desktop-container" [class.menu-open]="menuOpened">
	
	<div class="entete-desktop-navbar-items" [class.isMultiWS]="workspacelist">
		<div>
			<lua-icone [isIcon]="true" customIconName="Logo_Couleur_T_Horizontal" height="60px" width="100%"></lua-icone>
		</div>
		<div class="entete-desktop-navbar-items-info-user">
			<lua-objet-cabinet [name]="siteName" [address]="siteCity"></lua-objet-cabinet>
			<div #menuUtilisateur>
				<ng-container *ngIf="contact">
					<lua-avatar (click)="openMenu()" [firstName]="firstName" [lastName]="lastName" [withTooltip]="false"
						[color]="color">
					</lua-avatar>
				</ng-container>
			</div>
		</div>
	</div>
	<div *ngIf="workspacelist" class="switchWorkspaceBandeau">
		<div (click)="switchWorkspace()" class="switchAction">
			<h4>{{nomWorkspace}}</h4>
			<lua-bouton-icone mode="outlined" iconName="keyboard_arrow_down" color="Blanc"
			></lua-bouton-icone>
		</div>
		<div class="personalisationWS">
			<lua-bouton-icone iconName="tune" color="Blanc"
			(click)="openPanneauPersonalisationWS()"></lua-bouton-icone>
		</div>
	</div>
</div>