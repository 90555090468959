export enum EApplicationEventType {
	LifeCycleEvent = 0,
	BackgroundTaskEvent,
	FlagEvent,
	StoreEvent,
	ConfigEvent,
	DmsEvent,
	SecurityEvent,
	CurrentEntityChangedEvent,
	NetworkEvent,
	databaseDocumentEvent,
	formEvent,
	loggerEvent,
	notificationEvent,
	permissionsEvent,
	contactsEvent,
	purchaseEvent
}