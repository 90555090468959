import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { IContact } from '@osapp/model';

@Component({
  selector: 'di-liste-contacts',
  templateUrl: './liste-contacts.component.html',
  styleUrls: ['./liste-contacts.component.scss'],
})
export class ListeContactsComponent implements OnInit, OnChanges {

  public rechercheValue: string = "";
  public sousTexteAucunContact: string = "Vous allez retrouver ici tous les contacts et prescripteurs créés lors de la saisie d'ordonnance.";
	public mainTextAucunContact : string = "Aucun Contact";
  public organizedContacts: { [key: string]: IContact[] } = {};
  public organizedLetters: string[] = [];
  public filteredContacts: IContact[] = [];

  @Input() public contactsList: IContact[] = [];
  @Input() public selectedContactId: string | null = null;
  @Input() public displayHeader = true;
  @Output() contactSelection: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickAddContact: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() onSearchTerm: EventEmitter<string> = new EventEmitter<string>();


  constructor() { }


  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contactsList && changes.contactsList.currentValue) {
      this.filteredContacts = this.contactsList;
      this.organizeContactsByFirstLetter();
      this.getNbContactsList();
    }
  }


  private organizeContactsByFirstLetter() {
    this.organizedContacts = this.filteredContacts.reduce((acc, contact) => {
      const firstLetter = contact.lastName.trimStart().charAt(0).toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(contact);
      return acc;
    }, {} as { [key: string]: IContact[] });

    this.organizedLetters = Object.keys(this.organizedContacts).sort();
  }

  handleLineClick(idContact: string) {
    console.log("contact sélectionné : ", idContact);
    this.selectedContactId = idContact;
    this.contactSelection.emit(idContact);
  }

  showContactsList(): boolean {
    return this.filteredContacts && this.filteredContacts.length > 0;
  }

  getNbContactsList(): string {
    const nbContacts = this.filteredContacts.length;
		if(nbContacts == 0){
			return `Aucun contact`;
		}else{
			return `${nbContacts} contact${nbContacts > 1 ? "s" : ""}`;
		}
    
  }

  handleClickAddContact(event: Event) {
    this.clickAddContact.emit(event);
  }

  filterContacts(newValue: string) {
    if (newValue.length < 3 && newValue.length !== 0) return;

    if (newValue.length > 2) {
      this.rechercheValue = newValue;
      const searchTerm = this.rechercheValue.toLowerCase();

      this.filteredContacts = this.contactsList.filter(contact =>
        contact.lastName.toLowerCase().includes(searchTerm) ||
        contact.firstName.toLowerCase().includes(searchTerm) ||
        (contact.lastName + ' ' + contact.firstName).toLowerCase().includes(searchTerm) ||
				contact.finess?.includes(searchTerm) ||
				contact.rpps?.includes(searchTerm) ||
				contact.adeli?.toLowerCase().includes(searchTerm) 
      );
			if(this.filteredContacts.length == 0)
				this.mainTextAucunContact = "Aucun contact trouvé";
    } else if (newValue.length === 0) {
      this.filteredContacts = this.contactsList;
    }
    this.organizeContactsByFirstLetter();
    this.getNbContactsList();
    this.onSearchTerm.emit(newValue);
  }
}
