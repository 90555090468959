import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { ChangelogsModule } from '@osapp/modules/about/changelogs/changelogs.module';
import { BatteryModule } from '@osapp/modules/battery/battery.module';
import { ConflictsModule } from '@osapp/modules/conflicts/conflicts.module';
import { IDatabaseGroupingConfiguration } from '@osapp/modules/database-synchro/model/IDatabaseGroupingConfiguration';
import { DATABASES_GROUPING_CONFIG, DMS_SYNC_CONFIG } from '@osapp/modules/database-synchro/services/database-synchro.service';
import { DeeplinkModule } from '@osapp/modules/deeplink/deeplink.module';
import { GuidModule } from '@osapp/modules/guid/guid.module';
import { ShortGuidBuilder } from '@osapp/modules/guid/models/short-guid-builder';
import { PatchModule } from '@osapp/modules/patch/patch.module';
import { Sqlite2ToSqliteEvcoreExtbuildFreePatch } from '@osapp/modules/patch/patches/Sqlite2ToSqliteEvcoreExtbuildFreePatch';
import { PermissionsModule } from '@osapp/modules/permissions/permissions.module';
import { PrestationModule } from '@osapp/modules/prestation/prestation.module';
import { OsappModule } from '@osapp/osapp.module';
import * as constants from '../config';
import { CustomModule } from '../modules/customElements.module';
import { FacturationModule } from '../modules/facturation/facturation.module';
import { IdlPrestationIdBuilder } from '../modules/facturation/models/idl-prestation-id-builder';
import { IdlDmsModule } from '../modules/idl-dms/idl-dms.module';
import { SeancesModule } from '../modules/seances/seances.module';
import { AnakinRoutingModule } from './anakin-routing.module';
import { AnakinComponent } from './anakin.component';
import { ActeModule } from './features/shared/components/acte/acte.module';
import { EnteteDesktopModule } from './features/shared/components/entete-desktop/entete-desktop.module';
import { EnteteMobileModule } from './features/shared/components/entete-mobile/entete-mobile.module';
import { SidemenuDesktopModule } from './features/shared/components/sidemenu-desktop/sidemenu-desktop.module';
import { SeparateurDateModule } from "./features/transmissions/components/separateur-date/separateur-date.module";
import { AppInfoModule } from './pages/app-info/app-info.module';
import { AuthenticatorModule } from './pages/authenticator';
import { HomePageModule } from './pages/home/home.module';

const databasesGroupingConfigs: IDatabaseGroupingConfiguration[] = [
	{
		roles: [EDatabaseRole.workspace, EDatabaseRole.userContext],
		title: "Données patients",
		description: "contacts, patients, traitements, ..."
	}
];

@NgModule({
	declarations: [
		AnakinComponent
	],
	imports: [
		AnakinRoutingModule,
		PatchModule.forRoot([Sqlite2ToSqliteEvcoreExtbuildFreePatch]),
		BrowserAnimationsModule,
		IonicModule.forRoot(),
		OsappModule,
		GuidModule.forRoot({ builderType: ShortGuidBuilder }),
		FormsModule,
		ReactiveFormsModule,
		CustomModule,
		DeeplinkModule,
		SeancesModule,
		ServiceWorkerModule.register("ngsw-worker.js", { enabled: true, registrationStrategy: "registerImmediately" }),
		IdlDmsModule,
		ConflictsModule,
		PermissionsModule,
		BatteryModule,
		PrestationModule.forRoot({ idBuilderType: IdlPrestationIdBuilder }),
		ChangelogsModule,
		HomePageModule,
		SeparateurDateModule,
		AuthenticatorModule,
		EnteteMobileModule,
		EnteteDesktopModule,
		ActeModule,
		SidemenuDesktopModule,
		AppInfoModule,
		FacturationModule,
	],
	bootstrap: [AnakinComponent],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: DATABASES_GROUPING_CONFIG, useValue: databasesGroupingConfigs },
		{ provide: DMS_SYNC_CONFIG, useValue: { title: "Documents", description: "ordonnances, photos, ..." } },
		constants.appInfo.applicationServiceProvider,
		constants.appInfo.domainServiceProvider
	]
})
export class AnakinModule {
	constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
		matIconRegistry.addSvgIcon(
			'Logo_Blanc',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/Logo_Blanc.svg')
		);
		matIconRegistry.addSvgIcon(
			'Logo_Couleur',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/Logo_Couleur.svg')
		);
		matIconRegistry.addSvgIcon(
			'Logo_Blanc_T_Horizontal',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/Logo_Blanc_T_Horizontal.svg')
		);
		matIconRegistry.addSvgIcon(
			'Logo_Blanc_T_Vertical',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/Logo_Blanc_T_Vertical.svg')
		);
		matIconRegistry.addSvgIcon(
			'Logo_Couleur_T_Horizontal',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/Logo_Couleur_T_Horizontal.svg')
		);
		matIconRegistry.addSvgIcon(
			'Logo_Couleur_T_Vertical',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/Logo_Couleur_T_Vertical.svg')
		);
		matIconRegistry.addSvgIcon(
			'femme',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/femme.svg')
		);
		matIconRegistry.addSvgIcon(
			'homme',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/homme.svg')
		);
		matIconRegistry.addSvgIcon(
			'mau',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/majoration-acte-unique.svg')
		);
		matIconRegistry.addSvgIcon(
			'mci',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/majoration-coordination-infirmier.svg')
		);
		matIconRegistry.addSvgIcon(
			'md',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/majoration-dimanche.svg')
		);
		matIconRegistry.addSvgIcon(
			'mie',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/majoration-jeune-enfants.svg')
		);
		matIconRegistry.addSvgIcon(
			'mip',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/majoration-infirmier-pratique-avancee.svg')
		);
	}
}
