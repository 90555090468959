import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ArrayHelper, DateHelper } from '@osapp/helpers';
import { EPrefix, IContact } from '@osapp/model';
import { IViewCountResult } from '@osapp/model/IViewCountResult';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService, GroupsService } from '@osapp/services';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IPatient } from '../../../modules/patients/model/IPatient';
import { PatientsService } from '../../../modules/patients/services/patients.service';
import { NB_ITEM_TO_DISPLAY } from '../../anakin.constants';
import { FiltreInfirmiersComponent } from '../../features/facturation/components/filtres/filtre-infirmiers/filtre-infirmiers.component';
import { EProfile } from '../../features/shared/enums/EProfile';
import { DeviceService } from '../../features/shared/services/device.service';
import { DrawerPopoverService } from '../../features/shared/services/drawer-popover.service';
import { LoaderService } from '../../features/shared/services/loader.service';
import { SeanceService } from '../../features/shared/services/seance.service';

@Component({
  selector: 'di-facturation',
  templateUrl: './facturation.page.html',
  styleUrls: ['./facturation.page.scss'],
})
export class FacturationPage extends DestroyableComponentBase implements OnInit {

  public ordoActiveCountMessage: string;
  public dateFacturation: Date = new Date();
  public facturationfilters: Array<string> = ["À FACTURER", "FACTURÉES"];
  public defaultFilter = this.facturationfilters[0];
  public ordonnances: Traitement[] = [];
  public filteredOrdonnance: Traitement[] = [];
  public isMobile: boolean = false;
  public displayCount: number = NB_ITEM_TO_DISPLAY;
  public patientsMap: Map<string, IPatient> = new Map();
  public filtreForm: FormGroup;
  public searchedValue: string = "";
  public aucuneOrdonnance: boolean = false;
  public MainTextNoResult: string = "Aucune ordonnance";
  public SubTextNoResult: string = "Vous allez retrouver ici toutes les ordonnances patients en cours.";
  public countSeancesByStatus: IViewCountResult[];
  public countSeancesByDateFacturation: IViewCountResult[];
  public maxDate: Date = new Date();

  public filterInfirmiersSelected: string[] = [];
  public infirmiers: IContact[] = [];
  public idsInfirmiersSelected: string[] = [];
	public colorFilter : string = "Indigo";
  public mapOrdonnanceInfirmiers: Map<string, string[]> = new Map(); 

  constructor(
    private svcTraitement: TraitementService,
    private svcDevice: DeviceService,
    private fb: FormBuilder,
    private router: Router,
    private svcLoader: LoaderService,
    private svcPatient: PatientsService,
    private svcSeance: SeanceService,
    private svcContact: ContactsService,
    private svcDrawerPopover: DrawerPopoverService,
		private svcGroupe : GroupsService
  ) { super(); }

  ngOnInit() {
    this.filtreForm = this.fb.group({
      dateFacturation: [this.dateFacturation ? this.dateFacturation : new Date(), []],
    });

    // Actualise la liste des ordonnances quand l'une d'elles a été supprimées
    this.svcTraitement.deletion$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadListOrdonnances();
    });

    this.svcDevice.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flag: boolean) => {
        this.isMobile = flag;
      });
    this.loadListOrdonnances();
  }

  private loadListOrdonnances() {
    this.svcLoader.showLoader();

    // On exécute les requêtes en parallèle, take(1) permet à chaque requête d'indiquer au forkJoin qu'elle vient de se terminer (car les requêtes peuvent être longues)
    forkJoin({
      traitements: this.svcTraitement.getAllTraitementsOrdonnances().pipe(
        take(1)
      ),
      countSeancesGroupByStatusAndDateFacturation: this.svcSeance.countSeancesGroupByStatusAndDateFacturation().pipe(
        take(1)
      ),
      contacts: this.svcContact.getSiteContactsAnakin([], EPrefix.contact, true, true).pipe(
        take(1)
      ),
      seancesGroupByInfirmier: this.svcSeance.selectSeancesGroupByInfirmier().pipe(
        take(1)
      )
    }).pipe(
      takeUntil(this.destroyed$),
			switchMap((data) =>
				this.svcGroupe.getContactsGroups(data.contacts.map((infi: IContact) => infi._id)).pipe(
					map((infiGroups) => {
						data.contacts = data.contacts.map((inf: IContact) => {
							const groups = infiGroups.get(inf._id) || [];
							if (groups.some(group => group._id === 'grp_remplacant')) {
								inf.profiles = ArrayHelper.hasElements(inf.profiles)
									? Array.from(new Set([...inf.profiles, EProfile.remplacant]))
									: [EProfile.remplacant];
							}
							return inf;
						});
						return data;
					})
				)
			),
      tap(({ traitements, countSeancesGroupByStatusAndDateFacturation, contacts, seancesGroupByInfirmier }: {
        traitements: Traitement[];
        countSeancesGroupByStatusAndDateFacturation: IViewCountResult[];
        contacts: IContact[];
        seancesGroupByInfirmier: IViewCountResult[];
      }) => {
        this.ordonnances = traitements;
        this.countSeancesByDateFacturation = countSeancesGroupByStatusAndDateFacturation;
        this.setCountsByDateFacturation();
        this.infirmiers = contacts.filter(contact => contact.userId);
        seancesGroupByInfirmier.forEach((result: IViewCountResult) => {
          const ordonnanceId: string = result.key[0];
          const infirmierId: string = result.key[1] as string;
          if (this.mapOrdonnanceInfirmiers.has(ordonnanceId)) {
            this.mapOrdonnanceInfirmiers.get(ordonnanceId).push(infirmierId);
          } else {
            this.mapOrdonnanceInfirmiers.set(ordonnanceId, [infirmierId]);
          }
        });
      }),
      switchMap(() => this.svcSeance.getSeancesCountsForOrdonnances(this.ordonnances).pipe(
        tap(({ ordonnances, countSeancesByStatus }) => {
          this.ordonnances = ordonnances;
          this.countSeancesByStatus = countSeancesByStatus;
        })
      )),
      switchMap(() => {
        const mapOrdoPatients = this.ordonnances.map((ordonnance: Traitement) => Traitement.extractPatientId(ordonnance._id)).filter(id => id);
        const setOrdoPatients = new Set(mapOrdoPatients)
        const patientIds = Array.from(setOrdoPatients);
        return this.svcPatient.getPatientsByIds(patientIds);
      }),
      tap((patients: IPatient[]) => {
        this.patientsMap = new Map(patients.map(patient => [patient._id, patient]));
        this.ordonnances.forEach((ordonnance: Traitement) => {
          ordonnance.patient = this.patientsMap.get(Traitement.extractPatientId(ordonnance._id))
        })
        this.applyFilter();
        this.getNbOrdonnance();
        this.aucuneOrdonnance = this.ordonnances.length === 0;
        if (this.aucuneOrdonnance) {
          this.MainTextNoResult = "Aucune ordonnance";
          this.SubTextNoResult = "Vous allez retrouver ici toutes les ordonnances par patient.";
        }
        this.svcLoader.hideLoader();
      }),
      catchError(() => {
        this.svcLoader.hideLoader();
        return of(null);
      })
    ).subscribe();
  }

  private applyFilter(): void {
    this.filteredOrdonnance = [...this.ordonnances.filter((ordonnance: Traitement) => {
      const treatmentEntries = this.countSeancesByStatus.filter((traitement: IViewCountResult) =>
        traitement.key[0] === ordonnance._id
      );
      if (this.defaultFilter === "À FACTURER") {
        if (
          this.idsInfirmiersSelected.length > 0 
          && (!this.mapOrdonnanceInfirmiers.has(ordonnance._id) || this.mapOrdonnanceInfirmiers.get(ordonnance._id)?.every((infimierId: string) => !this.idsInfirmiersSelected.includes(infimierId)))
        ) { 
          return false; 
        }
        // A facturer : ordonnance qui ont au moins une séance to_be_done ou done
        return treatmentEntries.length === 0 || treatmentEntries.some((entry) => entry.key[1] === 2 || entry.key[1] === 6)
      } else {
        // Facturées : ordonnanes qui n'ont pas de séances to_be_done ni done
        return treatmentEntries.length > 0 && treatmentEntries.every((entry) => entry.key[1] !== 2 && entry.key[1] !== 6)
      }
    })];

    this.filteredOrdonnance.sort((a, b) => {
      return (b.createDate as Date).getTime() - (a.createDate as Date).getTime();
    });
    this.getNbOrdonnance();
  }

  changeFilter(filtreSelected: string) {
    this.defaultFilter = filtreSelected;
    this.applyFilter();
    if (this.searchedValue && this.searchedValue.length > 2)
      this.filterOrdonnances(this.searchedValue);
  }

  onValueChange(newDate: string) {
    const newDateFormated = new Date(newDate);
    this.dateFacturation = newDateFormated;
    this.filtreForm.setValue({
      dateFacturation: newDateFormated,
    });
    this.setCountsByDateFacturation();
    this.applyFilter();
  }

  handleFacturer = (idOrdonnance: string): void => {
    if (!idOrdonnance) return;
    this.router.navigate(["facturation", "controle", idOrdonnance], { state: { dateFacturation: this.dateFacturation } });
  }

  showMore() {
    this.displayCount += NB_ITEM_TO_DISPLAY;
  }

  public filterOrdonnances(searchValue: string) {
    this.searchedValue = searchValue.trim().toLowerCase();
    if (this.searchedValue && this.searchedValue.length > 2) {
      this.applyFilter();
			const searchTerms = this.searchedValue.split(/\s+/).filter(term => term);

      this.filteredOrdonnance = this.filteredOrdonnance.filter(ordonnance => {
        const patient = this.patientsMap.get(Traitement.extractPatientId(ordonnance._id));
        if (!patient) return false;

        const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
				const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';
				const usualLastName = patient.usualLastName ? patient.usualLastName.toLowerCase() : '';


				const fullName = `${firstName} ${usualLastName} ${lastName}`.trim();

				return searchTerms.every(term => fullName.includes(term));
      });
    } else {
      if (searchValue === "") {
        this.applyFilter();

      }
    }
    this.aucuneOrdonnance = this.filteredOrdonnance.length === 0;
    if (this.aucuneOrdonnance) {
      this.MainTextNoResult = "Aucune ordonnance trouvée";
      this.SubTextNoResult = "Essayer de modifier votre recherche";
    }
    this.getNbOrdonnance();
  }

  public getNbOrdonnance(): void {
    const nbOrdoActive = this.filteredOrdonnance.length;
    if (nbOrdoActive == 0) {
      this.ordoActiveCountMessage = "Aucune ordonnance";
    }
    else {
      const filterString = nbOrdoActive > 1
        ? this.defaultFilter.toLowerCase()
        : (
          this.defaultFilter === "FACTURÉES"
            ? this.defaultFilter.toLowerCase().slice(0, -1)
            : this.defaultFilter.toLowerCase()
        )
      this.ordoActiveCountMessage = `${nbOrdoActive} ordonnance${nbOrdoActive > 1
        ? `s ${filterString}`
        : ` ${filterString}`}`;
    }
  }

  public showOrdonnanceList() {
    return this.filteredOrdonnance.length > 0;
  }

  private setCountsByDateFacturation() {
    const calculateCountByDateAndStatus = (ordonnance: Traitement, status: number) =>
      this.countSeancesByDateFacturation
        .filter((traitement: IViewCountResult) =>
          traitement.key[0] === ordonnance._id &&
          new Date(traitement.key[2]) <= DateHelper.fillDay(this.dateFacturation) &&
          traitement.key[1] === status
        )
        .reduce((total, traitement) => total + traitement.value, 0);

    this.ordonnances = this.ordonnances.map((ordonnance: Traitement) => {
      ordonnance.countSeancesDoneByDateFacturation = calculateCountByDateAndStatus(ordonnance, 2);
      ordonnance.countSeancesToBeDoneByDateFacturation = calculateCountByDateAndStatus(ordonnance, 6);
      ordonnance.countSeancesCancelledByDateFacturation = calculateCountByDateAndStatus(ordonnance, 3);
      ordonnance.countSeancesPausedByDateFacturation = calculateCountByDateAndStatus(ordonnance, 7);
      return ordonnance;
    });
  }

  openMenuFiltreInfirmier(event: Event) {
      this.svcDrawerPopover.open(
        "",
        this.isMobile ? "250px" : "",
        event.currentTarget,
        FiltreInfirmiersComponent,
        {
          infirmiers: this.infirmiers,
          idsInfirmiersSelected: this.idsInfirmiersSelected,
          onFilter: this.selectionNewInfirmiers
        }
      );
    }

  selectionNewInfirmiers = (idsInfirmiers: string[]): void => {
    this.idsInfirmiersSelected = idsInfirmiers;
    this.filterInfirmiersSelected = this.infirmiers
      .filter(x => this.idsInfirmiersSelected.includes(x._id))
      .map(x => {
				if(this.idsInfirmiersSelected.length === 1)
					this.colorFilter = x.avatarCouleur ?? "Indigo"
				const firstLetter = x.firstName?.trimStart().charAt(0).toUpperCase() || "";
        return `${firstLetter}${x.firstName?.slice(1) || ""} ${x.lastName.toUpperCase()}`;
      });
    this.svcDrawerPopover.close();
    this.applyFilter();
  }
}