import { Injectable } from "@angular/core";
import { ArrayHelper, DateHelper } from "@osapp/helpers";
import { EDatabaseRole, EPrefix, ETimetablePattern, IDataSource, IStoreDataResponse } from "@osapp/model";
import { IViewCountResult } from "@osapp/model/IViewCountResult";
import { IDataSourceViewCount } from "@osapp/model/store/IDataSourceViewCount";
import { IDayRepetition } from "@osapp/modules/event-markers/models/iday-repetition";
import { Store } from "@osapp/services";
import { Acte } from "apps/idl/src/model/Acte";
import { EStatusSeance } from "apps/idl/src/model/EStatusSeance";
import { ITraitement } from "apps/idl/src/model/ITraitement";
import { Traitement } from "apps/idl/src/model/Traitement";
import { IRetrocession } from "apps/idl/src/modules/patients/model/IRetrocession";
import { TraitementService } from "apps/idl/src/services/traitement.service";
import { Observable, ReplaySubject, Subject, of, range } from "rxjs";
import { concatMap, defaultIfEmpty, delay, map, mergeMap, takeWhile, tap } from "rxjs/operators";
import { IModifActesSeance } from "../../../models/IModifActesSeance";
import { SeanceKey } from "../../../models/SeanceKey";
import { StoredSeance } from "../../../models/StoredSeance";
import { EMoments } from "../enums/EMoments";

@Injectable({
    providedIn: "root"
})
export class SeanceService {

    constructor(private svcStore: Store, private svcTraitement: TraitementService) {
    }

    private refreshSeanceSubject = new Subject<void>();
    refreshSeanceList$ = this.refreshSeanceSubject.asObservable();

    private seanceUpdatedSubject = new ReplaySubject<Date>(1);
    seanceUpdated$: Observable<Date> = this.seanceUpdatedSubject.asObservable();

    triggerSeanceUpdated(dateSeance: Date): void {
        this.seanceUpdatedSubject.next(dateSeance);
    }

    clearSeanceUpdated(): void {
        this.seanceUpdatedSubject = new ReplaySubject<Date>(1);
        this.seanceUpdated$ = this.seanceUpdatedSubject.asObservable();
    }

    triggerRefreshSeanceList() {
        this.refreshSeanceSubject.next();
    }

    public selectSeancesByTraitement(traitementId: string, withFacturee: boolean = false): Observable<StoredSeance[]> {
        const dataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: `seances/by_traitement${withFacturee ? "" : "_without_facturee"}`,
            viewParams: {
                key: traitementId
            },
        };
        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances))
        );
    }

    public selectLastSeanceDoneByTraitement(traitementId: string): Observable<StoredSeance> {
        const dataSource = this.createSeanceDataSource(
            (seance: StoredSeance) => (seance.traitementId === traitementId && (seance.status === EStatusSeance.done || seance.status === EStatusSeance.completed))
        );
        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances)),
            map(sortedSeances => {
                return ArrayHelper.getLastElement(sortedSeances)
            })
        );
    }


    // Récupère les séances qui commencent à la date donnée
    public selectSeancesByDate(date: Date, withFacturee: boolean = false): Observable<StoredSeance[]> {
        const startOfDay = DateHelper.transform(date, ETimetablePattern.isoFormat_hyphen) + "T00:00:00.000Z";
        const endOfDay = DateHelper.transform(date, ETimetablePattern.isoFormat_hyphen) + "T23:59:59.000Z";
        const dataSource: IDataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: `seances/by_date${withFacturee ? "" : "_without_facturee"}`,
            viewParams: {
                startkey: startOfDay,
                endkey: endOfDay
            }
        };
        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances))
        );
    }

    // Récupère les séances qui commencent à la date donnée
    public selectSeancesByDateMomentAndPatient(date: Date, moment: EMoments, patientId: string): Observable<StoredSeance[]> {
        return this.selectSeancesByDate(date).pipe(
            map((seances: StoredSeance[]) => {
                return seances.filter((seance: StoredSeance) => {
                    const seanceMoment: EMoments = StoredSeance.determineMoment(seance.moment ?? new Date(seance.startDate)) as EMoments;
                    return seance.patientId === patientId && seanceMoment === moment
                })
            })
        )
    }

    // Récupère les séances validées en même temps que la séance donnée
    public selectSeancesByConcurrentSeanceId(seanceId: string, withFacturee: boolean = false): Observable<StoredSeance[]> {
        const dataSource: IDataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: `seances/by_concurrent_seance_id${withFacturee ? "" : "_without_facturee"}`,
            viewParams: {
                key: seanceId
            }
        };
        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances))
        );
    }


    // Récupère les séances qui commencent sur l'intervalle de date donné
    public selectSeancesByRange(startDate: Date, endDate: Date, withFacturee: boolean = false): Observable<StoredSeance[]> {
        const startDateString = DateHelper.transform(startDate, ETimetablePattern.isoFormat_hyphen) + "T00:00:00.000Z";
        const endDateSting = DateHelper.transform(endDate, ETimetablePattern.isoFormat_hyphen) + "T23:59:59.000Z";
        const dataSource: IDataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: `seances/by_date${withFacturee ? "" : "_without_facturee"}`,
            viewParams: {
                startkey: startDateString,
                endkey: endDateSting
            }
        };
        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances))
        );
    }

    // Récupère les séances pour une rétrocession donnée
    public selectSeancesByRetrocession(retrocession: IRetrocession): Observable<StoredSeance[]> {
        return this.selectSeancesByRange(retrocession.dateDebut, retrocession.dateFin, true).pipe(
            map((seances: StoredSeance[]) => seances.filter((seance: StoredSeance) => seance.infirmierId === retrocession.infirmierId && (seance.status === 2 || seance.status === 5)))
        )
    }

    // Récupère les séances affectés à l'infirmier ainsi que celles non affectées
    public selectSeancesByInfirmier(infirmierId: string, withFacturee: boolean = false): Observable<StoredSeance[]> {
        const dataSource: IDataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: `seances/by_infirmier${withFacturee ? "" : "_without_facturee"}`,
            viewParams: {
                key: infirmierId
            }
        };
        return this.svcStore.get<StoredSeance>(dataSource);
    }

    // Récupère pour chaque ordonnance et infirmier le nombre de séances qui lui sont attribuées
    public selectSeancesGroupByInfirmier(): Observable<IViewCountResult[]> {
        const dataSource: IDataSourceViewCount = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/count_by_traitement_infirmier",
            viewParams: {
                group: true
            }
        };
        return this.svcStore.getGroupedDocumentCountFromView<StoredSeance>(dataSource, ArrayHelper.getFirstElement(dataSource.databasesIds));
    }

    // Récupère les séances affectés à l'infirmier ainsi que celles non affectées pour un jour donné
    public selectSeancesByDateAndInfirmier(date: Date, infirmierIds: string[]): Observable<StoredSeance[]> {
        const startOfDay = DateHelper.resetDay(date);
        const endOfDay = DateHelper.fillDay(date);
        const dataSource = this.createSeanceDataSource(
            (seance: StoredSeance) => (
                (
                    (seance.infirmierId && infirmierIds.some(id => seance.infirmierId.includes(id))) ||
                    !seance.infirmierId
                )
                && DateHelper.isBetweenTwoDates(seance.startDate, startOfDay, endOfDay)
            ));
        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances))
        );
    }



    // Récupère les séances sur une plage de date donnée en fonction de l'infirmier choisi
    public selectSeancesByRangeAndInfirmier(startDate: Date, endDate: Date, infirmierId: string): Observable<StoredSeance[]> {
        const dataSource = this.createSeanceDataSource(
            (seance: StoredSeance) => ((seance.infirmierId === infirmierId || seance.infirmierId === "") && DateHelper.isBetweenTwoDates(seance.startDate, DateHelper.resetDay(startDate), DateHelper.fillDay(endDate)))
        );
        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances))
        );
    }


    private createSeanceDataSource(filterFn: (seance: StoredSeance) => boolean): IDataSource {
        return {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewParams: {
                include_docs: true,
                startkey: `${EPrefix.seance}`,
                endkey: `${EPrefix.seance}${Store.C_ANYTHING_CODE_ASCII}`,
            },
            filter: filterFn
        };
    }

    public sortSeancesChronologically(seances: StoredSeance[]): StoredSeance[] {
        if (!seances) return [];
        return seances.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
    }

    // Récupère les séances pour un patient
    public selectSeancesByPatient(patientId: string): Observable<StoredSeance[]> {
        const dataSource = this.createSeanceDataSource(
            (seance: StoredSeance) => (seance.patientId === patientId)
        );

        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances)),

        );
    }

    // Récupère les séances pour un patient via une vue
    public selectSeancesByPatientView(patientId: string, withFacturee: boolean = false): Observable<StoredSeance[]> {
        const dataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: `seances/by_patient${withFacturee ? "" : "_without_facturee"}`,
            viewParams: {
                key: patientId
            },

        };

        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances)),

        );
    }


    // Récupère une séance
    public selectSeance(seanceId: string): Observable<StoredSeance> {
        const dataSource: IDataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewParams: {
                include_docs: true,
                key: seanceId
            }
        };
        return this.svcStore.getOne<StoredSeance>(dataSource);
    }

    // Récupère plusieurs séances par id
    public selectSeances(seanceIds: string[]): Observable<StoredSeance[]> {
        const dataSource: IDataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewParams: {
                include_docs: true,
                keys: seanceIds
            }
        };
        return this.svcStore.get<StoredSeance>(dataSource);
    }

    // Crée une nouvelle séance
    public createSeance(seance: StoredSeance): Observable<IStoreDataResponse> {
        const databaseId = ArrayHelper.getFirstElement(this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));
        const key: SeanceKey = new SeanceKey(seance.traitementId, seance.patientId);
        seance._id = key.toString();
        return this.svcStore.put(seance, databaseId).pipe(
            tap(() => {
                this.triggerRefreshSeanceList();
            })
        )
    }

    // Met à jour une séance
    public updateSeance(seance: StoredSeance, triggerResfresh: boolean = true): Observable<boolean> {
        delete seance.infirmier;
        delete seance.patient;
        delete seance.ordonnance;
        const databaseId = ArrayHelper.getFirstElement(this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));
        return this.svcStore.put(seance, databaseId).pipe(
            tap(() => {
                if (triggerResfresh) {
                    this.triggerRefreshSeanceList();
                }
            }),
            mergeMap((response: IStoreDataResponse) => {
                return of(response.ok)
            }
            )
        )
    }

    public updateSeances(seances: StoredSeance[], triggerResfresh: boolean = true, verifyPropagation: boolean = true): Observable<boolean> {
        seances.forEach(seance => {
            delete seance.infirmier;
            delete seance.patient;
            delete seance.ordonnance;
        })
        const databaseId = ArrayHelper.getFirstElement(this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));
        return this.svcStore.bulkDocsWithConflictRetry(databaseId, seances, 3).pipe(
            mergeMap(({ successfulDocs, failedDocs }) => {
                if(verifyPropagation){
                    const updatedDocIds = successfulDocs.map(doc => doc._id);
                    return this.verifyDocumentsUpdated(updatedDocIds, successfulDocs);
                }
                return of(true)
            }),
            map(() => {
                if (triggerResfresh) {
                    this.triggerRefreshSeanceList();
                }
                return true;
            })
        )
    }

    // Verifie que la mise à jour des documents a bien été propagée (vérification nécessaire dans les environnements d'integration et de production)
    private verifyDocumentsUpdated(docIds: string[], expectedDocs: any[], maxRetries = 5): Observable<boolean> {
        return range(0, maxRetries).pipe(
            concatMap(attempt =>
                this.selectSeances(docIds).pipe(
                    map(retrievedDocs => {
                        const allConsistent = retrievedDocs.every(retrievedDoc => {
                            const expectedDoc = expectedDocs.find(doc => doc._id === retrievedDoc._id);
                            return expectedDoc && retrievedDoc._rev === expectedDoc._rev;
                        });

                        return { attempt, allConsistent };
                    }),
                    delay(100 * Math.pow(2, attempt))
                )
            ),
            takeWhile(({ allConsistent }) => !allConsistent, true),
            map(({ allConsistent }) => allConsistent),
            defaultIfEmpty(false)
        );
    }

    //Supprime une seance
    public deleteSeance(seance: StoredSeance): Observable<boolean> {
        return this.svcStore.delete(seance).pipe(
            tap(() => {
                this.triggerRefreshSeanceList();
            }),
            map((poResponse: IStoreDataResponse) => poResponse.ok)
        )
    }

    // A partir d'une séance donnée, retourne la liste des séances futurs
    public getSeancesNonFactures(selectedSeance: StoredSeance): Observable<StoredSeance[]> {
        return this.svcTraitement.getTraitementANAKIN(selectedSeance.traitementId).pipe(
            mergeMap((traitement: Traitement) => this.selectSeancesByTraitement(traitement._id)),
            map((seances: StoredSeance[]) =>
                seances.filter((seance: StoredSeance) =>
                    seance.status !== EStatusSeance.completed
                )
            )
        );
    }

    // A partir d'une séance donnée, retourne la liste de toutes les séances du traitement qui ne sont pas facturées
    public getFuturSeances(selectedSeance: StoredSeance): Observable<StoredSeance[]> {
        return this.svcTraitement.getTraitementANAKIN(selectedSeance.traitementId).pipe(
            mergeMap((traitement: Traitement) => this.selectSeancesByTraitement(traitement._id)),
            map((seances: StoredSeance[]) =>
                seances.filter((seance: StoredSeance) =>
                    DateHelper.compareTwoDates(seance.startDate, selectedSeance.startDate) > 0 // futures seulement
                )
            ),
            map((futurSeances: StoredSeance[]) => {
                futurSeances.push(selectedSeance);
                return futurSeances.sort((a: StoredSeance, b: StoredSeance) =>
                    DateHelper.compareTwoDates(a.startDate, b.startDate)
                );
            })
        );
    }


    // Met à jour les actes d'une seance en fonction d'une liste de modifications d'acte à appliquer
    public updateActesSeance(seance: StoredSeance, modifActesSeance: IModifActesSeance): StoredSeance {
        modifActesSeance.actesUpdated.forEach(({ src, dest }: { src: Acte; dest: Acte }) => {
            const indexToUpdate = seance.actes.findIndex((acte: Acte) => acte.guid === src.guid);
            if (indexToUpdate !== -1) {
                seance.actes[indexToUpdate] = dest;
            }
        })

        modifActesSeance.actesAdded.forEach((acte: Acte) => {
            seance.actes.push(acte)
        })

        modifActesSeance.actesDeleted.forEach((toDeleteActe: Acte) => {
            const index = seance.actes.findIndex((acte: Acte) => acte.guid === toDeleteActe.guid);
            if (index !== -1) {
                seance.actes.splice(index, 1);
            }
        });
        return seance;
    }

    public countSeancesByStatusAndDateFacturation(traitement: ITraitement, status: EStatusSeance, dateFacturation: Date): Observable<number> {
        const dateFacturationString = DateHelper.transform(dateFacturation, ETimetablePattern.isoFormat_hyphen);

        const dataSource: IDataSourceViewCount = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/count_by_date_facturation",
            viewParams: {
                startkey: [traitement._id, status, "1970-01-01T00:00:00.000Z"],
                endkey: [traitement._id, status, dateFacturationString]
            }
        };

        return this.svcStore.getSingleDocumentCountFromView(dataSource, ArrayHelper.getFirstElement(dataSource.databasesIds)).pipe(
            map(result => typeof result === 'number' ? result : 0) // Vérifie que c'est bien un nombre
        );
    }

    public countSeancesGroupByStatusAndDateFacturation(): Observable<IViewCountResult[]> {

        const dataSource: IDataSourceViewCount = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/count_by_date_facturation",
            viewParams: {
                group: true
            }
        };
        return this.svcStore.getGroupedDocumentCountFromView(dataSource, ArrayHelper.getFirstElement(dataSource.databasesIds))
    }

    public countSeancesByStatus(traitement: ITraitement, status: EStatusSeance): Observable<number> {
        const dataSource: IDataSourceViewCount = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/count_by_status",
            viewParams: {
                key: [traitement._id, status]
            }
        };

        return this.svcStore.getSingleDocumentCountFromView(dataSource, ArrayHelper.getFirstElement(dataSource.databasesIds)).pipe(
            map(result => typeof result === 'number' ? result : 0) // Vérifie que c'est bien un nombre
        );
    }

    public countSeancesByTraitement(traitement: ITraitement): Observable<number> {
        const dataSource: IDataSourceViewCount = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/count_by_traitement",
            viewParams: {
                key: traitement._id
            }
        };

        return this.svcStore.getSingleDocumentCountFromView(dataSource, ArrayHelper.getFirstElement(dataSource.databasesIds)).pipe(
            map(result => typeof result === 'number' ? result : 0) // Vérifie que c'est bien un nombre
        );
    }

    public countSeancesGroupByTraitement(): Observable<IViewCountResult[]> {
        const dataSource: IDataSourceViewCount = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/count_by_traitement",
            viewParams: {
                group: true
            }
        };

        return this.svcStore.getGroupedDocumentCountFromView(dataSource, ArrayHelper.getFirstElement(dataSource.databasesIds))
    }

    public countSeancesGroupByStatus(): Observable<IViewCountResult[]> {
        const dataSource: IDataSourceViewCount = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/count_by_status",
            viewParams: {
                group: true
            }
        };

        return this.svcStore.getGroupedDocumentCountFromView(dataSource, ArrayHelper.getFirstElement(dataSource.databasesIds))
    }

    public countSeancesGroupByStatusAndInfirmier(): Observable<IViewCountResult[]> {
        const dataSource: IDataSourceViewCount = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/count_by_traitement_infirmier_status",
            viewParams: {
                group: true
            }
        };

        return this.svcStore.getGroupedDocumentCountFromView(dataSource, ArrayHelper.getFirstElement(dataSource.databasesIds))
    }

    public static sortDayRepetitions(dayRepetitions: IDayRepetition[]): IDayRepetition[] {
        return dayRepetitions.sort((a, b) => {
            const getTimeInMinutes = (entry: IDayRepetition): number => {
                if (entry.type === "range") {
                    return entry.from.hours * 60 + entry.from.minutes;
                } else if (entry.type === "hours-minutes") {
                    return parseInt(entry.hours, 10) * 60 + parseInt(entry.minutes, 10);
                } else {
                    throw new Error(`Type inconnu : ${entry.type}`);
                }
            };
            const timeA = getTimeInMinutes(a);
            const timeB = getTimeInMinutes(b);
            return timeA - timeB;
        });
    }

    // Met à jour la propriété isAldExonerante des séances existantes (non facturées) d'un traitement
    public updateTraitementSeances(ordonnance: Traitement): Observable<boolean> {
        return this.selectSeancesByTraitement(ordonnance._id).pipe(
            map((seances: StoredSeance[]) =>
                seances.filter((seance) => {
                    if (seance.status === EStatusSeance.completed) return false;

                    let hasChanges = false;
                    seance.actes.forEach((acte: Acte) => {
                        if (acte.isAldExonerante !== ordonnance.isAld) {
                            acte.isAldExonerante = ordonnance.isAld;
                            hasChanges = true;
                        }
                    });

                    return hasChanges;
                })
            ),
            mergeMap((updatedSeances) =>
                updatedSeances.length > 0 ? this.updateSeances(updatedSeances) : of(true)
            )
        );
    }

    // Associe les counts de séances à une liste d'ordonnances
    getSeancesCountsForOrdonnances(ordonnances: Traitement[]): Observable<{ ordonnances: Traitement[], countSeancesByStatus: IViewCountResult[] }> {
        return this.countSeancesGroupByStatus().pipe(
            map((result: IViewCountResult[]) => {
                const countsMap = result.reduce((acc, { key, value }: IViewCountResult) => {
                    const [ordonnanceId, status] = key;
                    if (!acc[ordonnanceId]) {
                        acc[ordonnanceId] = {
                            total: 0,
                            done: 0,
                            completed: 0,
                            to_be_done: 0,
                            paused: 0,
                            canceled: 0
                        };
                    }

                    const statusPropertyMap = {
                        [EStatusSeance.done]: 'done',
                        [EStatusSeance.canceled]: 'canceled',
                        [EStatusSeance.completed]: 'completed',
                        [EStatusSeance.to_be_done]: 'to_be_done',
                        [EStatusSeance.paused]: 'paused'
                    };

                    const statusProperty = statusPropertyMap[status];
                    if (statusProperty) {
                        acc[ordonnanceId][statusProperty] += value;
                    }
                    acc[ordonnanceId].total += value;
                    return acc;
                }, {} as Record<string, {
                    total: number,
                    done: number,
                    completed: number,
                    to_be_done: number,
                    paused: number,
                    canceled: number
                }>);

                const updatedOrdonnances = ordonnances.map((ordonnance: Traitement) => {
                    const counts = countsMap[ordonnance._id];
                    if (!counts) return ordonnance;
                    return {
                        ...ordonnance,
                        countSeancesTotal: counts.total || 0,
                        countSeancesDone: counts.done || 0,
                        countSeancesCompleted: counts.completed || 0,
                        countSeancesPaused: counts.paused || 0,
                        countSeancesToBeDone: counts.to_be_done || 0,
                        countSeancesCanceled: counts.canceled || 0,
                    } as Traitement;
                });

                return { ordonnances: updatedOrdonnances, countSeancesByStatus: result };
            })
        );
    }
}