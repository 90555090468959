import { Component, Input, OnInit } from '@angular/core';
import { IContact } from '@osapp/model';

@Component({
  selector: 'di-filtre-infirmiers',
  templateUrl: './filtre-infirmiers.component.html',
  styleUrls: ['./filtre-infirmiers.component.scss'],
})
export class FiltreInfirmiersComponent implements OnInit {

  @Input() infirmiers: IContact[] = [];
  @Input() idsInfirmiersSelected: string[] = [];
  @Input() position: string = "R";
  @Input() libelleBtn: string = "Afficher";
  @Input() onFilter: (idsSelected: string[]) => void;
  @Input() nonAffectes: boolean = true;

  public idsInfirmiersSelectedFinal: string[] = [];

  //TODO : récupérer les groupes pour aovir les tags remplacants sur les contacts
  public remplacantTag = {
    tagLabel: "Remplaçant",
    tagColor: "CouleurPrimaire",
    tagMode: "filled",
  };

  constructor() { }

  ngOnInit() {
    this.idsInfirmiersSelectedFinal = [...this.idsInfirmiersSelected];
  }

  public afficher() {
    if (this.onFilter) {
      this.onFilter(this.idsInfirmiersSelectedFinal);
    }
  }
  public onSelection(event: Event, infirmier: IContact) {
    const infirmierId = infirmier._id;
    if (this.idsInfirmiersSelectedFinal.includes(infirmierId)) {
      this.idsInfirmiersSelectedFinal = this.idsInfirmiersSelectedFinal.filter(id => id !== infirmierId);
    }
    else {
      this.idsInfirmiersSelectedFinal.push(infirmierId);
    }
  }

  public onSelectionSeancesNonAffectees(event: Event) {
    if (this.idsInfirmiersSelectedFinal.includes('')) {
      this.idsInfirmiersSelectedFinal = this.idsInfirmiersSelectedFinal.filter(id => id !== '');
    }
    else {
      this.idsInfirmiersSelectedFinal.push('');
    }
  }
}
