<div class="panneau-mise-en-pause__container">
	<div class="container__content">
    <form [formGroup]="mepForm">
      <div>
        <p *ngIf="seance.status === 7">
          Les séances sont en pause, elles ne seront pas facturées par défaut.
        </p>
        <p *ngIf="seance.status !== 7">
          Les séances seront mises en pause, elles ne seront pas facturées par défaut.
        </p>
        <div class="mep_commentaire">
  				<lua-input 
            label="Mon commentaire"           
            [multiline]="true" 
            formControlName="commentaire"
            placeholder="Saisir un commentaire" 
            [fullWidth]="true">
          </lua-input>
        </div>
        <div class="mep_date-debut">
          <lua-input 
            [fullWidth]="true" 
            placeholder="Date de début" 
            label="Date de début" 
            type="date"
            formControlName="dateDebutPause"
            [min]=ordonnance.prescriptionDate>
          </lua-input>
        </div>
      </div>
      <div class="mep_reprise">
        <h2>Connaissez vous la date de reprise des soins ?</h2>
        <div class="mep_date-fin">
          <lua-input 
            [fullWidth]="true" 
            placeholder="Date de reprise des soins" 
            label="Date de reprise des soins" 
            type="date"
            formControlName="dateFinPause"
            [min]="dateFinPauseMinDate">
          </lua-input>
        </div>
      </div>
      <div class="mep_bouton-action" class.mobile="isMobile">
        <lua-action-secondaire 
          size="large"
          [avecIcone]="true" 
          libelle="Mettre en pause les soins" 
          nomIcone="pause_circle"
          [fullWidth]="true" 
          positionIcone="L" 
          (click)="mettreEnPause()"
          [isDisabled]="!mepForm.valid">
        </lua-action-secondaire>
        <lua-action-principale 
					*ngIf="seance.status === 7" 
          libelle="Reprendre les soins" 
          startIcon="play_circle"
          (click)="reprendreLesSoins()" 
          [fullWidth]="true">
          </lua-action-principale>
        <lua-bouton-texte 
          libelle="Annuler" 
          [fullWidth]="true" 
          size="large"
          (click)="cancel()">
        </lua-bouton-texte>
      </div>
    </form>		
	</div>
</div>