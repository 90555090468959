import { EStatusSeance } from "apps/idl/src/model/EStatusSeance";
import { SeanceIconConfig } from "../../../models/SeanceIconConfig";

const defaultConfig: SeanceIconConfig = {
    name: 'circle',
    color: 'Indigo',
    mode: 'outlined',
};

export const EBoutonIconEtatSeance: Record<EStatusSeance, SeanceIconConfig> = {
    [EStatusSeance.canceled]: {
        name: 'cancel',
        color: 'Rouge',
        mode: 'filled',
    },
    [EStatusSeance.done]: {
        name: 'check_circle',
        color: 'Vert',
        mode: 'filled',
    },
    [EStatusSeance.paused]: {
        name: 'pause_circle',
        color: 'Orange',
        mode: 'outlined',
    },
    [EStatusSeance.to_be_done]: defaultConfig,
    [EStatusSeance.pending]: defaultConfig,
    [EStatusSeance.inProgress]: defaultConfig,
    [EStatusSeance.completed]: defaultConfig,
} as const;