<div class="menu-desktop-container" (mouseenter)="handleHoverMenu()" (mouseleave)="handleMenuLeave()"
	[ngClass]="getClasses()">
	<div *ngIf="!menuOpened" class="icon-container">
		<lua-bouton-icone iconName="menu" color="Blanc"
			(click)="openMenu()"></lua-bouton-icone>
	</div>
	<div class="header-menu-opened" *ngIf="menuOpened">
		<lua-bouton-icone iconName="menu" color="Blanc"
			(click)="closeMenu()"></lua-bouton-icone>
	</div>
	<div class="menu-desktop-button-list">
		<ng-container *ngFor="let icone of desktopIcons">
			<lua-appbar-item class="menu-item" [menuOpen]="menuOpened" [iconName]="icone.iconName" [text]="icone.label"
				iconMode="outlined" [badgeNumber]="icone.badgeCount" [selected]="icone.route == selectedRoute"
				(click)="redirect(icone.route)"></lua-appbar-item>
		</ng-container>
		<lua-appbar-item class="menu-item" [menuOpen]="menuOpened" iconName="language" text="Amélipro" iconMode="outlined"
			(click)="handleRedirectAmeli()"></lua-appbar-item>
	</div>
</div>