import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule, PipeTransform, Provider, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { IonicModule } from '@ionic/angular';
import { ContactsModule } from '@osapp/components/contacts/contacts.module';
import { DateModule } from '@osapp/components/date/date.module';
import { FormModule } from '@osapp/components/forms/form/form.module';
import { FormListModule } from '@osapp/components/forms/formList/formList.module';
import { GalleryModule } from '@osapp/components/gallery/gallery.module';
import { SharedComponentsModule } from '@osapp/components/sharedComponents.module';
import { SlideboxModule } from '@osapp/components/slidebox/slidebox.module';
import { CalaoAvatarModule } from '@osapp/modules/avatar/calao-avatar.module';
import { ConflictsModule } from '@osapp/modules/conflicts/conflicts.module';
import { FilterModule } from '@osapp/modules/filter/filter.module';
import { HeaderModule } from '@osapp/modules/header';
import { ModalModule } from '@osapp/modules/modal';
import { PermissionsModule } from '@osapp/modules/permissions/permissions.module';
import { FavoritesModule } from '@osapp/modules/preferences/favorites/favorites.module';
import { SelectorModule } from '@osapp/modules/selector/selector.module';
import { SlidingListModule } from '@osapp/modules/sliding-list/sliding-list.module';
import { VirtualScrollModule } from '@osapp/modules/virtual-scroll/virtual-scroll.module';
import { PipeModule } from '@osapp/pipes';
import { IdlPipeModule } from '../../pipes/idlPipes.module';
import { ActesModule } from '../actes/actes.module';
import { OlaqinModule } from '../olaqin/olaqin.module';
import { TraitementsListMarkerComponent } from '../traitements/components/traitements-list-marker/traitements-list-marker.component';
import { ConstantesDetailsComponent } from './components/constantes/constantes-details/constantes-details.component';
import { ConstantesModalComponent } from './components/constantes/constantes-modal/constantes-modal.component';
import { ConstantesPageComponent } from './components/constantes/constantes-page/constantes-page.component';
import { SurveillanceComponent } from './components/constantes/surveillance/surveillance.component';
import { SurveillancesItemComponent } from './components/constantes/surveillances-item/surveillances-item.component';
import { SurveillancesListComponent } from './components/constantes/surveillances-list/surveillances-list.component';
import { CouvertureAMCPComponent } from './components/couvertures/couverture-amc-p/couverture-amc-p.component';
import { CouvertureAMOPComponent } from './components/couvertures/couverture-amo-p/couverture-amo-p.component';
import { CouvertureModalComponent } from './components/couvertures/couverture-modal/couverture-modal.component';
import { CouvertureStatusComponent } from './components/couvertures/couverture-status/couverture-status.component';
import { CouverturesListComponent } from './components/couvertures/couvertures-list/couvertures-list.component';
import { DesmosSearchButtonComponent } from './components/desmos-search-button/desmos-search-button.component';
import { DirectivesComponent } from './components/directives/directives/directives.component';
import { ADRiCallActionButtonComponent } from './components/idlActionButtons/adri-call-action-button/adri-call-action-button.component';
import { ExportActionButtonComponent } from './components/idlActionButtons/export-action-button/export-action-button.component';
import { ActionButtonFieldTraitementComponent } from './components/idlActionButtons/idl-action-button-field-traitement/idl-action-button-field-traitement.component';
import { IdlActionButtonLectureCVComponent } from './components/idlActionButtons/idl-action-button-lecture-cv/idl-action-button-lecture-cv.component';
import { MajPatientFsvActionButtonComponent } from './components/idlActionButtons/maj-patient-fsv-action-button/maj-patient-fsv-action-button.component';
import { PatientContactsListComponent } from './components/patient-contacts-list/patient-contacts-list.component';
import { PatientSelectorModalComponent } from './components/patient-selector-modal/patient-selector-modal.component';
import { PatientSelectorModalOpenerService } from './components/patient-selector-modal/services/patient-selector-modal-opener.service';
import { PatientSelectorComponent } from './components/patient-selector/patient-selector.component';
import { PatientComponent } from './components/patient/patient.component';
import { PatientsListComponent } from './components/patients/patients-list.component';
import { RecentPatientsListComponent } from './components/patients/recent-patients-list.component';
import { TraitementsListComponent } from './components/traitementsList/traitementsList.component';
import { TransmissionModalComponent } from './components/transmissions/transmission-modal/transmission-modal.component';
import { TransmissionPageComponent } from './components/transmissions/transmission-page/transmission-page.component';
import { TransmissionSecondHeaderComponent } from './components/transmissions/transmission-second-header/transmission-second-header.component';
import { TransmissionComponent } from './components/transmissions/transmission/transmission.component';
import { TransmissionsListComponent } from './components/transmissions/transmissions-list.component';
import { PatientsBookComponent } from './patients-book.component';
import { PatientNamePipe } from './pipes/patient-name.pipe';
import { ExportService } from './services/export.service';
import { IdlActionButtonService } from './services/idl-action-button.service';

const modules: Type<any>[] = [
	IonicModule,
	CommonModule,
	IdlPipeModule,
	PipeModule,
	SlideboxModule,
	SharedComponentsModule,
	GalleryModule,
	FormModule,
	FormListModule,
	MatFormFieldModule,
	MatInputModule,
	MatTooltipModule,
	FormsModule,
	HeaderModule,
	ModalModule,
	SuperTabsModule,
	FilterModule,
	ActesModule,
	SelectorModule,
	FavoritesModule,
	ScrollingModule,
	MatRadioModule,
	ConflictsModule,
	DateModule,
	SlidingListModule,
	VirtualScrollModule,
	PermissionsModule,
	OlaqinModule,
	MatAutocompleteModule,
	MatSelectModule,
	ContactsModule,
	CalaoAvatarModule
];
const components: Type<any>[] = [
	TraitementsListComponent,
	ActionButtonFieldTraitementComponent,
	ExportActionButtonComponent,
	ADRiCallActionButtonComponent,
	MajPatientFsvActionButtonComponent,
	PatientsBookComponent,
	PatientsListComponent,
	RecentPatientsListComponent,
	TransmissionsListComponent,
	TransmissionPageComponent,
	TransmissionComponent,
	TransmissionModalComponent,
	TransmissionSecondHeaderComponent,
	ConstantesDetailsComponent,
	SurveillancesItemComponent,
	ConstantesPageComponent,
	ConstantesModalComponent,
	DesmosSearchButtonComponent,
	PatientComponent,
	PatientSelectorComponent,
	PatientSelectorModalComponent,
	SurveillanceComponent,
	SurveillancesListComponent,
	DirectivesComponent,
	TraitementsListMarkerComponent,
	CouverturesListComponent,
	CouvertureModalComponent,
	CouvertureAMCPComponent,
	CouvertureAMOPComponent,
	IdlActionButtonLectureCVComponent,
	PatientContactsListComponent,
	CouvertureStatusComponent
];
const providers: Provider[] = [
	ExportService,
	IdlActionButtonService,
	TitleCasePipe,
	PatientSelectorModalOpenerService
];
const pipes: Type<PipeTransform>[] = [
	PatientNamePipe
];

@NgModule({
	imports: modules,
	exports: [...components, ...pipes],
	declarations: [...components, ...pipes],
	providers: [...providers, ...pipes]
})
export class PatientsModule { }