import { EntityBuilder } from '../../services/EntityBuilder';
import { ISecurityConfig } from '../../services/security/ISecurityConfig';
import { IAppInfo } from '../application/IAppInfo';
import { IBackgroundTask } from '../backgroundTask/IBackgroundTask';
import { IEnvironment } from '../environment/IEnvironment';
import { EZoneGeo } from '../EZoneGeo';
import { IAuthentication } from '../IAuthentication';
import { IDatabaseConfig } from '../store/IDatabaseConfig';
import { IUpdateConfig } from '../update/IUpdateConfig';
import { ILoggerConfig } from './ILoggerConfig';

export abstract class ConfigData {

	/** Informations statique sur l'application. */
	public static appInfo: IAppInfo;
	/** Informations statique sur le type d'authentification qui va être utilisé par l'application. */
	public static authentication: IAuthentication;
	/** Informations sur l'environnement. */
	public static environment: IEnvironment;
	/** Informations sur les tâches de fond. */
	public static backgroundTask: IBackgroundTask;
	/** Configuration pour la mise à jour de l'application. */
	public static update: IUpdateConfig;
	/** Générateurs d'entités. */
	public static entityBuilders: EntityBuilder[];
	/** Indique si on est en version de production ou non. */
	public static isProductionEnvironment: boolean;
	/** Configuration des bases de données. */
	public static databases: IDatabaseConfig[] = [];
	/** Configuration des bases de données de workspace. */
	public static workspaceDatabases: IDatabaseConfig[];
	/** Configuration de la sécurité de l'application. */
	public static security?: ISecurityConfig;
	/** Configuration du logging. */
	public static logger: ILoggerConfig;
	/** Zone géographique de l'utilisateur */
	public static geoZoneApp?: number = EZoneGeo.METROPOLE;


}