import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { ArrayHelper, DateHelper, UserHelper } from '@osapp/helpers';
import { EPrefix, ETimetablePattern, IContact } from '@osapp/model';
import { FilesystemService } from '@osapp/modules/filesystem/services/filesystem.service';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService, PlatformService } from '@osapp/services';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { Subject, forkJoin, merge } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MenuSelectionInfirmierComponent } from '../../features/shared/components/menu-selection-infirmier/menu-selection-infirmier.component';
import { EMoments } from '../../features/shared/enums/EMoments';
import { DeviceService } from '../../features/shared/services/device.service';
import { DrawerPopoverService } from '../../features/shared/services/drawer-popover.service';
import { EvenementService } from '../../features/shared/services/evenement.service';
import { ImpressionService } from '../../features/shared/services/impression.service';
import { LoaderService } from '../../features/shared/services/loader.service';
import { SeanceService } from '../../features/shared/services/seance.service';
import { SnackbarService } from '../../features/shared/services/snackbar.service';
import { Evenement } from '../../models/Evenement';
import { StoredSeance } from '../../models/StoredSeance';

@Component({
  selector: 'di-tournees',
  templateUrl: './tournees.page.html',
  styleUrls: ['./tournees.page.scss'],
})
export class TourneesPage extends DestroyableComponentBase implements OnInit {

  private readonly C_TOURNEE_DIRECTORY_NAME = "Tournées";
  public tourneesfilters: Array<string> = ["Liste", "Agenda"];
  public defaultFilter = this.tourneesfilters[0];
  public isMobile: boolean = false;
  public nbRdvPatient: number = 0;
  public totalPrevu: number = 0;
  public today: Date = new Date();
  public seances: StoredSeance[] = [];
  public filteredSeances: StoredSeance[] = [];
  public events: Evenement[] = [];
  public filteredEvents: Evenement[] = [];
  public infirmierSelected: IContact[];

  public infirmiersWS: IContact[];
  public libelleInfirmier: string;

  public dateSelected: Date;
  public dateSelectedFormat: string;
  private dateSelectedSubject = new Subject<Date>();

  public ordonnanceIdsTab: string[] = [];
  public patientIdsTab: string[];
  public ordonnances: Map<string, Traitement> = new Map();
  public patients: Map<string, IPatient>;

  //Utilisé uniquement pour les impressions
  public seancesImp!: any[];
  public eventsImp: any[];
  public sortedSeancesImp: Record<string, { type: 'seance' | 'event'; data: any }[]> = {};
  public sortedSeancesEventsImp: { type: 'seance' | 'event'; data: any }[] = [];

  @ViewChild('printContainer', { read: ViewContainerRef }) printContainer!: ViewContainerRef;



  constructor(
    private svcDevice: DeviceService,
    private svcSeance: SeanceService,
    private svcDrawerPopover: DrawerPopoverService,
    private svcContact: ContactsService,
    private svcLoader: LoaderService,
    private svcEvenement: EvenementService,
    private el: ElementRef,
    private svcTraitement: TraitementService,
    private svcPatient: PatientsService,
    private svcImpression: ImpressionService,
    private ioFileOpener: FileOpener,
    private readonly svcFilesystem: FilesystemService,
    private svcSnackbar: SnackbarService,
    private svcPlatform: PlatformService,
  ) { super(); }

  ngOnInit() {
    this.initDateSelected();
    this.initInfirmiers();

    // Listen for mobile changes
    this.svcDevice.isMobile$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((flag: boolean) => {
      this.isMobile = flag;
    });

    merge(
      this.svcSeance.refreshSeanceList$,
      this.svcEvenement.refreshEventList$
    ).pipe(
      takeUntil(this.destroyed$),
      // Permet de gérer le cas où plusieurs séances sont réalisées d'un coup, pour exécuter loadListSeances une seule fois
      debounceTime(200),
      switchMap(() => this.fetchData(this.dateSelected))
    ).subscribe();

    this.dateSelectedSubject.pipe(
      tap(() => this.svcLoader.showLoader()),
      takeUntil(this.destroyed$),
      switchMap((date: Date) => {
        this.dateSelected = date;
        return this.fetchData(date);
      }),
      tap(() => this.svcLoader.hideLoader())
    ).subscribe();
  }

  onDateChange(newDate: Date) {
    if (newDate && newDate !== this.dateSelected) {
      this.dateSelectedSubject.next(newDate);
    }
  }

  openDatePicker(picker: any) {
    if (!this.dateSelected) {
      this.dateSelected = new Date();
    }
    picker.singlePicker?.select(this.dateSelected); // Préselectionne la date
    picker.singlePicker?.open(); // Ouvre le datepicker
  }

  private fetchData(date: Date) {
    return forkJoin([
      this.svcSeance.selectSeancesByDate(date,true),
      this.svcEvenement.selectEventsByDate(date)
    ]).pipe(
      tap(([seances, events]: [StoredSeance[], Evenement[]]) => {
        this.seances = seances;
        this.events = events;
        this.getOrdonnanceIdsAndPatientId();
      }),
      switchMap(() => forkJoin([
        this.svcTraitement.getTraitementByIds(this.ordonnanceIdsTab),
        this.svcPatient.getPatientsByIds(this.patientIdsTab)
      ])),
      tap(([ordonnances, patients]: [Traitement[], IPatient[]]) => {
        const ordonnanceMap = new Map(ordonnances.map(ordonnance => [ordonnance._id, ordonnance]));
        const patientMap = new Map(patients.map(patient => [patient._id, patient]));

        this.seances = this.seances.map((seance: StoredSeance) => {
          seance.ordonnance = ordonnanceMap.get(seance.traitementId);
          seance.patient = patientMap.get(seance.patientId);
          return seance;
        });
        this.filterSeancesAndEventsByInfirmier();
      })
    );
  }

  private filterSeancesAndEventsByInfirmier() {
    const infirmierIds = this.infirmierSelected.map(infirmier => infirmier._id);
    this.filteredSeances = this.seances.filter((seance: StoredSeance) => !seance.infirmierId || infirmierIds.includes(seance.infirmierId));
    this.filteredSeances = this.filteredSeances.map((seance: StoredSeance) => {
      seance.infirmier = this.infirmiersWS.find((infirmier: IContact) => infirmier._id === seance.infirmierId)
      return seance;
    })
    this.filteredEvents = this.events.filter((event: Evenement) => !event.infirmierId || infirmierIds.includes(event.infirmierId));
    this.filteredEvents = this.filteredEvents.map((event: Evenement) => {
      event.infirmier = this.infirmiersWS.find((infirmier: IContact) => infirmier._id === event.infirmierId)
      return event;
    })
    this.nbRdvPatient = this.filteredSeances.length;
    this.totalPrevu = this.filteredSeances.reduce((total, seance) => {
      // Somme des prix des actes
      const acteTotal = seance.actes ? seance.actes.reduce((acc, acte) => acc + (acte.price || 0), 0) : 0;
      // Somme de toutes les majorations de la séance
      const majorationTotal = seance.majorations ? seance.majorations.reduce((acc, majoration) => acc + (majoration.price || 0), 0) : 0;
      // Somme de toutes les indemnités de la séance
      const indemniteTotal = seance.indemnites ? seance.indemnites.reduce((acc, indemnite) => acc + (indemnite.price || 0), 0) : 0;
      // Ajouter le total de cette séance au total général
      return total + acteTotal + majorationTotal + indemniteTotal;
    }, 0);
  }

  handleClickToday() {
    this.dateSelectedSubject.next(this.today);
  }

  openMenuSelectionInfirmier(event: Event) {
    this.svcDrawerPopover.open(
      "",
      "400px",
      event.currentTarget,
      MenuSelectionInfirmierComponent,
      {
        infirmiers: this.infirmiersWS,
        onSelection: this.selectionNewInfirmier
      }
    );
  }

  selectionNewInfirmier = (infirmiers: IContact[]): void => {
    this.infirmierSelected = infirmiers;
    this.svcDrawerPopover.close();
    this.filterSeancesAndEventsByInfirmier();
    if (infirmiers.length == 1) {
      this.libelleInfirmier = `${ArrayHelper.getFirstElement(this.infirmierSelected).firstName} ${ArrayHelper.getFirstElement(this.infirmierSelected).lastName}`;
      this.el.nativeElement.style.setProperty(
        '--my-color',
        `var(--${ArrayHelper.getFirstElement(this.infirmierSelected).avatarCouleur ?? 'CouleurPrimaire'})`
      );
    }
    else {
      this.libelleInfirmier = 'Tous les infirmiers';
      this.el.nativeElement.style.setProperty(
        '--my-color',
        `var(--CouleurPrimaire)`
      );
    }
  }

  previousDay() {
    const newDate = DateHelper.addDays(this.dateSelected, -1);
    this.dateSelectedSubject.next(newDate);
  }

  nextDay() {
    const newDate = DateHelper.addDays(this.dateSelected, 1);
    this.dateSelectedSubject.next(newDate);
  }

  changeFilter(filtre: string) {
    this.defaultFilter = filtre;
    //il faut changer de vue et recharger toutes les séances d'une semaine
  }

  async printTournee() {
    this.sortSeancesByTimeOfDay();
    const printElement = this.svcImpression.createTourneePrintView(
      this.printContainer,
      this.dateSelectedFormat,
      this.libelleInfirmier,
      this.sortedSeancesEventsImp
    );
    const titlePrint = this.infirmierSelected.length === 1 && ArrayHelper.getFirstElement(this.infirmierSelected)?.finess
      ? `tournee_${ArrayHelper.getFirstElement(this.infirmierSelected).finess}_${DateHelper.transform(this.dateSelected, ETimetablePattern.yyyyMMdd)}`
      : `tournee_${DateHelper.transform(this.dateSelected, ETimetablePattern.yyyyMMdd)}`;
    await this.svcImpression.printPdf(printElement, titlePrint, this.C_TOURNEE_DIRECTORY_NAME, this.isMobile);
  }



  private formatPhoneNumber(input: string): string {
    input = input.replace(/\D/g, '');
    return input.match(/.{1,2}/g)?.join(' ') || '';
  }

  private sortSeancesByTimeOfDay(): void {
    this.sortedSeancesImp = {}; // Reset the sorted data
    this.seancesImp = this.filteredSeances;
    this.eventsImp = this.filteredEvents;

    // tri des Seances
    this.seancesImp.forEach((x) => {
      const moment = StoredSeance.determineMoment(x.moment ?? new Date(x.startDate));
      if (!this.sortedSeancesImp[moment]) {
        this.sortedSeancesImp[moment] = [];
      }

      x.labelMoment = x.moment ? StoredSeance.getLabelMoment(x.moment).replace("-", " ") : DateHelper.transform(x.startDate, ETimetablePattern.HH_mm);
      x.floorAndAccesCode = [x.patient.floor ? `Etage: ${x.patient.floor}` : "", x.patient.accessCode ? `Code: ${x.patient.accessCode}` : ""].filter(Boolean).join(" ");
      x.phone = x.patient.phone ? this.formatPhoneNumber(x.patient.phone) : "";
      x.adress = [x.patient.street, x.patient.city].filter(Boolean).join(", ");
      const dejaTagPremierSoin = this.seancesImp.some(seancePat => seancePat.patient._id === x.patient._id && seancePat.estPremierSoin);
      x.estPremierSoin = x.actes.some(act => DateHelper.diffDays(act.startDate, x.startDate) === 0) && !dejaTagPremierSoin;
      x.lastNameFormatted = this.svcPatient.getFullNamePatient(x.patient);

      this.sortedSeancesImp[moment].push({ type: 'seance', data: x });
    });

    // tri des Events
    this.eventsImp.forEach((event) => {
      let moment;
      let hour = null;
      let minute = null;

      if (event.moment?.type === 'hours-minutes') {
        hour = event.moment.hours;
        minute = event.moment.minutes;

        switch (true) {
          case (hour >= 8 && hour < 12): moment = EMoments.MATIN; break;
          case (hour >= 12 && hour < 18): moment = EMoments.APRES_MIDI; break;
          case (hour >= 18 && hour < 23): moment = EMoments.SOIR; break;
          case (hour < 8 || hour >= 23): moment = EMoments.NUIT; break;
          default: throw new Error("Invalid time range for moment");
        }
      } else {
        //type range
        hour = event.moment?.from?.hours;
        minute = event.moment?.from?.minutes;
        moment = StoredSeance.determineMoment(event.moment);
      }

      event.labelMoment = StoredSeance.getLabelMoment(event.moment).replace("-", " ");
      //On rajoute un startDate sur le moment pour pouvoir tout trier a la fin
      const dateEvent = new Date(event.date);
      if (hour) {
        dateEvent.setHours(hour, minute || 0);
        event.startDate = dateEvent.toISOString();
      }

      if (!this.sortedSeancesImp[moment]) {
        this.sortedSeancesImp[moment] = [];
      }
      this.sortedSeancesImp[moment].push({ type: 'event', data: event });
    });

    // Convertion en un simple tableau
    this.sortedSeancesEventsImp = Object.keys(this.sortedSeancesImp)
      .flatMap(moment => this.sortedSeancesImp[moment])
      .sort((a, b) => new Date(a.data.startDate).getTime() - new Date(b.data.startDate).getTime());
  }

  private initDateSelected() {
    this.dateSelected = this.today;
    this.dateSelectedFormat = DateHelper.transform(this.dateSelected, ETimetablePattern.EEEE_dd_MMMM);
  }

  private initInfirmiers() {
    this.svcContact.getSiteContactsAnakin([], EPrefix.contact, true, true)
      .pipe(
        map((contacts: IContact[]) => contacts.filter((contact: IContact) => contact.userId)),
        tap((filteredContacts: IContact[]) => {
          // On stocke tous les infirmiers du workspace
          this.infirmiersWS = filteredContacts;

          // On présélectionne l'infimier connecté
          this.infirmierSelected = filteredContacts.filter((contact: IContact) => contact._id === UserHelper.getUserContactId())
          const firstInfirmier = ArrayHelper.getFirstElement(this.infirmierSelected)
          this.libelleInfirmier = `${firstInfirmier.firstName} ${firstInfirmier.lastName}`;
          this.el.nativeElement.style.setProperty(
            '--my-color',
            `var(--${firstInfirmier.avatarCouleur ?? 'CouleurPrimaire'})`
          );
          this.dateSelectedSubject.next(this.dateSelected);
        }),
        takeUntil(this.destroyed$)
      ).subscribe();
  }

  private getOrdonnanceIdsAndPatientId() {
    const ordonnanceIds = new Set<string>();
    const patientIds = new Set<string>();

    this.seances.forEach(seance => {
      if (seance.traitementId) {
        ordonnanceIds.add(seance.traitementId);
      }

      if (seance.patientId) {
        patientIds.add(seance.patientId);
      }
    });

    if (patientIds.size > 0) {
      this.patientIdsTab = Array.from(patientIds);
    }

    if (ordonnanceIds.size > 0) {
      this.ordonnanceIdsTab = Array.from(ordonnanceIds);
    }
    return Array.from(ordonnanceIds);
  }

  private createOrdonnanceMap(ordonnances: Traitement[]) {
    const ordonnanceMap = new Map<string, Traitement>();
    ordonnances.forEach(ordonnance => {
      ordonnanceMap.set(ordonnance._id, ordonnance);
    });
    this.ordonnances = ordonnanceMap;
  }

  private createPatientMap(patients: IPatient[]) {
    const patientMap = new Map<string, IPatient>();
    patients.forEach(pat => {
      patientMap.set(pat._id, pat);
    });
    this.patients = patientMap;
  }
}
