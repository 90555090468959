<div class="panneau-detail-facture__container">
	<div class="container__content">
		<div class="facture-container">
			<header class="facture-header">
				<div class="facture-title" [ngClass]="{ 'avoir': isAvoir }">
					<h1>{{invoice.invoiceType | firstUpperCase}}</h1>
					<h1>{{ isAvoir ? '-' : '' }}{{ total | priceFormat:2 }} €</h1>
				</div>
				<div class="facture-subtitle">
					<div class="left-part">
						<small class="facture-number">N° {{invoice.invoiceNumber}}</small>
						<small class="facture-date">du {{dateInvoice}}</small>
					</div>
					<div class="right-part">
						<lua-tag *ngIf="showTag" [label]="tag.label" [color]="tag.color" [mode]="tag.mode"></lua-tag>
					</div>
				</div>
				<div class="facture-actions">
					<ng-container *ngIf="!isAvoir">
						<lua-bouton-toolbar iconName="download" (click)="printInvoice()" [primary]="true" labelType="vertical"
							label="Facture"></lua-bouton-toolbar>
						<lua-bouton-toolbar iconName="print" [primary]="true" (click)="handelOpenFsp($event)" labelType="vertical"
							label="Feuille de soins"></lua-bouton-toolbar>
							<lua-bouton-toolbar 
								iconName="cycle" 
								labelType="vertical" 
								color="Indigo20" 
								(click)="openMenuConfirmationRecyclage($event)" 
								[disabled]="isBtnCancelDisabled"
								mode="filled"
								label="Recycler">
							</lua-bouton-toolbar>
							<lua-bouton-toolbar 
								iconName="cancel" 
								labelType="vertical" 
								color="Indigo20" 
								(click)="openMenuConfirmationAnnulation($event)" 
								[disabled]="isBtnCancelDisabled"
								mode="filled"
								label="Annuler">
							</lua-bouton-toolbar>
					</ng-container>
					<ng-container *ngIf="isAvoir">
						<lua-bouton-toolbar 
							iconName="print" 
							[primary]="true" 
							(click)="printFsp()" 
							labelType="vertical"
							label="Imprimer">
						</lua-bouton-toolbar>
						<lua-bouton-toolbar 
							iconName="download" 
							(click)="printInvoice()" 
							labelType="vertical"
							color="Indigo20" 
							label="Avoir">
						</lua-bouton-toolbar>
					</ng-container>					
				</div>
				
			</header>

			<ng-container *ngIf="isAvoir && invoiceCanceled">
				<div class="zone-secondaire-avoir" (click)="afficherInvoiceCanceled(invoiceCanceled)">
					<p>
						Avoir sur la 
						<span class="link-like">
						  facture n°{{invoiceCanceled.invoiceNumber}}
						</span>
						du {{dateInvoiceCanceled}}
					</p>					  
					<div>
						<lua-icone 
							iconName="arrow_outward" 
							mode="filled" 
							[color]="Noir">
						</lua-icone>
					</div>
				</div>
			</ng-container>

			<section class="section">
				<div *ngIf="isCancelled" class="section-facture-cancelled">
					<div class="container">
						<h2 class="title">Facture annulée</h2>
						<p>La facture a été annulée,{{invoice?.cancelDate || avoir?.date ? " le " : ""}}{{invoice?.cancelDate || avoir?.date | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}}{{authorCancelInvoice ? ' par ' : ""}}{{authorCancelInvoice?.firstName}} {{authorCancelInvoice?.lastName | uppercase}}.<br>
							L'avoir N°{{ avoir?.invoiceNumber ? +avoir.invoiceNumber : '' }} du {{avoir?.date | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}} annulant cette facture a été généré.</p>
					</div>
					<lua-action-secondaire
						libelle="Afficher l'avoir"
						color="Raspberry"
						size="large"
						(click)="openAvoir()"
					></lua-action-secondaire>
				</div>
				<div class="section-head">
					<h2>Patient</h2>
					<h2>{{invoice?.totalPartPatient | priceFormat:2 }} €</h2>
				</div>
				<di-part-detail-facturation 
					*ngIf="!isAvoir && invoice?.totalPartPatient > 0" [text]="invoice?.commentairePat"
					[text]="invoice?.commentairePat" 
					[status]="invoice?.statusPat ?? 'aRegler'"
					typePart="pp" 
					[reglementType]="invoice?.modeReglementPat" 
					(click)="handleClick($event,'pp')"
					[date]="invoice?.datePat">
				</di-part-detail-facturation>
				<div class="detail-row">
					<small>Nom prénom</small> <strong class="actionPatient" (click)="ouvrirDossierPatient()">{{fullLastName |
						uppercase}} {{patient.firstName | firstUpperCase}}
					</strong>
				</div>
				<div class="detail-row">
					<small>Date de naissance</small> <strong>{{ birthDate }}</strong>
				</div>
				<div class="detail-row">
					<small>Adresse</small> <strong>{{ patient.street }}</strong>
				</div>
				<div class="detail-row">
					<small>Code postal</small> <strong>{{ patient.zipCode }}</strong>
				</div>
				<div class="detail-row">
					<small>Ville</small> <strong>{{ patient.city }}</strong>
				</div>
			</section>

			<section class="section">
				<div class="section-head">
					<h2>Sécurité sociale</h2>
					<h2>{{invoice?.totalPartAMO | priceFormat:2 }} €</h2>
				</div>
				<di-part-detail-facturation 
					*ngIf="!isAvoir && invoice?.totalPartAMO > 0"
					[text]="invoice?.commentaireAMO" 
					[date]="invoice?.dateAMO"
					[status]="invoice?.statusAMO ?? 'aEnvoyer'" 
					typePart="amo"
					(click)="handleClick($event,'amo')">
				</di-part-detail-facturation>
				<div class="detail-row">
					<small>Tiers</small> <strong>{{invoice.labelAMO ?? '-'}}</strong>
				</div>
				<div class="detail-row">
					<small>N°</small> <strong>{{amoInfo}}</strong>
				</div>
			</section>

			<section class="section">
				<div class="section-head">
					<h2>Mutuelle</h2>
					<h2>{{invoice?.totalPartAMC | priceFormat:2 }} €</h2>
				</div>
				<di-part-detail-facturation 
					*ngIf="!isAvoir && invoice?.totalPartAMC > 0"
					[text]="invoice?.commentaireAMC" 
					[date]="invoice?.dateAMC"
					[status]="invoice?.statusAMC ?? 'aEnvoyer'" 
					typePart="amc"
					(click)="handleClick($event,'amc')">
				</di-part-detail-facturation>
				<div class="detail-row">
					<small>Tiers</small> <strong>{{invoice.labelAMC ?? '-'}}</strong>
				</div>
				<div class="detail-row">
					<small>N° télétransmission</small> <strong>{{amcInfo}}</strong>
				</div>
				<div class="detail-row">
					<small>N° adhérent</small> <strong>{{ invoice.amcNumeroAdherent ?? '-' }}</strong>
				</div>
			</section>

			<section *ngIf="infirmier" class="section">
				<div class="section-head">
					<h2>Infirmier</h2>
				</div>
				<div class="detail-row">
					<small>Nom prénom</small> <strong>{{infirmier.lastName |
						uppercase}} {{infirmier.firstName | firstUpperCase}}
					</strong>
				</div>
				<div class="detail-row">
					<small>N° Finess</small> <strong>{{ infirmier.finess }}</strong>
				</div>
				<div class="detail-row">
					<small>N° RPPS / Adeli</small> <strong>{{ infirmier.rpps ?? infirmier.adeli }}</strong>
				</div>
			</section>

			<section class="section">
				<div class="section-head">
					<h2>Soins facturés</h2>
				</div>
				<div class="liste-actes-head">
					<small>DATE</small>
					<small>AMO</small>
					<small>AMC</small>
					<small>PAT.</small>
					<small>HONO.</small>
				</div>
				<ng-container *ngFor="let acte of invoice.actes">
					<div class="detail-row acte">
						<small>{{acte.date | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}}</small>
						<div class="acte-info">
							<p>{{acte.description}}</p>
							<div class="acte-info-tarif">
								<small>{{acte.partAMO | priceFormat:2 }} </small>
								<small>{{acte.partAMC | priceFormat:2 }} </small>
								<small>{{acte.partPatient | priceFormat:2 }} </small>
								<small>{{acte.honoraires | priceFormat:2 }} </small>
							</div>
						</div>
					</div>
				</ng-container>

			</section>
		</div>
	</div>
</div>