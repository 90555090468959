<div class="panneau-deplacement">
	<div class="deplacement-container">
		<ng-container *ngIf="isLoaded">
			<form [formGroup]="ikForm" class="form-ik-form">
				<div class="deplacement-content">
					<div class="info-content">
			  <p>
				Les indemnités de déplacement vont s'appliquer à toutes les séances réalisées. Les IK seront conservées pour les prochaines facturations de ce patient.
			  </p>
						<div *ngFor="let item of indemnitesForfaitairesList; let i = index">
							<div *ngIf="i < 1 || showAllDeplacement" class="majoration">
								<lua-checkbox [checked]="isSelectedInListIndem(item)" (checkedChange)="toggleCheckDeplacement(event,item)"
									[disabled]="item.disabled"></lua-checkbox>
								<div class="content-majoration">
									<di-majoration [indemnity]="true" [indemnityLabel]="item.type +' '+item.label" [priceLabel]="item.price"
										[disabled]="item.disabled"></di-majoration>
								</div>
							</div>
						</div>
						<ng-container *ngIf="IKSelected">
							<div class="majoration">
								<lua-checkbox [checked]="isIkChecked" (checkedChange)="toggleIKSelected()" [disabled]="IKSelected.disabled"></lua-checkbox>
								<div class="content-majoration">
									<di-majoration [indemnity]="true" [indemnityLabel]="IKSelected.type +' '+IKSelected.label"
										[priceLabel]="IKSelected.price | priceFormat:2" [disabled]="IKSelected.disabled"></di-majoration>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="isIkChecked && !IKSelected?.disabled">
							<div class="ik-config-container">
								<lua-input (valueChange)="onInputChange($event)" label="Distance réelle (aller-retour)" type="number"
									formControlName="distance" placeholder="Cabinet au lieu de traitement du patient"
									[fullWidth]="true"></lua-input>
								<small>Distance après abattement : {{abattement}} Km </small>
								<div class="container-item">
									<lua-radio-button [checked]="selectedOption === 'IK'" (click)="selectOption('IK')"></lua-radio-button>
									<p>Plaine (0,35€ / Km)</p>
								</div>
								<div class="container-item">
									<lua-radio-button [checked]="selectedOption === 'IKM'" (click)="selectOption('IKM')"></lua-radio-button>
									<p>Montagne (0,50€ / Km)</p>
								</div>
								<div class="container-item">
									<lua-radio-button [checked]="selectedOption === 'IKS'" (click)="selectOption('IKS')"></lua-radio-button>
									<p>Pied / Ski (3,40€ / Km)</p>
								</div>
	
								<lua-input label="Montant indemnité kilométrique" formControlName="montant" placeholder=""
									[fullWidth]="true"></lua-input>
							</div>
						</ng-container>
					</div>
					<div class="actions">
						<lua-action-principale
							[libelle]="libelleBtnSave"
							startIcon="check_circle" 
							[fullWidth]="false" 
							(click)="handleClickConfirm()">
						</lua-action-principale>
						<lua-bouton-texte 
							libelle="Annuler" 		
							color="CouleurPrimaire" 
							[fullWidth]="false"
							(click)="handleClickAnnule()">
						</lua-bouton-texte>
					</div>
				</div>
			</form>
		</ng-container>
		<ng-container *ngIf="!isLoaded">
			<lua-loader-circulaire
				class="panneau-deplacement-loader"
				[size]="60"
				color="CouleurPrimaire"
				[speed]="1.5">
			</lua-loader-circulaire>
		</ng-container>
	</div>
</div>