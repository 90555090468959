import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BlobUrlManager } from '@osapp/components/image/model/BlobUrlManager';

@Component({
  selector: 'di-confirmation-archive',
  templateUrl: './confirmation-archive.component.html',
  styleUrls: ['./confirmation-archive.component.scss'],
})
export class ConfirmationArchiveComponent implements OnInit {

	isLargeScreen: boolean = false;
	private readonly moBlobUrlManager = new BlobUrlManager();


  constructor() {}

  ngOnInit(): void {
	
  }
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  onConfirm() {
    this.confirm.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
  

}
