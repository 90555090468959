import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ArrayHelper, DateHelper, FileHelper, StringHelper } from '@osapp/helpers';
import { ETimetablePattern, IGalleryFile } from '@osapp/model';
import { DmsFile } from '@osapp/modules/dms/model/DmsFile';
import { LongGuidBuilder } from '@osapp/modules/guid/models/long-guid-builder';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { OlaqinService } from 'apps/idl/src/modules/olaqin/services/olaqin.service';
import { EExportResult } from 'apps/idl/src/modules/patients/model/EExportResult';
import { ESitSupportAT } from 'apps/idl/src/modules/patients/model/ESitSupportAT';
import { ExportError } from 'apps/idl/src/modules/patients/model/ExportError';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { AMOP } from 'apps/idl/src/modules/patients/model/amo-p';
import { ECouvertureType } from 'apps/idl/src/modules/patients/model/ecouverture-type.enum';
import { EQualite } from 'apps/idl/src/modules/patients/model/equalite.enum';
import { ESituation } from 'apps/idl/src/modules/patients/model/esituation.enum';
import { ETypePieceJustificative } from 'apps/idl/src/modules/patients/model/etype-piece-justificative.enum';
import { EUpdateMode } from 'apps/idl/src/modules/patients/model/eupdate-mode.enum';
import { IAMO } from 'apps/idl/src/modules/patients/model/iamo';
import { CouverturesService } from 'apps/idl/src/modules/patients/services/couvertures.service';
import { ExportService } from 'apps/idl/src/modules/patients/services/export.service';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { EMPTY, of } from 'rxjs';
import { catchError, debounceTime, finalize, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { EGarantie } from '../../../../../../modules/patients/model/egarantie.enum';
import { DrawerPopoverService } from '../../../services/drawer-popover.service';
import { LoaderService } from '../../../services/loader.service';
import { PanneauService } from '../../../services/panneau.service';
import { UploadFileService } from '../../../services/uploadFile.service';
import { ConfirmationSuppressionComponent } from '../../confirmation-suppression/confirmation-suppression.component';
import { LectureTerminalComponent } from '../../lecture-terminal/lecture-terminal.component';
import { SSNHelper } from '../../../helpers/ssn.helper';

@Component({
  selector: 'di-panneau-patient-amo',
  templateUrl: './panneau-patient-amo.component.html',
  styleUrls: ['./panneau-patient-amo.component.scss'],
})
export class PanneauPatientAmoComponent implements OnInit, AfterViewInit {
  @ViewChild('appelAdri', { static: false }) appelAdri!:
    ElementRef<HTMLDivElement>;
  @ViewChild('appelCV', { static: false }) appelCV!:
    ElementRef<HTMLDivElement>;

  @ViewChild('selectConvention', { static: false }) selectConvention!: ElementRef<HTMLDivElement>;


  @Input()
  public patient: IPatient;

  @Input()
  public couverture?: AMOP;

  // True si on veut lancer la lecture de la carte vitable à l'ouverture du panneau
  @Input()
  public carteVitale?: boolean;

  public model: IPatient;

  // Variables responsables de l'affichage d'alertes
  public secured = false;
  public securedMessage = "Saisie débloquée ! Il sera nécessaire de lire la Carte Vitale ou d'utiliser l'ADRI pour faire une FSE sécurisée."
  public adriDone = "";
  public adriDoneMessage = "";
  public carteVitaleDone = "";
  public carteVitaleDoneMessage = "";
  public isError: boolean = false;
  public errorMessage: string;

  public errorMessagePhoto: string = "Le fichier sélectionné est trop volumineux (5Mo maximum)";
  public isErrorPhoto: boolean = false;

  public creationDateMessage = ""

  // Flag permettant de savoir si un appel Adri ou une lecture carte est en cours
  public adriOrCVInProgress: boolean = false;

  public listDoc: IGalleryFile[] = [];
  public listNewFile: File[] = [];
  private readonly guidBuilder = new LongGuidBuilder();

  public amoForm: FormGroup;
  public tierPayant = true;
  public ald = false;

  public amoVisible: IAMO;

  //Liste de toutes les CPAM disponibles
  public cpam: IAMO[] = [];
  //Liste des CPAM correspondant à la recherche de l'utilisateur
  public filteredCpam: IAMO[] = [];
  //Valeur recherché dans le champ CPAM
  public searchedCPAM: string = "";
  //True is on doit afficher la liste des CPAM sélectionnables
  public showOptions: boolean = false;

  //Variables contenant les options sélectionnables dans les select du form
  public qualites = [
    { label: "Assuré", value: EQualite.assure },
    { label: "Ascendant, descendant, collatéraux", value: EQualite.ascendantDescendantCollateraux },
    { label: "Conjoint", value: EQualite.conjoint },
    { label: "Conjoint divorcé", value: EQualite.conjointDivorce },
    { label: "Concubin", value: EQualite.concubin },
    { label: "Conjoint Séparé", value: EQualite.conjointSepare },
    { label: "Enfant", value: EQualite.enfant },
    { label: "Conjoint Veuf", value: EQualite.conjointVeuf },
    { label: "Autre", value: EQualite.autre },
  ];

  //Liste de garanties initialisée quand une CPAM est sélectionnée
  public garanties: ISelectOption<EGarantie>[] = [];

  public piecesJustificatives = [
    { label: "Aucune pièce", value: ETypePieceJustificative.aucunePièce },
    { label: "Attestation de droits", value: ETypePieceJustificative.attestationDeDroits },
    { label: "Carte d'assuré social", value: ETypePieceJustificative.carteAssureSocial }
  ];

  public situations = [
    { label: "Assurance maladie", value: ESituation.assuranceMaladie },
    { label: "Accident du travail", value: ESituation.accidentTravail },
    { label: "Maternité", value: ESituation.maternite },
    { label: "Prévention maladie", value: ESituation.preventionMaladie },
    { label: "Soins médicaux gratuits", value: ESituation.soinsMedicauxGratuits }
  ];

  public supportATOptions = [
    { label: "Feuillet AT", value: ESitSupportAT.feuilletAT },
    { label: "Attestation d'affiliation", value: ESitSupportAT.attestationAffiliation },
    { label: "Courrier Caisse", value: ESitSupportAT.courrierCaisse },
    { label: "Déclaration de la victime", value: ESitSupportAT.declarationDeLaVictime },
  ];

  public erreurAdri: string;
  public erreurCv: string;

  constructor(
    private svcPatients: PatientsService,
    private svcCouvertures: CouverturesService,
    private fb: FormBuilder,
    private svcExport: ExportService,
    private cd: ChangeDetectorRef,
    private svcDrawerPopover: DrawerPopoverService,
    private svcPanneau: PanneauService,
    private svcUploadFile: UploadFileService,
    private svcLoader: LoaderService,
    private svcOlaqin: OlaqinService
  ) { }

  private ssnValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return { invalidSSN: 'Numéro de sécurité sociale vide' }; 

      const isValid = SSNHelper.verifySSN(control.value);
      return isValid ? null : { invalidSSN: 'Numéro de sécurité sociale invalide' };
    };
  }

  ngOnInit() {
    this.model = this.patient;
    this.amoForm = this.fb.group({
      numeroSecuriteSociale: [this.model ? this.model.socialNumber || '' : '', [this.ssnValidator()]],
      cpam: ['', Validators.required],
      qualite: ['', []],
      pieceJustificative: ['', Validators.required],
      garantie: ['', []],
      dateDebut: ['', []],
      dateFin: ['', []],
      situation: ['', []],
      situationFields: this.fb.group({})
    });
    this.formatSSN();

    // Lorsqu'on change la valeur de situation, on affiche/cache différents champ du form
    this.amoForm.get('situation').valueChanges.subscribe(value => {
      this.selectSituation(value)
    });

    // Lorsqu'on change la valeur de pièce justificative, on affiche/cache différents champ du form
    this.amoForm.get('pieceJustificative').valueChanges.subscribe(value => {
      this.selectPieceJustificative(value);
    });

    // Lorsqu'on change la valeur de numéro de sécurité sociale, on formate la valeur dans le champ du form
    this.amoForm.get('numeroSecuriteSociale').valueChanges.pipe(
      debounceTime(500)
    ).subscribe(value => {
      this.calculateSSNKey(value);
    });

    if (this.couverture?.amoId) {
      this.svcCouvertures.getEtablissementsByIds([this.couverture.amoId])
        .pipe(
          tap((etablissements: (IAMO)[]) => {
            this.amoVisible = ArrayHelper.getFirstElement(etablissements);
          })
        )
        .subscribe();
    }

    //Chargement de toutes les CPAM sélectionnables
    this.svcCouvertures.getEtablissements(ECouvertureType.AMOP).subscribe((amos: IAMO[]) => {
      this.cpam = amos;
      this.filteredCpam = this.cpam;
      this.fillAmoForm();
    })
    // Affichage des alertes ADRI et carte vitale
    this.setAdriLastCallMessage();
    this.setCVLastCallMessage();
    this.secured = this.couverture?.updateMode !== EUpdateMode.manual;

    this.svcOlaqin.erreurLectureCV$.pipe(
      tap((messageErreur) => {
        this.erreurCv = messageErreur || "Une erreur est survenue lors de la lecture du la CV";
        this.adriOrCVInProgress = false;
      })
    ).subscribe();
  }

  ngAfterViewInit(): void {
    if (this.carteVitale) {
      this.appelCV.nativeElement.scrollIntoView({ behavior: 'auto' });
      setTimeout(() => {
        this.readCarteVitale();
      }, 500);
    }
  }

  public get situationFields(): FormGroup {
    return this.amoForm.get('situationFields') as FormGroup;
  }

  public selectPieceJustificative(pieceJustificative: ETypePieceJustificative) {
    if (pieceJustificative !== ETypePieceJustificative.aucunePièce) {
      this.amoForm.addControl('datePieceJustificative', this.fb.control(DateHelper.toDateUrl(new Date())))
    } else {
      if (this.amoForm.contains('datePieceJustificative'))
        this.amoForm.removeControl('datePieceJustificative')
    }
  }

  // En fonction de la situation sélectionnée, on met à jour les champs du form
  public selectSituation(situation: string) {
    const situationFieldsGroup = this.amoForm.get('situationFields') as FormGroup;

    // On vide le situationFieldsGroup de ces inputs
    Object.keys(situationFieldsGroup.controls).forEach(controlName => {
      situationFieldsGroup.removeControl(controlName);
    });

    if (situation === ESituation.assuranceMaladie) {
    } else if (situation === ESituation.accidentTravail) {
      situationFieldsGroup.addControl('dateAccidentTravail', this.fb.control(''));
      situationFieldsGroup.addControl('supportAccidentTravail', this.fb.control(''));
      situationFieldsGroup.addControl('attestationAccidentTravail', this.fb.control(''));
      situationFieldsGroup.addControl('destinataireAccidentTravail', this.fb.control(''));
    } else if (situation === ESituation.maternite) {
      situationFieldsGroup.addControl('dateMaternite', this.fb.control(''));
    } else if (situation === ESituation.preventionMaladie) {
      situationFieldsGroup.addControl('datePreventionMaladie', this.fb.control(''));
      situationFieldsGroup.addControl('numeroPreventionMaladie', this.fb.control(''));
      situationFieldsGroup.addControl('infoPreventionMaladie', this.fb.control(''));
    } else if (situation === ESituation.soinsMedicauxGratuits) {
      situationFieldsGroup.addControl('montantSMGSoinsMedicauxGratuits', this.fb.control(''));
    }
  }

  // Formatte le numéro de sécurité sociale en ajoutant les espaces nécessaires
  public formatSSN(ssn?: string): void {
    if(!ssn){
      ssn = this.amoForm.get('numeroSecuriteSociale')?.value || '';
    }
    const formattedSSN = SSNHelper.formatSSN(ssn);
    this.amoForm.get('numeroSecuriteSociale')?.setValue(formattedSSN, { emitEvent: false });
  }

  // Calcul la clé du SSN
  public calculateSSNKey(ssn: string) {
    ssn = ssn.replace(/\s+/g, '');
    if (ssn.length === 13) {
      ssn += SSNHelper.getSSNKey(ssn);
      this.formatSSN(ssn);
    }
  }

  // Fonction de recherche de CPAM
  public updateFilteredEtablissements() {
    if (this.searchedCPAM.length === 0) {
      this.filteredCpam = this.cpam;
    } else {
      this.filteredCpam = this.cpam.filter((etablissement: IAMO) => {
        return etablissement.label.toLowerCase().includes(this.searchedCPAM.toLowerCase()) ||
          etablissement._id.replace("-", "").includes(this.searchedCPAM);
      });
    }
  }

  // Fonction exécutée à la sélection d'une CPAM. Affiche les garanties en lien avec la CPAM sélectionnée
  public onOptionClick(option: IAMO): void {
    const updateHistory = {
      date: this.couverture.updateDate = new Date(),
      mode: this.couverture.updateMode = EUpdateMode.manual
    };
    ArrayHelper.hasElements(this.couverture.updateHistory) ? this.couverture.updateHistory.unshift(updateHistory) : this.couverture.updateHistory = [updateHistory];
    this.garanties = CouverturesService.getFilteredGarantieOptions(option._id);
    this.couverture.amoId = option._id;
    this.amoForm.get('cpam')?.setValue(option.label);
    if (this.garanties.length > 1)
      this.amoForm.get('garantie')?.setValue(this.garanties[1].value);
    this.showOptions = false;
  }

  // Quand on clique sur l'input CPAM, on affiche les options CPAM
  public onInputClick(): void {
    this.updateFilteredEtablissements();
    this.showOptions = true;
  }

  // Quand on saisi une valeur dans le champ CPAM, on filtre les options CPAM
  public onInputChange(value: string): void {
    this.searchedCPAM = value;
    this.updateFilteredEtablissements();
  }

  // Si on clique en dehors du champs CPAM, alors on cache les options CPAM
  @HostListener('document:click', ['$event'])
  public onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const cpamElement = document.querySelector('.form-inputs__cpam');
    if (cpamElement && !cpamElement.contains(target)) {
      this.showOptions = false;
    }
  }

  // Formatte l'id d'un établissement CPAM
  public getFormatedId(poEtablissement: IAMO): string {
    return poEtablissement._id.split("_")[1].split("-").join(" ");
  }

  public toggleTierPayant(tierPayant: boolean) {
    this.tierPayant = tierPayant;
  }

  public toggleAld(ald: boolean) {
    this.ald = ald;
  }

  // Ajout d'un document attestation dans l'AMO
  public async addAttestation(file: File) {
    if (file) {
      if (file.size > 1000 * 1024) {
        try {
          const newFile: File = await FileHelper.resizeImageAnakn(file);
          this.listNewFile.push(newFile);
        } catch (error) {
          this.isError = true;
          console.error('Error resizing file:', error);
        }
      }
      else {
        this.isErrorPhoto = false;
        this.listNewFile.push(file);
      }
    }
  }

  // Suppression d'un nouveau document attestation dans l'AMO
  public deleteNewFile(event: File): void {
    this.listNewFile = [];
  }

  // Suppression d'un ancien document attestation dans l'AMO
  public deleteOldFile(event: IGalleryFile): void {
    const index = this.listDoc.findIndex(file =>
      file.guid === event.guid
    );

    if (index !== -1) {
      this.listDoc.splice(index, 1);
    }
  }

  // Exécute un appel ADRI
  public callAdri() {
    this.adriOrCVInProgress = true;
    const formValues = this.amoForm.value;
    this.model.socialNumber = formValues.numeroSecuriteSociale.replace(/\s+/g, '');
    const ss = formValues.numeroSecuriteSociale.replace(/\s+/g, '');
    this.erreurAdri = "";

    this.svcPatients.getPatient(this.model._id).pipe(
      switchMap((patient: IPatient) => {
        this.model = patient;
        if (this.model.socialNumber !== ss) {
          this.model.socialNumber = ss;
        }
        this.model.adriLastUpdate = new Date();
        this.model.updateDate = new Date();
        this.model.updateMode = EUpdateMode.ADRi;
        if (StringHelper.isBlank(this.model.externalId)) {
          return this.svcPatients.savePatientAnakin(this.model, true, this.appelAdri?.nativeElement).pipe(
            tap((result: boolean) => this.handleAdriResult(result))
          );
        } else {
          return this.svcExport.exportPatientAnakin(this.model, this.appelAdri?.nativeElement).pipe(
            tap((result: EExportResult) => this.handleAdriResult(result !== EExportResult.patientFailed))
          );
        }
      }),
      catchError((poError: ExportError | any) => {
        console.error(poError.message);
        this.erreurAdri = poError.message;
        return EMPTY;
      }),
      finalize(() => {
        this.adriOrCVInProgress = false;
      }),
      take(1)
    ).subscribe();
  }

  // Si l'appel ADRI est un succès alors on récupère les nouvelles infos du patient et on les affiche dans le panneau
  // Sinon on affiche une alerte d'erreur
  public handleAdriResult(result: boolean) {
    this.svcLoader.hideLoader();
    this.adriOrCVInProgress = false;
    if (result) {
      this.svcPanneau.close();
    } else {
      this.adriDone = "error";
      this.adriDoneMessage = `Les droits n'ont pas pu être vérifiés`;
    }
  }

  // Affiche l'alerte concernant le dernier appel ADRI de ce patient
  public setAdriLastCallMessage() {
    if (this.model.adriLastUpdate) {
      this.secured = true
      this.adriDone = "success";
      this.adriDoneMessage = `Les droits ont été vérifiés avec succès le ${DateHelper.transform(this.model.adriLastUpdate, ETimetablePattern.dd_MM_yyyy_slash)} à ${DateHelper.transform(this.model.adriLastUpdate, ETimetablePattern.HH_mm)}`;
    }
  }

  // Affiche l'alerte concernant la dernière lecture CV de ce patient
  public setCVLastCallMessage() {
    if (this.model.cvLastUpdate) {
      this.secured = true
      this.carteVitaleDone = "success";
      this.carteVitaleDoneMessage = `Lecture de carte vitale avec succès le ${DateHelper.transform(this.model.cvLastUpdate, ETimetablePattern.dd_MM_yyyy_slash)} à ${DateHelper.transform(this.model.cvLastUpdate, ETimetablePattern.HH_mm)}`;
    }
  }

  public isFormValid(): boolean {
    if (this.amoForm.get('numeroSecuriteSociale')?.valid && this.amoForm.get('cpam')?.value === "") {
      return false;
    }
    return this.amoForm.invalid;
  }

  // Prérempli le form si une couverture est renseignée
  public fillAmoForm() {
    if (this.couverture) {
      this.garanties = CouverturesService.getFilteredGarantieOptions(this.couverture.amoId);
      // this.onOptionClick(ArrayHelper.getFirstElement(this.filteredCpam.filter((cpam: IAMO) => cpam._id === this.couverture.amoId)));
      this.amoForm.get('cpam')?.setValue(this.amoVisible.label);
      this.amoForm.get('garantie')?.setValue(this.couverture.garantie);
      this.amoForm.get('qualite')?.setValue(this.couverture.qualite);
      this.amoForm.get('pieceJustificative')?.setValue(this.couverture.pieceJustificative);
      this.selectPieceJustificative(this.couverture.pieceJustificative);
      this.amoForm.get('situationDate')?.setValue(new Date(this.couverture.situationDate));
      this.amoForm.get('dateDebut')?.setValue(this.couverture.dateDebut ? DateHelper.toDateUrl(new Date(this.couverture.dateDebut)) : '');
      this.amoForm.get('dateFin')?.setValue(this.couverture.dateFin ? DateHelper.toDateUrl(new Date(this.couverture.dateFin)) : '');
      this.amoForm.get('situation')?.setValue(this.couverture.situation);
      this.selectSituation(this.couverture.situation)
      this.cd.detectChanges()
      this.amoForm.get('datePieceJustificative')?.setValue(this.couverture.datePieceJustificative ? DateHelper.toDateUrl(new Date(this.couverture.datePieceJustificative)) : '');
      this.situationFields.get('dateAccidentTravail')?.setValue(DateHelper.toDateUrl(new Date(this.couverture.situationDate)))
      this.situationFields.get('supportAccidentTravail')?.setValue(this.couverture.supportAT)
      this.situationFields.get('attestationAccidentTravail')?.setValue(this.couverture.numAttestationAT)
      this.situationFields.get('destinataireAccidentTravail')?.setValue(this.couverture.destinataireAT)
      this.situationFields.get('dateMaternite')?.setValue(DateHelper.toDateUrl(new Date(this.couverture.situationDate)))
      this.situationFields.get('datePreventionMaladie')?.setValue(DateHelper.toDateUrl(new Date(this.couverture.situationDate)))
      this.situationFields.get('numeroPreventionMaladie')?.setValue(this.couverture.numPM)
      this.situationFields.get('infoPreventionMaladie')?.setValue(this.couverture.champPM)
      this.situationFields.get('montantSMGSoinsMedicauxGratuits')?.setValue(this.couverture.montantSMG)
      this.ald = this.couverture.ald;
      this.tierPayant = this.couverture.tp;
      let creationType: string = "";
      switch (this.couverture.updateMode) {
        case EUpdateMode.ADRi:
          creationType = "par appel ADRI";
          break;
        case EUpdateMode.carteVitale:
          creationType = "par lecture de carte vitale";
          break;
        case EUpdateMode.manual:
        default:
          creationType = "manuelle"
          break;
      }
      this.creationDateMessage = `Mise à jour ${creationType} le ` + DateHelper.transform(new Date(this.couverture.updateDate), ETimetablePattern.dd_MM_yyyy_HH_mm_slash)
      this.listDoc = ArrayHelper.hasElements(this.couverture.files) ? [...this.couverture.files] : [];
    } else {
      this.couverture = new AMOP(this.model._id);
      this.creationDateMessage = "Création manuelle le " + DateHelper.transform(new Date(), ETimetablePattern.dd_MM_yyyy_HH_mm_slash)
      this.amoForm.get('qualite')?.setValue(EQualite.assure);
      this.amoForm.get('situation')?.setValue(ESituation.assuranceMaladie);
    }
  }

  // Exécute une lecture de la carte vitale
  public readCarteVitale() {
    this.erreurCv = "";
    this.adriOrCVInProgress = true;
    this.svcDrawerPopover.open("Lecture carte Vitale", "300px", this.appelCV?.nativeElement, LectureTerminalComponent, { patient: this.model });
    return this.svcDrawerPopover.closePopover$
      .pipe(
        takeWhile(_ => !this.erreurCv),
        tap(() => {
          this.adriOrCVInProgress = false;
          this.svcPanneau.close();
        }),
        catchError((poError: ExportError | any) => {
          console.error(poError.message);
          this.erreurCv = poError.message;
          return EMPTY;
        }),
        finalize(() => {
          this.adriOrCVInProgress = false;
        }),
        take(1)
      ).subscribe()
  }

  public goBack(): void {
    this.svcPanneau.close();
  }

  // Si le formulaire est valide, alors une sauvegarde du patient est effectuée puis une sauvegarde de l'AMO
  public handleSubmit(event: Event): void {
    event.preventDefault();
    const formValues = this.amoForm.value;
    
    if (this.amoForm.valid) {
      this.couverture.garantie = formValues.garantie.value ?? formValues.garantie
      this.couverture.ald = this.ald
      this.couverture.situation = formValues.situation
      this.couverture.tp = this.tierPayant
      if (formValues.dateDebut)
        this.couverture.dateDebut = formValues.dateDebut
      if (formValues.dateFin)
        this.couverture.dateFin = formValues.dateFin
      this.couverture.qualite = formValues.qualite
      this.couverture.pieceJustificative = formValues.pieceJustificative

      this.listNewFile.map((file: File) => {
        const dmsFile: DmsFile = new DmsFile(file, file.name);
        const galleryFile: IGalleryFile = {
          file: dmsFile,
          isNew: true,
          name: dmsFile.Name,
          description: "",
          guid: this.guidBuilder.build({ withHyphens: false, upperCase: false })
        };
        this.listDoc.push(galleryFile);
      });
      this.listNewFile = [];

      if (!this.couverture.files)
        this.couverture.files = [];
      this.couverture.files = this.listDoc;

      delete this.couverture.datePieceJustificative
      if (formValues.pieceJustificative !== ETypePieceJustificative.aucunePièce)
        this.couverture.datePieceJustificative = formValues.datePieceJustificative

      delete this.couverture.supportAT
      delete this.couverture.situationDate
      delete this.couverture.numAttestationAT
      delete this.couverture.destinataireAT
      delete this.couverture.situationDate
      delete this.couverture.situationDate
      delete this.couverture.numPM
      delete this.couverture.champPM
      delete this.couverture.montantSMG
      if (formValues.situation === ESituation.assuranceMaladie) {

      } else if (formValues.situation === ESituation.accidentTravail) {
        this.couverture.supportAT = formValues.situationFields.supportAccidentTravail
        this.couverture.situationDate = formValues.situationFields.dateAccidentTravail
        this.couverture.numAttestationAT = formValues.situationFields.attestationAccidentTravail
        this.couverture.destinataireAT = formValues.situationFields.destinataireAccidentTravail
      } else if (formValues.situation === ESituation.maternite) {
        this.couverture.situationDate = formValues.situationFields.dateMaternite
      } else if (formValues.situation === ESituation.preventionMaladie) {
        this.couverture.situationDate = formValues.situationFields.datePreventionMaladie
        this.couverture.numPM = formValues.situationFields.numeroPreventionMaladie
        this.couverture.champPM = formValues.situationFields.infoPreventionMaladie
      } else if (formValues.situation === ESituation.soinsMedicauxGratuits) {
        this.couverture.montantSMG = formValues.situationFields.montantSMGSoinsMedicauxGratuits
      }

      // getPatient pour récupérer le externalId qui a pu être assigné lors de l'appel Adri
      this.svcPatients.getPatient(this.model._id).pipe(
        tap((patient: IPatient) => {
          this.model = patient;
          this.model.socialNumber = formValues.numeroSecuriteSociale.replace(/\s+/g, '');
        }),
        switchMap(() => this.svcUploadFile.saveFilesWithErrorHandler(this.couverture.files, this.couverture._id)),
        switchMap(() => this.svcPatients.savePatientAnakin(this.model, false)),
        switchMap(() => {
          // puis on export l'amo dans fsv
          return this.svcCouvertures.saveCouvertureANAKIN(this.couverture, this.selectConvention).pipe(
            catchError((error) => {
              this.errorMessage = error.error.message;
              this.isError = true;
              throw (error);
            })
          );
        })
      )
        .subscribe();
    }else {
      // Sinon on ne save que numeroSecuriteSociale si il est valide
      const ssnControl = this.amoForm.get('numeroSecuriteSociale');
      if (ssnControl && ssnControl.valid) {
        this.svcPatients.getPatient(this.model._id).pipe(
          tap((patient: IPatient) => {
            this.model = patient;
            this.model.socialNumber = formValues.numeroSecuriteSociale.replace(/\s+/g, '');
          }),
          switchMap(() => this.svcPatients.savePatientAnakin(this.model, false)),
          tap(() => {
            this.svcPanneau.close();
          })
        ).subscribe();
      }
    }
  }

  // Suppression de l'AMO
  public deleteAmo(event: Event) {
    this.svcDrawerPopover.open("Confirmation de suppression", "30%", event.currentTarget, ConfirmationSuppressionComponent, {
      onConfirm: () => this.handleConfirm(),
      onCancel: () => this.handleCancel()
    })
  }

  private handleConfirm() {
    this.svcCouvertures.deleteCouverture(this.couverture, this.patient._id).pipe(
      switchMap((result: boolean) => {
        if (!result)
          return of(false)

        // On supprime au niveau du patient les informations sur la dernière lecture CV / appel ADRI 
        delete this.model.adriLastUpdate;
        delete this.model.cvLastUpdate;
        return this.svcPatients.savePatientAnakin(this.model).pipe(
          // A ce moment l'AMO a forcément été supprimé. si savePatientAnakin échoue on retourne true quand même
          map(() => true)
        );
      }),
    ).subscribe((response: boolean) => {
      if (response) {
        this.svcPanneau.close();
      } else {
        console.error("La suppression de la couverture a échoué")
      }
    });
    this.svcDrawerPopover.close();
  }

  private handleCancel() {
    this.svcDrawerPopover.close();
  }
}