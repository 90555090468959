import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from '../../../../../modules/patients/services/patients.service';
import { DateHelper } from '@osapp/helpers';

@Component({
  selector: 'di-ligne-patient',
  templateUrl: './ligne-patient.component.html',
  styleUrls: ['./ligne-patient.component.scss'],
})
export class LignePatientComponent implements OnInit {

  @Input()
  public patient: IPatient;

	@Input()
  public mobileViewForce? = false;

  @Output()
  public onClickLigne = new EventEmitter<string>();

  public adress = "";
  public age = "";
  public birthDate = "";
  public phoneNumber = "";
  public isDeceased: boolean;
	public fullLastName: string;
	public gender: string = "";

  constructor(
		private svcPatient : PatientsService
	) { }

  ngOnInit() {
    const { street, zipCode, city } = this.patient || {};
    const addressParts = [
      street,
      [zipCode, city].filter(Boolean).join(' ')
    ].filter(Boolean);
    this.adress = addressParts.join(', ');
		this.gender = this.getPatientGender(this.patient.gender);
		this.fullLastName = this.svcPatient.getFullNamePatient(this.patient); 
		if(this.patient.birthDate){
      const birthDateFormatted: Date = new Date(this.patient.birthDate);
      const today: Date = new Date();
      this.age = `${DateHelper.calculateAge(birthDateFormatted, today) } ans`
      this.birthDate = `${birthDateFormatted.getDate()} ${birthDateFormatted.toLocaleString('fr-FR', {month: 'long'})} ${birthDateFormatted.getFullYear()}`
    }
    if(this.patient.phone)
      this.phoneNumber = `${this.patient.phone?.replace(/(.{2})/g, '$1 ') ?? ""}`
  }

  public getPatientGender(gender: string): string {
    switch(gender){
      case '1':
        return 'homme';
      case '2':
        return 'femme';
      default:
        return '';
    }
  }

  onClick(patientId: string): void {
    this.onClickLigne.emit(patientId);
  }

}
