import { Component, DoCheck, Input, IterableDiffer, IterableDiffers, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DateHelper, NumberHelper } from '@osapp/helpers';
import { ISeance } from '../../../../../model/ISeance';
import { Traitement } from '../../../../../model/Traitement';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { StoredSeance } from '../../../../models/StoredSeance';
import { PanneauService } from '../../services/panneau.service';
import { DrawerPopoverService } from '../../services/drawer-popover.service';
import { DeviceService } from '../../services/device.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { IAMO } from 'apps/idl/src/modules/patients/model/iamo';
import { IAMC } from 'apps/idl/src/modules/patients/model/iamc';
import { AMCP } from 'apps/idl/src/modules/patients/model/amc-p';
import { Router } from '@angular/router';
import { SelectionTitulaireComponent } from '../../../facturation/components/selection-titulaire/selection-titulaire.component';
import { IContact } from '@osapp/model';
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { LoaderService } from '../../services/loader.service';
import { SeanceRealisationService } from '../../services/seance-realisation.service';
import { SeanceService } from '../../services/seance.service';
import { FormGroup } from '@angular/forms';
import { ESeancesListMode } from '../../enums/ESeancesListMode';
import { EBoutonIconFiltreSeancesListMode } from '../../enums/EBoutonIconFiltreSeancesListMode';
import { Invoice } from 'apps/idl/src/modules/facturation/models/invoice';
import { NB_SEANCE_MAX_TARIFICATION_AUTO } from 'apps/idl/src/anakin/shared/seances.constants';

@Component({
  selector: 'di-groupe-liste-seance',
  templateUrl: './groupe-liste-seances-component.html',
  styleUrls: ['./groupe-liste-seances-component.scss']
})
export class GroupeListeSeancesComponent extends DestroyableComponentBase implements OnInit, OnChanges, DoCheck {

  @Input() ordonnance: Traitement;
  @Input() patient: IPatient;
  @Input() seances?: StoredSeance[];
  @Input() facturationStart: boolean = false;
  @Input() isFacturable$: BehaviorSubject<boolean>;
  @Input() amoCouverture: IAMO;
  @Input() amcCouverture: IAMC;
  @Input() amcPatient: AMCP;
  @Input() infirmiers: IContact[];
  @Input() showSelectionTitulaire: boolean;
  @Input() onShowPanneauFacturation: (titulaireSelected?: IContact) => {};
  @Input() targetSeanceId?: string;

  @Input() nomPatientFormatted?: string;
  @Input() adressePatientFormatted?: string;
  @Input() libelleBtnDeplacement?: string;
  @Input() openPanneauDeplacement?: Function;
  @Input() filtreForm?: FormGroup;
  @Input() onDateFacturationChange?: Function;
  @Input() filterInfirmiersSelected$: BehaviorSubject<string[]>;
  @Input() openMenuFiltreInfirmier?: Function;
  @Input() openMenuFiltreEtat?: Function;
  @Input() colorFilter$: BehaviorSubject<string>;
  @Input() factureTarifiee: Invoice;
  @Input() showWarningTarification: boolean = false;

  public groupedSeances: { date: string, seances: ISeance[] }[] = [];
  public isMobile: boolean = false;
  public libelleBtnToutRealiser: string = "Tout réaliser";
  public isToutRealiser: boolean = false;
  public maxDateFacturation: Date = new Date();

  /**Gestion tarification à la volée */
  public libelleBtnFacturer: string = "Facturer";
  public afficherLoaderTarif: boolean = true;
  public warningTarificationMessage: string = `Pour des raisons de performances la tarification automatique est limitée à ${NB_SEANCE_MAX_TARIFICATION_AUTO} séances.`;

  private differenceSeances: IterableDiffer<StoredSeance>;
  public isFacturable: boolean;
  public colorFilter: string;
  public filterInfirmiersSelected: string[];
  @Input() filterEtatsSelected: string[];

  public seancesListMode: ESeancesListMode = ESeancesListMode.DETAILED;
  public seancesListOptions = Object.values(ESeancesListMode);
  public boutonIconSeancesListOptions = EBoutonIconFiltreSeancesListMode;


  constructor(
    private svcPanneau: PanneauService,
    private svcDrawerPopover: DrawerPopoverService,
    private router: Router,
    private svcDevice: DeviceService,
    private svcSeance: SeanceService,
    private svcSeanceRealisation: SeanceRealisationService,
    private svcLoader: LoaderService,
    private iterableDiffers: IterableDiffers
  ) {
    super();
    this.differenceSeances = this.iterableDiffers.find([]).create<StoredSeance>();
  }

  ngOnInit(): void {
    this.groupSeancesByDate();
    this.verifierEtatBtnRealiser();

    merge(
      this.svcDevice.isMobile$.pipe(tap(flag => this.isMobile = flag)),
      this.isFacturable$.pipe(tap(flag => this.isFacturable = flag)),
      this.colorFilter$.pipe(tap(color => this.colorFilter = color)),
      this.filterInfirmiersSelected$.pipe(tap(infirmiers => this.filterInfirmiersSelected = infirmiers))
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['seances']) {
      this.groupSeancesByDate();
    }
    if (changes['factureTarifiee']) {
      this.setLibelleBtnFacturer();
      this.setAfficherLoaderTarif();
    }
    this.verifierEtatBtnRealiser();
  }

  // Quand on ouvre le composant dans un panneau sur mobile, 
  // ngChange n'est pas déclenché lorsqu'on modifie le contenu de this.seances
  // On utilise iterableDiffers pour détecter un changement au niveau du contenu du this.seances.
  ngDoCheck(): void {
    if (this.differenceSeances.diff(this.seances)) {
      this.groupSeancesByDate();
      this.verifierEtatBtnRealiser();
    }
  }

  private setLibelleBtnFacturer() {
    if (this.factureTarifiee) {
      const nbSeancesAFacturer: number = this.seances.filter(x => x.status === EStatusSeance.done).length || 0;
      const totalAFacturer: number = this.factureTarifiee.totalPartAMC + this.factureTarifiee.totalPartAMO + this.factureTarifiee.totalPartPatient;
      const totalAFacturerFormate: string = NumberHelper.round(totalAFacturer, 2);
      this.libelleBtnFacturer = `Facturer  ${nbSeancesAFacturer} séance${nbSeancesAFacturer > 1 ? 's' : ''} (${totalAFacturerFormate} €)`;
    } else {
      this.libelleBtnFacturer = "Facturer";
    }
  }

  private setAfficherLoaderTarif() {
    //On affiche le loader de tarification si on a des séances à facturer
    const nbSeancesDone = this.seances.filter(x => x.status === EStatusSeance.done).length;
    this.afficherLoaderTarif = nbSeancesDone > 0 && nbSeancesDone <= NB_SEANCE_MAX_TARIFICATION_AUTO;
  }

  facturer(event?: Event) {
    if (this.showSelectionTitulaire) {
      this.openSelectionTitulaire(event);
    } else {
      this.onShowPanneauFacturation();
    }
  }

  private openSelectionTitulaire = (event: Event) => {
    this.svcDrawerPopover.open("Sélectionnez le titulaire",
      "auto",
      event.currentTarget,
      SelectionTitulaireComponent,
      {
        onFacturer: this.onShowPanneauFacturation,
        infirmiers: this.infirmiers.filter(x => !x.profiles || x.profiles.length === 0) //On retire les remplacants
      })
  }

  onClickCancel() {
    if (this.isMobile) {
      this.svcPanneau.close();
    } else {
      this.router.navigate(["facturation"]);
    }
  }


  private groupSeancesByDate(): void {
    const seancesByDate: { [key: string]: ISeance[] } = {};
    const today = new Date();


    this.seances.forEach(seance => {
      const dateKey = this.getFormattedDate(new Date(seance.startDate), today);
      if (!seancesByDate[dateKey]) {
        seancesByDate[dateKey] = [];
      }
      seancesByDate[dateKey].push(seance);
    });

    this.groupedSeances = [];
    this.groupedSeances = Object.keys(seancesByDate).map(date => ({
      date: date,
      seances: seancesByDate[date]
    }));
  }

  public getFormattedDate(date: Date, today: Date): string {

    const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const todayOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    const isToday = DateHelper.compareTwoDates(dateOnly, todayOnly) === 0;

    return isToday
      ? `Aujourd'hui, ${date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`
      : date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  }

  public handleClickRealiser() {
    const seancesToUpdate: StoredSeance[] = this.seances.filter((seance: StoredSeance) => seance.status !== EStatusSeance.canceled && seance.status !== EStatusSeance.paused);

    const seancesUpdated$: Observable<StoredSeance[]> = this.isToutRealiser
      ? this.svcSeanceRealisation.realiserSeancesSilent(seancesToUpdate, this.patient)
      : this.svcSeanceRealisation.annulerSeancesSilent(seancesToUpdate);

    const nombreSeances: number = seancesToUpdate.length;
    this.svcLoader.showLoader(this.isToutRealiser ? "Réalisation des " + nombreSeances + " séances..." : "Annulation des " + nombreSeances + " séances");

    seancesUpdated$.pipe(
      switchMap((seances: StoredSeance[]) => this.svcSeance.updateSeances(seances))
    ).subscribe();
  }


  private verifierEtatBtnRealiser() {
    this.isToutRealiser = this.seances.some(x => x.status === EStatusSeance.to_be_done);
    this.libelleBtnToutRealiser = this.isToutRealiser ? "Tout réaliser" : "Tout déréaliser";
  }

  public onSeancesListModeSelected(mode: ESeancesListMode): void {
    this.seancesListMode = mode;
  }
}
