export enum EDatabaseRole {
	applicationStorage = "applicationStorage",
	calendar = "calendar",
	components = "components",
	config = "config",
	contacts = "contacts",
	conversations = "conversations",
	conversationsStorage = "conversationsStorage",
	custom = "custom",
	dmsStorage = "dmsStorage",
	formsDefinitions = "formsDefinitions",
	formsEntries = "formsEntries",
	surveyEntries = "surveyEntries",
	votes = "votes",
	workspace = "workspace",
	applicationLog = "applicationLog",
	userContext = "userContext",
	plannings = "plannings",
	modules = "modules"		
}