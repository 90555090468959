<div class="page-parametres__container">
    <div class="page-parametres__page-gauche" *ngIf="showMenu">
        <h1 *ngIf="isMobileView">Paramètres</h1>
        <div class="menu-items">
            <div class="list-item">
                <lua-list-item 
                    iconName="mail" 
                    text="Utilisateurs" 
                    iconColor="CouleurPrimaire"
                    [isSelected]="!this.isMobileView && this.showUtilisateurs" 
                    (click)="handleSelectionUtilisateurs()">
                </lua-list-item>
            </div>
            <div class="list-item">
                <lua-list-item 
                    iconName="location_city" 
                    text="Cabinet" 
                    iconColor="CouleurPrimaire"
                    [isSelected]="!this.isMobileView && this.showCabinet" 
                    (click)="handleSelectionCabinet()">
                </lua-list-item>
            </div>
            <ng-container *ngIf="isSCE">
                <div class="list-item">
                    <lua-list-item 
                        iconName="smart_card_reader" 
                        text="Lecteurs" 
                        iconColor="CouleurPrimaire"
                        [isSelected]="!this.isMobileView && this.showLecteurs" 
                        (click)="handleSelectionLecteurs()">
                    </lua-list-item>
                </div>            
            </ng-container>
        </div>
    </div>
    <div class="page-parametres__page-droite" *ngIf="showPageDroite">
        <di-utilisateurs *ngIf="showUtilisateurs" [isMobileView]="isMobileView" [resetUsersList]="resetUsersList$" ></di-utilisateurs>
        <di-cabinet 
            *ngIf="showCabinet" 
            [onCancel]="handleRetourParametres"
            [isMobileView]="isMobileView">
        </di-cabinet>
        <lua-bouton-texte *ngIf="isMobileView && !showCabinet" libelle="Retour aux paramètres" [fullWidth]="true"
            (click)="handleRetourParametres()">
        </lua-bouton-texte>
    </div>
</div>
