import {
  Component,
  DoCheck,
  ElementRef,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lua-filtres',
  templateUrl: './filtres.component.html',
  styleUrls: ['./filtres.component.css'],
})
export class FiltresComponent implements OnInit, OnChanges, DoCheck {
  @Input() selectedFilters: string[] = [];
  @Input() label = '';
  @Input() iconName = '';
  @Input() color = 'Indigo';
  @Input() fullWidth = false;
  @Input() onClick: () => void = () => {};
  textColor = '';
  filtered = false;
  classes: {[key: string]: boolean} = {};

  private differenceSelectedFilters: IterableDiffer<string>;

  constructor(
    private el: ElementRef,
    private iterableDiffers: IterableDiffers
  ) {
    this.differenceSelectedFilters = this.iterableDiffers
      .find([])
      .create<string>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['color']) {
      this.updateColor();
    }

    if (changes['selectedFilters']) {
      this.initFiltre();
    }
  }

  ngOnInit(): void {
    this.initFiltre();
    this.updateColor();
  }

  ngDoCheck(): void {
    if (this.differenceSelectedFilters.diff(this.selectedFilters)) {
      this.initFiltre();
    }
  }

  private initFiltre() {
    this.filtered = this.selectedFilters.length > 0;
    this.textColor = this.filtered ? 'Blanc' : 'Dark2';
    this.updateClasses();
  }

  private updateClasses() {
    this.classes = {
      renseigne: this.filtered,
      'full-width': this.fullWidth,
    };
  }

  private updateColor() {
    this.el.nativeElement.style.setProperty(
      '--filtre-color',
      `var(--${this.color})`
    );
  }

  getClasses() {
    return this.classes;
  }
}
