<label
  class="lua-checkbox"
  [class.disabled]="disabled"
  [class.right]="position === 'R'"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <ng-container *ngIf="position === 'L'">
    <ng-container *ngTemplateOutlet="checkboxTemplate"></ng-container>
  </ng-container>

  <p *ngIf="label" [class.right]="position === 'R'" class="label-text">{{ label }}</p>

  <ng-container *ngIf="position === 'R'">
    <ng-container *ngTemplateOutlet="checkboxTemplate"></ng-container>
  </ng-container>
</label>

<ng-template #checkboxTemplate>
  <input
    type="checkbox"
    [disabled]="disabled"
    [checked]="checked"
    (change)="onCheckboxChange()"
  />
  <span *ngIf="!iconName" class="checkmark"></span>
  <lua-icone
    *ngIf="iconName"
    [iconName]="iconName"
    [mode]="checked ? 'filled' : 'outlined'"
    color="CouleurPrimaire"
    fontSize="20px"
    [isIcon]="false"
  >
  </lua-icone>
</ng-template>
