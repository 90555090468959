<div class="di-filtre-tiers-payant">
  <div class="liste-etats">
    <ng-container *ngFor="let etat of etatsSeance">
      <div class="etat">
        <lua-icone 
          [mode]="etat.icon.mode" 
          [iconName]="etat.icon.name" 
          [color]="etat.icon.color" 
          fontSize="20px">
        </lua-icone>
        <lua-checkbox 
          [label]="etat.label"
          position="R"
          [checked]="etatsSelected.includes(etat.status)"
          (checkedChange)="onSelection($event, etat.status)">
        </lua-checkbox>
      </div>
    </ng-container>
  </div>
  <lua-action-secondaire libelle="Filtrer les soins" size="large" [fullWidth]="false" (click)="afficher()"></lua-action-secondaire>
</div>