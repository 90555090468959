import { FormGroup } from '@angular/forms';
import { IStoreDocument } from '../model';
import { StringHelper } from "./stringHelper";

export abstract class FormHelper {

	//#region METHODS

	/** Renvoie un booléen qui indique si la valeur peut être remplie ou pas.
	 * @param pbOneTimeFilled Booléen qui indique si la valeur ne doit être remplie qu'une fois.
	 * @param poModel Modele a remplir.
	 * @param poValue Valeur a remplir.
	 */
	public static canBeFilled(pbOneTimeFilled: boolean, poModel: IStoreDocument, poValue: any): boolean {
		if (!pbOneTimeFilled)
			return true;
		else if (typeof poValue === "string")
			return StringHelper.isBlank(poValue);
		else
			return !poModel._rev || !poValue;
	}

	/**
	 * Supprime tous les espaces d'une chaîne de caractères.
	 * @param value La valeur à nettoyer.
	 * @returns La chaîne sans espaces.
	 */
	public static removeSpaces(value: string): string {
		return value.replace(/\s+/g, '');
	}

	/**
	 * Supprime les espaces d'un champ de formulaire au moment de la saisie.
	 * @param event L'événement `input`.
	 * @param form Le formulaire Angular.
	 * @param inputName Le nom du champ dans le formulaire.
	 */
	public static removeSpacesFromInput(event: Event, form: FormGroup, inputName: string): void {
		const inputElement = event.target as HTMLInputElement;
		const newValue = FormHelper.removeSpaces(inputElement.value);

		if (newValue !== inputElement.value) {
			form.get(inputName)?.setValue(newValue, { emitEvent: false });
		}
	}

	public static validFinessInput(event: Event, form: FormGroup, inputName: string): void {
    const maxLength = 9;
    this.removeSpacesFromInput(event, form, inputName);
    const input = form.get(inputName);
    if (input) {
        let value = input.value;
        value = value.replace(/\D/g, '');
        value = value.substring(0, maxLength);
        form.get(inputName)?.setValue(value, { emitEvent: false });
    }
	}

	//#endregion

}