<div class="di-menu-selection-infirmier">
	<div class="infirmier-list">
		<ng-container *ngIf="infirmiers">
			<div *ngFor="let infirmier of infirmiers; let i = index" class="infirmier" [class.right]="position === 'R'" >
        <ng-container *ngIf="position === 'L'">
          <lua-checkbox 
          [checked]="idsInfirmiersSelected.includes(infirmier._id)" 
          (checkedChange)="onSelection($event, infirmier)"
          ></lua-checkbox>
        </ng-container>
				<lua-objet-utilisateur 
          [firstName]="infirmier.firstName" 
          [lastName]="infirmier.lastName.toUpperCase()"
          [avatarColor]="infirmier.avatarCouleur" 
          [tags]="infirmier.profiles?.includes('remplacant') ? [remplacantTag] : []">
        </lua-objet-utilisateur>
        <ng-container *ngIf="position === 'R'">
          <lua-checkbox           
          [checked]="idsInfirmiersSelected.includes(infirmier._id)" 
          (checkedChange)="onSelection($event, infirmier)"
          ></lua-checkbox>
        </ng-container>
			</div>
      <div class="infirmier" [class.right]="position === 'R'" *ngIf="nonAffectes">
        <ng-container *ngIf="position === 'L'">
            <lua-checkbox 
            [checked]="idsInfirmiersSelected.includes('')" 
            (checkedChange)="onSelectionSeancesNonAffectees($event)"
            ></lua-checkbox>
          </ng-container>
          <lua-objet-utilisateur
            [isDefault]="true" 
            defaultLabel="Soins non affectés"
            >
          </lua-objet-utilisateur>
          <ng-container *ngIf="position === 'R'">
            <lua-checkbox           
            [checked]="idsInfirmiersSelected.includes('')" 
            (checkedChange)="onSelectionSeancesNonAffectees($event)"
            ></lua-checkbox>
          </ng-container>
        </div>
		</ng-container>
	</div>
	<lua-action-secondaire 
    [libelle]="libelleBtn" 
    size="large"
    [fullWidth]="false" 
    (click)="afficher()">
  </lua-action-secondaire>
</div>