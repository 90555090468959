import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DateHelper } from '@osapp/helpers';
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { EStatusSeance } from '../../../../../../model/EStatusSeance';
import { StoredSeance } from '../../../../../models/StoredSeance';
import { DrawerPopoverService } from '../../../services/drawer-popover.service';
import { PanneauService } from '../../../services/panneau.service';
import { SeanceService } from '../../../services/seance.service';
import { ITraitement } from 'apps/idl/src/model/ITraitement';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';

@Component({
  selector: 'di-panneau-mise-en-pause',
  templateUrl: './panneau-mise-en-pause.component.html',
  styleUrls: ['./panneau-mise-en-pause.component.scss'],
})
export class PanneauMiseEnPauseComponent extends DestroyableComponentBase implements OnInit {
  @Input() seance: StoredSeance;
  @Input() ordonnance: ITraitement;
  @Input() dateSeance?: Date;
  @Input() isMobile: boolean;
  @Input() onCancel: () => void;

  public mepForm: FormGroup;
  public dateFinPauseMinDate: string;

  constructor(
    private svcDrawerPopover: DrawerPopoverService,
    private svcPanneau: PanneauService,
    private svcSeance: SeanceService,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    const dateSeanceFormat: Date = this.dateSeance ?? new Date();

    this.mepForm = this.fb.group({
      dateDebutPause: [this.seance?.dateDebutPause ? DateHelper.toDateUrl(this.seance.dateDebutPause) : DateHelper.toDateUrl(dateSeanceFormat), Validators.required],
      dateFinPause: [this.seance?.dateFinPause ? DateHelper.toDateUrl(new Date(this.seance.dateFinPause)) : "", []],
      commentaire: [this.seance?.commentairePause ?? "", []]
    },
    { validators: this.validateDateRange });

    this.dateFinPauseMinDate = this.mepForm.get('dateDebutPause')?.value || DateHelper.toDateUrl(this.seance.dateDebutPause);

    this.mepForm.get('dateDebutPause')?.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (dateDebutPause: string) => {
        this.dateFinPauseMinDate = dateDebutPause && dateDebutPause !== ""
          ? dateDebutPause
          : DateHelper.toDateUrl(new Date(this.ordonnance.prescriptionDate));     
        }
    );
  }

  // Contrôle la validité des dates saisies
  validateDateRange(formGroup: AbstractControl): ValidationErrors | null {
    const dateDebutPause = formGroup.get('dateDebutPause')?.value;
    const dateFinPause = formGroup.get('dateFinPause')?.value;

    if (!dateFinPause) {
      return null;
    }

    if (DateHelper.compareTwoDates(new Date(dateFinPause), new Date(dateDebutPause)) < 0) {
      return { dateFinBeforeDebut: true }; 
    }

    return null;
  }

  cancel() {
    if (this.onCancel) this.onCancel();
  }

  reprendreLesSoins() {
    const dateReprise = new Date(this.seance.startDate);
    const newDateFinPause = DateHelper.addSeconds(new Date(this.seance.startDate), -1);

    this.svcSeance.selectSeancesByTraitement(this.seance.traitementId).pipe(
      map((seances: StoredSeance[]) => {
        const resumedSeances = this.resumeSeances(
          seances.filter((seance) => this.seance.dateFinPause 
          ? DateHelper.isBetweenTwoDates(seance.startDate, dateReprise, this.seance.dateFinPause) 
          : DateHelper.compareTwoDates(seance.startDate, dateReprise) >= 0)
        );

        const pausedSeances = this.pauseSeances(
          seances.filter((seance) => DateHelper.isBetweenTwoDates(seance.startDate, this.seance.dateDebutPause, newDateFinPause)),
          this.seance.dateDebutPause,
          newDateFinPause,
          this.seance.commentairePause
        );

        return [...resumedSeances, ...pausedSeances];
      }),
      mergeMap((seances: StoredSeance[]) => this.svcSeance.updateSeances(seances)),
      tap(() => this.close()),
      catchError((error) => {
        throw error;
      })
    ).subscribe();
  }
  
  mettreEnPause() {
    if(!this.mepForm.valid) return;

    const formValues = this.mepForm.value;
    const dateDebutPauseForm = new Date(formValues.dateDebutPause);
    const dateDebutSeance = new Date(this.seance.startDate);
    const dateDebutPause = DateHelper.areDayEqual(dateDebutPauseForm, dateDebutSeance) ? dateDebutSeance : DateHelper.resetDay(dateDebutPauseForm);
    const dateFinPause = formValues.dateFinPause !== '' ? DateHelper.fillDay(new Date(formValues.dateFinPause)) : undefined;
    const commentairePause = formValues.commentaire;
    
    this.svcSeance.selectSeancesByTraitement(this.seance.traitementId).pipe(
      map((seances: StoredSeance[]) => this.pauseSeances(
          seances.filter((seance: StoredSeance) => dateFinPause ? DateHelper.isBetweenTwoDates(seance.startDate, dateDebutPause, dateFinPause) : DateHelper.compareTwoDates(seance.startDate, dateDebutPause) >= 0),
          dateDebutPause,
          dateFinPause,
          commentairePause
        )),
        mergeMap((seances: StoredSeance[]) => this.svcSeance.updateSeances(seances)),
        tap(() => {
          this.close();
      }),
      catchError((error) => {
        throw (error);
      })
    ).subscribe();	 
  }
  
  pauseSeances(seances: StoredSeance[], dateDebut: Date, dateFin: Date, commentaire: string): StoredSeance[]{
    return seances.map((seance: StoredSeance) => {
      if (![EStatusSeance.to_be_done, EStatusSeance.paused].includes(seance.status)) return seance;
      seance.status = EStatusSeance.paused;
      seance.commentairePause = commentaire;
      seance.dateDebutPause = dateDebut;
      seance.dateFinPause = dateFin;
      return seance;
    })
  }

  resumeSeances(seances: StoredSeance[]): StoredSeance[] {
    return seances.map((seance: StoredSeance) => {
      if(seance.status !== EStatusSeance.paused) return seance;
      delete seance.dateDebutPause;
      delete seance.dateFinPause;
      delete seance.commentairePause;
      seance.status = EStatusSeance.to_be_done;
      return seance;
    })
  }
  
  private close(): void {
    this.svcDrawerPopover.close();
    this.svcPanneau.close();
  }
}
