import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class DeviceService {

    private readonly mobileWidthQuery = '(max-width: 768px)';
    private _isMobile: BehaviorSubject<boolean>;
		private _isMultiWS: Subject<boolean>;

    constructor(private breakpointObserver: BreakpointObserver) {
				this._isMultiWS = new Subject<boolean>()
        this._isMobile = new BehaviorSubject<boolean>(this.isMobile());
        this.setupBreakpointObserver();
    }

		emitShowBandeau(isMulti : boolean) {
			this._isMultiWS.next(isMulti);
		}

    private setupBreakpointObserver(): void {
        this.breakpointObserver.observe([this.mobileWidthQuery])
            .pipe(
                map((state: BreakpointState) => state.matches)
            )
            .subscribe(isMobile => this._isMobile.next(isMobile));
    }

    private isMobile(): boolean {
        return window.matchMedia(this.mobileWidthQuery).matches;
    }

    get isMobile$(): Observable<boolean> {
        return this._isMobile.asObservable();
    }

		get _isMultiWS$(): Observable<boolean> {
			return this._isMultiWS.asObservable();
	}

}