import { Exclude, Expose } from 'class-transformer';
import { DateHelper } from '../../../helpers';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { EPrefix } from '../../../model/EPrefix';
import { ResolveModel } from '../../utils/models/decorators/resolve-model.decorator';
import { ModelResolver } from '../../utils/models/model-resolver';
import { EPrestationStatus } from "./eprestation-status.enum";
import { IPrestation } from "./iprestation";
import { PrestationLine } from './prestation-line';

export class Prestation<T extends PrestationLine = PrestationLine> implements IPrestation<T> {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public _deleted?: boolean;
	/** @implements */
	public deleted?: boolean;
	/** @implements */
	public _conflicts?: string[];
	/** @implements */
	@ResolveModel(PrestationLine)
	public lines: T[];
	@Exclude()
	private meStatus: EPrestationStatus;
	/** @implements */
	@Expose()
	public get status(): EPrestationStatus { return this.meStatus ?? EPrestationStatus.created; }
	public set status(peStatus: EPrestationStatus) {
		if (peStatus !== this.meStatus) {
			this.meStatus = peStatus;
		}
	}

	/** @implements */
	public vendorId: string;
	/** @implements */
	public seanceId?: string;

	/** @implements */
	public authorId: string;
	/** @implements */
	public createDate: Date;
	/** @implements */
	public lastUpdateDate: Date;
	/** @implements */
	@ResolveModel(PrestationLine)
	public originalLines: T[];
	/** @implements */
	public originalStatus: EPrestationStatus;
	/** @implements */
	public originalVendorId: string;
	/** @implements */
	public observation?: string;
	/** Prix total de la facture. */
	public get total(): number {
		return this.lines?.reduce<number>((pnTotal: number, poLine: T) => {
			if (poLine.deleted)
				return pnTotal;
			return pnTotal + poLine.total;
		}, 0);
	}

	@Exclude()
	private mdDate: Date;
	public get dateFromId(): Date {
		if (!this.mdDate)
			this.mdDate = DateHelper.parseReverseDate(this.dateStringFromId);

		return this.mdDate;
	}

	@Exclude()
	private mdDateString: string;
	public get dateStringFromId(): string {
		if (!this.mdDateString)
			this.mdDateString = ArrayHelper.getFirstElement(IdHelper.extractDatesStringsFromId(this._id));

		return this.mdDateString;
	}

	public get statusIcon(): string {
		return Prestation.getPrestationStatusIcon(this.status);
	}

	public get statusLabel(): string {
		return Prestation.getPrestationStatusLabel(this.status);
	}

	public get statusColor(): string {
		return Prestation.getPrestationStatusColor(this.status);
	}

	public get originalStatusIcon(): string {
		return Prestation.getPrestationStatusIcon(this.originalStatus);
	}

	public get originalStatusLabel(): string {
		return Prestation.getPrestationStatusLabel(this.originalStatus);
	}

	public get originalStatusColor(): string {
		return Prestation.getPrestationStatusColor(this.originalStatus);
	}

	public get isLocked(): boolean {
		return this.status === EPrestationStatus.canceled || this.status === EPrestationStatus.locked;
	}

	@Exclude()
	private msCustomerId: string;
	public get customerId(): string {
		if (!this.msCustomerId)
			this.msCustomerId = Prestation.extractCustomerId(this._id);

		return this.msCustomerId;
	}

	@Exclude()
	private msSiteId: string;
	public get siteId(): string {
		if (!this.msSiteId)
			this.msSiteId = Prestation.extractSiteId(this._id);

		return this.msSiteId;
	}

	/** @implements */
	public tarification?: boolean;

	//#endregion

	//#region METHODS

	constructor(poData?: IPrestation) {
		if (poData)
			ObjectHelper.assign(this, poData);
	}

	public static getPrestationStatusIcon(peStatus: EPrestationStatus): string {
		switch (peStatus) {
			case EPrestationStatus.created:
				return "date-validation";
			case EPrestationStatus.canceled:
				return "date-supp";
			case EPrestationStatus.checked:
				return "checkmark";
			case EPrestationStatus.sent:
				return "invoice";
			case EPrestationStatus.locked:
				return "lock-closed-outline";
		};
	}

	public static getPrestationStatusLabel(peStatus: EPrestationStatus): string {
		switch (peStatus) {
			case EPrestationStatus.created:
				return "Créée";
			case EPrestationStatus.canceled:
				return "Annulée";
			case EPrestationStatus.checked:
				return "Vérifiée";
			case EPrestationStatus.sent:
				return "Envoyée";
			case EPrestationStatus.locked:
				return "Bloquée";
		};
	}

	public static getPrestationStatusColor(peStatus: EPrestationStatus): string {
		switch (peStatus) {
			case EPrestationStatus.created:
			case EPrestationStatus.sent:
			case EPrestationStatus.checked:
				return "success";
			case EPrestationStatus.canceled:
			case EPrestationStatus.locked:
				return "danger";
		};
	}

	public static extractCustomerId(psPrestationId: string): string {
		return ArrayHelper.getLastElement(IdHelper.extractAllIds(psPrestationId)) ?? "";
	}

	public static extractSiteId(psPrestationId: string): string {
		return IdHelper.extractAllIds(psPrestationId).find((psPartId: string) => psPartId.startsWith(EPrefix.site)) ?? "";
	}

	/** Réinitialise la facture. */
	public reset(): Prestation {
		this.lines = this.originalLines.map((poLine: T) => ModelResolver.toClass({ ...poLine.reset() }, PrestationLine) as T);
		this.vendorId = this.originalVendorId;
		this.status = this.originalStatus;

		return this;
	}

	//#endregion

}
