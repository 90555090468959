import { RouteComponent } from "../model";
import { SiteDetailsComponent } from "../modules/sites/components/site-details/site-details.component";
import { ApplicationSettingsComponent } from "./applicationSettings/applicationSettings.component";
import { SecuritySettingsComponent } from "./applicationSettings/sections/securitySettings.component";
import { AuthenticatorComponent } from './authenticator/authenticator.component';
import { ContactComponent } from './contacts/contact/contact.component';
import { ContactsComponent } from "./contacts/contacts.component";
import { ContactSelectorComponent } from "./contacts/contactSelector/contactSelector.component";
import { GroupDetailsComponent } from './contacts/group-details/group-details.component';
import { DiagnosticsComponent } from "./diagnostics/diagnostics.component";
import { DynamicPageComponent } from "./dynamicPage/dynamicPage.component";
import { EntitySelectorComponent } from "./entity/entitySelector/entitySelector.component";
import { EntityTypeSelectorComponent } from "./entity/entityTypeSelector/entityTypeSelector.component";
import { FilePickerComponent } from "./filePicker/filePicker.component";
import { FormComponent } from "./forms/form/form.component";
import { FormListComponent } from "./forms/formList/formList.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { GoogleMapComponent } from "./geolocation/googleMap.component";
import { LikeComponent } from "./like/like.component";
import { LinkedItemsListComponent } from "./linkedItemsList/linkedItemsList.component";
import { MenuComponent } from "./menu/menu.component";
import { SearchComponent } from "./search/search.component";
import { SlideboxComponent } from "./slidebox/slidebox.component";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { WebViewComponent } from './webview/webview.component';

/** Liste de tous les composants d'OSApp */
export const RouteComponentRegistries: RouteComponent[] = [
	{ id: 'applicationSettings', componentType: ApplicationSettingsComponent },
	{ id: 'authenticator', componentType: AuthenticatorComponent },
	{ id: 'contact-form', componentType: ContactComponent },
	{ id: 'contact-selector', componentType: ContactSelectorComponent },
	{ id: 'contacts-book', componentType: ContactsComponent },
	{ id: 'dynamicPage', componentType: DynamicPageComponent },
	{ id: 'filePicker', componentType: FilePickerComponent },
	{ id: 'group-details', componentType: GroupDetailsComponent },
	{ id: 'site-details', componentType: SiteDetailsComponent },
	{ id: 'form', componentType: FormComponent },
	{ id: 'formList', componentType: FormListComponent },
	{ id: 'gallery', componentType: GalleryComponent },
	{ id: 'googleMap', componentType: GoogleMapComponent },
	{ id: 'home', componentType: MenuComponent },
	{ id: 'like', componentType: LikeComponent },
	{ id: 'linkedItemsList', componentType: LinkedItemsListComponent },
	{ id: 'menu', componentType: MenuComponent },
	{ id: 'search', componentType: SearchComponent },
	{ id: 'securitySettings', componentType: SecuritySettingsComponent },
	{ id: 'slideBox', componentType: SlideboxComponent },
	{ id: 'webview', componentType: WebViewComponent },
	{ id: 'entityTypeSelector', componentType: EntityTypeSelectorComponent },
	{ id: 'entitySelector', componentType: EntitySelectorComponent },
	{ id: 'toolbar', componentType: ToolbarComponent },
	{ id: "diagnostics-page", componentType: DiagnosticsComponent },
];
