import { Component, OnDestroy, OnInit } from '@angular/core';
import { ArrayHelper, DateHelper } from '@osapp/helpers';
import { EPrefix, ETimetablePattern, IContact, IGroup } from '@osapp/model';
import { IViewCountResult } from '@osapp/model/IViewCountResult';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService, GroupsService } from '@osapp/services';
import { Observable, merge, of } from 'rxjs';
import { concatMap, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Traitement } from '../../../../model/Traitement';
import { IPatient } from '../../../../modules/patients/model/IPatient';
import { PatientsService } from '../../../../modules/patients/services/patients.service';
import { TraitementService } from '../../../../services/traitement.service';
import { NB_ITEM_TO_DISPLAY } from '../../../anakin.constants';
import { FiltreInfirmiersComponent } from '../../../features/facturation/components/filtres/filtre-infirmiers/filtre-infirmiers.component';
import { PanneauPatientOrdonanceComponent } from '../../../features/ordonnances/components/panneau-patient-ordonnance/panneau-patient-ordonnance.component';
import { EProfile } from '../../../features/shared/enums/EProfile';
import { DeviceService } from '../../../features/shared/services/device.service';
import { DrawerPopoverService } from '../../../features/shared/services/drawer-popover.service';
import { LoaderService } from '../../../features/shared/services/loader.service';
import { PanneauService } from '../../../features/shared/services/panneau.service';
import { SeanceService } from '../../../features/shared/services/seance.service';

@Component({
  selector: 'di-ordonnance',
  templateUrl: './ordonnance.page.html',
  styleUrls: ['./ordonnance.page.scss'],
})
export class OrdonnancePage extends DestroyableComponentBase implements OnInit, OnDestroy {
  public ordonnancefilters: Array<string> = ["ACTIVES", "TERMINÉES"];
  public selectedFilter= this.ordonnancefilters[0];
	public ordoActiveCountMessage :string;
	public isMobileView: boolean = false;
	public searchedValue : string = "";
	public ordonnances: Traitement[] = [];
  public filteredOrdonnances: Traitement[] = [];
	public MainTextNoResult : string;
	public SubTextNoResult : string;
  public patientsMap: Map<string, IPatient> = new Map();
  public displayCount: number = NB_ITEM_TO_DISPLAY;
 
  public countSeancesByStatus: IViewCountResult[];
  public countSeancesByStatusAndInfirmiers: IViewCountResult[];
  public infirmiers: IContact[] = [];
  public idsInfirmiersSelected: string[] = [];
  public filterInfirmiersSelected: string[] = [];
  public isMultipleInfirmiers: boolean = false;
	public colorFilter : string = "Indigo";

  constructor( 
		private svcDevice : DeviceService,
		private svcPatient : PatientsService,
		private svcPanneau: PanneauService,
		private svcTraitement : TraitementService,	
    private svcSeance: SeanceService,
    private svcLoader: LoaderService,
		private svcDrawerPopover: DrawerPopoverService,
    private svcContact: ContactsService,
		private svcGroupe : GroupsService
	) {
    super()
  }

  ngOnInit() {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
      this.isMobileView = flag;
    });

		merge(
			this.svcTraitement.deletion$,
			this.svcPanneau.closePanel$,
      this.svcTraitement.ordonnanceChanges$.pipe(skip(1))
		).pipe(
			takeUntil(this.destroyed$)
		).subscribe(() => {
			this.loadListOrdonnances();
		});

    this.loadListOrdonnances();
	}

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.svcLoader.hideLoader();
  }

  private loadListOrdonnances() : void {
    this.svcLoader.showLoader("Récupération des ordonnances...");
    this.getOrdonnances().pipe(
      concatMap(() => this.getWorkspaceInfirmiers()),
      concatMap(() => 
        this.isMultipleInfirmiers 
        ? this.mapOrdonnancesToInfirmiers().pipe(
          concatMap(() => this.getSeancesCountsByInfirmiers()),
          ) 
        : of(null)),
      concatMap(() => this.addPatientToOrdonnances()),
      concatMap(() => this.svcSeance.getSeancesCountsForOrdonnances(this.ordonnances).pipe(
        tap(({ ordonnances, countSeancesByStatus }) => {
          this.ordonnances = ordonnances;
          this.countSeancesByStatus = countSeancesByStatus;
        })
      )),
      tap(() => {
        this.applyFilters();
        this.svcLoader.hideLoader();  
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	private getOrdonnances(): Observable<Traitement[]> {
    //TODO : Si demain on a beaucoup de traitement à récupérer il faudra utiliser les options "skip" et "limit" 
    //car actuellement on récupère tous les traitements
    return this.svcTraitement.getAllTraitementsOrdonnances().pipe(
        tap((traitements: Traitement[]) => {
            if (traitements.length === 0) {
                this.ordonnances = [];
            } else {
                this.ordonnances = traitements.map(traitement => {
                  traitement.createDate = new Date(traitement.createDate); 
                  return traitement
                });

                this.ordonnances.sort((a, b) => {
                    return (b.createDate as Date).getTime() - (a.createDate as Date).getTime();
                });
            }
        })
    );
  }

  private applyFilters(): void{
    this.filterOrdonnancesByStatus();
    this.filterOrdonnancesBySearchedValue();
    if (this.isMultipleInfirmiers){
      this.filterOrdonnancesByInfirmier();
    }
    this.setOrdonnancesCountLabel();
  }

  private filterOrdonnancesByStatus(): void {
    if (this.selectedFilter === "ACTIVES") {
      // Actives : ordonnance avec au moins une séance 'to be done' OU sans aucune séance générée
      this.filteredOrdonnances = this.ordonnances.filter((ordonnance: Traitement) => {
        const treatmentEntry = this.countSeancesByStatus.find((traitement: IViewCountResult) =>
          traitement.key[0] === ordonnance._id && traitement.key[1] === 6
        );
        return (treatmentEntry?.value ?? 0) > 0 || ordonnance.countSeancesTotal === 0;
      });

    } else if (this.selectedFilter === "TERMINÉES") {
      // Terminées : aucune séance 'to be done' ET des séances générées
      this.filteredOrdonnances = this.ordonnances.filter((ordonnance: Traitement) => {
        const treatmentEntry = this.countSeancesByStatus.find((traitement: IViewCountResult) =>
          traitement.key[0] === ordonnance._id && traitement.key[1] === 6
        );
        return (treatmentEntry?.value ?? 0) === 0 && ordonnance.countSeancesTotal > 0;
      });
    }

    this.filteredOrdonnances.sort((a, b) => {
      return (b.createDate as Date).getTime() - (a.createDate as Date).getTime();
    });
  }

  public showMore() {
    this.displayCount += NB_ITEM_TO_DISPLAY;
  }

  public searchOrdonnances(searchValue: string){
    this.searchedValue = searchValue.trim().toLowerCase();
    this.applyFilters();
  }

  public filterOrdonnancesBySearchedValue() {
    if (!this.searchedValue || this.searchedValue.length <= 2) return;
		const searchTerms = this.searchedValue.split(/\s+/).filter(term => term);
    this.filteredOrdonnances = this.filteredOrdonnances.filter((ordonnance: Traitement) => {
      const patient = this.patientsMap.get(Traitement.extractPatientId(ordonnance._id));
      if (!patient) return false;

      const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
      const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';
      const usualLastName = patient.usualLastName ? patient.usualLastName.toLowerCase() : '';
      const formattedBirthDateSlash = DateHelper.transform(patient.birthDate, ETimetablePattern.dd_MM_yyyy_slash);

      const fullName = `${firstName} ${usualLastName} ${lastName} ${formattedBirthDateSlash}`.trim();

				return searchTerms.every(term => fullName.includes(term));
    });
  }

  public showOrdonnanceList() {
    return this.filteredOrdonnances.length > 0;
  }

	public setOrdonnancesCountLabel() : void {
    if (this.filteredOrdonnances.length === 0){
      if (this.searchedValue.length <= 2){
        this.MainTextNoResult = "Aucune ordonnance";
        this.SubTextNoResult = "Vous allez retrouver ici toutes les ordonnances par patient.";
      }else{
        this.MainTextNoResult = "Aucune ordonnance trouvée";
        this.SubTextNoResult = "Essayer de modifier votre recherche";
      }
    }
    const nbOrdoActive = this.filteredOrdonnances.length ;
		if(nbOrdoActive == 0){
			this.ordoActiveCountMessage = "Aucune ordonnance";
		}else{
      this.ordoActiveCountMessage = `${nbOrdoActive} ordonnance${nbOrdoActive > 1 ? `s ${this.selectedFilter.toLowerCase()}` : ` ${this.selectedFilter.toLowerCase().slice(0, -1)}`}`;
		}
  }

	public changeFiltre(filtre: string){
    this.selectedFilter	= filtre;
    this.applyFilters();
  }

  public handleClickAddOrdonnance(event: Event) {
    this.svcPanneau.open("Patients",PanneauPatientOrdonanceComponent,{ })
  }

  openMenuFiltreInfirmier(event: Event) {
    this.svcDrawerPopover.open(
      "",
      "",
      event.currentTarget,
      FiltreInfirmiersComponent,
      {
        infirmiers: this.infirmiers,
        idsInfirmiersSelected: this.idsInfirmiersSelected,
        onFilter: this.selectionNewInfirmiers
      }
    );
  }

  selectionNewInfirmiers = (idsInfirmiers: string[]): void => {
    this.idsInfirmiersSelected = idsInfirmiers;
    this.filterInfirmiersSelected = this.infirmiers
      .filter((infirmier: IContact) => this.idsInfirmiersSelected.includes(infirmier._id))
      .map((infirmier: IContact) => {
				if(this.idsInfirmiersSelected.length === 1)
					this.colorFilter = infirmier.avatarCouleur ?? "Indigo"
				else
				this.colorFilter = "Indigo"
        const firstLetter = infirmier.firstName?.trimStart().charAt(0).toUpperCase() || "";
        return `${firstLetter}${infirmier.firstName?.slice(1) || ""} ${infirmier.lastName.toUpperCase()}`;
      });
    this.svcDrawerPopover.close();
    this.applyFilters();
  }

  public filterOrdonnancesByInfirmier() {
    if(this.idsInfirmiersSelected.length === 0) return;
    this.filteredOrdonnances = this.filteredOrdonnances.filter((ordonnance: Traitement) => {
      return (ordonnance.infirmierIds ?? []).some((id: string) => this.idsInfirmiersSelected.includes(id));
    });
    this.updateSeancesCountsByInfirmiers();
  }

  public getSeancesCountsByInfirmiers(): Observable<IViewCountResult[]>{
    return this.svcSeance.countSeancesGroupByStatusAndInfirmier().pipe(
      tap((result: IViewCountResult[]) => {
        this.countSeancesByStatusAndInfirmiers = result;
      })
    );
  }

  public updateSeancesCountsByInfirmiers(): void{
    if(this.idsInfirmiersSelected.length > 0){
      const countsMap = this.countSeancesByStatusAndInfirmiers.reduce((acc, viewCountKey) => {
        const [ordonnanceId, infirmierId, status] = viewCountKey.key;
        if (!acc[ordonnanceId]) {
          acc[ordonnanceId] = { total: 0, done: 0, completed: 0,to_be_done:0,paused:0,canceled:0 };
        }
        if (this.idsInfirmiersSelected.includes(infirmierId as string)) {
          if (status === 2) {
            acc[ordonnanceId].done += viewCountKey.value;
          } else if (status === 5) {
            acc[ordonnanceId].completed += viewCountKey.value;
          }else if (status === 7) {
            acc[ordonnanceId].paused += viewCountKey.value;
          }else if (status === 6) {
            acc[ordonnanceId].to_be_done += viewCountKey.value;
          }
					else if (status === 3) {
            acc[ordonnanceId].canceled += viewCountKey.value;
          }
          acc[ordonnanceId].total += viewCountKey.value;
        }
        return acc;
      }, {});
      this.filteredOrdonnances = [...this.filteredOrdonnances.map((ordonnance: Traitement) => {
        const counts = countsMap[ordonnance._id];
        return {
          ...ordonnance,
          countSeancesTotal: counts?.total ?? 0,
          countSeancesDone: counts?.done ?? 0,
          countSeancesCompleted: counts?.completed ?? 0,
					countSeancesPaused: counts?.paused || 0,
					countSeancesToBeDone: counts?.to_be_done || 0,
					countSeancesCanceled: counts?.canceled || 0,

        } as Traitement;
      })];
    }else{
      this.filteredOrdonnances = this.filteredOrdonnances.map((filteredOrdonnance: Traitement) => {
        const ordonnanceWithCounts = this.ordonnances.find(
          (ordonnance: Traitement) => ordonnance._id === filteredOrdonnance._id
        );
        if (!ordonnanceWithCounts) return filteredOrdonnance;
        
        return {
          ...filteredOrdonnance,
          countSeancesTotal: ordonnanceWithCounts.countSeancesTotal,
          countSeancesDone: ordonnanceWithCounts.countSeancesDone,
          countSeancesCompleted: ordonnanceWithCounts.countSeancesCompleted,
					countSeancesPaused: ordonnanceWithCounts.countSeancesPaused,
					countSeancesToBeDone: ordonnanceWithCounts.countSeancesToBeDone,
        } as Traitement;
      });
    }
  }

  public getWorkspaceInfirmiers(): Observable<IContact[]> {
		return this.svcContact.getSiteContactsAnakin([], EPrefix.contact, true, true).pipe(
			tap((contacts: IContact[]) => {
				this.infirmiers = contacts.filter(contact => contact.userId);
				this.isMultipleInfirmiers = this.infirmiers.length > 1;
			}),
			switchMap((contacts: IContact[]) =>
				this.svcGroupe.getContactsGroups(contacts.map((infi: IContact) => infi._id)).pipe(
					map((infiGroups: Map<string, IGroup[]>) => {
						return contacts.map((inf: IContact) => {
							const groups = infiGroups.get(inf._id) || [];
							if (groups.some(group => group._id === 'grp_remplacant')) {
								inf.profiles = ArrayHelper.hasElements(inf.profiles)
									? Array.from(new Set([...inf.profiles, EProfile.remplacant]))
									: [EProfile.remplacant];
							}
							return inf;
						});
					})
				)
			)
		);
	}
	
  
  // Construit la map qui associe chaque ordonnance aux infirmiers qui ont des séances assignées
  public mapOrdonnancesToInfirmiers(): Observable<IViewCountResult[]>{
    return this.svcSeance.selectSeancesGroupByInfirmier().pipe(
      tap((result: IViewCountResult[]) => {
        result.forEach((ordonnanceInfimier: IViewCountResult) => {
          const [ordonnanceId, infirmierId] = ordonnanceInfimier.key as string[];
          const ordonnanceFound = this.ordonnances.find((ordonnance: Traitement) => ordonnance._id === ordonnanceId);
          if (ordonnanceFound) {
            ordonnanceFound.infirmierIds = Array.from(new Set([...(ordonnanceFound.infirmierIds || []), infirmierId]));
          }
        })
      })
    )
  }

  // Ajoute le patient à chaque ordonnance
  public addPatientToOrdonnances(): Observable<IPatient[]>{
    const mapOrdoPatients = this.ordonnances.map((ordonnance: Traitement) => Traitement.extractPatientId(ordonnance._id)).filter(id => id);
    const setOrdoPatients = new Set(mapOrdoPatients);
    const patientIds = Array.from(setOrdoPatients);
      return this.svcPatient.getPatientsByIds(patientIds).pipe(
        tap((patients: IPatient[]) => {
          // //TODO : a conserver pour vérifier que les migrations sont correctes et qu'aucun patient n'a été supprimé
          // if (patientIds.length !== patients.length) {
          //   // Récupérer les IDs des patients obtenus depuis la BDD
          //   const patientIdsRetrieved = patients.map(patient => patient._id);

          //   // Trouver les IDs manquants en comparant la liste originale avec la liste récupérée
          //   const missingIds = patientIds.filter(id => !patientIdsRetrieved.includes(id));
          //     console.error("patients manquants", missingIds.filter(Boolean).join(";"));
          // }
          this.patientsMap = new Map(patients.map(patient => [patient._id, patient]));
          this.ordonnances.forEach((ordonnance: Traitement) => {
            ordonnance.patient = this.patientsMap.get(Traitement.extractPatientId(ordonnance._id));
          });
        }
      )
    )
  }
}