import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHelper, IdHelper, StringHelper } from '@osapp/helpers';
import { EPrefix, IContact } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { EExerciceCondition } from '../../../../../modules/patients/model/EExerciceCondition';
import { ConfirmationSuppressionComponent } from '../../../shared/components/confirmation-suppression/confirmation-suppression.component';
import { DeviceService } from '../../../shared/services/device.service';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { GeocodingService } from '../../../shared/services/geocoding.service';
import { PanneauService } from '../../../shared/services/panneau.service';
import { optsConditionExercice, optsSpeExercice } from "../model/constants";

@Component({
  selector: 'di-form-contacts',
  templateUrl: './form-contacts.component.html',
  styleUrls: ['./form-contacts.component.scss'],
})
export class FormContactsComponent extends DestroyableComponentBase implements OnInit, OnChanges {

  @Input() public contact?: IContact = null;
  @Input() public patient?: IPatient = null;
  @Input() public context?: string = null;
  @Input() public onDeleteContact: (contactId: string) => void = () => { };
  @Input() public onSaveContact: (contactId: string) => void = () => { };
  @Input() public onCancelContact: () => void = () => { };

  public isMedecin: boolean = false;
  public isCallDisabled: boolean = false;
  public isEmailDisabled: boolean = false;
  public isItineraryDisabled: boolean = false;
  public isMessageDisabled: boolean = true;
  public texteSecondaireIsMedecin: string = "Il sera proposé lors de la saisie d'une ordonnance.";
  public mode: "add" | "edit" = "add";
  public phoneNumber: string = '';

  public optsConditionExercice = optsConditionExercice;
	public optsSpeExercice = optsSpeExercice.sort((a, b) => a.label.localeCompare(b.label));

  // True si on créé le médecin traitant d'un patient
  public medecinTraitantCreation: boolean = false;
  public contactPatientCreation: boolean = false;

  // True si le contact a créer doit être un prescripteur d'ordonnance
  public prescripteurCreation: boolean = false;

  public isMobileView: boolean = false;

  public contactForm: FormGroup;

  // Route vers laquelle redirigée au submit
  public redirectRoute: string[];
  // Contexte dans lequel le formulaire a été appelé (ex: édition d'un patient, création d'une ordonnance, édition d'une ordonnance)
  public redirectContext: string;
  // Le contexte peut soit être l'ajout ou l'édition
  public redirectContextMode: string;

  // True si le formulaire est ouvert dans un panneau
  public isPanneau: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private svcContact: ContactsService,
    private fb: FormBuilder,
    private svcDrawerPopover: DrawerPopoverService,
    private svcGeocoding: GeocodingService,
    private svcPatient: PatientsService,
    private svcDevice: DeviceService,
    private svcPanneau: PanneauService
  ) {
    super();
    // Récupération de la redirection à faire onSubmit si existante
    const state = this.router.getCurrentNavigation()?.extras.state as { route: string[], context: string, mode: string, patient: IPatient };
    if (state) {
      this.redirectRoute = state.route;
      this.redirectContext = state.context;
      this.redirectContextMode = state.mode;
      this.patient = state.patient;
      switch (this.redirectContext) {
        case 'ordonnance':
          // On est dans le contexte de création d'un prescripteur d'ordonnance
          this.prescripteurCreation = true;
          break;
        case 'patient_medecin':
          // On est dans le contexte d'ajout d'un médecin traitant pour un patient
          this.medecinTraitantCreation = true;
          break;
        case 'patient_contact':
          // On est dans le contexte d'ajout d'un contact lié pour un patient
          this.contactPatientCreation = true;
          break;
        default: break;
      }
    }
  }

  ngOnInit() {
    this.svcDevice.isMobile$.subscribe((flag: boolean) => {
      this.isMobileView = flag;
    });

    switch (this.context) {
      case 'patient_contact':
        // On est dans le contexte d'ajout d'un contact lié pour un patient
        this.contactPatientCreation = true;
        break;
      case 'panneau':
        this.isPanneau = true;
        break;
      default: break;
    }

    this.initContact();
    this.initModeForm();
    this.initFormContact();
  }

  ngOnChanges() {
    this.initContact();
    this.initModeForm();
    this.initFormContact();
  }

  initFormContact() {
    this.contactForm = this.fb.group({
      firstName: [this.contact.firstName ?? ""],
      lastName: [this.contact.lastName ?? "", Validators.required],
      finess: [this.contact.finess ?? ""],
      facturationNumber: [this.contact.rpps ?? this.contact.adeli ?? ""],
      phone: [this.contact.phone ? this.formatPhoneNumber(this.contact.phone) : ""],
      email: [this.contact.email ?? "", Validators.email],
      street: [this.contact.street ?? ""],
      zipCode: [this.contact.zipCode ?? ""],
      city: [this.contact.city ?? ""],
      comment: [this.contact.comment ?? ""],
      exerciceCondition: [this.contact.exerciceCondition ?? EExerciceCondition.liberal],
      codeSpecialite: [this.contact.codeSpecialite ?? "" ,this.getSpecialiteValidators() ]
    });

		this.contactForm.get('exerciceCondition')?.valueChanges.subscribe(value => {
			this.updateSpecialiteValidators(value);
		});

    this.initContactInfosMedical();
    this.controlValidationForm();
  }

	private getSpecialiteValidators(): ValidatorFn[] {
		return this.contact.exerciceCondition !== EExerciceCondition.liberal ? [Validators.required] : [];
	}

	private updateSpecialiteValidators(value: EExerciceCondition): void {
		const codeSpecialiteControl = this.contactForm.get('codeSpecialite');
		if (!codeSpecialiteControl) return;
	
		if (value !== EExerciceCondition.liberal) {
			codeSpecialiteControl.setValidators([Validators.required]);
		} else {
			codeSpecialiteControl.clearValidators();
		}
		codeSpecialiteControl.updateValueAndValidity();
	}

  public shouldShowSpecialiteSelect(): boolean {
		return this.contactForm.get('exerciceCondition')?.value !== EExerciceCondition.liberal;
	}

  initContact() {
    if (!this.contact) {
      this.initContactDefault();

      //Permet de récupérer le contact si on sélectionne un contact en vue mobile 
      //ou 
      //si un contact est sélectionné et que l'on passe en vue mobile
      this.route.paramMap.subscribe(params => {
        const contactId = params.get('id');
        if (contactId) {
          this.getContact(contactId).then(() => {
            this.initValueErgo()
            this.initModeForm();
            this.initFormContact();
          });
        }
      });
    }
  }

  private initValueErgo(): void {
    this.contact.isDoctor = this.contact.isDoctor || !!this.contact.finess;
  }

  private initContactInfosMedical() {
    if (this.medecinTraitantCreation || this.prescripteurCreation) {
      this.isMedecin = true;
    } else {
      this.isMedecin = this.contact.isDoctor;
    }
  }

  initContactDefault() {
    this.contact = {
      _id: "",
      lastName: "",
      firstName: "",
      exerciceCondition: EExerciceCondition.liberal
    };
  }

  initModeForm() {
    this.mode = this.contact._id ? "edit" : "add";
  }

  toggleIsMedecin(isMedecin: boolean) {
    //Le switch me retourne la nouvelle valeur du slider
    this.isMedecin = isMedecin;
    this.resetInfosMedical();
  }

  handleClickSaveBtn() {
    //penser à ajouter les anti-click a répétition
    this.saveContact();
  }

  handleClickCancelBtn() {
    if (this.context) {
      this.onCancelContact();
      this.redirect();
    } else {
      this.initFormContact();
      this.onCancelContact();
      if (this.redirectRoute) {
        this.contact = null;
        this.redirect()
      } else if (this.isMobileView) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(["contacts"])
        });
      }
    }

  }

  makeCallContact() {
    if (this.contact?.phone) {
      window.location.href = `tel:${this.contact.phone}`;
    }
  }

  sendMailContact() {
    if (this.contact?.email) {
      window.location.href = `mailto:${this.contact?.email}`;
    }
  }

  deleteContact(event: Event) {
    //TODO: ajouter un loader
    this.svcDrawerPopover.open("Confirmation de suppression", "50%", event.currentTarget, ConfirmationSuppressionComponent, {
      onConfirm: () => this.handleConfirm(),
      onCancel: () => this.handleCancel()
    })
  }

  private handleConfirm() {
    this.svcDrawerPopover.close();
    this.svcContact.deleteContactAnakin(this.contact).subscribe((isDeleted: boolean) => {
      if (isDeleted) {
        this.onDeleteContact(this.contact._id);
        if (this.isMobileView) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(["contacts"])
          });
        }
      }
    });
  }

  private handleCancel() {
    this.svcDrawerPopover.close();
  }

  //Permet d'éviter la saisie d'espace et de copier coller un espace
  removeSpacesFromInput(event: Event, inputName: string) {
    FormHelper.removeSpacesFromInput(event, this.contactForm, inputName);
  }

	validFinessInput(event: any, inputName: string): void {
		FormHelper.validFinessInput(event, this.contactForm, inputName);
	}


  public onPhoneInputFormat(event: Event) {
    let input = (event.target as HTMLInputElement).value;
    this.phoneNumber = this.formatPhoneNumber(input);
    this.contactForm.get('phone')?.setValue(this.phoneNumber, { emitEvent: false });
  }

  private formatPhoneNumber(input: string): string {
    input = input.replace(/\D/g, '');
    return input.match(/.{1,2}/g)?.join(' ') || '';
  }

  private resetInfosMedical() {
    this.contactForm.patchValue({
      finess: '',
      facturationNumber: '',
      exerciceCondition: EExerciceCondition.liberal
    });
  }

  private async getContact(idContact) {
    this.contact = await this.svcContact.getContact(idContact).toPromise();
  }

  private controlValidationForm(): void {
    Object.keys(this.contactForm.controls).forEach(field => {
      const control = this.contactForm.get(field);
      control?.updateValueAndValidity();
    });
  }

  private saveContact() {
    this.contact = { ...this.contact, ...this.contactForm.value };
    this.contact = this.svcContact.setRppsAdeliValue(this.contact);
    this.contact.phone &&= FormHelper.removeSpaces(this.contact.phone);

    if (this.isMedecin) {
      //s'il est medecin
      this.contact.isDoctor = this.isMedecin;
      if (!this.contact.exerciceCondition) {
        this.contact.exerciceCondition = null;
      }

      //On vide les espaces à l'enregistrement si les champs existents
      this.contact.facturationNumber &&= FormHelper.removeSpaces(this.contact.facturationNumber);
      this.contact.finess &&= FormHelper.removeSpaces(this.contact.finess);
    } else {
      //s'il n'est pas medecin il ne doit pas avoir ses champs
      delete this.contact.exerciceCondition;
      delete this.contact.facturationNumber;
      delete this.contact.finess;
      this.contact.isDoctor = this.isMedecin;
    }

    if (StringHelper.isBlank(this.contact._id)) {
      this.contact._id = IdHelper.buildId(EPrefix.contact);
    }

    // Dans le cas où c'est le contact d'un patient, et que ce n'est pas un médecin traitant ni un prescripteur d'ordonnance
    if (this.patient && !this.medecinTraitantCreation && !this.prescripteurCreation) {
      this.contact.isLinkPatient = true;
    }

    //TODO: ajouter un loader
    this.svcContact.saveContactAnakin(this.contact).pipe(
      switchMap((isSaved: boolean) => {
        if (!isSaved) {
          return of(false);
        }

        // On sauvegarde ce nouveau contact en tant que médecin traitant du patient
        if (this.medecinTraitantCreation) {
          // On crée un médecin traitant, il faut que isDoctor soit true
          if (this.contact.isDoctor) {
            this.patient.doctors = [this.contact._id];
            return this.svcPatient.savePatientAnakin(this.patient)
          } else {
            return of(false)
          }
        }
        if (this.contactPatientCreation) {
          this.patient.contacts = [this.contact._id];
          return this.svcPatient.savePatientAnakin(this.patient)
        }
        return of(true)
      })).subscribe((result: boolean) => {
        if (!result) {
          return;
        }

        if (this.redirectRoute || this.context) {
          this.redirect();
        } else {
          this.router.navigate(["contacts"])
        }

        this.onSaveContact(this.contact._id);
      });
  }

  public navigateToAddress() {
    const address = `${this.contact.street} ${this.contact.zipCode} ${this.contact.city}`;
    this.svcGeocoding.getGeolocation(address).pipe(
      mergeMap(coords => {
        return this.svcGeocoding.navigateToCoordinates(coords.lat, coords.lng);
      })
    ).subscribe();
  }

  public redirect() {
    if (this.isPanneau) {
      this.svcPanneau.close();
      return;
    }
    if (this.redirectRoute || this.context) {
      let state = {};
      switch (this.redirectContext || this.context) {
        case 'ordonnance':
          if (this.redirectContextMode === 'add') {
            // Redirection vers la page de création d'ordonnance avec le patient et le prescripteur créé
            state = { patientId: this.patient._id, medecin: this.contact };
          } else if (this.redirectContextMode === 'edit') {
            // Redirection vers la page d'édition d'ordonnance avec le prescripteur créé
            state = { medecin: this.contact };
          }
          break;
        case 'patient_medecin':
        case 'patient_contact':
          this.redirectRoute = ['patients', this.patient._id];
          // Redirection vers la page détail patient dans l'onglet Informations
          state = { tabSelected: "Informations" };
          break;
        default:
          break;
      }
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(this.redirectRoute, { state: state })
      })
    }
  }

	allFormValid(){
		const finessValue = this.contactForm.get('finess')?.value;
    return this.contactForm.valid && (!this.isMedecin || !finessValue || finessValue.length === 9);
	}
}