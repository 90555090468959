import { Component, Input, OnInit } from '@angular/core';
import { BlobUrlManager } from '@osapp/components/image/model/BlobUrlManager';

@Component({
  selector: 'di-confirmation-impression',
  templateUrl: './confirmation-impression.component.html',
  styleUrls: ['./confirmation-impression.component.scss'],
})
export class ConfirmationImpressionComponent implements OnInit {

	isLargeScreen: boolean = false;
	private readonly moBlobUrlManager = new BlobUrlManager();

	@Input() withCerfa: () => void;
	@Input() withOutCerfa: () => void;
	@Input() cancel: () => void;

  constructor() {}

  ngOnInit(): void {
	
  }

	
	onWithCerfaClick() {
		if (this.withCerfa) {
			this.withCerfa();
		}
	}

	onWithOutCerfaClick() {
		if (this.withOutCerfa) {
			this.withOutCerfa();
		}
	}

	onCancel() {
		if (this.cancel) {
			this.cancel();
		}
	}
  

}
