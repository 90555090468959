import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DateHelper, FileHelper } from "@osapp/helpers";
import { EPrefix, ETimetablePattern, IContact, IGalleryFile, IStoreDataResponse } from "@osapp/model";
import { DmsFile } from "@osapp/modules/dms/model/DmsFile";
import { LongGuidBuilder } from "@osapp/modules/guid/models/long-guid-builder";
import { ContactsService, PlatformService, Store } from "@osapp/services";
import { IActe } from "apps/idl/src/model/IActe";
import { ITraitement } from "apps/idl/src/model/ITraitement";
import { IAccordPrealable } from "apps/idl/src/modules/patients/model/IAccordPrealable";
import { AccordPrealableService } from "apps/idl/src/modules/traitement/slides-traitement/accordPrealable/accordPrealable.service";
import { EMPTY, from, of } from "rxjs";
import { catchError, delay, finalize, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { DrawerPopoverService } from "../../services/drawer-popover.service";
import { PanneauService } from "../../services/panneau.service";
import { UploadFileService } from "../../services/uploadFile.service";
import { ConfirmationSuppressionComponent } from "../confirmation-suppression/confirmation-suppression.component";


@Component({
	selector: "di-accord-prealable",
	templateUrl: "./accord-prealable.component.html",
	styleUrls: ["./accord-prealable.component.scss"],
})
export class AccordPrealableComponent implements OnInit {

	@Input()
	public ordonnance: ITraitement;

	@Input()
	public accord?: IAccordPrealable;

	public accordPrealableDemandeForm: FormGroup;
	public accordPrealableRetourForm: FormGroup;

	public actes: IActe[];
	public infirmiers: { label: string, value: string }[];

	public selectedRetourDemande: boolean;
	public actesUrgents: boolean;
	public actesEnSerie: boolean;

	public errorMessagePhoto: string = "Le fichier sélectionné est trop volumineux (5Mo maximum)";
	public isErrorPhoto: boolean = false;

	public fileRetour: IGalleryFile;
	public newFileRetour: File;
	private readonly guidBuilder = new LongGuidBuilder();

	// Erreur concernant la création de la demande d'AP
	public erreurImpression: string;
	// Erreur concernant l'impression de la demande d'AP
	public warningImpression: string;

	constructor(
		private svcContact: ContactsService,
		private fb: FormBuilder,
		private svcPanneau: PanneauService,
		private svcAccordPrealable: AccordPrealableService,
		private cdr: ChangeDetectorRef,
		private svcUploadFile: UploadFileService,
		private svcDrawerPopover: DrawerPopoverService,
		private psvcPlatform: PlatformService,
		private svcStore : Store
		
	) { }

	ngOnInit(): void {
		this.actes = this.ordonnance.actes.filter(acte => acte.isPriorAgreement);
		this.svcContact.getSiteContactsAnakin([], EPrefix.contact, true, true).pipe(
			map((contacts: IContact[]) => {
				return contacts.map((contact: IContact) => {
					if (!contact.isDoctor && contact.finess)
						contact.isDoctor = true;
					return contact;
				});
			}),
			tap((contacts: IContact[]) => {
				// On affiche seulements les infirmiers qui ont un numéro rpps ou adeli 
				this.infirmiers = contacts.filter(contact => contact.userId && (contact.rpps || contact.adeli)).map((infirmier: IContact) => ({
					label: `${infirmier.firstName} ${infirmier.lastName.toUpperCase()}`,
					value: infirmier._id
				}));
			})
		).subscribe();

		this.accordPrealableDemandeForm = this.fb.group({
			dateDemande: [this.accord?.dateDemande || '', Validators.required],
			demandeur: [this.accord?.demandeurAPId || '', Validators.required],
			justification: [this.accord?.elementMedicaux || ''],
		});
		this.actesUrgents = this.accord?.isUrgent || false;
		this.actesEnSerie = this.accord?.isSerie || false;

		this.accordPrealableRetourForm = this.fb.group({
			dateRetour: [this.accord?.dateResultat || '', Validators.required],
		});
		this.selectedRetourDemande = this.accord?.resultat ?? null;

		this.fileRetour = this.accord?.fileResultat;

		this.cdr.detectChanges();
	}

	public toggleActeUrgents(value: boolean) {
		this.actesUrgents = value;
	}

	public toggleActeEnSerie(value: boolean) {
		this.actesEnSerie = value;
	}

	public selectRetourDemande(value: boolean) {
		if (this.selectedRetourDemande === value) {
			this.selectedRetourDemande = null;
		} else {
			this.selectedRetourDemande = value
		}
	}

	// Ajout d'un document dans l'accord préalable
	public async addDocument(file: File) {
		if (file) {
			if (file.size > 1000 * 1024) {
				try {
					const newFile: File = await FileHelper.resizeImageAnakn(file);
					this.newFileRetour = newFile;
				} catch (error) {
					this.isErrorPhoto = true;
					console.error('Error resizing file:', error);
				}
			}
			else {
				this.isErrorPhoto = false;
				this.newFileRetour = file;
			}
		}
	}

	// Suppression d'un nouveau document attestation dans l'accord préalable
	public deleteNewFile(event: File): void {
		this.newFileRetour = null;
	}

	// Suppression d'un ancien document attestation dans l'accord préalable
	public deleteOldFile(event: IGalleryFile): void {
		this.fileRetour = null;
	}

	public handleSubmitDemande(event: Event): void {
		event.preventDefault();
		if (!this.accordPrealableDemandeForm.valid)
			return;

		const formValues = this.accordPrealableDemandeForm.value;
		const updatedAccord = {
			dateDemande: DateHelper.transform(new Date(formValues.dateDemande), ETimetablePattern.isoFormat_hyphen),
			lastUpdate: DateHelper.transform(new Date(), ETimetablePattern.isoFormat_hyphen),
			isUrgent: this.actesUrgents,
			isSerie: this.actesEnSerie,
			elementMedicaux: formValues.justification,
			demandeurAPId: formValues.demandeur,
		};

		if (!this.accord) {
			this.accord = {
				_id: `${EPrefix.accordPrealable}${this.ordonnance._id}`,
				traitement: this.ordonnance._id,
				...updatedAccord,
			};
		} else {
			Object.assign(this.accord, updatedAccord);
		}

		this.svcAccordPrealable.createOrUpdate(this.accord)
			.pipe(
				switchMap((reponse) => 
					this.psvcPlatform.isMobileApp 
						? this.svcStore.replicateSingleDocument(this.accord._id).pipe(map(() => updatedAccord)) 
						: of(reponse)
				),				
				switchMap((reponse: IStoreDataResponse) => {
					this.erreurImpression = "";
					if (reponse) {
						return from(this.svcAccordPrealable.downloadApPdf(this.accord)).pipe(
							catchError((error) => {
								this.warningImpression = "Demande créée mais impression impossible : " + error.error.message;
								this.erreurImpression = "";
								return EMPTY;
							})
						);
					}
					return EMPTY;
				}),
				catchError((error) => {
					console.log('Error occurred:', error);
					this.warningImpression = "";
					this.erreurImpression = error.error.message;
					return EMPTY;
				}),
				finalize(() => {
					if (!this.erreurImpression && !this.warningImpression) {
						this.svcPanneau.close();
					}
				})
			).subscribe();
	}

	public handleSubmitRetour(event: Event): void {
		event.preventDefault();
		// Si le form n'est pas valide (pas de date de retour ou pas de radio button OUI/NON coché) OU que la demande n'a pas été submit 
		if (!this.accordPrealableRetourForm.valid || !(this.selectedRetourDemande === false || this.selectedRetourDemande) || !this.accord)
			return;

		const formValues = this.accordPrealableRetourForm.value;
		this.accord.dateResultat = DateHelper.transform(new Date(formValues.dateRetour), ETimetablePattern.isoFormat_hyphen);
		this.accord.resultat = this.selectedRetourDemande;
		if (this.newFileRetour) {
			const dmsFile: DmsFile = new DmsFile(this.newFileRetour, this.newFileRetour.name);
			const galleryFile: IGalleryFile = {
				file: dmsFile,
				isNew: true,
				name: dmsFile.Name,
				description: "",
				guid: this.guidBuilder.build({ withHyphens: false, upperCase: false })
			};
			this.fileRetour = galleryFile;
		}
		if (this.fileRetour) {
			this.accord.fileResultat = this.fileRetour;
		} else {
			delete this.accord.fileResultat
		}

		this.svcAccordPrealable.createOrUpdate(this.accord)
			.pipe(
				mergeMap(() => {
					if (this.accord.fileResultat)
						return this.svcUploadFile.saveFilesWithErrorHandler([this.accord.fileResultat], this.accord._id)
					return EMPTY
				}),
				// Délai pour que l'enregistrement du fichier dans DMS ait le temps d'être finalisé
				delay(1000),
			).subscribe({
				complete: () => this.svcPanneau.close()
			})
	}

	public handleCancel() {
		this.svcPanneau.close();
	}


	public deleteAP(event: Event) {
		this.svcDrawerPopover.open("Confirmation de suppression", "30%", event.currentTarget, ConfirmationSuppressionComponent, {
			onConfirm: () => this.handleConfirmDelete(),
			onCancel: () => this.handleCancelDelete()
		})
	}

	private handleConfirmDelete() {
		if (this.accord) {
			this.svcAccordPrealable.delete(this.accord).pipe(
				tap(() => {
					this.svcDrawerPopover.close();
					this.svcPanneau.close();
				})
			).subscribe()
		}
	}

	private handleCancelDelete() {
		this.svcDrawerPopover.close();
	}

}
