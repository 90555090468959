<div class="lua-objet-utilisateur" [ngClass]="getClasses()">
  <ng-container *ngIf="!isDefault">
    <lua-avatar
      [firstName]="firstName"
      [lastName]="lastName"
      [color]="avatarColor"
      [withTooltip]="false"
    ></lua-avatar>
    <div class="text-container">
      <div class="name-line">
        <div>{{ lastName + ' ' + firstName }}</div>
        <div class="tags">
          <ng-container *ngFor="let tag of tags">
            <lua-tag
              [label]="tag.tagLabel"
              [color]="tag.tagColor"
              [mode]="tag.tagMode"
            ></lua-tag>
          </ng-container>
        </div>
      </div>
      <div *ngIf="info" class="info-line">{{ info }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="isDefault">
    <lua-avatar
      color="Noir20"
      [withTooltip]="false"
    ></lua-avatar>
    <div class="text-container">
      <div class="name-line">
        <div>{{ defaultLabel }}</div>
      </div>
    </div>
  </ng-container>
</div>
