import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Keyboard } from '@capacitor/keyboard';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { IonInput, NavController } from '@ionic/angular';
import { LifeCycleObserverComponentBase } from '@osapp/helpers/LifeCycleObserverComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { UserData } from '@osapp/model/application/UserData';
import { EAuthenticationAction } from '@osapp/model/authenticator/EAuthenticationAction';
import { IAuthenticatorParams } from '@osapp/model/authenticator/IAuthenticatorParams';
import { ConfigData } from '@osapp/model/config/ConfigData';
import { ELifeCycleEvent } from '@osapp/model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '@osapp/model/lifeCycle/ILifeCycleEvent';
import { Credentials } from '@osapp/model/security/Credentials';
import { EUnlockMode } from '@osapp/model/security/EUnlockMode';
import { ICredentialsDocument } from '@osapp/model/security/ICredentialsDocument';
import { ISecuritySettingsDocument } from '@osapp/model/security/ISecuritySettingsDocument';
import { IUpdate } from '@osapp/model/update/IUpdate';
import { IWorkspace } from '@osapp/model/workspaces/IWorkspace';
import { UserContactService } from '@osapp/modules/contacts/userContact/services/user-contact.service';
import { EFlag } from '@osapp/modules/flags/models/EFlag';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { PerformanceManager } from '@osapp/modules/performance/PerformanceManager';
import { EPermissionsFlag } from '@osapp/modules/permissions/models/EPermissionsFlag';
import { PinService } from '@osapp/modules/security/pin/services/pin.service';
import { ISite } from '@osapp/modules/sites/models/isite';
import { SitesService } from '@osapp/modules/sites/services/sites.service';
import { ApplicationService } from '@osapp/services/application.service';
import { FlagService } from '@osapp/services/flag.service';
import { GlobalDataService } from '@osapp/services/global-data.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@osapp/services/loading.service';
import { PageManagerService } from '@osapp/services/pageManager.service';
import { PlatformService } from '@osapp/services/platform.service';
import { SecurityService } from '@osapp/services/security.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { UpdateService } from '@osapp/services/update.service';
import { WorkspaceService } from '@osapp/services/workspace.service';
import { EMPTY, Observable, Subscription, concat, defer, from, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mapTo, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ConnexionService } from '../../features/connexion/services/connexion.service';
import { ErrorService } from '../../features/connexion/services/error.service';
import { AuthenticatorService } from '../../features/shared/services/authenticator.service';
import { DeviceService } from '../../features/shared/services/device.service';
import { LoaderService } from '../../features/shared/services/loader.service';

type UnlockMode = "biometric" | "face" | "finger";

export interface ControlConfig {
  name: string;
  value: any;
  validators?: ValidatorFn[] | ValidatorFn;
}

@Component({
	selector: "calao-authenticator",
	templateUrl: 'authenticator.component.html',
	styleUrls: ['./authenticator.component.scss'],
})
export class AuthenticatorComponent extends LifeCycleObserverComponentBase implements OnInit, OnDestroy, AfterViewInit {

	//#region FIELDS

	public static readonly C_COMPONENT_NAME = "authenticator";

	/** Clé de recherche du document NoSQL de paramétrage dans la vue par type. */
	private static readonly C_AUTHENTICATION_COMPONENT_TYPE_KEY: string = AuthenticatorComponent.C_COMPONENT_NAME;

	private static readonly C_LOG_ID = "AUTH.C::";

	private static readonly C_NO_AVAILABLE_UPDATE_VERSION = "Aucune";

	/** Evénement du clic sur le lien des changelogs. */
	@Output("onChangelogsClicked") private readonly moChangelogsClickedEvent = new EventEmitter<void>();

	private mbAuthenticatingUser = false;
	/** Fonction d'abonnement pour désactiver le backButton natif, l'exécuter pour se désabonner. */
	private readonly moBackButtonSubscription: Subscription;

	private readonly moAuthenticationPerformanceManager = new PerformanceManager;

	/** Url de redirection après authentification de l'utilisateur */
	private msRedirectUrl: string;
	private isDevMode: boolean = ConfigData.environment.id == "dev1";

	/** Si aucune mise à jour 'Aucune', sinon la version de la mise à jour disponible. */
	private msAvailableUpdateVersion: string;

	//#endregion

	//#region PROPERTIES

	@ViewChild("passwordInput") public passwordInput: IonInput;

	@ViewChild("pinInput") public pinInput: IonInput;

	/** Paramètre du composant. Si non injecté récupère ceux de `ConfigData.authentication.authenticatorParams`, si absent récupère dans la base de données de `AuthenticatorComponent.C_AUTHENTICATION_COMPONENT_TYPE_KEY`. */
	@Input() public params: IAuthenticatorParams;

	/** Paramètres du composant */
	public password: string = "";
	public login: string = "";
	public isPasswordVisible: boolean = false;
	public passwordIcon: string = "visibility";
	public isPasswordError: boolean = false;
	public isEmailError: boolean = false;
	public messageErrorLogin: string = "Identifiant ou mot de passe invalide";
	//Observable permettant d'afficher les erreurs sur la page de connexion
	public errorConnexion$: Observable<string | null>;

	public credentials = new Credentials();
	public unlockMode: UnlockMode;
	public expiredToken: boolean;
	/** Indique si la section d'authentification par login/mot de passe est écrasée. */
	public loginCollapsed = false;
	public versionNumber: string;
	public passwordInputType = "password";
	/** Retourne l'icône associée au type de biométrie de la plateforme. */
	public get biometricIconName(): string {
		// Android 9+ = "biometric" ; Android 8- = "finger" ; iOS = "faceid" ;
		return this.unlockMode === "biometric" || this.unlockMode === "finger" ? "finger-print" : "faceid";
	}

	/** Retourne les classes à appliquer sur l'icône de déverrouillage par biométrie sous forme de chaîne de caractères. */
	public get biometricIconClass(): string {
		return `ion-no-padding${this.unlockMode === "face" ? " face-id" : ""}`;
	}

	/** Retourne le type de déverrouillage par biométrie. */
	public get biometricType(): string {
		// Android 9+ = "biometric" ; Android 8- = "finger" ; iOS = "faceid" ;
		return this.unlockMode === "biometric" || this.unlockMode === "finger" ? "TouchID" : "FaceID";
	}

	/** Retourne l'icône associée à la visibilité du mot de passe. */
	public get passwordVisibilityIconName(): string {
		return this.passwordInputType === "password" ? "eye-off-outline" : "eye-outline";
	}

	public readonly isMobileApp: boolean;
	public isMobileView : boolean = false;

	/**Retourne `true` si un PIN est sauvegardé, `false` sinon. */
	public get isSavedPin(): boolean {
		return this.isvcPin.isSaved;
	}

	public pinCollapsed = true;
	public pin: string;
	public readonly isStoreRelease: boolean = ConfigData.environment.storeRelease;
	public authConfig: ControlConfig[];
	public authForm: FormGroup;
	public readonly changelogsUrl: string = ConfigData.appInfo.changelogsUrl;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des pages. */
		private isvcPageManager: PageManagerService,
		/** Service de gestion des empreintes digitales. */
		private ioFingerprintAIO: FingerprintAIO,
		/** Service de gestion des popups et toasts. */
		private isvcUiMessage: UiMessageService,
		/** Service de gestion de la sécurité. */
		private isvcSecurity: SecurityService,
		/** Service spinner */
		private isvcLoading: LoadingService,
		private isvcUserContact: UserContactService,
		private ioRoute: ActivatedRoute,
		private ioNavController: NavController,
		private isvcApplication: ApplicationService,
		private readonly isvcUpdate: UpdateService,
		private readonly isvcModal: ModalService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcSites: SitesService,
		private readonly isvcPin: PinService,
		private readonly isvcFlag: FlagService,
		private readonly isvcGlobalData: GlobalDataService,
		private svcError: ErrorService,
		private router: Router,
		private svcLoader: LoaderService,
		private fb: FormBuilder,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef,
		private svcAuthenticator: AuthenticatorService,
		private svcConnexion: ConnexionService,
		private svcDevice : DeviceService
	) {
		super(null, poChangeDetector);

		this.isMobileApp = psvcPlatform.isMobileApp;
		// On ne peut pas mettre de pipe avec la méthode `subscribeWithPriority`.
		this.moBackButtonSubscription = psvcPlatform.getBackButtonSubscription(() => { /* Ne fait rien. */ });
		this.errorConnexion$ = this.svcError.getErrorConnexion();
	}

	protected onLifeCycleEvent(poValue: ILifeCycleEvent): void {
		switch (poValue.data.value) {

			case ELifeCycleEvent.viewDidEnter:
				this.isvcLoading.dismiss(); // Suppression loader du lancement de l'app (app.component.core).
				break;
		}
	}

	public ngOnInit(): void {
		this.initComponent();

		/*On surveille les erreurs retournées à la page de connexion et on catch seulement l'erreur si on doit rediriger sur la page de compte bloqué */
		this.errorConnexion$.subscribe(errorMessage => {
			if (errorMessage && errorMessage.toLowerCase().includes('compte est bloqué')) {
				this.svcError.setErrorConnexion(null);
				this.ioNavController.navigateRoot("/compteBloque");

			}
		});

		this.svcDevice.isMobile$
			.subscribe((flag: boolean) => {
				this.isMobileView = flag;
		});

		this.ioRoute.queryParamMap.pipe(
			tap((poQueryParamMap: ParamMap) => this.msRedirectUrl = this.getDecodedRedirectUrl(poQueryParamMap.get("redirectUrl"))),
			takeUntil(this.destroyed$)
		).subscribe();

		switch (this.params.authenticationAction) {

			case EAuthenticationAction.route:
				if (this.params.loginEditEnabled !== false)
					this.params.loginEditEnabled = true;

				if (this.params.newAccountEnabled !== false)
					this.params.newAccountEnabled = true;
				break;

			case EAuthenticationAction.internal:
				this.params.loginEditEnabled = false;
				this.params.newAccountEnabled = false;
				break;
		}

		this.isvcSecurity.checkTokenExpiration()
			.pipe(
				tap((pbExpired: boolean) => {
					this.expiredToken = pbExpired;
					this.detectChanges();
				}),
				mergeMap(_ => concat(this.initCredentials(), this.initFingerPrintAuth(), this.tryUnlock())),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.authConfig = [
			{ name: 'login', value : '',validators: [Validators.required] },
			{ name: 'password', value: '',validators: [Validators.required] }
		];

		this.authForm = this.fb.group({});
      this.authConfig.forEach(control => {
        this.authForm.addControl(
          control.name,
          this.fb.control(control.value, control.validators || [])
        );
      });
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();

		this.moBackButtonSubscription.unsubscribe();
	}

	public ngAfterViewInit(): void {
		super.ngAfterViewInit();

		this.moAuthenticationPerformanceManager.markStart();
		// Permet de focus l'input du PIN
		if (this.pinInput)
			setTimeout(() => this.pinInput.setFocus(), 500);
	}

	/** Initialise l'attribut `this.params` si absent et initialise l'interface graphique. */
	private initComponent(): void {
		this.initParams();

		if (!StringHelper.isBlank(this.params.login) && !StringHelper.isBlank(this.params.password))
			this.credentials = Object.assign(new Credentials(), { login: this.params.login, password: this.params.password });

		if (ConfigData && ConfigData.appInfo)
			this.versionNumber = ConfigData.appInfo.appVersion;
	}

	/** Initialise l'attribut `this.params`. */
	private initParams(): void {
		if (this.params)
			return;

		if (ConfigData.authentication.authenticatorParams)
			this.params = ConfigData.authentication.authenticatorParams;
		else {
			this.isvcSecurity.getAuthenticatorConfig(AuthenticatorComponent.C_AUTHENTICATION_COMPONENT_TYPE_KEY)
				.pipe(
					filter((poResult: IAuthenticatorParams) => !!poResult),
					tap((poResult: IAuthenticatorParams) => this.params === poResult),
					takeUntil(this.destroyed$))
				.subscribe(_ => { }, poError => console.error("AUTH.C:: Erreur récupération config composant :", poError));
		}
	}

	private tryUnlock(): Observable<boolean> {
		return this.isvcSecurity.getSavedSecuritySettings()
			.pipe(
				mergeMap((poSecuritySettings: ISecuritySettingsDocument) => {
					if (this.credentials?.isValidToken()) {
						if (poSecuritySettings?.unlockMode === EUnlockMode.fingerprint)
							return this.unlockFingerprint();
						else
							if (poSecuritySettings?.unlockMode === EUnlockMode.pin)
								this.pinCollapsed = !(this.loginCollapsed = true);
					}
					return EMPTY;
				}),
				takeUntil(this.destroyed$)
			);
	}

	private initCredentials(): Observable<ICredentialsDocument> {
		return this.isvcSecurity.getUserCredentials()
			.pipe(
				filter((poResult: Credentials) => !!poResult),
				tap((poResultCredentials: Credentials) => {
					if (StringHelper.isBlank(poResultCredentials.login) && !StringHelper.isBlank(this.params.login))
						poResultCredentials.login = this.params.login;

					if (StringHelper.isBlank(poResultCredentials.password) && !StringHelper.isBlank(this.params.password))
						poResultCredentials.password = this.params.password;

					this.credentials = ConfigData.environment.excludeLogins?.includes(poResultCredentials.login) ? new Credentials() : poResultCredentials;
					this.detectChanges();
				}),
				catchError(poError => {
					console.error("AUTH.C:: Erreur récupération 'credentials' utilisateur :", poError);
					return throwError(poError);
				}),
				takeUntil(this.destroyed$)
			);
	}

	private initFingerPrintAuth(): Observable<UnlockMode> {
		return defer(() => this.ioFingerprintAIO.isAvailable())
			.pipe(
				tap((psUnlockMode: UnlockMode) => {
					if (this.credentials.isValidToken())
						this.unlockMode = psUnlockMode;
				}),
				finalize(() => {
					this.loginCollapsed = !!this.unlockMode && !this.expiredToken;
					this.detectChanges();
				}),
				catchError(poError => {
					console.warn("AUTH.C:: Fingerprint availability check failed. Fingerprint will be considered as unavailable.", poError);
					return EMPTY;
				}),
				takeUntil(this.destroyed$)
			);
	}

	/** Lance l'authentification par empreinte. */
	public onFingerprintUnlockButtonClick(): void {
		this.unlockFingerprint().subscribe();
	}

	private unlockFingerprint(): Observable<boolean> {
		return this.isvcSecurity.unlockFingerprint()
			.pipe(
				tap(() => this.setUserLoginInteractionDuration()),
				mergeMap(_ => this.isvcApplication.waitForFlag(EPermissionsFlag.isLoaded, true)),
				mergeMap(_ => this.isvcUserContact.getContact$()),
				mergeMap(_ => this.redirectAfterAuth(true)),
				catchError(poError => {
					console.error("AUTH.C:: Erreur déverrouillage par empreinte :", poError);
					this.expiredToken = true;
					return throwError(poError);
				})
			);
	}

	public handleClickIcon(event: MouseEvent) {
		this.isPasswordVisible = !this.isPasswordVisible;
		this.passwordInputType = this.isPasswordVisible ? 'text' : 'password';
		this.passwordIcon = this.isPasswordVisible ? 'visibility_off' : 'visibility';
	}

	public navigateToMotDePassePerdu() {
		this.ioNavController.navigateRoot('/passwordDemande?type=lost');
	}

	/** On authentifie l'utilisateur avec ses login mdp et on lance le comportement voulu.
	 * @param psLogin
	 * @param psPassword
	 */
	private authenticateUser(psLogin: string, psPassword: string): Observable<boolean> {
		this.mbAuthenticatingUser = true;

		return this.isvcSecurity.authenticateUserAnakin(psLogin, psPassword)
			.pipe(
				tap(() => this.setUserLoginInteractionDuration()),
				mergeMap(() => of(true)),
				finalize(() => {
					this.mbAuthenticatingUser = false;
				})
			);
	}

	private setUserLoginInteractionDuration(): void {
		this.isvcGlobalData.setData(
			ApplicationService.C_USER_LOGIN_INTERACTION_DURATION_KEY,
			this.moAuthenticationPerformanceManager.markEnd().measure()
		);
	}

	private redirectAfterAuth(pbShouldRedirect: boolean): Observable<boolean> {
		return this.isvcWorkspace.selectCurrentWorkspaceAnakin()
			.pipe(
				mergeMap((workspaceInfo) => {
					if (!workspaceInfo) {
						return from(this.ioNavController.navigateRoot("/selectionWorkspace")).pipe(mapTo(false));
					} else {
						if (this.params.selectSite) {
							this.svcConnexion.selectWorkspace(workspaceInfo.id)
							this.svcConnexion.waitForConnexionFlags();
							return of(true)
						} else {
							return of(true);
						}
					}
				}),
				catchError((error) => {
					console.error('Error selecting workspace:', error);
					return of(false);
				}),
				finalize(() => {
					this.isvcFlag.setFlagValue(EFlag.appAvailable, true);
					console.log('Finalized workspace selection');
				})
			);
	}



	/** Vérification des informations d'authentification de l'utilisateur et initialisation des bases de données si correctes. */
	public validateUser(): void {
		if (this.authForm.invalid) {
			this.svcError.setErrorConnexion(this.messageErrorLogin);
		} else if (!this.mbAuthenticatingUser) {
			const formValues = this.authForm.value;

			this.svcError.setErrorConnexion(null);
			this.credentials.login = formValues.login;
			this.credentials.password = formValues.password;

			Keyboard.hide().catch(() => { });

			this.svcLoader.showLoader("", "Blanc");

			from(this.authenticateUser(this.credentials.login, this.credentials.password))
				.pipe(
					mergeMap((pbShouldRedirect: boolean) => this.redirectAfterAuth(pbShouldRedirect)),
					catchError((error) => {
						console.error("AUTH.C:: Erreur authentification utilisateur :", error);
						return of(false);
					}),
					finalize(() => {
						this.svcLoader.hideLoader();
					})
				)
				.subscribe();
		}
	}

	/** Permet d'afficher la liste des sites sue lesquels on peut se connecter. Ne s'affiche pas si un seul site.  */
	private selectCurrentSite(workspaceId): Observable<ISite> {
		let lsDefaultSiteId: string;

		return this.isvcWorkspace.getWorkspaceDocument(workspaceId)
			.pipe(
				tap((poWorkspace: IWorkspace) => lsDefaultSiteId = poWorkspace.defaultSiteId),
				mergeMap(() => this.isvcSites.getUserSiteIds()),
				map((paSitesIds: string[]) => lsDefaultSiteId ? ArrayHelper.unique([lsDefaultSiteId, ...paSitesIds]) : paSitesIds),
				mergeMap((paSitesIds: string[]) => {
					if (paSitesIds.length === 0) {
						const messageError = "Aucun site n'est rattaché à ce workspace";
						this.svcError.setErrorConnexion(messageError);
						return throwError(messageError);
					}

					if (paSitesIds.length === 1) {
						return this.isvcSites.getSite(paSitesIds[0]);
					}

					//On a plusieurs cabinets/sites, on redirige vers la page de sélection des cabinets/sites
					this.ioNavController.navigateRoot("/selectionCabinet", { queryParams: { cabinetlistId: paSitesIds } });
					return of(null);
				}),
				tap((site: ISite) => {
					if (site) {
						UserData.currentSite = site;
						UserData.currentSite.isDefaultSite = site._id === lsDefaultSiteId;
						this.svcAuthenticator.setAuthenticationStatus(true);
						this.ioNavController.navigateRoot("/home");
					}
				}),
			);
	}

	public toggleLoginSection(): void {
		this.loginCollapsed = !this.loginCollapsed;
	}

	/** Met à zéro et le focus sur l'input `Password`. */
	public setPasswordFocus(): void {
		this.passwordInput.setFocus();
	}

	public onPasswordFocused(): void {
		this.passwordInput.value = "";
	}

	public onPinClicked(): void {
		this.pinCollapsed = !this.pinCollapsed;
	}

	public unlockPin(): void {
		this.isvcSecurity.unlockPin(this.pin)
			.pipe(
				tap(() => this.setUserLoginInteractionDuration()),
				mergeMap(_ => this.isvcApplication.waitForFlag(EPermissionsFlag.isLoaded, true)),
				mergeMap(_ => this.isvcUserContact.getContact$()),
				mergeMap(_ => this.redirectAfterAuth(true)),
				catchError(poError => {
					console.error("AUTH.C:: Erreur déverrouillage par code pin :", poError);
					this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: `Code incorrect, ${this.isvcPin.getRemainingAttempts()} tentatives restantes.` }));
					return throwError(poError);
				})
			).subscribe();
	}

	private getDecodedRedirectUrl(psRedirectUrl: string): string {
		if (!StringHelper.isBlank(psRedirectUrl)) {
			let lsDecodedUrl: string;

			while (true) {
				lsDecodedUrl = decodeURIComponent(psRedirectUrl);

				if (lsDecodedUrl === psRedirectUrl)
					break;

				psRedirectUrl = lsDecodedUrl;
			};

			return psRedirectUrl;
		}
		else
			return psRedirectUrl;
	}

	public togglePasswordVisibility(): void {
		this.passwordInputType = this.passwordInputType === "password" ? "" : "password";
	}

	public checkUpdate(): void {
		this.showUpdatePopup().pipe(
			catchError(poError => {
				console.error(`${AuthenticatorComponent.C_LOG_ID}Error while checking updates.`, poError);
				return EMPTY;
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	public debugComponent(): void {
		this.ioNavController.navigateRoot("/debugComponent");
	}

	private showUpdatePopup(): Observable<IUpdate> {
		return this.isvcUpdate.popupUpdate().pipe(
			tap(
				(poUpdate: IUpdate) => {
					if (!poUpdate) { // Si aucune nouvelle mise à jour.
						if (this.msAvailableUpdateVersion === AuthenticatorComponent.C_NO_AVAILABLE_UPDATE_VERSION) {
							// Affichage d'un message pour indiquer à l'utilisateur qu'une recherche de mise à jour a bien été faite.
							this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Aucune mise à jour disponible.", header: "Mise à jour" }));
						}
						else {
							this.msAvailableUpdateVersion = AuthenticatorComponent.C_NO_AVAILABLE_UPDATE_VERSION;
						}
					}
					else
						this.msAvailableUpdateVersion = poUpdate.version;
				},
				poError => this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Problème lors de la recherche de mise à jour.", message: poError.message }))
			)
		);
	}

	/** Envoie un événement pour notifier que le lien des changelogs a été cliqué. */
	public raiseChangelogsClickedAction(): void {
		this.moChangelogsClickedEvent.emit();
	}

	//#endregion

}
