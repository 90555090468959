<div class="container">
	<div class="content">
		<h2>Sélectionnez votre espace de travail</h2>
		<div class="button-list">
			<di-bouton-surface
				*ngFor="let workspace of workspaces"
				mode="filled"
				customIconName="Logo_Couleur"
				[title]="workspace.name"
				(click)="selectWorkspace($event, workspace.id)"
				[colorIcone] = "workspace.color"
			></di-bouton-surface>
		</div>
		<lua-bouton-texte
			libelle="Annuler"
			color="Blanc"
			(click)="disconnect()"
		></lua-bouton-texte>
	</div>
</div>
