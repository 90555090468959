import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { StoredSeance } from "../../../models/StoredSeance";
import { map, switchMap, tap } from "rxjs/operators";
import { SeanceService } from "./seance.service";
import { SeancesGeneratorService } from "./seances-generator.service";
import { IPatient } from "apps/idl/src/modules/patients/model/IPatient";
import { ArrayHelper, DateHelper } from "@osapp/helpers";
import { IDayRepetition } from "@osapp/modules/event-markers/models/iday-repetition";
import { Recurrence } from "@osapp/modules/event-markers/models/recurrence";
import { C_HOURS_MINUTES_REPETITION_TYPE } from "@osapp/modules/event-markers/models/hours-minutes-repetition";
import { EPlace } from "apps/idl/src/model/EPlace";
import { IndemniteService } from "apps/idl/src/services/indemnite.service";
import { Indemnite } from "apps/idl/src/modules/traitement/model/Indemnite";
import { Deplacement } from "apps/idl/src/modules/traitement/model/Deplacement";
import { ESecteur } from "apps/idl/src/model/ESecteur";
import { IdlApplicationService } from "apps/idl/src/services/idlApplicationService.service";
import { IDeplacementByProfession } from "apps/idl/src/model/IDeplacementByProfession";
import { EIndemniteType } from "apps/idl/src/modules/traitement/model/EIndemniteType";
import { Majoration } from "apps/idl/src/model/Majoration";
import { IActeDocumentByLc } from "apps/idl/src/model/IActeDocumentByLc";
import { Acte } from "apps/idl/src/model/Acte";
import { EStatusSeance } from "apps/idl/src/model/EStatusSeance";
import { EMajorationType } from "apps/idl/src/model/EMajorationType";
import { EPathologie } from "apps/idl/src/model/EPathologies";
import { EEtatActe } from "../enums/EEtatActe";
import { ContactsService } from "@osapp/services";
import { UserData } from "@osapp/model";
import { ActesService } from "apps/idl/src/modules/actes/actes.service";

@Injectable({
    providedIn: "root"
})
export class SeanceRealisationService {

    private C_BSI_ELIGIBLE_KEY_LETTERS = ["BSA", "BSB", "BSC"];
    private C_IFI_ELIGIBLE_KEY_LETTERS = ["BSA", "BSB", "BSC", "AMX", "PAI"];

    constructor(
        private svcSeanceGenerator: SeancesGeneratorService,
        private svcIndemnite: IndemniteService,
        private svcApplication: IdlApplicationService,
        private svcSeance: SeanceService,
        private svcActe: ActesService
    ) {}

    // Calcul l'abattement des actes des séances
    public calculateSeanceAbatement(seances: StoredSeance[], patient: IPatient): Observable<StoredSeance[]> {
        seances.forEach((seance: StoredSeance) => {
            seance.actes.forEach((acte: Acte) => {
                acte.etat = EEtatActe.done;
            })
        })
        return this.svcSeanceGenerator.applyTaxAllowance(seances, patient).pipe(
            map((seances: StoredSeance[]) => {
                return seances.map((seance: StoredSeance) => {
                    // gestion des BSI a 100% abbatu en cas de deuxieme ou xieme passage dans la même journée
                    const momentseance: IDayRepetition = seance.moment ?? Recurrence.getRepetition(StoredSeance.determineMoment(new Date(seance.startDate)));
                    seance.actes.forEach(acte => {
                        if (this.C_BSI_ELIGIBLE_KEY_LETTERS.some(bsi => bsi === acte.keyLetters) && (acte.recurrences && Array.isArray(acte.recurrences))) {
                            acte.recurrences.forEach(recurrence => {
                                const recurrenceSort = SeanceService.sortDayRepetitions(recurrence.dayRepetitions);
                                const index = recurrenceSort.findIndex((rep: IDayRepetition) =>
                                    rep.type === momentseance.type &&
                                    (rep.type === 'range' &&
                                        rep.from.hours === momentseance.from.hours &&
                                        rep.to.hours === momentseance.to.hours) ||
                                    (rep.type === C_HOURS_MINUTES_REPETITION_TYPE &&
                                        rep.hours === momentseance.hours)
                                );

                                if (index > 0) {
                                    acte.taxAllowance = 0;
                                }
                            });
                        }
                    });
                    return seance;
                })
            })
        );
    }

    public getIkIndemnite(patient: IPatient): Indemnite{
        if (patient.lastIkUpdate) {
            return Indemnite.getInstanceFromData(patient.lastIkUpdate)
        } else {
            return new Indemnite("IK-1-1", this.svcIndemnite.getIndemniteTypeFromId("IK-1-1"), '', '',);
        }
    }

    public getDeplacement(indemnite?: Indemnite): Deplacement{
        let deplacement = new Deplacement();
        deplacement.isManualPrice = false
        if(!indemnite){
            deplacement.sectorType = ESecteur.Plaine;
        }else{
            deplacement.sectorType = this.svcIndemnite.getSecteur(indemnite.type);
            deplacement.distance = indemnite.distance;
        }
        return deplacement;
    }

    // Retourne la distance parcourue après abattement
    public getAbattement(deplacement: Deplacement): number{
        return this.svcIndemnite.calculateAbattementDistance(deplacement);
    }

    public initializeIndemnitesForfaitairesList(dateFacturation: Date): Observable<Indemnite[]> {
        return this.svcIndemnite.getDeplacementsByProfession(this.svcApplication.profession).pipe(
            map((deplacements: IDeplacementByProfession[]) => {
                return deplacements
                    .filter(deplacement => !deplacement.lettreCle.includes(EIndemniteType.IK))
                    .map(deplacement => new Indemnite(
                        deplacement.id,
                        this.svcIndemnite.getIndemniteTypeFromId(deplacement.id),
                        deplacement.description,
                        '',
                        this.svcIndemnite.getDeplacementTarif(deplacement, dateFacturation)
                    ));
            })
        );
    }

    public initializeMajorationsList(): Observable<Majoration[]> {
        return this.svcSeanceGenerator.getMajorations().pipe(
            map((majorations: IActeDocumentByLc[]) => {
                return majorations.map((majoration: IActeDocumentByLc) => {
                    const type = this.svcSeanceGenerator.getMajorationTypeFromId(majoration.id);
                    return new Majoration(
                        majoration.id,
                        type,
                        this.svcSeanceGenerator.getMajorationPriceFromArray([majoration], type),
                        this.svcSeanceGenerator.getMajorationDescriptionFromArray([majoration], type)
                    );
                });
            })
        )
    }

    // True si l'IFI est éligible pour la séance
    private isIFIEligible(seance: StoredSeance): boolean {
        if (ArrayHelper.hasElements(seance.actes)) {
            if (seance.actes.some(acte => acte.keyLetters === "IFI")) {
                return false;
            }
            // On regroupe toutes les lettre-clés dans un seul tableau sans doublon dans lequel on vérifie s'il y a des lettre-clés éligibles à l'IFI.
            return ArrayHelper.unique(seance.actes.map((acte: Acte) => acte.keyLetters))
                .some((psKeyLetter: string) => this.C_IFI_ELIGIBLE_KEY_LETTERS.some((psEligible: string) => psEligible === psKeyLetter));
        }
        else
            return false;
    }

    // True si l'IK est éligible pour la séance
    public isIKEligible(seance: StoredSeance): boolean {
        // L'acte ne peut pas être unique et un IFI 0.01
        return seance.mePlace === EPlace.home && (seance.actes.length !== 1 || seance.actes[0].id !== 'act_0542');
    }

    // True si l'IFD est éligible pour la séance
    private isIFDEligible(seance: StoredSeance): boolean {
        return seance.actes.every(acte => acte.keyLetters !== "IFI")
    }

    // Retourne la liste des indemnités par défaut pour une séance donnée
    public getSelectedIndemnities(seance: StoredSeance, patient: IPatient, indemnitesForfaitairesList: Indemnite[]): Indemnite[] {
        const { status, indemnites, mePlace } = seance;
        const IkIndemnite: Indemnite = this.getIkIndemnite(patient);
        let selectedIndemnites: Indemnite[] = [];
        if (status === EStatusSeance.done) {
            selectedIndemnites = indemnites ? indemnites.map(Indemnite.getInstanceFromData) : [];
        } else {
            selectedIndemnites.push(IkIndemnite);
        }

        const indemniteEligibility = {
            IFI: this.isIFIEligible(seance),
            IFD: this.isIFDEligible(seance),
            IK: this.isIKEligible(seance)
        }

        if (!indemniteEligibility.IK) {
            selectedIndemnites = selectedIndemnites.filter((indem: Indemnite) => !indem.isIKType)
        }
         
        indemnitesForfaitairesList.forEach((indemnite) => {
            indemnite.disabled = !indemniteEligibility[indemnite.type];
            if (status === EStatusSeance.done) return;
            const isHomePlace = mePlace === EPlace.home;
            if(!isHomePlace) return;
            if ((indemnite.type === EIndemniteType.IFD && !indemniteEligibility.IFI && indemniteEligibility.IFD) ||
                (indemnite.type === EIndemniteType.IFI && indemniteEligibility.IFI)) {
                selectedIndemnites.push(indemnite);
            }
        })
        return selectedIndemnites;
    }

    private isMajorationEligible(seance: StoredSeance, majoration: EMajorationType): boolean {
        return !Array.isArray(seance.actes) || seance.actes.every(acte => !acte.excludeMajorations?.includes(majoration));
    }

    private isMIEEligible(seance: StoredSeance, patient: IPatient, agePatient: number): boolean {
        if (!this.isMajorationEligible(seance, EMajorationType.Mie)) {
            return false;
        }
        const isChild = agePatient && agePatient < 7;
        const actes = seance.actes;
        // L'acte ne peut pas être unique et un IFI 0.01
        const hasSingleActeIFI = actes.length === 1 && actes[0].id === 'act_0542';

        return isChild && !hasSingleActeIFI;
    }

    private isMCIEligible(seance: StoredSeance, patient: IPatient): boolean {
        if (!this.isMajorationEligible(seance, EMajorationType.Mci)) {
            return false;
        }
        return seance.actes.some((acte: Acte) => acte.isMciEligible) || patient.pathologies?.includes(EPathologie.palliatif);
    }

    // Eligible si l'acte est isolé est de coefficient inférieur à 1.5
    private isMAUEligible(seance: StoredSeance, eligibleIFI: boolean): boolean {
        if (!this.isMajorationEligible(seance, EMajorationType.Mau)) {
            return false;
        }
        return seance.actes.length === 1 && seance.actes[0].priceCoefficient <= 1.5 &&
            !eligibleIFI &&
            // L'acte ne peut pas être un acte antigrippal
            seance.actes[0].id !== 'act_0350' &&
            // L'acte ne peut pas être un IFI 0.01
            seance.actes[0].id !== 'act_0542';
    }

    private isMIPEligible(seance: StoredSeance, patient: IPatient, agePatient: number): boolean {
        if (!this.isMajorationEligible(seance, EMajorationType.Mip)) {
            return false;
        }

        const isChildOrElderly = agePatient && agePatient < 7 || agePatient >= 80;
        const actes = seance.actes;
        // L'acte ne peut pas être unique et un IFI 0.01
        const hasSingleActeIFI = actes.length === 1 && actes[0].id === 'act_0542';
        const containsPAI = actes.some(acte => acte.keyLetters === "PAI");
        return isChildOrElderly && !hasSingleActeIFI && containsPAI;
    }

    private isN1Eligible(seance: StoredSeance, heureRealisation: string): boolean {
        if (!this.isMajorationEligible(seance, EMajorationType.NightFirstHalf)) {
            return false;
        }
        const [hours, minutes] = heureRealisation.split(':').map(Number); 
        return (hours >= 5 && hours < 8) || (hours >= 20 && hours < 23);
    }


    private isN2Eligible(seance: StoredSeance, heureRealisation: string): boolean {
        if (!this.isMajorationEligible(seance, EMajorationType.NightSecondHalf)) {
            return false;
        }
        const [hours, minutes] = heureRealisation.split(':').map(Number);  
        return hours >= 23 || hours < 5;
    }

    public getSelectedMajorations(seance: StoredSeance, patient: IPatient, majorationsList: Majoration[], heureRealisation: string): Majoration[] {
        let selectedMajorations: Majoration[] = [];
        if (seance.status === EStatusSeance.done) {
            selectedMajorations = [...seance.majorations];
        }
        const eligibleIFI: boolean = this.isIFIEligible(seance);
        const agePatient: number = this.getAgePatient(seance, patient); 

        const majorationEligibility = {
            Dim: this.isMajorationEligible(seance, EMajorationType.SundayAndHolyday),
            MIE: this.isMIEEligible(seance, patient, agePatient),
            MCI: this.isMCIEligible(seance, patient),
            MAU: this.isMAUEligible(seance, eligibleIFI),
            MIP: this.isMIPEligible(seance, patient, agePatient),
            N1: this.isN1Eligible(seance, heureRealisation),
            N2: this.isN2Eligible(seance, heureRealisation)
        }

        const eligibleMipOrNotDim: boolean = majorationEligibility.MIP || !majorationEligibility.Dim;
        const sundayOrHoliday: boolean = eligibleMipOrNotDim ? false : this.svcSeanceGenerator.isSundayOrPublicHoliday(new Date(seance.startDate));

        majorationsList.forEach((majoration) => {
            majoration.disabled = !majorationEligibility[majoration.type];
            if (seance.status === EStatusSeance.to_be_done &&
                (
                    (majoration.type === EMajorationType.Mie && majorationEligibility.MIE) ||
                    (majoration.type === EMajorationType.SundayAndHolyday && !majorationEligibility.N1 && !majorationEligibility.N2 && sundayOrHoliday) ||
                    (majoration.type === EMajorationType.Mci && majorationEligibility.MCI) ||
                    (majoration.type === EMajorationType.NightFirstHalf && majorationEligibility.N1) ||
                    (majoration.type === EMajorationType.NightSecondHalf && majorationEligibility.N2) ||
                    (majoration.type === EMajorationType.Mau && majorationEligibility.MAU && !eligibleIFI && !majorationEligibility.MCI) ||
                    (majoration.type === EMajorationType.Mip && majorationEligibility.MIP)
                )
            ) {
                selectedMajorations.push(majoration);
            } else if (majoration.disabled) {
                selectedMajorations = selectedMajorations.filter(a => a.type !== majoration.type);
            }
        });
        return selectedMajorations;
    }

    private getAgePatient(seance: StoredSeance, patient: IPatient): number {
        const birthDateStr = patient?.birthDate;
        const isValidBirthDate = birthDateStr && DateHelper.isDate(birthDateStr);
        if (!isValidBirthDate) return null;

        const birthDate = new Date(birthDateStr);
        const seanceStartDate = new Date(seance.startDate);
        return DateHelper.calculateAge(birthDate, seanceStartDate);
    }

    // Récupère les indemnités de déplacement de la profession "infirmier libéral"
    public getDeplacementsByProfession(): Observable<IDeplacementByProfession[]>{
        return this.svcIndemnite.getDeplacementsByProfession(this.svcApplication.profession)
    }

    public setSeanceToBeDone(seance: StoredSeance): StoredSeance {
        seance.actes.map((acte: Acte) => {
            acte.etat = EEtatActe.to_be_done;
            acte.taxAllowance = 1;
        })
        seance.majorations = [];
        seance.indemnites = [];
        seance.status = EStatusSeance.to_be_done;
        delete seance.statusChangeDate;
        delete seance.concurrentSeanceId;
        delete seance.commentaireIndisponibilitePatient;
        return seance;
    }

    public setSeancesDone(seance: StoredSeance, heureRealisation: string, majorations: Majoration[], indemnites: Indemnite[], patient: IPatient, concurrentSeances?: StoredSeance[]): StoredSeance[] {
        delete seance.concurrentSeanceId;
        seance = this.annulerPatientIndispo(seance);
        seance = this.annulerPause(seance);
        const statusChangeDate = new Date();
        seance.statusChangeDate = statusChangeDate;
        seance.status = EStatusSeance.done;
        if (seance.actes.some((acte: Acte) => acte.etat === EEtatActe.done)) seance.concurrentSeanceId = seance._id;
        if (heureRealisation) {
            const [hours, minutes] = heureRealisation.split(':').map(Number);
            const dateSeance = new Date(seance.startDate);
            dateSeance.setHours(hours, minutes)
            seance.startDate = dateSeance;
        }
        seance.majorations = majorations;
        indemnites.sort((a, b) => a.type.localeCompare(b.type));

        const IK: Indemnite = indemnites.find((indemnite: Indemnite) => indemnite.isIKType);
        seance.indemnites = IK && (!IK.distance || IK.disabled) ? indemnites.filter((indemnite: Indemnite) => !indemnite.isIKType) : indemnites;

        seance.infirmierId = ContactsService.getContactIdFromUserId(UserData.current._id);
        if(concurrentSeances){
            concurrentSeances.forEach((seanceChild: StoredSeance) => {
                delete seanceChild.concurrentSeanceId;
                seanceChild.majorations = [];
                seanceChild.indemnites = [];
                if (seanceChild.actes.some((acte: Acte) => acte.etat === EEtatActe.done)) {
                    seanceChild.concurrentSeanceId = seance._id;
                    seanceChild.infirmierId = seance.infirmierId;
                    seanceChild.statusChangeDate = statusChangeDate;
                    seanceChild.status = EStatusSeance.done;
                    seanceChild.startDate = seance.startDate;
                }
            })
        }
        return [seance, ...(concurrentSeances ?? [])];
    }

    public annulerPatientIndispo(seance: StoredSeance): StoredSeance {
        delete seance.commentaireIndisponibilitePatient;
        return seance;
    }

    public annulerPause(seance: StoredSeance): StoredSeance {
        delete seance.dateDebutPause;
        delete seance.dateFinPause;
        delete seance.commentairePause;
        return seance;
    }

    public initializeActesList(seances: StoredSeance[]) {
        const actesIds: string[] = ArrayHelper.unique(ArrayHelper.flat(ArrayHelper.flat(seances.map((seance: StoredSeance) =>
            seance.actes.map((acte: Acte) => acte._id ?? [])))));

        return this.svcActe.getActes(actesIds).pipe(
            switchMap((actes: Acte[]) => {
                // On récupère les actes dans le référentiel pour mettre à jour les actes dans la seance
                let cotationRulesIds: string[] = [];
                actes.forEach((acte: Acte) => {
                    cotationRulesIds.push(...(acte.cotationRulesIds ?? []));
                    seances.forEach((seance: StoredSeance) => {
                        seance.actes.forEach((seanceActe: Acte) => {
                            if (seanceActe.id === acte.id) {
                                seanceActe.cotationRulesIds = ArrayHelper.unique(acte.cotationRulesIds ?? []);
                                seanceActe.isPackage = acte.isPackage;
                                seanceActe.excludeMajorations = acte.excludeMajorations;
                                seanceActe.excludeFromTaxAllowance = acte.excludeFromTaxAllowance;
                            }
                        });
                    });
                })
                return this.svcActe.getCotationRules(ArrayHelper.unique(cotationRulesIds));
            })
        )

    }

    public realiserSeancesSilent(seances: StoredSeance[], patient: IPatient): Observable<StoredSeance[]> {
        seances = seances.filter((seance: StoredSeance) => seance.status !== EStatusSeance.done && seance.status !== EStatusSeance.completed);
        if(seances.length === 0) return of([]);
        let indemnitesList: Indemnite[];
        let majorationsList: Majoration[];
        return forkJoin({
            indemnites: this.initializeIndemnitesForfaitairesList(new Date()),
            majorations: this.initializeMajorationsList()
        }).pipe(
            tap(({ indemnites, majorations }) => {
                indemnitesList = indemnites;
                majorationsList = majorations;
            }),
            switchMap(() => this.calculateSeanceAbatement(seances, patient)),
            map((seancesUpdated: StoredSeance[]) => 
                seancesUpdated.map((seance: StoredSeance) => {
                    const heureRealisation: string = DateHelper.getHoursAndMinutes(seance.startDate)
                    const selectedMajorations: Majoration[] = this.getSelectedMajorations(seance, patient, majorationsList, heureRealisation);
                    const selectedIndemnites: Indemnite[] = this.getSelectedIndemnities(seance, patient, indemnitesList);
                    return ArrayHelper.getFirstElement(this.setSeancesDone(seance, heureRealisation, selectedMajorations, selectedIndemnites, patient));
                })
            )
        );
    }

    public annulerSeancesSilent(seances: StoredSeance[]): Observable<StoredSeance[]> {
        seances = seances.filter((seance: StoredSeance) => seance.status !== EStatusSeance.to_be_done && seance.status !== EStatusSeance.completed);
        if (seances.length === 0) return of([]);
        return forkJoin(seances.map((seance: StoredSeance) => {
            if (!seance.concurrentSeanceId || seance._id === seance.concurrentSeanceId) {
                return of([this.setSeanceToBeDone(seance)]);
            }

            return this.svcSeance.selectSeancesByConcurrentSeanceId(seance.concurrentSeanceId).pipe(
                map((concurrentSeances: StoredSeance[]) => concurrentSeances.map((seance:StoredSeance) => this.setSeanceToBeDone(seance)))
            );
        })).pipe(
            map((updatedSeances: StoredSeance[][]) => updatedSeances.flat())
        )
    }
}