<div class="menu-modifier-seance">

	<div>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="schedule"
			text="Changer l'heure" (click)="editHeure($event)">
		</lua-list-item>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="calendar_month"
			text="Changer la date" (click)="editDate($event)">
		</lua-list-item>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="group"
			text="Changer l'infirmier" (click)="changerInfirmier($event)">
		</lua-list-item>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="edit"
			text="Modifier les actes" (click)="editActes()">
		</lua-list-item>
		<lua-divider-horizontal></lua-divider-horizontal>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="check_box_FILL1"
			text="Soins réalisés" (click)="openMenuSoins()">
		</lua-list-item>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="person_off"
			text="Patient non disponible" (click)="editPatient($event)">
		</lua-list-item>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="add"
			text="Ajouter une séance" (click)="addSeance()">
		</lua-list-item>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="pause_circle"
			[text]="seance.status == 7 ? 'Reprendre les soins' : 'Mettre en pause les soins'" (click)="openPanneauInterruptionTraitement()">
		</lua-list-item>
		<lua-divider-horizontal></lua-divider-horizontal>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="location_on"
			text="Itinéraire vers le domicile" (click)="editLocal()">
		</lua-list-item>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="contract"
			text="Afficher l’ordonnance" (click)="afficheOrdo()">
		</lua-list-item>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="person"
			text="Ouvrir le dossier patient" (click)="ouvrirDossierPatient()">
		</lua-list-item>
		<lua-list-item class="list-item" text="Facturer le patient" iconColor="CouleurPrimaire" iconName="request_quote"
			(click)="handleFacturationClick($event)"></lua-list-item>
		<lua-divider-horizontal></lua-divider-horizontal>
		<lua-list-item class="list-item" textColor="Noir" iconColor="Error" iconName="delete" text="Supprimer la séance"
			(click)="handleSupprimerClick($event)">
		</lua-list-item>
	</div>

</div>