<div class="di-carte-seance-container">
	<div *ngIf="withBoutonEtat" class="bouton-etat-container" [ngClass]="{'bouton-etat-container-compact': mode === 'compact'}">
    	<lua-bouton-icone 
			*ngIf="seance.status !== 5 "
			[iconName]="boutonEtatIcon.name" 
			[color]="boutonEtatIcon.color" 
			[mode]="boutonEtatIcon.mode"
			(onClick)="updateSeanceStatus()">
		</lua-bouton-icone>
	</div>
	<div *ngIf="mode === 'detailed'" class="di-carte-seance-detailed" [ngClass]="getClasses()" #seanceElement>
		<div class="di-carte-seance__header">
			<div class="patient-info__container">
				<ng-container *ngIf="patient">
					<lua-objet-patient size="small" [firstName]="patient.firstName | firstUpperCase"
						[lastName]="fullLastName | uppercase" [info]="tailleXs ? '' : getAdressePatient(patient)"></lua-objet-patient>
				</ng-container>
			</div>
			<div class="tags-moment__container">
				<div *ngIf="tagsSoins" class="liste-tags">
					<lua-tag *ngFor="let tag of tagsSoins" [label]="tag.label" [color]="tag.color" [mode]="tag.mode"></lua-tag>
				</div>
	
				<strong (click)="seance.status !== 5 ? openMenuRapide($event,'moment') : null">{{ moment }}</strong>
			</div>
		</div>
		<lua-divider-horizontal *ngIf="!tailleXs" size="small"></lua-divider-horizontal>
		<div *ngIf="!tailleXs">
			<div class="di-carte-seance__body">
				<strong class="title">{{ ordonnance?.description || '' }}</strong>
				<div *ngIf="seance.actes" class="actes-container">
					<ng-container *ngFor="let item of seance.actes">
						<di-acte (click)="seance.status !== 5 ? openMenuRapide($event,'soinsRealise') : null" [acte]="item"
							[ellipsis]="true">
						</di-acte>
					</ng-container>
				</div>
				<div *ngIf="seance.majorations" class="majorations-container">
					<ng-container *ngFor="let item of seance.majorations">
						<di-majoration [type]="item.type" [priceLabel]="item.price"></di-majoration>
					</ng-container>
				</div>
				<div *ngIf="seance.indemnites" class="indemnites-container">
					<ng-container *ngFor="let item of seance.indemnites">
						<di-majoration [indemnity]="true" [indemnityLabel]="item.type + item.label"
							[priceLabel]="item.price"></di-majoration>
					</ng-container>
				</div>
				<div *ngIf="commentaire" class="commentaire-container">
					<di-commentaire [commentaire]="commentaire"></di-commentaire>
				</div>
				<di-ligne-pause *ngIf="seance.status == 7" [dateSeance]="seance.startDate" [seance]="seance" [ordonnance]="ordonnance"></di-ligne-pause>
			</div>
			<div class="di-carte-seance__footer">
				<di-infirmier (click)="seance.status == 6 ? openMenuRapide($event,'infirmier') : null" *ngIf="infirmier"
					[infirmier]="infirmier" [date]="dateDeRealisation" [showMenu]="false" color="CouleurPrimaire"></di-infirmier>
				<di-infirmier (click)="seance.status == 6 ? openMenuRapide($event,'infirmier') : null" *ngIf="!infirmier"
					color="Noir20" [showMenu]="false"></di-infirmier>
	
				<div *ngIf="seance.status == 5" class="facture">
					<strong>FACTURÉ</strong>
					<lua-icone iconName="check_circle" color="CouleurPrimaire" mode="outlined"></lua-icone>
				</div>
				<lua-bouton-icone *ngIf="seance.status != 5" iconName="More_Vert" size="medium" color="CouleurPrimaire"
					mode="filled" [isDisabled]="disabledIcon" (click)="openMenu($event)"></lua-bouton-icone>
			</div>
		</div>
		<div *ngIf="tailleXs" class="small_card_content">
			<lua-avatar *ngIf="infirmier" size="medium" [firstName]="infirmier.firstName" [lastName]="infirmier.lastName"
				[color]="getAvatarColor()" [withTooltip]="false"></lua-avatar>
			<strong> {{ getTailleXsBody() }} </strong>
		</div>
	</div>

	<div *ngIf="mode === 'compact'" class="di-carte-seance-compact" [ngClass]="getClasses()" #seanceElement>
		<div class="di-carte-seance__header">
			<div class="left-container">
				<h2>{{dateString}} - {{moment}}</h2>
			</div>
			<div class="right-container">
				<div *ngIf="seance.majorations?.length > 0" class="majorations-indemnites-container">
					<ng-container *ngFor="let item of seance.majorations">
						<di-majoration [type]="item.type" [priceLabel]="item.price" [icon]="true" [tooltip]="item.type + ' - ' + item.price+'€'"></di-majoration>
					</ng-container>
				</div>
				<div *ngIf="seance.indemnites?.length > 0" class="majorations-indemnites-container">
					<ng-container *ngFor="let item of seance.indemnites">
						<di-majoration [indemnity]="true" [indemnityLabel]="item.type + item.label" [tooltip]="item.type + item.label + ' - ' + item.price+'€'"
							[priceLabel]="item.price" [icon]="false" [price]="false"></di-majoration>
					</ng-container>
				</div>
				<div class="avatar-container">
					<lua-avatar *ngIf="infirmier" size="medium" [firstName]="infirmier.firstName" [lastName]="infirmier.lastName"
						[color]="getAvatarColor()" [withTooltip]="false" (click)="seance.status == 6 ? openMenuRapide($event,'infirmier') : null"></lua-avatar>
					<lua-avatar *ngIf="!infirmier" size="medium"
						color="Noir20" [withTooltip]="false" (click)="seance.status == 6 ? openMenuRapide($event,'infirmier') : null"></lua-avatar>
				</div>
				<lua-bouton-icone *ngIf="seance.status != 5" iconName="More_Vert" size="medium" color="CouleurPrimaire"
					mode="filled" [isDisabled]="disabledIcon" (click)="openMenu($event)"></lua-bouton-icone>
			</div>
		</div>
		<div class="di-carte-seance__body">
			<div *ngIf="seance.actes" class="actes-container">
				<ng-container *ngFor="let item of seance.actes">
					<di-acte (click)="seance.status !== 5 ? openMenuRapide($event,'soinsRealise') : null" [acte]="item"
						[ellipsis]="true">
					</di-acte>
				</ng-container>
			</div>
		</div>
	</div>
</div>