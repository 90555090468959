import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { FileHelper, IdHelper } from '@osapp/helpers';
import { EPrefix, IGalleryFile } from '@osapp/model';
import { DmsFile } from '@osapp/modules/dms/model/DmsFile';
import { LongGuidBuilder } from '@osapp/modules/guid/models/long-guid-builder';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { finalize, tap } from 'rxjs/operators';
import { ITransmissionRapport } from '../../../../../modules/patients/model/ITransmissionRapport';
import { RapportService } from '../../../../../modules/patients/services/rapport.service';
import { LoaderService } from '../../../shared/services/loader.service';
import { PanneauService } from '../../../shared/services/panneau.service';
import { TransmissionService } from '../../../shared/services/transmission.service';


export interface ControlConfig {
	name: string;
	value: any;
	validators?: ValidatorFn[] | ValidatorFn;
}

@Component({
	selector: 'di-panneau-detail-transmission',
	templateUrl: './panneau-detail-transmission.component.html',
	styleUrls: ['./panneau-detail-transmission.component.scss'],
})
export class PanneauDetailTransmissionComponent implements OnInit {

	@Input() public patient?: IPatient;
	@Input() public transmission?: ITransmissionRapport;
	@Input() public openDoc?: boolean;

	public model: ITransmissionRapport;
	public listDoc: IGalleryFile[] = [];
	public listNewFile: File[] = [];


	public currentStep: number = 1;
	public totalStep: number = 2;
	private readonly guidBuilder = new LongGuidBuilder();
	public errorMessage: string = "Le fichier sélectionné est trop volumineux (5Mo maximum)";;
	public isError: boolean = false;


	public transmissionForm: FormGroup;

	public transmissionControlsConfig: ControlConfig[];

	constructor(
		private svcPanneau: PanneauService,
		private svcPatients: PatientsService,
		private svcRapport: RapportService,
		private fb: FormBuilder,
		private svcTransmission: TransmissionService,
		private svcLoader : LoaderService
	) { }

	ngOnInit() {

		if (this.transmission) {
			this.model = this.transmission;
			this.listDoc = [...this.transmission.files];
			this.setPatientTitre(this.getIdPatient(this.transmission._id));
		}
		else if (this.patient) {
			this.setPatientTitre(this.patient._id);
		}
		this.transmissionControlsConfig = [
			{ name: 'titre', value: this.model ? this.model.titre : '' },
			{ name: 'description', value: this.model ? this.model.description : '' }
		];

		this.transmissionForm = this.fb.group({});
		this.transmissionControlsConfig.forEach(control => {
			this.transmissionForm.addControl(
				control.name,
				this.fb.control(control.value, control.validators || [])
			);
		});
	}

	public async handleFileSelected(file: File): Promise<void> {
		if (file) {
			if (file.size > 1000 * 1024) {
				try {
					const newFile: File = await FileHelper.resizeImageAnakn(file);
					this.listNewFile.push(newFile);
				} catch (error) {
					this.isError = true;
					console.error('Error resizing file:', error);
				}
			} else {
				this.listNewFile.push(file);
				this.isError = false;
			}
		}
	}



	public deleteNewFile(event: File): void {
		const index = this.listNewFile.findIndex(file =>
			file.name === event.name &&
			file.size === event.size &&
			file.type === event.type
		);

		if (index !== -1) {
			this.listNewFile.splice(index, 1);
		}
	}

	public deleteOldFile(event: IGalleryFile): void {
		const index = this.listDoc.findIndex(file =>
			file.guid === event.guid
		);

		if (index !== -1) {
			this.listDoc.splice(index, 1);
		}
	}

	public handleSubmit(event: Event): void {
		event.preventDefault();
		if (this.transmissionForm.valid) {
			const formValues = this.transmissionForm.value;
			let formTrans = {}
			if (this.model) {
				formTrans = { ...this.model }
			} else {
				formTrans = {
					_id: ''
				}
			}
			formTrans = {
				...formTrans,
				...formValues
			}
			this.saveTransmission(formTrans)
		}
	}

	onClickPatient(patientId: string) {
		this.setPatientTitre(patientId);
	}

	private setPatientTitre(patientId: string) {
		this.svcPatients.getPatient(patientId).pipe(
			tap((patient: IPatient) => {
				this.patient = patient;
				this.svcPanneau.setTitle(`${this.patient.firstName} ${this.patient.lastName}`);
				this.stepSuivant();
			})
		).subscribe();
	}

	private saveTransmission(transValue: any) {
		this.svcLoader.showLoader("Sauvegarde de la transmission en cours...");
		let transmission: ITransmissionRapport;
		if (transValue._id === '')
			transmission = this.svcRapport.createTransmissionRapport(this.patient);
		else
			transmission = this.model

		this.listNewFile.map((file: File) => {
			const dmsFile: DmsFile = new DmsFile(file, file.name);
			const galleryFile: IGalleryFile = {
				file: dmsFile,
				isNew: true,
				name: dmsFile.Name,
				description: "",
				guid: this.guidBuilder.build({ withHyphens: false, upperCase: false })
			};
			this.listDoc.push(galleryFile);
		});
		this.listNewFile = [];

		transmission.files = this.listDoc;
		transmission.titre = transValue.titre;
		transmission.description = transValue.description;
		transmission.dateLastModification = new Date();
		transmission.createdDate = new Date();
		this.svcRapport.saveTransmissionANAKIN(transmission)
		.pipe(
			finalize(() => {
				this.svcPanneau.close();
				this.svcLoader.hideLoader();
			})
		).subscribe({
			next: () => {
				this.svcTransmission.triggerRefreshTransmissionList();
				this.svcPanneau.close();
			},
			error: (error) => {
			}
		});
	}

	public getIdPatient(string: string): string {
		return `${EPrefix.patient}${IdHelper.getGuidFromId(string, EPrefix.patient).split(IdHelper.C_ID_SEPARATOR)[0]}`;
	}

	private stepSuivant() {
		this.currentStep++;
	}

	private stepPrecedent() {
		this.svcPanneau.setTitle("Patients");
		this.patient = null;
		this.listDoc = [];
		this.listNewFile = [];
		this.currentStep--;
	}

	public close() {
		this.svcPanneau.close();
	}
}