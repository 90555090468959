<form [formGroup]="myAccountForm" (ngSubmit)="handleClickSaveBtn()" class="page-mon-compte__container">
	<div class="page-mon-compte__header">
		<h1>Mon compte</h1>
	</div>
	<div class="page-mon-compte__body">
		<div class="gap-20 page-mon-compte__infos">
			<lua-input formControlName="lastName" label="Nom (obligatoire)" placeholder="Nom (obligatoire)"
				[fullWidth]="true"></lua-input>
			<lua-input formControlName="firstName" label="Prénom" placeholder="Prénom" [fullWidth]="true"></lua-input>
			<lua-color-picker *ngIf="selectedColor" label="Ma couleur"
				secondaryLabel="Permet d’identifier plus facilement un usilisateur dans une liste de séances, de soins, …."
				[selectedOptionsChange]="onOptionClick" [selectedColor]="selectedColor"></lua-color-picker>

		</div>
		<div class="gap-20 page-mon-compte__infos-connexion">
			<div>
				<h2>Connexion</h2>
			</div>
			<lua-input [disabled]="true" formControlName="email" label="Email de connexion" placeholder="Email de connexion"
				[fullWidth]="true"></lua-input>
			<lua-input [disabled]="true" formControlName="password" label="Mot de passe" placeholder="Mot de passe"
				[fullWidth]="true" type="password"></lua-input>
			<lua-bouton-texte class="page-mon-compte__btn-change-pwd" [fullWidth]="true" libelle="Modifier mon mot de passe"
				(click)="showChangePasswordDrawer()"></lua-bouton-texte>
		</div>
		<div class="gap-20 page-mon-compte__infos-facturation">
			<div>
				<h2>Informations de facturation</h2>
				<small>Ces informations sont nécessaires dans le cadre de la télétransmission à la sécurité sociale</small>
			</div>
			<lua-input 
				formControlName="finess" 
				(input)="validFinessInput($event,'finess')" 
				label="N° Finess" 
				placeholder="N° Finess" 
				[fullWidth]="true">
			</lua-input>
			<lua-select formControlName="exerciceCondition" label="Condition d'Exercice" [options]="optsConditionExercice"
				[disabled]="false"></lua-select>
			<lua-select *ngIf="shouldShowSpecialiteSelect()" formControlName="codeSpecialite" label="Spécialité d'Exercice"
				[options]="optsSpeExercice" [disabled]="false"></lua-select>
			<lua-input 
				formControlName="facturationNumber" 
				(input)="removeSpacesFromInput($event,'facturationNumber')" 
				label="N° RPPS ou ADELI" 
				placeholder="N° RPPS ou ADELI"
				[fullWidth]="true">
			</lua-input>
		</div>
		<div class="gap-20 page-mon-compte__infos-facturation">
			<div>
				<h2>Ma signature</h2>
				<small>Ces informations sont nécessaires dans le cadre de la télétransmission à la sécurité sociale</small>
			</div>
			<lua-papier (click)="showEditSignatureDrawer()" mode="filled" [fullWidth]="true" [selectionnable]="true">
				<di-signature [readOnly]="true" #signaturePadReadOnly [image]="imageSignature"></di-signature>
			</lua-papier>
		</div>
	</div>
	<div class="gap-20 page-mon-compte__footer">
		<lua-action-principale libelle="Enregistrer" [isDisabled]="!allFormValid()" (click)="handleClickSaveBtn()"
			[fullWidth]="true"></lua-action-principale>
		<lua-bouton-texte libelle="Annuler" [fullWidth]="true" (click)="handleClickCancelBtn()"></lua-bouton-texte>
	</div>
</form>
<lua-swipeable-drawer class="password-drawer" [visible]="showPasswordDrawer" title="Modification du mot de passe"
	maxHeight="660px" (onGoBackClick)="onGoBackClick($event)" (onCloseButtonClick)="onCloseButtonClick($event)">
	<div *ngIf="showOldPasswordDrawer" class="page-mon-compte__drawer-old-pwd-container">
		<div class="page-mon-compte__drawer-old-pwd-body">
			<div>
				<h2>Mot de passe actuel</h2>
				<lua-input-filled [isAutoFocus]="true" [type]="passwordInputTypeOld" [value]="oldPassword"
					libelle="Mot de passe actuel" [iconName]="passwordIconOld" (valueChange)="handleChangeOldPassword($event)"
					(onClickIcon)="showOldPassword($event)"></lua-input-filled>
			</div>
			<lua-alerte class="page-mon-compte__drawer-old-pwd-alert" *ngIf="msgChangePasswordError" [filled]="false"
				type="error" [message]="msgChangePasswordError"></lua-alerte>
		</div>
		<div class="page-mon-compte__drawer-old-pwd-footer">
			<lua-action-principale libelle="Continuer" endIcon="arrow_right_alt" [isDisabled]="!oldPassword"
				(click)="continueToPasswordChange()" [fullWidth]="true"></lua-action-principale>
			<lua-bouton-texte [fullWidth]="true" (click)="onGoBackClick($event)" color="CouleurPrimaire"
				libelle="Annuler"></lua-bouton-texte>
		</div>
	</div>
	<div *ngIf="showNewPasswordDrawer" class="page-mon-compte__drawer-pwd-container">
		<div class="page-mon-compte__drawer-pwd-body">
			<div>
				<h2>Nouveau mot de passe</h2>
				<lua-input-filled [type]="passwordInputTypeNew" [value]="newPassword" libelle="Nouveau mot de passe"
					[iconName]="passwordIconNew" (valueChange)="handleChangeNewPassword($event)"
					(onClickIcon)="showNewPassword($event)"></lua-input-filled>
			</div>
			<div class="control">
				<div *ngFor="let validation of validations" class="item-control"
					[hidden]="validation.show && !validation.show()">
					<lua-icone [mode]="hasError(validation.errorKey) ? 'outlined' : 'filled'"
						[iconName]="hasError(validation.errorKey) ? 'circle' : 'check_circle'"
						[color]="hasError(validation.errorKey) ? 'Noir' : 'OK'" fontSize="20px"></lua-icone>
					<p [ngClass]="{ 'bold-text': !hasError(validation.errorKey) }">{{ validation.message }}</p>
				</div>
			</div>
			<lua-input-filled [iconName]="passwordIconConfirm" [value]="confirmNewPassword"
				(onClickIcon)="showConfirmPassword($event)" (valueChange)="handleChangeConfirmPassword($event)"
				[type]="passwordInputTypeConfirm" libelle="Confirmez votre mot de passe"
				[isError]="isPasswordError"></lua-input-filled><br>
		</div>
		<div class="page-mon-compte__drawer-pwd-footer">
			<lua-action-principale libelle="Enregistrer" [isDisabled]="!isValideMdp" (click)="changePassword()"
				[fullWidth]="true"></lua-action-principale>
			<lua-bouton-texte (click)="onGoBackClick($event)" color="CouleurPrimaire" libelle="Annuler"></lua-bouton-texte>
		</div>
	</div>
</lua-swipeable-drawer>
<lua-swipeable-drawer class="signature-drawer" [visible]="showSignatureDrawer" title="Signature" maxHeight="550px"
	(onGoBackClick)="closeSignatureDrawer($event)" (onCloseButtonClick)="closeSignatureDrawer($event)">
	<div class="signature-drawer__container">
		<lua-papier mode="filled" [fullWidth]="true" [selectionnable]="true">
			<di-signature #signaturePad [image]="imageSignature"></di-signature>
		</lua-papier>
		<lua-action-secondaire class="btn-restart-signature" nomIcone="edit" [avecIcone]="true" positionIcone="L"
			libelle="Recommencer" (click)="resetSignature($event)"></lua-action-secondaire>
		<lua-action-principale libelle="Enregistrer" [isDisabled]="myAccountForm.invalid"
			(click)="saveSignatureDrawer($event)" [fullWidth]="true"></lua-action-principale>
		<lua-bouton-texte libelle="Annuler" [fullWidth]="true" (click)="closeSignatureDrawer($event)"></lua-bouton-texte>
	</div>
</lua-swipeable-drawer>
<lua-snackbar *ngIf="messageSnackbar" position="bottom center" [message]="messageSnackbar" [type]="typeSnackbar"
	buttonIconName="add"></lua-snackbar>