<div class="di-list-seances-retrocessions">
	<ng-container *ngIf="journees && journees.length > 0">
		<div class="entete-tableau">
			<lua-tableau-entete position="center" entete="DATE"></lua-tableau-entete>
			<ng-container *ngIf="!isMobile">
				<lua-tableau-entete entete="PATIENTS"></lua-tableau-entete>
				<lua-tableau-entete
					entete="ACTES REALISES (ET MAJORATIONS)"
				></lua-tableau-entete>
				<lua-tableau-entete entete="INDEMNITES"></lua-tableau-entete>
			</ng-container>
			<lua-tableau-entete entete="MONTANT RETROCEDE"></lua-tableau-entete>
		</div>
		<ng-container *ngIf="journees && journees.length > 0">
			<ng-container *ngFor="let journee of journeesDisplayed">
				<div class="journee-container">
					<lua-accordion
						iconPosition="left"
						iconClose="arrow_drop_down"
						iconOpen="arrow_drop_up"
					>
						<div class="ligne-retrocession" header>
							<div>
								<strong>{{ journee.date }}</strong>
							</div>
							<ng-container *ngIf="!isMobile"> 
                                <div class="two-rows">
                                    <p>{{ journee.dateObject.total | priceFormat : 2 }} €</p>
                                    <small
                                        >{{ journee.dateObject.patientIds.size }} patient{{
                                            journee.dateObject.patientIds.size > 1 ? "s" : ""
                                        }}</small
                                    >
                                </div>
                                <div class="two-rows">
                                    <p>{{ journee.dateObject.totalActesMajorations | priceFormat : 2 }} €</p>
                                    <small>{{ pourcentageActesMajorations }}%</small>
                                </div>
                                <div class="two-rows">
                                    <p>{{ journee.dateObject.totalIndemnites | priceFormat : 2 }} €</p>
                                    <small>{{ pourcentageIndemnites }}%</small>
                                </div>
                            </ng-container>
							<div class="one-row">
								<strong>{{ journee.dateObject.total | priceFormat : 2 }} €</strong>
							</div>
						</div>
						<div class="seances-journee">
							<div
								class="ligne-retrocession ligne-seance"
								*ngFor="let seance of journee.dateObject.seances"
							>
								<div class="two-rows patient-row">
									<p>{{ seance.patientName }}</p>
									<small>{{ seance.moment }}</small>
								</div>
								<ng-container *ngIf="!isMobile">
                                    <div class="two-rows">
                                        <p>
                                            {{
                                                (seance.totalActesMajorations *
                                                    pourcentageActesMajorations) /
                                                    100 | priceFormat : 2
                                            }}
                                            €
                                        </p>
                                        <small
                                            >{{ seance.totalActesMajorations | priceFormat : 2 }} x
                                            {{ pourcentageActesMajorations }}%</small
                                        >
                                    </div>
                                    <div class="two-rows">
                                        <p>
                                            {{
                                                (seance.totalIndemnites * pourcentageIndemnites) / 100
                                                    | priceFormat : 2
                                            }}
                                            €
                                        </p>
                                        <small
                                            >{{ seance.totalIndemnites | priceFormat : 2 }} x
                                            {{ pourcentageIndemnites }}%</small
                                        >
                                    </div>
                                </ng-container>
								<div class="one-row">
									<strong
										>{{
											(seance.totalActesMajorations *
												pourcentageActesMajorations) /
												100 +
												(seance.totalIndemnites * pourcentageIndemnites) / 100
												| priceFormat : 2
										}}
										€</strong
									>
								</div>
							</div>
						</div>
					</lua-accordion>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="!journees || journees.length === 0">
		<lua-message-aucune-donnee
			iconName="directions_car"
			mainText="Aucune séance réalisée"
			[subText]="'Les soins réalisés par ' + infirmierFirstName + ' apparaîtront ici.'"
		></lua-message-aucune-donnee>
	</ng-container>
</div>
