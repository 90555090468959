import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LifeCycleObserverComponentBase } from '@osapp/helpers';
import { ILifeCycleEvent, IWorkspaceInfo, UserData } from '@osapp/model';
import { EPermissionsFlag } from '@osapp/modules/permissions/models/EPermissionsFlag';
import { FlagService, PlatformService, Store, WorkspaceService } from '@osapp/services';
import { SecurityService } from '@osapp/services/security.service';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConnexionService } from '../../../features/connexion/services/connexion.service';
import { AuthenticatorService } from '../../../features/shared/services/authenticator.service';

@Component({
	templateUrl: './workspace-selection.page.html',
	styleUrls: ['./workspace-selection.page.scss'],
})
export class WorkspaceSelectionPage extends LifeCycleObserverComponentBase implements OnInit, OnDestroy, AfterViewInit {

	public workspaces: IWorkspaceInfo[];
	public selectedWorkspace: string;

	constructor(
		changeDetector: ChangeDetectorRef,
		private svcSecurity: SecurityService,
		private svcWorkspace: WorkspaceService,
		private svcConnexion: ConnexionService,		
		private svcAuthenticator: AuthenticatorService,
		private svcStore: Store,
		private svcFlag: FlagService,
		private svcApp : PlatformService
	) {
		super(null, changeDetector);
	}

	ngOnInit(): void {
		if(!UserData.current)
		{
			this.disconnect();
			return;
		}else{
			this.workspaces = this.svcWorkspace.getWorkspaces().sort((a, b) => a.name.localeCompare(b.name))
			UserData.current.workspaceInfosList = this.workspaces;
			this.svcConnexion.waitForConnexionFlags();
		}
	}

	protected onLifeCycleEvent(event: ILifeCycleEvent): void {
		throw new Error('Method not implemented.');
	}

	public selectWorkspace(event: Event, workspaceId: string): void {
    this.selectedWorkspace = workspaceId;

    // Mise à jour des informations de l'utilisateur
    UserData.current.workspaceInfos = UserData.current.workspaceInfos.filter(
        (workspace: IWorkspaceInfo) => workspace.id === workspaceId
    );

    // Sélection du workspace selon l'environnement (mobile ou non)
    if (this.svcApp.isMobileApp) {
        this.svcConnexion.selectWorkspace(workspaceId);
    } else {
        this.svcStore.initWorkspaceDatabase(workspaceId).pipe(
            tap(() => {
                this.svcFlag.setFlagValue(EPermissionsFlag.isLoaded, true);
                this.svcConnexion.selectWorkspace(workspaceId);
            }),
            catchError(error => {
                console.error("Erreur lors de l'initialisation du workspace :", error);
                return EMPTY;
            })
        ).subscribe();
    }
	}

	public disconnect(): void {
		this.svcAuthenticator.setAuthenticationStatus(false);
		this.svcSecurity.disconnect().subscribe();
	}

}
