import { Component, Input, OnInit } from "@angular/core";
import { DateHelper } from "@osapp/helpers";
import { ETimetablePattern } from "@osapp/model";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { takeUntil } from "rxjs/operators";
import { StoredSeance } from "../../../../models/StoredSeance";
import { DeviceService } from "../../services/device.service";
import { DrawerPopoverService } from "../../services/drawer-popover.service";
import { PanneauService } from "../../services/panneau.service";
import { PanneauMiseEnPauseComponent } from "../panneaux/panneau-mise-en-pause/panneau-mise-en-pause.component";
import { ITraitement } from "apps/idl/src/model/ITraitement";

@Component({
	selector: "di-ligne-pause",
	templateUrl: "./ligne-pause.component.html",
	styleUrls: ["./ligne-pause.component.scss"],
})
export class LignePauseComponent extends DestroyableComponentBase implements OnInit {
	@Input() seance: StoredSeance;
	@Input() ordonnance: ITraitement;
	@Input() dateSeance?: Date;

	public formattedDateFinPause: string;
	public isMobile: boolean = false;

	constructor(
		private svcDrawerPopover: DrawerPopoverService,
		private svcPanneau: PanneauService,
		private svcDevice: DeviceService
	) {
		super();
	}

	public ngOnInit(): void {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
			this.isMobile = flag;
		});

		if (this.seance.dateFinPause) {
			this.formattedDateFinPause = DateHelper.transform(this.seance.dateFinPause, ETimetablePattern.dd_MM_yyyy_slash)
		}
	}

	public openPanneauInterruptionTraitement(): void {
		const title = "Reprendre les soins";
		const content = PanneauMiseEnPauseComponent;
		const inputs = {
			seance: this.seance,
			ordonnance: this.ordonnance,
			dateSeance: this.dateSeance ?? new Date(),
			onCancel: () => {
				this.isMobile ? this.svcDrawerPopover.close() : this.svcPanneau.close();
			},
		};
		this.svcPanneau.open(title, content, inputs);
		if (this.isMobile) {
			this.svcPanneau.close();
			this.svcDrawerPopover.open(title,
				"auto",
				null,
				content,
				inputs
			)
		} else {
			this.svcPanneau.open(title, content, inputs);
		}
	}
}
