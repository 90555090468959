<div class="lua-filtre-groupe">
  <div *ngFor="let filter of filters"
      class="filtre-element"
      [ngClass]="{
        selected: selectedFilter === filter
      }"
      (click)="onFilterClick(filter)">
    <lua-icone *ngIf="filterIcons && filterIcons[filter]" [iconName]="filterIcons[filter]" mode="outlined" [color]="selectedFilter === filter ? 'Blanc' : 'Indigo'"></lua-icone>
    <p *ngIf="showLibelle">{{ filter }}</p>
  </div>

</div>
