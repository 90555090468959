<div class="authenticator-container">
	<div class="login-container">
		<div class="login-content">
			<div class="login-titre">
				<div *ngIf="!isMobileView" class="login-titre-web">
					<h1>Connexion</h1>
					<small>Entrez vos informations de connexion</small>
				</div>
				<div *ngIf="isMobileView" class="login-titre-mobile">
					<lua-icone
						[isIcon]="true"
						customIconName="Logo_Couleur_T_Vertical"
						width="100%"
						height="100%"
					></lua-icone>
				</div>
			</div>
			<div class="login-form">
				<form class="form-auth" [formGroup]="authForm">
					<lua-input type="email" formControlName="login" [fullWidth]="true" label="Email de connexion"
						placeholder="Quel est votre email de connexion ?" [isError]="isEmailError">
					</lua-input>
					<lua-input formControlName="password" [fullWidth]="true" label="Mot de passe" [type]="passwordInputType"
						iconToolTip="afficher le mot de passe" (clickIcon)="handleClickIcon($event)" [iconEnd]="passwordIcon"
						placeholder="Quel est votre mot de passe ?" [isError]="isPasswordError" modeIcon="outlined">
					</lua-input>
					<lua-alerte *ngIf="errorConnexion$ | async as errorMessage" [filled]="true" [fullWidth]="true" type="error"
						iconName="info" [message]="errorMessage"></lua-alerte>
					<lua-action-principale [fullWidth]="true" libelle="Connexion"
						(click)="validateUser()"></lua-action-principale>
					<lua-bouton-texte [fullWidth]="true" libelle="Mot de passe perdu ?"
						(click)="navigateToMotDePassePerdu()"></lua-bouton-texte>
				</form>
			</div>
		</div>
	</div>
	<di-side-page-desmos colorTitre="Blanc" colorLogo="Blanc" colorBackground="DegradeOrisha2Couleurs"
		[showBackgroundImage]="false"></di-side-page-desmos>
</div>