import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { DateHelper } from "@osapp/helpers";
import { ETimetablePattern, IContact } from "@osapp/model";
import { ContactsService } from "@osapp/services";
import { ITagSoin } from "apps/idl/src/anakin/models/ITagSoin";
import { StoredSeance } from "apps/idl/src/anakin/models/StoredSeance";
import { Acte } from "apps/idl/src/model/Acte";
import { EStatusSeance } from "apps/idl/src/model/EStatusSeance";
import { Traitement } from "apps/idl/src/model/Traitement";
import { IPatient } from "apps/idl/src/modules/patients/model/IPatient";
import { Observable } from "rxjs";
import { finalize, switchMap, tap } from "rxjs/operators";
import { PatientsService } from "../../../../../modules/patients/services/patients.service";
import { SeanceIconConfig } from "../../../../models/SeanceIconConfig";
import { EBoutonIconEtatSeance } from "../../enums/EBoutonIconEtatSeance";
import { ESeancesListMode } from "../../enums/ESeancesListMode";
import { DrawerPopoverService } from "../../services/drawer-popover.service";
import { LoaderService } from "../../services/loader.service";
import { SeanceRealisationService } from "../../services/seance-realisation.service";
import { SeanceService } from "../../services/seance.service";
import { MenuModifierSeanceComponent } from "../menu-modifier-seance/menu-modifier-seance.component";

@Component({
	selector: "di-carte-seance",
	templateUrl: "./carte-seance.component.html",
	styleUrls: ["./carte-seance.component.scss"],
})
export class CarteSeanceComponent implements OnInit {
	@ViewChild('seanceElement', { static: false }) seanceElement: ElementRef;

	@Input() seance: StoredSeance;
	@Input() patient: IPatient;
	@Input() ordonnance: Traitement;
	@Input() public tagsSoins: ITagSoin[];
	@Input() public tailleXs: boolean = false;
	@Input() public withBoutonEtat: boolean = false;
	@Input() public mode: ESeancesListMode = ESeancesListMode.DETAILED

	public title: string = "Description ordonnance";
	public infirmier: IContact;
	public commentaire: string = "";
	public showPopover: boolean = false;
	public classes: any;
	public fullLastName: string;
	public completed = EStatusSeance.completed;
	public dateDeRealisation: string = "";

	public isEnPause: boolean = false;
	public moment: string = "";
	public dateString: string;

	public boutonEtatIcon: SeanceIconConfig;

	constructor(
		private svcDrawerPopover: DrawerPopoverService,
		private svcContact: ContactsService,
		private svcPatient: PatientsService,
		private svcSeance: SeanceService,
		private svcSeanceRealisation: SeanceRealisationService,
		private svcLoader: LoaderService
	) { }

	public getAdressePatient(pat: IPatient): string {
		if (!pat) return "";

		const addressParts: string[] = [];
		if (pat.street) {
			addressParts.push(pat.street);
		}
		if (pat.zipCode) {
			addressParts.push(pat.zipCode);
		}
		if (pat.city) {
			addressParts.push(pat.city);
		}

		return addressParts.join(" ");
	}
	getTailleXsBody(): string {
		if (this.ordonnance?.description) {
			return this.ordonnance.description;
		}
		return this.seance.actes
			.map((acte) => acte.keyLetters + acte.priceCoefficient)
			.join(" + ");
	}

	private sortActes() {
		this.seance.actes.sort((a: Acte, b: Acte) => b.priceCoefficient - a.priceCoefficient)
	}

	public ngOnInit() {
		this.dateString = this.getFormattedDate(new Date(this.seance.startDate), new Date());

		this.sortActes();
		this.updateClasses();
		this.fullLastName = this.svcPatient.getFullNamePatient(this.patient);
		if (this.seance) {
			this.commentaire = [
				this.seance.commentaireManuel,
				this.seance.commentaireIndisponibilitePatient
			]
				.filter(Boolean)
				.join("\n");
		}
		if (this.seance.status === EStatusSeance.done)
			this.dateDeRealisation = DateHelper.transform(this.seance.statusChangeDate, ETimetablePattern.dd_MM_yyyy_HH_mm_slash);
		if (this.seance && this.seance.infirmierId && !this.seance.infirmier) {
			this.svcContact.getContact(this.seance.infirmierId).pipe(
				tap((infirmier) => {
					this.infirmier = infirmier;
				}),
			).subscribe();
		} else {
			this.infirmier = this.seance.infirmier;
		}
		if (this.seance.moment && (this.seance.status !== EStatusSeance.done && this.seance.status !== EStatusSeance.completed)) {
			this.moment = StoredSeance.getLabelMoment(this.seance.moment);
		}
		if (this.seance.status === EStatusSeance.done || this.seance.status === EStatusSeance.completed) {
			this.moment = DateHelper.getHoursAndMinutes(this.seance.startDate)
		}
		this.setBoutonEtatIcon();
	}

	private updateClasses() {
		this.classes = {
			tailleXS: this.tailleXs,
		};
	}

	getAvatarColor() {
		return this.infirmier.avatarCouleur ?? 'CouleurPrimaire';
	}

	getClasses() {
		return this.classes;
	}

	public openMenu(event: Event): void {
		this.showPopover = true;
		this.svcDrawerPopover?.open(
			"",
			"50%",
			event.currentTarget,
			MenuModifierSeanceComponent,
			{
				seance: this.seance,
				patient: this.patient
			},
			() => (this.showPopover = false)
		);
	}



	public openMenuRapide(event: Event, choix: string): void {
		this.showPopover = true;
		this.svcDrawerPopover?.open(
			"",
			"50%",
			event.currentTarget,
			MenuModifierSeanceComponent,
			{
				seance: this.seance,
				patient: this.patient,
				openChoix: choix,
				anchorElement: event.currentTarget
			},
			() => (this.showPopover = false)
		);
	}

	public setBoutonEtatIcon(): void {
		if (!this.seance) return;
		this.boutonEtatIcon = EBoutonIconEtatSeance[this.seance.status];
	}

	public updateSeanceStatus(): void {
		const status = this.seance.status;
		if (status === EStatusSeance.completed) return;

		let action$: Observable<StoredSeance[]>;
		let loaderMessage = '';

		switch (status) {
			case EStatusSeance.canceled:
			case EStatusSeance.to_be_done:
			case EStatusSeance.paused:
				loaderMessage = "Réalisation de la séance...";
				action$ = this.svcSeanceRealisation.realiserSeancesSilent([this.seance], this.patient);
				break;
			case EStatusSeance.done:
				loaderMessage = "Annulation de la séance...";
				action$ = this.svcSeanceRealisation.annulerSeancesSilent([this.seance]);
				break;
			default:
				return;
		}

		this.svcLoader.showLoader(loaderMessage);

		action$.pipe(
			switchMap((seances: StoredSeance[]) => this.svcSeance.updateSeances(seances)),
			finalize(() => this.svcLoader.hideLoader())
		).subscribe();
	}

	public getFormattedDate(date: Date, today: Date): string {
		const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
		const todayOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

		const isToday = DateHelper.compareTwoDates(dateOnly, todayOnly) === 0;

		return isToday
			? `Aujourd'hui, ${date.toLocaleDateString('fr-FR', { weekday: 'long', month: 'long', day: 'numeric' })}`
			: date.toLocaleDateString('fr-FR', { weekday: 'long', month: 'long', day: 'numeric' });
	}
}