import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'di-confirmation-recyclage',
  templateUrl: './confirmation-recyclage.component.html',
  styleUrls: ['./confirmation-recyclage.component.scss'],
})
export class ConfirmationRecyclageComponent implements OnInit {

  constructor() { }

  ngOnInit() { }


  @Input() confirm: () => void;
  @Input() cancel: () => void;

  onConfirm() {
    if (this.confirm) {
      this.confirm();
    }
  }

  onCancel() {
    if (this.cancel) {
      this.cancel();
    }
  }
}
