import { EStatusSeance } from "../../model/EStatusSeance";
import { EBoutonIconEtatSeance } from "../features/shared/enums/EBoutonIconEtatSeance";
import { IFiltreEtatSeance } from "../models/seances/IFiltreEtatSeance";

export const FILTRE_ETATS_SEANCE: IFiltreEtatSeance[] = [
    {
        label: "Séances réalisées",
        status: EStatusSeance.done,
        icon: EBoutonIconEtatSeance[EStatusSeance.done]
    },
    {
        label: "Séances à réaliser",
        status: EStatusSeance.to_be_done,
        icon: EBoutonIconEtatSeance[EStatusSeance.to_be_done]
    },
    {
        label: "Séances en pause",
        status: EStatusSeance.paused,
        icon: EBoutonIconEtatSeance[EStatusSeance.paused]
    },
    {
        label: "Non réalisées (patient absent)",
        status: EStatusSeance.canceled,
        icon: EBoutonIconEtatSeance[EStatusSeance.canceled]
    }
];

export const NB_SEANCE_MAX_TARIFICATION_AUTO: number = 50;
