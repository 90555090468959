import {
	Component,
	Input,
	OnChanges,
	OnInit
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { FormHelper, IdHelper } from "@osapp/helpers";
import { ArrayHelper } from "@osapp/helpers/arrayHelper";
import { EDatabaseRole, EPrefix, IContact, IEntityLink, IStoreDocument, IUser } from "@osapp/model";
import { ISite } from "@osapp/modules/sites/models/isite";
import { SitesService } from "@osapp/modules/sites/services/sites.service";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { ContactsService, EntityLinkService, GroupsService, Store, WorkspaceService } from "@osapp/services";
import { Observable, of, throwError } from "rxjs";
import { catchError, switchMap, takeUntil, tap } from "rxjs/operators";
import { ColorOption } from "../../../../models/IColorOption";
import { EProfile } from "../../enums/EProfile";
import { SnackbarService } from "../../services/snackbar.service";


@Component({
	selector: "di-form-inviter-utilisateur",
	templateUrl: "./form-inviter-utilisateur.component.html",
	styleUrls: ["./form-inviter-utilisateur.component.scss"],
})
export class FormInviterUtilisateurComponent extends DestroyableComponentBase implements OnInit, OnChanges {
	@Input() public userFind?: IUser;
	@Input() public contact?: IContact;
	@Input() onSaveUtilisateur:() => void;
	@Input() onCancelForm:() => void;

	public utilisateurForm: FormGroup;
	public errorMessage : string ="";
	public isError : boolean = false;
	public idRetourPatient: string | null = null;
	public showBlocRemplacant: boolean = false;

	constructor(
		private fb: FormBuilder,
		private svcSite : SitesService,
		private svcWorkspace : WorkspaceService,
		private svcGroup : GroupsService,
		private svcContact : ContactsService,
		private svcLink : EntityLinkService,
		private svcStore : Store,
		private router : Router,
		private svcSnackbar : SnackbarService
		) {
			super();
		}
	infirmierObj: { value: string; label: string; disabled: boolean };
	remplacantObj: { value: string; label: string; disabled: boolean };
	options: any[];
	texteSecondaireGereCabinet = "Permet à l’utilisateur de gérer le cabinet";
	texteSecondaireGereCompte = "Vous pouvez désactiver temporairement un compte";
	public selectedOptions : string;
	public cabinets : ISite[] = [];

	public colors = [
		{
			name: "Indigo",
			value: "Indigo",
		},
		{
			name: "Raspberry",
			value: "Raspberry",
		},
		{
			name: "OrangeClair",
			value: "OrangeClair",
		},
		{
			name: "Warning",
			value: "Warning",
		},
		{ name: "OK", value: "OK" },
		{ name: "BleuVif", value: "BleuVif" },
	];
	public selectedColor : ColorOption [];
	public selectedCabinet : string[] = [];
	public isAdmin: boolean = false;
	public isActif: boolean = false;

	ngOnInit() {
		this.infirmierObj = {
			value: `grp_titulaire`,
			label: `Infirmier (ère)`,
			disabled: false,
		};

		this.remplacantObj = {
			value: `grp_remplacant`,
			label: `Remplaçant (e)`,
			disabled: false,
		};

		this.options = [this.infirmierObj, this.remplacantObj];
		this.selectedOptions = this.options[0].value;
		this.initFormContact();

		this.svcSite.getSites().pipe(
			tap( (sites :ISite[]) => {
				this.cabinets = sites;
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	ngOnChanges() {
		this.initFormContact();
	}

	initFormContact() {
		if(this.userFind)
		{
			this.utilisateurForm = this.fb.group({
				email: [this.userFind?.email],
			});
			this.selectedColor = []

		}
		if(this.contact && !this.userFind)
		{
			this.utilisateurForm = this.fb.group({
				email: [this.contact?.email],
				finess : [this.contact?.finess],
				street : [this.contact?.street],
				zipCode : [this.contact?.zipCode],
				city : [this.contact?.city],
			});
			this.selectedColor = this.contact.avatarCouleur ? [{name:this.contact.avatarCouleur,value:this.contact.avatarCouleur}] : [{name:"CouleurPrimaire",value:"CouleurPrimaire"}]
			this.isAdmin = this.contact.isAdmin;
			this.svcLink.getEntityLinks(this.contact._id).pipe(
				tap((links:IEntityLink[]) => {
					this.selectedCabinet = [];
					links.forEach(group => {
						// Vérifie si le groupe correspond à un cabinet
						const cabinetMatch = this.cabinets.find(cabinet => group._id.endsWith(cabinet._id));
						if (cabinetMatch) {
								this.selectedCabinet.push(cabinetMatch._id);
						}
						
						// Vérifie si le groupe correspond à une option
						const optionMatch = this.options.find(option => group._id.includes(option.value));
						if (optionMatch) {
								this.selectedOptions = optionMatch.value;
								this.showBlocRemplacant = optionMatch.value == this.remplacantObj.value
						}
				});
				})
			).subscribe();
		}
	}

	onDataSelect(option: string[]) {
		this.selectedOptions = option[0];
		this.showBlocRemplacant = option[0] == this.remplacantObj.value
		if(this.showBlocRemplacant){
			this.addAdditionalFields();
		}else{
			this.removeAdditionalFields();
		}
	}

	addAdditionalFields() {
		this.utilisateurForm.addControl('finess', new FormControl(this.contact?.finess));
		this.utilisateurForm.addControl('street', new FormControl(this.contact?.street));
		this.utilisateurForm.addControl('zipCode', new FormControl(this.contact?.zipCode));
		this.utilisateurForm.addControl('city', new FormControl(this.contact?.city));
	}
	
	removeAdditionalFields() {
		this.utilisateurForm.removeControl('finess');
		this.utilisateurForm.removeControl('street');
		this.utilisateurForm.removeControl('zipCode');
		this.utilisateurForm.removeControl('city');
	}

	onCabinetSelect(event: Event, option: ISite) {
		const siteIdSelected = option._id;
		if(this.selectedCabinet.includes(siteIdSelected))
			this.selectedCabinet = this.selectedCabinet.filter(siteId => siteId != siteIdSelected);
		else this.selectedCabinet.push(siteIdSelected);
	}

	onOptionClick = (colColorOptior: any[]) => {
		if(colColorOptior.length == 0) 
			this.selectedColor= [];
		else
			this.selectedColor = colColorOptior;
	}

	toggleIsAdmin(checked: boolean) {
		this.isAdmin = checked;
	}
	toggleIsActif(checked: boolean) {
		this.isActif = checked;
	}

	handleClickSaveBtn() {
		if(this.userFind)
		{
			this.svcWorkspace.inviteUserToWorkspaceANAKIN(this.userFind.email,this.selectedCabinet,this.selectedOptions,ArrayHelper.getFirstElement(this.selectedColor).value,this.isAdmin).pipe(
				tap( (response: any) => {
					this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
						this.router.navigate(['/parametres']);
					});			
				}),
				catchError(error => {
					this.errorMessage = error.error.message;
					this.isError = true;
					return throwError(error);
				})
			).subscribe()
		}
		else
		{
			this.contact.avatarCouleur = ArrayHelper.getFirstElement(this.selectedColor).value;
			if(this.showBlocRemplacant)
			{
				this.contact.finess = this.utilisateurForm.get("finess").value;
				this.contact.street = this.utilisateurForm.get("street").value;
				this.contact.zipCode = this.utilisateurForm.get("zipCode").value;
				this.contact.city = this.utilisateurForm.get("city").value;
			}
			this.contact.isAdmin = this.isAdmin;
			// si le remplaçant est selectionné
			this.contact.profiles = (this.selectedOptions === this.remplacantObj.value) ? [EProfile.remplacant] : [];
			this.svcContact.saveContactAnakin(this.contact).subscribe();
			const datebaseId = ArrayHelper.getFirstElement(this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))
			this.svcLink.getEntityLinks(this.contact._id).pipe(
				tap((links: IEntityLink[]) => {
					// Extraire tous les identifiants actuels des liens en tant que tableau de chaînes
					const currentLinkIds = links.map(link => link._id);
					// Initialiser les tableaux de chaînes pour stocker les nouveaux liens à ajouter et les liens à garder
					const newLinksToAdd: string[] = [];
					const linksToKeep: string[] = [];
			
					// Vérifier chaque identifiant de `selectedCabinet`
					this.selectedCabinet.forEach(cabinetId => {
						if (!currentLinkIds.some(link => link.endsWith(cabinetId))) {
							newLinksToAdd.push(cabinetId);
						} else {
							linksToKeep.push(cabinetId);
						}
					});

					// Vérifier chaque identifiant de `selectedOptions`
					if (!currentLinkIds.some(link => link.includes(this.selectedOptions))) {
							newLinksToAdd.push(this.selectedOptions);
						} else {
							linksToKeep.push(this.selectedOptions);
						}
			
					// Identifier les liens à supprimer : ceux qui sont dans `currentLinkIds` mais absents de `linksToKeep`
					const linksToDelete: string[] = [];
					currentLinkIds.forEach(link => {
						const containsFragment = linksToKeep.some(fragment => link.endsWith(fragment));
						if (!containsFragment) {
							linksToDelete.push(link);
						}
					});	
					
					newLinksToAdd.forEach(grp => {
						let observable: Observable<IStoreDocument>;
						switch (true) {
							case grp.startsWith(EPrefix.group):
								observable = this.svcGroup.getGroup(grp);
								break;
							case grp.startsWith(EPrefix.site):
								observable = this.svcSite.getSite(grp);
								break;
							default:
								observable = of(null);
								break;
						}
					
						observable.pipe(
							switchMap((entity: IStoreDocument) => {
								if(!entity) 
									return of(null)									
								const listeLinkNew: IEntityLink[] = this.svcLink.buildEntityLinks(
									this.svcLink.buildEntity(entity),
									this.svcLink.buildEntity(this.contact)
								);
								return this.svcStore.bulkDocs(datebaseId, listeLinkNew);
							})
						).subscribe();
					});

					linksToDelete.forEach(grp => {
						const [id1, id2] = IdHelper.getGuidFromId(grp,EPrefix.link).split('-');
						const link = `${EPrefix.link}${id1}-${id2}`;
						const reverseLink = `${EPrefix.link}${id2}-${id1}`;
						this.svcStore.deleteMultipleDocuments([link,reverseLink],datebaseId).subscribe();
					});
				}),
				tap(() => this.svcSnackbar.showToast("success", "bottom center", "Utilisateur modifié.","","","","6000"))

			).subscribe();
		}
	}

	handleClickCancelBtn() {
		if(this.onCancelForm)
			this.onCancelForm();
	}

	allValidForm(): boolean {
		const finessControl = this.utilisateurForm.get("finess");

		return !ArrayHelper.hasElements(this.selectedCabinet)
			|| this.selectedOptions == ""
			|| !ArrayHelper.hasElements(this.selectedColor)
			|| (finessControl?.value?.length > 0 && finessControl?.value?.length !== 9);
	}

	validFinessInput(event: any, inputName: string): void {
		FormHelper.validFinessInput(event, this.utilisateurForm, inputName);

	}
}
