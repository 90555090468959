import { Location } from "@angular/common";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateHelper } from "@osapp/helpers";
import { EGender, ETimetablePattern } from "@osapp/model";
import { EDocumentIdentite } from "apps/idl/src/model/EDocumentIdentite";
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';


export interface ControlConfig {
  name: string;
  value: any;
  validators?: ValidatorFn[] | ValidatorFn; // An array of validators
}

@Component({
  selector: 'di-formulaire-patient',
  templateUrl: './formulaire-patient.component.html',
  styleUrls: ['./formulaire-patient.component.scss'],
})
export class FormulairePatientComponent implements OnInit, OnChanges {

  @Input()
  public patient?: IPatient;

  @Input()
  public onSubmit: Function = () => { };

  @Input()
  public libelleButton: string;

  // True si on veut afficher les champs du formulaire relatif à l'identité du patient
  @Input()
  public showIdentityInputs: boolean;

  // True si on veut afficher les champs du formulaire relatif à l'adresse du patient
  @Input()
  public showLocationInputs: boolean;

  // True si on veut afficher des champs supplémentaires concernant l'identité du patient
  public showAdditionalInputs = false;

  public patientForm: FormGroup;

  public identityControlsConfig: ControlConfig[];
  public additionalInputsControlsConfig: ControlConfig[];
  public locationControlsConfig: ControlConfig[];
	public phoneNumber: string = '';


  public optionsGender = [
    { value: EGender.Man, label: "Homme", customIconName: "homme" },
    { value: EGender.Woman, label: "Femme", customIconName: "femme" }
  ]

  public optionsIdentityDocument = [
    { value: EDocumentIdentite.passeport, label: EDocumentIdentite.passeport },
    { value: EDocumentIdentite.carteidentite, label: EDocumentIdentite.carteidentite },
    { value: EDocumentIdentite.titresejour, label: EDocumentIdentite.titresejour }
  ]

  constructor(
    private location: Location,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.initForm();    
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Si l'utilisateur décide d'afficher les champs additionnels, alors on met à jours les controls du formulaire
    if (changes.showAdditionalInputs) {
      this.additionalInputsControlsConfig.forEach(control => {
        this.patientForm.addControl(
          control.name,
          this.fb.control(control.value, [])
        );
      });
    }

    // Si on fournit un patient existant au formulaire alors on préremplit les champs
    if(changes.patient){
      this.initForm();
    }
  }

  public initForm(){
    // Configuration des champs du formulaire qui concernent l'identité du patient
    this.identityControlsConfig = [
      { name: 'lastName', value: this.patient?.lastName ?? '', validators: [Validators.required] },
      { name: 'usualLastName', value: this.patient?.usualLastName ?? ''},
      { name: 'firstName', value: this.patient?.firstName ?? '', validators: [Validators.required] },
      { name: 'birthDate', value: this.patient?.birthDate ? DateHelper.transform(new Date(this.patient.birthDate), ETimetablePattern.isoFormat_hyphen) : ''},
      { name: 'birthPlace', value: this.patient?.birthPlace ?? ''},
      { name: 'inseeCodeBirthPlace', value: this.patient?.inseeCodeBirthPlace ?? ''},
      { name: 'identityDocument', value: this.patient?.identityDocument ? [this.optionsIdentityDocument.find(option => option.value === this.patient.identityDocument)?.value || '']: []},
      { name: 'gender', value: this.patient?.gender ?? ''},
    ];

    // Configuration des champs additionnels du formulaire qui concernent l'identité du patient
    this.additionalInputsControlsConfig = [
      { name: 'usualFirstName', value: this.patient?.usualFirstName ?? ''},
      { name: 'birthFirstName', value: this.patient?.birthFirstName ?? ''}
    ];

    // Configuration des champs du formulaire qui concernent l'adresse du patient
    this.locationControlsConfig = [
      { name: 'street', value: this.patient?.street ?? ''},
      { name: 'zipCode', value: this.patient?.zipCode ?? ''},
      { name: 'city', value: this.patient?.city ?? ''},
      { name: 'floor', value: this.patient?.floor ?? ''},
      { name: 'accessCode', value: this.patient?.accessCode ?? ''},
      { name: 'phone', value: this.patient?.phone ? this.formatPhoneNumber(this.patient.phone) : ''},
      { name: 'email', value: this.patient?.email ?? '', validators: [Validators.email] },
    ];
    // En fonction des champs affichés dans le formulaire, on ajoute la configuration adéquate au formulaire
    this.patientForm = this.fb.group({});
    if (this.showIdentityInputs) {
      this.identityControlsConfig.forEach(control => {
        this.patientForm.addControl(
          control.name,
          this.fb.control(control.value, control.validators || [])
        );
      });
    }
    if (this.showLocationInputs) {
      this.locationControlsConfig.forEach(control => {
        this.patientForm.addControl(
          control.name,
          this.fb.control(control.value, control.validators || [])
        );
      });
    }
  }

  public goBack(): void {
    this.location.back();
  }

  public displayAdditionalInputs(): void {
    this.showAdditionalInputs = true;
  }

  public handleSubmit(event: Event): void {
    event.preventDefault();
    if (this.patientForm.valid) {
      const formValues = this.patientForm.value;
			if(formValues.phone) formValues.phone = this.removeSpacesFromPhoneNumber(formValues.phone);
      let formPatient = {}
      if (this.patient) {
        formPatient = { ...this.patient }
      } else {
        formPatient = {
          _id: ''
        }
      }
      formPatient = {
        ...formPatient,
        ...formValues
      }
      if (this.showIdentityInputs) {
        formPatient = {
          ...formPatient,
          gender: formValues.gender[0],
          identityDocument: formValues.identityDocument[0]
        }
      }
      this.onSubmit(formPatient);
    }
  }

  public getClassesLocationForm() {
    return { 'form-inputs__right-column': true, 'form-inputs__single-column': !this.showIdentityInputs }
  }

	public onPhoneInputFormat(event: Event) {
    let input = (event.target as HTMLInputElement).value;
    this.phoneNumber = this.formatPhoneNumber(input);
    this.patientForm.get('phone')?.setValue(this.phoneNumber, { emitEvent: false });
  }

	private formatPhoneNumber(input: string): string {
    input = input.replace(/\D/g, '');
    return input.match(/.{1,2}/g)?.join(' ') || '';
  }

	private removeSpacesFromPhoneNumber(phoneNumber: string): string {
		return phoneNumber.replace(/\s+/g, '');
	}
}
