<div class="navbar-items">
	<div class="navigation-icons">
		<lua-bouton-icone *ngIf="!isHomePage()" iconName="arrow_back" color="Blanc" (onClick)="goBack()"></lua-bouton-icone>
		<lua-bouton-icone *ngIf="!isHomePage()" iconName="apps" color="Blanc"
			(onClick)="navigateToHome()"></lua-bouton-icone>
	</div>
	<div>
		<lua-icone isIcon="true" customIconName="Logo_Blanc" height="40px" width="40px"></lua-icone>
	</div>
	<div #menuUtilisateur>
		<ng-container *ngIf="contact">
			<lua-avatar (click)="openMenu()" [firstName]="firstName" [lastName]="lastName" [withTooltip]="false"
				[color]="color">
			</lua-avatar>
		</ng-container>

	</div>
</div>