import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Indemnite } from 'apps/idl/src/modules/traitement/model/Indemnite';
import { DrawerPopoverService } from '../../../services/drawer-popover.service';
import { PanneauService } from '../../../services/panneau.service';
import { IndemniteService } from 'apps/idl/src/services/indemnite.service';
import { ArrayHelper, NumberHelper } from '@osapp/helpers';
import { Deplacement } from 'apps/idl/src/modules/traitement/model/Deplacement';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { SeanceRealisationService } from '../../../services/seance-realisation.service';
import { finalize, tap } from 'rxjs/operators';
import { IDeplacementByProfession } from 'apps/idl/src/model/IDeplacementByProfession';
import { Observable, forkJoin } from 'rxjs';
import { IdlApplicationService } from 'apps/idl/src/services/idlApplicationService.service';
import { EIndemniteType } from 'apps/idl/src/modules/traitement/model/EIndemniteType';
import { StoredSeance } from 'apps/idl/src/anakin/models/StoredSeance';
import { Acte } from 'apps/idl/src/model/Acte';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';

@Component({
  selector: 'di-panneau-deplacement',
  templateUrl: './panneau-deplacement.component.html',
  styleUrls: ['./panneau-deplacement.component.scss'],
})
export class PanneauDeplacementComponent implements OnInit {

  @Input() public patient: IPatient;
  @Input() public dateFacturationFiltree: Date;
  @Input() public seance?: StoredSeance;
  @Input() public onSaveDeplacement: (indemnitesSelected: Indemnite[]) => void;

  private C_IFI_ELIGIBLE_KEY_LETTERS = ["BSA", "BSB", "BSC", "AMX", "PAI"];

  constructor(
    private fb: FormBuilder,
    private svcDrawerPopover: DrawerPopoverService,
    private svcPanneau: PanneauService,
    private svcIndemnite: IndemniteService,
    private svcSeanceRealisation: SeanceRealisationService,
    private svcApplication: IdlApplicationService,
    private svcPatient: PatientsService
  ) { }

  public ikForm: FormGroup;
  public libelleBtnSave: string;
  public indemnitesForfaitairesList: Indemnite[] = [];
  public selectedIndemnites: Indemnite[] = [];
  public showAllDeplacement = true;
  public IKSelected: Indemnite;
  public abattement: number = 0;
  public deplacement: Deplacement;
  public selectedOption: string = 'IK';
  public deplacementByLC: IDeplacementByProfession[];
  public isIkChecked: boolean = false;
  public isLoaded: boolean = false;

  ngOnInit() {
    this.initializeForm();

    forkJoin({
      deplacements: this.getDeplacementsByProfession(),
      indemnites: this.svcSeanceRealisation.initializeIndemnitesForfaitairesList(this.dateFacturationFiltree),
    }).pipe(
      tap(({ indemnites }) => {
        this.indemnitesForfaitairesList = indemnites;
      }),
      tap(() => {
        this.setDefaultIK();
        this.initializeSelectedIndemnities();
        this.setLibelleBtnSave();
      }),
      finalize(() => {
        this.isLoaded = true;
      })
    ).subscribe();
  }

  setLibelleBtnSave() {
    const libelleIfdIfi: string = this.selectedIndemnites.find(indem => !indem.isIKType && indem.type)?.type ?? "";
    const ik: Indemnite = this.selectedIndemnites.find(indem => indem.isIKType && indem.type);
    const libelleIk: string = [ik?.type, ik?.label].filter(Boolean).join(" ");
    this.libelleBtnSave = !libelleIfdIfi && !libelleIk ? "Enregistrer" : [libelleIfdIfi, libelleIk].filter(Boolean).join(" + ");
  }


  private initializeSelectedIndemnities(): void {
    this.selectedIndemnites = this.getSelectedIndemnities(this.patient, this.indemnitesForfaitairesList);
    this.isIkChecked = this.selectedIndemnites.some((indemnite: Indemnite) => indemnite.isIKType) && !this.IKSelected.disabled;
  }

  private getSelectedIndemnities(patient: IPatient, indemnitesForfaitairesList: Indemnite[]): Indemnite[] {
    let selectedIndemnites: Indemnite[] = this.IKSelected?.distance ? [this.IKSelected] : [];

    const isIFIEligible: boolean = this.isIFIEligible();
    const indemniteEligibility = {
      IFI: isIFIEligible,
      IFD: !isIFIEligible,
      IK: true //On gère la grosse majorité des cas
    };

    indemnitesForfaitairesList.forEach((indemnite) => {
      indemnite.disabled = !indemniteEligibility[indemnite.type];
      if ((indemnite.type === EIndemniteType.IFD && !indemniteEligibility.IFI && indemniteEligibility.IFD) ||
        (indemnite.type === EIndemniteType.IFI && indemniteEligibility.IFI)) {
        selectedIndemnites.push(indemnite);
      }
    })
    return selectedIndemnites;
  }

  // True si l'indemnite fait partie de celles sélectionnées
  public isSelectedInListIndem(indem: Indemnite): boolean {
    if (!ArrayHelper.hasElements(this.selectedIndemnites)) return false
    return this.selectedIndemnites.some(deplacement => deplacement.type === indem.type)
  }

  toggleCheckDeplacement(event: Event, depla: Indemnite) {
    if (depla.type === EIndemniteType.IFD && this.selectedIndemnites.some(a => a.type === EIndemniteType.IFI)) {
      this.selectedIndemnites = this.selectedIndemnites.filter(a => a.type !== EIndemniteType.IFI);
    }
    else if (depla.type === EIndemniteType.IFI && this.selectedIndemnites.some(a => a.type === EIndemniteType.IFD)) {
      this.selectedIndemnites = this.selectedIndemnites.filter(a => a.type !== EIndemniteType.IFD);
    }
    if (this.selectedIndemnites?.some(a => a.id === depla.id)) {
      this.selectedIndemnites = this.selectedIndemnites.filter(a => a.id !== depla.id);
    } else {
      this.selectedIndemnites.push(depla);
    }
    this.setLibelleBtnSave();
  }

  // Appelé au clic sur la checkbox IK
  toggleIKSelected(): void {
    if (this.IKSelected.disabled) return;
    // Si la config de l'IK était affiché, alors on supprime l'IK de la liste des indemnités sélectionnées
    if (this.isIkChecked) {
      this.selectedIndemnites = this.selectedIndemnites.filter((indem: Indemnite) => !indem.isIKType)
    } else {
      // Sinon on rajoute le dernier type d'IK coché ou IK plaine si il n'y a pas eu d'IK coché précédement
      this.selectedIndemnites.push(this.IKSelected)
    }
    this.isIkChecked = !this.isIkChecked;
    this.setLibelleBtnSave();
  }

  // Appelé lorsqu'on saisi une distance réelle pour l'IK
  public onInputChange(distance: number) {
    if (!distance) distance = 0;
    // On garde seulement les déplamcents qui ne sont pas de type IK car on va recalculer l'IK puis l'ajouter de nouveau
    this.selectedIndemnites = this.selectedIndemnites.filter(indem => !indem.isIKType);
    this.deplacement.distance = distance;
    this.IKSelected.distance = distance;
    if (distance) {
      this.IKSelected.label = `(${distance}km)`;
      this.abattement = this.svcSeanceRealisation.getAbattement(this.deplacement);
      this.deplacement.sectorPrice = this.svcIndemnite.getDeplacementTarif(
        this.deplacementByLC.find(deplacement => deplacement.id === "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType)
      );
      this.svcIndemnite.setIndemnitePrice(this.deplacement, this.IKSelected, this.deplacementByLC);
      this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
      this.selectedIndemnites.push(this.IKSelected)
    } else {
      this.IKSelected.price = 0;
      this.IKSelected.label = '';
      this.ikForm.get('montant')?.setValue(NumberHelper.round(0, 2));
    }
    this.setLibelleBtnSave();
  }

  // Appelé au clic sur un type d'IK
  selectOption(option: string) {
    // On garde seulement les déplamcents qui ne sont pas de type IK car on va recalculer l'IK puis l'ajouter de nouveau
    this.selectedIndemnites = this.selectedIndemnites.filter((indem: Indemnite) => !indem.isIKType);
    this.IKSelected.type = this.svcIndemnite.getType(option);
    this.selectedOption = option;
    this.deplacement.sectorType = this.svcIndemnite.getSecteur(option);
    this.abattement = this.svcSeanceRealisation.getAbattement(this.deplacement);
    this.IKSelected.id = "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType;
    this.deplacement.sectorPrice = this.svcIndemnite.getDeplacementTarif(
      this.deplacementByLC.find(deplacement => deplacement.id === "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType)
    );
    this.svcIndemnite.setIndemnitePrice(this.deplacement, this.IKSelected, this.deplacementByLC);
    this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
    this.selectedIndemnites.push(this.IKSelected);
    this.setLibelleBtnSave();
  }

  // True si l'IFI est éligible pour la séance
  private isIFIEligible(): boolean {
    if (!ArrayHelper.hasElements(this.seance?.actes)) return false;

    // On regroupe toutes les lettre-clés dans un seul tableau sans doublon dans lequel on vérifie s'il y a des lettre-clés éligibles à l'IFI.
    return ArrayHelper.unique(this.seance.actes.map((acte: Acte) => acte.keyLetters))
      .some((psKeyLetter: string) => this.C_IFI_ELIGIBLE_KEY_LETTERS.some((psEligible: string) => psEligible === psKeyLetter));
  }

  private setDefaultIK(): void {
    this.IKSelected = this.svcSeanceRealisation.getIkIndemnite(this.patient);
    this.selectedOption = this.IKSelected.type;
    this.ikForm.get('distance')?.setValue(this.IKSelected.distance);
    this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
    this.deplacement = this.svcSeanceRealisation.getDeplacement(this.IKSelected);
    this.abattement = this.svcSeanceRealisation.getAbattement(this.deplacement);
  }

  // Récupère les indemnités de déplacement de la profession "infirmier libéral"
  private getDeplacementsByProfession(): Observable<IDeplacementByProfession[]> {
    return this.svcIndemnite.getDeplacementsByProfession(this.svcApplication.profession).pipe(
      tap((deplacements: IDeplacementByProfession[]) => {
        this.deplacementByLC = deplacements;
      })
    )
  }

  // Initialise les champs du form avec les valeurs par défaut
  private initializeForm(): void {
    this.ikForm = this.fb.group({
      distance: [0],
      montant: [0],
    });
  }

  handleClickConfirm() {
    //On enregistre dans tous les cas les infos sur le patient
    this.saveInfoIkPatient();
    //Puis si on a des séances validées on applique les indemnités à toutes les séances
    if (this.onSaveDeplacement) this.onSaveDeplacement(this.selectedIndemnites);
  }

  private saveInfoIkPatient() {
    const IK: Indemnite = this.selectedIndemnites.find((indemnite: Indemnite) => indemnite.isIKType);
    if (IK && this.patient.lastIkUpdate !== IK && IK.distance) {
      this.patient.lastIkUpdate = IK;
    } else {
      delete this.patient.lastIkUpdate;
    }
    this.svcPatient.savePatientAnakin(this.patient).subscribe();
  }

  handleClickAnnule() {
    this.svcDrawerPopover.close();
    this.svcPanneau.close();
  }
}