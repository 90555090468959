import { Component, Input, OnInit } from '@angular/core';
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { FILTRE_ETATS_SEANCE } from 'apps/idl/src/anakin/shared/seances.constants';
import { IFiltreEtatSeance } from 'apps/idl/src/anakin/models/seances/IFiltreEtatSeance';

@Component({
  selector: 'di-filtre-etat-seance',
  templateUrl: './filtre-etat-seance.component.html',
  styleUrls: ['./filtre-etat-seance.component.scss'],
})
export class FiltreEtatSeanceComponent implements OnInit {

  @Input() etatsSelected: EStatusSeance[] = [];
  @Input() onFilter: (etatsSelected: EStatusSeance[]) => void;

  public etatsSelectedFinal: EStatusSeance[] = [];
  public etatsSeance: IFiltreEtatSeance[] = FILTRE_ETATS_SEANCE;

  constructor() { }

  ngOnInit() {
    this.etatsSelectedFinal = [...this.etatsSelected];
  }

  public afficher() {
    if (this.onFilter) {
      this.onFilter(this.etatsSelectedFinal);
    }
  }

  public onSelection(event: Event, etat: EStatusSeance) {
    if (this.etatsSelectedFinal.includes(etat)) {
      this.etatsSelectedFinal = this.etatsSelectedFinal.filter(x => x !== etat);
    }
    else {
      this.etatsSelectedFinal.push(etat);
    }
  }
}
