<div id="impression-retrocession-container">
  <div class="haut-de-page">
    <div class="section adresse-cabinet">
      <strong>{{nomCabinet}}</strong>
      <ng-container *ngFor="let information of informationsCabinet">
        <small class="noir80 adresse-cabinet-ligne">{{information}}</small>
      </ng-container>
    </div>  
    <div class="section titre-facture">
      <h1>Facture de rétrocession</h1>
      <p>Facture n°{{retrocession.numeroRetrocession}}</p>
      <p>{{dateRetrocession}}</p>
    </div>
  </div>
  <div class="section">
    <strong>Informations sur le remplaçant</strong>
    <small class="noir80">Nom et prénom : {{nomPrenomRemplacant}}</small>
    <small class="noir80">Adresse professionnelle : {{adresseRemplacant}}</small>
    <small class="noir80">Numéro Finess : {{finessRemplacant}}</small>
  </div>
  <div class="tableau">
    <div class="header-tableau">
      <h2>Détail des prestations</h2>
      <p>Période concernée : du {{retrocession.dateDebut | date: 'dd/MM/yyyy'}} au {{retrocession.dateFin | date: 'dd/MM/yyyy'}}</p>
    </div>
    <div class="entetes-tableau">
      <div class="align-right flex-2">
        <p class="">Actes ({{retrocession.pourcentageActesMajorations}}%)</p>
      </div>
      <div class="align-right flex-1">
        <p class="">Indemnitées ({{retrocession.pourcentageIndemnites}}%)</p>
      </div>
      <div class="align-right flex-1">
        <p class="">Montant rétrocédé</p>
      </div>
    </div>
    <div class="contenu-tableau">
      <div class="ligne-tableau" *ngFor="let journee of journeesDisplayed">
        <div class="align-left flex-1">
          <p class="strong">{{journee.date}}</p>
        </div>
        <div class="align-right flex-1">
          <p>{{ journee.dateObject.totalActesMajorations | priceFormat : 2 }} €</p>
        </div>
        <div class="align-right flex-1">
          <p>{{ journee.dateObject.totalIndemnites | priceFormat : 2 }} €</p>
        </div>
        <div class="align-right flex-1">
          <p class="strong">{{ journee.dateObject.total | priceFormat : 2 }} €</p>
        </div>
      </div>
    </div>
    <div class="total-tableau">
      <div class="align-left flex-1">
        <h2 class="strong">{{nombreJournees}} journée{{nombreJournees > 1 ? 's' : ''}}</h2>
      </div>
      <div class="align-right flex-1">
          <p class="strong">{{ totalActesMajorations | priceFormat : 2 }}€</p>
        </div>
        <div class="align-right flex-1">
          <p class="strong">{{ totalIndemnites | priceFormat : 2 }}€</p>
        </div>
        <div class="align-right flex-1">
          <h2 class="strong">{{ totalRetrocession | priceFormat : 2 }}€</h2>
        </div>
    </div>
  </div>
</div>
