<div class="choix-convention-container">
	<div class="list-item-container">
		<ng-container *ngIf="listConventions">
			<ng-container *ngFor="let conv of listConventions">
				<div class="itemConventionSelect">
					<lua-radio-button (click)="selectConvention(conv)" class="radio-button"></lua-radio-button>
					<di-convention class="convention-item" [convention]="conv"></di-convention>
				</div>
				<lua-divider-horizontal size="small"></lua-divider-horizontal>
			</ng-container>
		</ng-container>

	</div>
	<lua-action-principale libelle="Enregistrer" [fullWidth]="true" (click)="saveConvention()"
		[isDisabled]="!conventionSelected"></lua-action-principale>
	<lua-bouton-texte libelle="Plus tard" (click)="close()"></lua-bouton-texte>
</div>