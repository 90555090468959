import { Component, Input, OnDestroy, OnInit, Type } from '@angular/core';
import { DateHelper } from '@osapp/helpers';
import { EPrefix, ETimetablePattern, IContact, IGalleryFile } from '@osapp/model';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { IPreferences } from '@osapp/modules/preferences/model/IPreferences';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { C_PREFIX_ACT } from 'apps/idl/src/anakin/anakin.constants';
import { Acte } from 'apps/idl/src/model/Acte';
import { IActe } from 'apps/idl/src/model/IActe';
import { ITraitement } from 'apps/idl/src/model/ITraitement';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { ActesService } from 'apps/idl/src/modules/actes/actes.service';
import { IAccordPrealable } from 'apps/idl/src/modules/patients/model/IAccordPrealable';
import { AccordPrealableService } from 'apps/idl/src/modules/traitement/slides-traitement/accordPrealable/accordPrealable.service';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../../../../../cloud/osapp.api.node.nest/src/helpers/array.helper';
import { ConfirmationSuppressionComponent } from '../../../shared/components/confirmation-suppression/confirmation-suppression.component';
import { PanneauAccordPrealableComponent } from '../../../shared/components/panneaux/panneau-accord-prealable/panneau-accord-prealable.component';
import { PanneauRechercheActesComponent } from '../../../shared/components/panneaux/panneau-recherche-actes/panneau-recherche-actes.component';
import { DeviceService } from '../../../shared/services/device.service';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { PanneauService } from '../../../shared/services/panneau.service';
import { PanneauActePriseEnChargeComponent } from '../panneau-acte-prise-en-charge/panneau-acte-prise-en-charge.component';
import { PriseEnChargeComponent } from '../prise-en-charge/prise-en-charge.component';

@Component({
  selector: 'di-liste-actes',
  templateUrl: './liste-actes.component.html',
  styleUrls: ['./liste-actes.component.scss'],
})
export class ListeActesComponent extends DestroyableComponentBase implements OnInit, OnDestroy {

  @Input() ordonnance: ITraitement;
  @Input() mode: "add" | "edit";
  @Input() generateSeances: (redirectFacturation: boolean)=> void = () => { }
  @Input() updateOrdonnance: (ordonnance: ITraitement) => void = (ordonnance: ITraitement) => { }

  public panneauTitle = "";
  public panneauContent: Type<PanneauRechercheActesComponent> | Type<PanneauActePriseEnChargeComponent> | Type<PanneauAccordPrealableComponent>;
  public panneauInputs: object;

  public listFavoritesIdActe: IPreferences;
  public isMobile: boolean = false;
  public showPriseEnChargeWarning: boolean = false;
  public priseEnChargeMessage: string = "La prise en charge n'est pas renseignée";
  public showAccordPrealable: boolean = false;
  public isActeUrgent: boolean = false;
  public showPopover: boolean = false;

  private ordonnanceObj: Traitement;
  public accord: IAccordPrealable;
  public accordDate: string;
  public accordDemandeur: string;
  public accordUrgents: string;
  public accordSerie: string;
  public accordElementsMedicaux: string;
  public accordDateResultat: string;
  public accordResultat: string;
  public accordFileRetour: IGalleryFile;

  public generateSeancesDisabled: boolean = false;

  public accordPrealableDisabled: boolean = false;

  private favoritesClickSubject = new Subject<IActe>();

  constructor(
    private svcPanneau: PanneauService,
    private svcActe: ActesService,
    private svcDevice: DeviceService,
    private svcFavorites: FavoritesService,
    private svcDrawerPopover: DrawerPopoverService,
    private svcTraitement: TraitementService,
    private svcAccordPrealable: AccordPrealableService,
    private svcContact: ContactsService,
  ) {
    super();
  }

  ngOnInit() {
    this.svcActe.selectActe$.pipe(takeUntil(this.destroyed$)).subscribe((acteSelected) => {
      //avant d'ajouter l'acte on regarde si on a deja un acte dans l'ordonnance
      //si c'est le cas on récupère automatiquement sa prise en charge et on l'affecte au nouveau
      //sans ouvrir le panneau de prise en charge
      const acteWithPrice = new Acte(acteSelected);
      const afficherPanneauPEC = !this.setPriseEnChargeFromActe(acteWithPrice);
      this.ordonnanceObj.actes.push(acteWithPrice);
      const indexOfNewActe = this.ordonnanceObj.actes.length - 1;
      //On enregistre l'acte dans l'ordonnance
      this.saveActe();

      //TODO : vérifier les regles de cotation, pour que le montant de l'acte soit correct

      if (afficherPanneauPEC) {
        this.openPanneauPriseEnCharge(acteWithPrice, indexOfNewActe);
      } else {
        this.svcPanneau.close();
      }
      this.setGenerateSeancesDisabled();
    });

    this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
      this.isMobile = flag;
    });

    this.favoritesClickSubject
      .pipe(
        throttleTime(500),
        mergeMap((acte: IActe) => this.svcFavorites.toggle(acte._id)),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    this.svcFavorites.get(C_PREFIX_ACT, true)
      .pipe(
        tap((favoritesIds: IPreferences) => {
          this.listFavoritesIdActe = favoritesIds;
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    this.initOrdonnanceObj();

    this.getAccordPrealable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe();

    this.svcPanneau.closePanel$
      .pipe(
        switchMap(() => this.getAccordPrealable()),
        tap(() => this.setGenerateSeancesDisabled()),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    this.verifierAccordPrealable(this.ordonnanceObj?.actes);
    this.setGenerateSeancesDisabled();
  }

  // Disable le bouton Generer les séances si les conditions ne sont pas remplies
  public setGenerateSeancesDisabled(): void {
		if(this.ordonnanceObj.stopDate)
		{
			this.generateSeancesDisabled = true;
      return;
		}
		
    this.verifierActesAvecPriseEnCharge()
    
    // si il n'y a pas d'actes, on disable la génération de séances
    if (this.ordonnanceObj.actes.length === 0) {
      this.generateSeancesDisabled = true;
      return;
    }

    // si un acte n'a pas de prise en charge, on disable la génération de séance
    if (this.showPriseEnChargeWarning) {
      this.generateSeancesDisabled = true;
      this.accordPrealableDisabled = true;
      return;
    }

    this.accordPrealableDisabled = false;
    this.verifierAccordPrealable(this.ordonnanceObj?.actes);

    // On contrôle l'accord préalable si il est requis, sinon on enable la génération de séances
    if (this.showAccordPrealable) {
      // Si le retour de la demande est OUI, alors on enable la génération de séances
      if (this.accord?.resultat) {
        this.generateSeancesDisabled = false;
      } else {
        // Si c'est urgent, alors on enable la génération de séances
        if (this.isActeUrgent || this.accord?.isUrgent) {
          this.generateSeancesDisabled = false;
        } else {
          this.generateSeancesDisabled = true;
        }
      }
      return;
    }
    this.generateSeancesDisabled = false;
  }


  // Récupère l'accord préalable et met à jour les informations à afficher sur la page
  public getAccordPrealable(): Observable<boolean> {
    if (this.ordonnanceObj.actes.some((acte: Acte) => acte.isPriorAgreement)) {
      return this.svcAccordPrealable.get(this.ordonnance._id).pipe(
        switchMap((accord: IAccordPrealable | null) => {
          if (accord) {
            this.accord = accord;
            return this.getAccordInformation();
          } else {
            this.accord = null;
            return of(false);
          }
        }),
        catchError(_ => {
          this.accord = null;
          return of(false);
        })
      );
    } else {
      this.accord = null;
      return of(false)
    }
  }

  // Formatte les informations du document accord préalable 
  public getAccordInformation(): Observable<boolean> {
    // On met accordFileRetour à null pour que le template mette à jour le fichier à afficher
    this.accordFileRetour = null;
    if (this.accord) {
      return this.svcContact.getSiteContactsAnakin([], EPrefix.contact, true, true).pipe(
        tap((contacts: IContact[]) => {
          let demandeur: IContact = contacts.find(contact => contact._id === this.accord.demandeurAPId)
          this.accordDate = DateHelper.transform(this.accord.dateDemande, ETimetablePattern.dd_MM_yyyy_slash);
          if (demandeur) {
            this.accordDemandeur = `${demandeur.firstName} ${demandeur.lastName.toUpperCase()}`;
          }
          this.accordUrgents = this.accord.isUrgent ? 'Oui' : 'Non';
          this.accordSerie = this.accord.isSerie ? 'Oui' : 'Non';
          this.accordElementsMedicaux = this.accord.elementMedicaux;
          if (typeof this.accord.resultat === 'boolean') {
            this.accordDateResultat = DateHelper.transform(this.accord.dateResultat, ETimetablePattern.dd_MM_yyyy_slash);
            this.accordResultat = this.accord.resultat ? "OUI, demande d'actes acceptée" : "NON, demande d'actes refusée";
          }
          this.accordFileRetour = this.accord.fileResultat
          this.setGenerateSeancesDisabled();
        }),
        map(() => true)
      );
    } else {
      return of(false)
    }
  }

  initOrdonnanceObj() {
    this.ordonnanceObj = Traitement.createFromData(this.ordonnance);
  }

  /**
   * Affecte à un acte les infos de prise en charge à partir d'un autre acte
   * Retourne true si une valeur a été affectée
   */
  setPriseEnChargeFromActe(acteSelected: Acte): boolean {
    if (this.ordonnanceObj.actes.length == 0) return false;
    const lastActe = ArrayHelper.getLastElement(this.ordonnanceObj.actes);
    if (lastActe.recurrences.length == 0) return false;
    //si j'ai une prise en charge sur le dernier acte alors je l'affecte directement au nouvel acte
    acteSelected.duration = lastActe.duration;
    acteSelected.isAldExonerante = lastActe.isAldExonerante;
    acteSelected.recurrences = lastActe.recurrences;
    acteSelected.weekRepetition = lastActe.weekRepetition;
    acteSelected.startDate = lastActe.startDate;
    acteSelected.place = lastActe.place;
    acteSelected.recurrences = lastActe.recurrences;
    acteSelected.sundayAndPublicHolidays = lastActe.sundayAndPublicHolidays;
    return true;
  }

  getTexteSecondairePriseEnCharge(acte: Acte): string {
    const texteSec = "";
    return texteSec;
  }

  showListeActes() {
    return this.ordonnanceObj.actes && this.ordonnanceObj.actes.length > 0;
  }

  openPanneauActes(): void {
    this.panneauTitle = "Actes";
    this.panneauContent = PanneauRechercheActesComponent;
    this.panneauInputs = {
      ordonnance: this.ordonnance
    };
    this.openPanneau();
  }

  openPanneauPriseEnCharge(acte: Acte, index: number): void {
    if (this.isMobile) {
      this.svcPanneau.close();
      this.svcDrawerPopover.open("Panneau prise en charge",
        "85%",
        null,
        PriseEnChargeComponent,
        {
          acte: acte,
					ordonnance: this.ordonnance,
          index: index,
          onSave: this.saveActeAvecPriseEnCharge,
          onCancel: this.cancelPriseEnCharge
        })
    } else {
      this.panneauTitle = "Prise en charge";
      this.panneauContent = PanneauActePriseEnChargeComponent;
      this.panneauInputs = {
        acte: acte,
				ordonnance: this.ordonnance,
        index: index,
        onSave: this.saveActeAvecPriseEnCharge,
        onCancel: this.cancelPriseEnCharge
      };
      this.openPanneau();
    }
  }

  openPanneau() {
    this.svcPanneau.open(this.panneauTitle, this.panneauContent, this.panneauInputs);
  }

  onFavoritesClicked(acte: IActe): void {
    this.favoritesClickSubject.next(acte);
  }

  toggleActeUrgent() {
    this.isActeUrgent = !this.isActeUrgent;
    this.setGenerateSeancesDisabled()
  }

  openPanneauAccordPrealable() {
    this.panneauTitle = "Demande d'accord préalable";
    this.panneauContent = PanneauAccordPrealableComponent;
    this.panneauInputs = {
      ordonnance: this.ordonnance,
      accord: this.accord
    };
    this.openPanneau();
  }

  getFavoriteStatus(acte: Acte): string {
    return this.isMobile ? '' :
      this.listFavoritesIdActe?.ids?.includes(acte._id) ?
        'done' : 'to be done';
  }

  //TODO : A remettre en place une fois la maquette terminée car NIE voudra à nouveau les 3 petits points
  // openMenu(event: Event, acte: Acte, index: number): void {
  //   this.showPopover = true;
  //   this.svcDrawerPopover.open("",
  //     "25%",
  //     event.currentTarget,
  //     MenuOptionsActeComponent,
  //     {
  //       ordonnance: this.ordonnanceObj,
  //       acte: acte,
  //       index: index,
  //       onDeleteClick: () => { this.handleDeleteActe(index) },
  //       onEditClick: (acte: Acte) => { console.log("onEditClick : ", acte) /* TODO: a modifier une fois le retour de NIE */ }
  //     },
  //     () => this.showPopover = false)
  // }

  openConfirmDeleteActe(event: Event, index: number) {
    this.showPopover = true;
    this.svcDrawerPopover.open(
      "Confirmation de suppression",
      "30%",
      event.currentTarget,
      ConfirmationSuppressionComponent,
      {
        onConfirm: () => this.handleConfirmDeleteActe(index),
        onCancel: () => this.closeMenu(),
      },
      () => (this.showPopover = false)
    );
  }

  handleConfirmDeleteActe(index: number) {
    this.svcDrawerPopover.close();
    //On retire l'acte de la liste d'acte de l'ordonnance
    this.ordonnanceObj.actes.splice(index, 1);
    //On sauvegarde l'ordonnance
    this.svcTraitement.saveTraitementANAKIN(this.ordonnanceObj).pipe(takeUntil(this.destroyed$)).subscribe({
      next: (updatedOrdonnance) => {
				this.ordonnance = updatedOrdonnance;
				this.initOrdonnanceObj();
        this.verifierAccordPrealable(this.ordonnanceObj.actes);
        this.setGenerateSeancesDisabled();
      },
      error: (error) => {
        console.error("Une erreur est survenue lors de la suppression de l'acte de l'ordonnance : ", error);
      }
    });
  }

  closeMenu(): void {
    this.showPopover = false;
    this.svcDrawerPopover.close();
  }

  public onGenerateSeances(redirectFactu? : boolean): void {
    // Si l'acte est urgent et qu'il n'y a pas de demande d'accord préalable alors on crée une avec isUrgent à true
    if (this.isActeUrgent && !this.accord) {
      this.accord = {
        _id: `${EPrefix.accordPrealable}${this.ordonnance._id}`,
        traitement: this.ordonnance._id,
        dateDemande: DateHelper.transform(new Date(), ETimetablePattern.isoFormat_hyphen),
        lastUpdate: DateHelper.transform(new Date(), ETimetablePattern.isoFormat_hyphen),
        isUrgent: true,
        isSerie: false,
        elementMedicaux: "",
        demandeurAPId: ContactsService.getUserContactId()
      };
      this.svcAccordPrealable.createOrUpdate(this.accord).pipe(takeUntil(this.destroyed$)).subscribe()
    }
    this.generateSeances(redirectFactu);
  }

  saveActeAvecPriseEnCharge = (acteToSave: Acte, index: number): void => {
    //on met à jour l'acte dans le traitement
    this.ordonnanceObj.actes[index] = acteToSave;

    //A voir si c'est obligatoire
    // StoreHelper.makeDocumentDirty(this.ordonnanceObj);

    // //puis je sauvegarde l'ordonnance avec l'acte 
    this.svcTraitement.saveTraitementANAKIN(this.ordonnanceObj).pipe(takeUntil(this.destroyed$)).subscribe(_ => {
      this.updateActesListe();
      this.closePanneauSwiperDrawable();
    });
  }

  cancelPriseEnCharge = () => {
    this.closePanneauSwiperDrawable();
  }

  saveActe() {
    this.svcTraitement.saveTraitementANAKIN(this.ordonnanceObj).pipe(takeUntil(this.destroyed$)).subscribe(_ => {
      this.updateActesListe();
    });
  }

  // Lorsque la liste d'actes est mise à jour, on effectue les vérifications nécessaires
  private updateActesListe(){
    //si il y a l'option d'accord préalable, je dois afficher le contenu correspondant
    this.verifierAccordPrealable(this.ordonnanceObj.actes);
    //on vérifie si on doit autoriser la génération de séances
    this.setGenerateSeancesDisabled();
    // On met à jour l'ordonnance au niveau du composant "detail-ordonnance"
    this.updateOrdonnance(this.ordonnanceObj);
  }

  private closePanneauSwiperDrawable() {
    if (this.isMobile) {
      this.svcDrawerPopover.close();
    } else {
      this.svcPanneau.close();
    }
  }

  private verifierAccordPrealable(actes: Acte[]) {
    this.showAccordPrealable = actes && actes.some(x => x.isPriorAgreement);
  }

  private verifierActesAvecPriseEnCharge() {
    this.showPriseEnChargeWarning = this.ordonnanceObj.actes.length > 0 && this.ordonnanceObj.actes.some(x => x.recurrences.length === 0 || !x.recurrences);
  }
}
