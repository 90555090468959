<div [ngClass]="'layout'">
	<nav>
		<di-entete-mobile *ngIf="isMobile && isAuthenticated"></di-entete-mobile>
		<div *ngIf="!isMobile && isAuthenticated">
			<di-entete-desktop [menuOpened]="menuOpened"></di-entete-desktop>
			<di-sidemenu-desktop [menuToggleFunction]="onMenuToggle" [menuOpened]="menuOpened"></di-sidemenu-desktop>
		</div>
	</nav>
	<main [class.main-desktop]="!isMobile && isAuthenticated" [class.menu-open]="menuOpened" [class.isMultiWS]="isMultiWS">
		<router-outlet id="content"></router-outlet>
		<!-- Ordre des templates est important : permet de savoir quels composants peuvent être affichés au dessus des autres -->
		<ng-template #panneauContainer></ng-template>
		<ng-template #drawerContainer></ng-template>
		<ng-template #popoverContainer></ng-template>
		<ng-template #loaderContainer></ng-template>
	</main>
</div>