import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Credentials, ESecurityFlag, IContact, IUser, IWorkspaceInfo, UserData } from '@osapp/model';
import { EPermissionsFlag } from '@osapp/modules/permissions/models/EPermissionsFlag';
import { SitesService } from '@osapp/modules/sites/services/sites.service';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService, FlagService, SecurityService, Store } from '@osapp/services';
import { from, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthenticatorService } from '../../services/authenticator.service';
import { DeviceService } from '../../services/device.service';
import { DrawerPopoverService } from '../../services/drawer-popover.service';
import { PanneauService } from '../../services/panneau.service';
import { MenuCompteUtilisateurComponent } from '../menu-compte-utilisateur/menu-compte-utilisateur.component';
import { PanneauPersonalisationWorkspaceComponent } from '../panneaux/panneau-prersonalisation-workspace/panneau-prersonalisation-workspace.component';

@Component({
	selector: "di-entete-desktop",
	templateUrl: './entete-desktop.component.html',
	styleUrls: ['./entete-desktop.component.scss'],
})
export class EnteteDesktopComponent extends DestroyableComponentBase implements OnInit {
	@ViewChild('menuUtilisateur', { static: false }) menuUtilisateur!: ElementRef<HTMLDivElement>;

	@Input() menuOpened : boolean;

	public firstName: string;
	public color: string;
	public colorWorkspace: string;
	public lastName: string;
	public siteName: string;
	public siteCity: string;
	public showPopover: boolean = false;
	public contact: IContact;
	public workspacelist: IWorkspaceInfo[];
	public nomWorkspace: string;

	constructor(
		private svcDrawerPopover: DrawerPopoverService,
		private svcContact: ContactsService,
		private svcSecurity: SecurityService,
		private ioNavController: NavController,
		private svcAuthenticator: AuthenticatorService,
		private svcFlag: FlagService,
		private svcStore: Store,
		private svcPanneau : PanneauService,
		private svcDevice : DeviceService,
		private svcSite: SitesService
	) { 
		super() 
	}

	public ngOnInit(): void {
		this.loadSiteInfo();

		this.svcSite.refreshSiteInfo$
			.pipe(
				takeUntil(this.destroyed$),
				tap(() => {
					this.loadSiteInfo();
				})
			)
			.subscribe();

		this.svcContact.reloadContact$
			.pipe(
				takeUntil(this.destroyed$),
				switchMap(() => {
					this.loadContact();
					return of(true);
				})
			)
			.subscribe();
		this.loadContact();
	}

	private loadSiteInfo() {
		this.siteName = UserData.currentSite?.name;
		this.siteCity = UserData.currentSite?.city;
	}

	private loadContact() {
		this.svcContact.getContact(ContactsService.getContactIdFromUserId(UserData.current._id)).pipe(
			tap((contact: IContact) => {
				this.contact = contact;
				this.firstName = this.contact.firstName;
				this.lastName = this.contact.lastName;
				this.color = this.contact.avatarCouleur ?? 'CouleurPrimaire'
				this.workspacelist = UserData.current.workspaceInfosList
				if (this.workspacelist && this.workspacelist.length > 0) {
					this.setInfoBandeau();
				}
				this.svcDevice.emitShowBandeau(this.workspacelist && this.workspacelist.length > 0);
			}),
		).subscribe();
	}

	private setInfoBandeau() {
		this.nomWorkspace = UserData.current.workspaceInfos[0].name;
		this.colorWorkspace = UserData.current.workspaceInfos[0].color ?? "CouleurPrimaire";
		document.documentElement.style.setProperty('--bandeauColor', `var(--${this.colorWorkspace})`);
	}

	public openMenu(): void {
		this.showPopover = true;
		this.svcDrawerPopover.open("", "25%", this.menuUtilisateur?.nativeElement, MenuCompteUtilisateurComponent, {}, () => this.showPopover = false)
	}

	public openPanneauPersonalisationWS() {
		this.svcPanneau.open("Personnalisation de l’espace de travail", PanneauPersonalisationWorkspaceComponent, {
			onUpdate: () => {
				this.setInfoBandeau();
			},
		})
	}

	public closeMenu(): void {
		this.showPopover = false;
		this.svcDrawerPopover.close();
	}

	public switchWorkspace(): void {
		this.svcSecurity.getUserCredentials().pipe(
			switchMap((credentials: Credentials) => this.svcSecurity.getLocalUserDocument(credentials.login)),
			tap((user: IUser) => {
				this.svcStore.deleteWorkspaceDatabases();
				UserData.current.workspaceInfos = user.workspaceInfos;
				this.svcAuthenticator.setAuthenticationStatus(false);
				this.svcFlag.setFlagValue(ESecurityFlag.authenticated, false);
				this.svcFlag.setFlagValue(EPermissionsFlag.isLoaded, false);
			}),
			switchMap(() => from(this.ioNavController.navigateRoot("/selectionWorkspace")))
		).subscribe()
	}
}
