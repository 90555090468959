import { Component, Input, OnInit } from "@angular/core";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { IModifActesSeance } from "apps/idl/src/anakin/models/IModifActesSeance";
import { StoredSeance } from "apps/idl/src/anakin/models/StoredSeance";
import { Acte } from "apps/idl/src/model/Acte";
import { ActesService } from "apps/idl/src/modules/actes/actes.service";
import { takeUntil, tap } from "rxjs/operators";
import { Traitement } from "../../../../../model/Traitement";
import { TraitementService } from "../../../../../services/traitement.service";
import { EEtatActe } from "../../enums/EEtatActe";
import { DeviceService } from "../../services/device.service";
import { DrawerPopoverService } from "../../services/drawer-popover.service";
import { PanneauService } from "../../services/panneau.service";
import { ConfirmationSuppressionComponent } from "../confirmation-suppression/confirmation-suppression.component";
import { PanneauRechercheActesComponent } from "../panneaux/panneau-recherche-actes/panneau-recherche-actes.component";

@Component({
	selector: "di-modifier-actes",
	templateUrl: "./modifier-actes.component.html",
	styleUrls: ["./modifier-actes.component.scss"],
})
export class ModifierActesComponent
	extends DestroyableComponentBase
	implements OnInit {

	@Input() seance: StoredSeance;

	drawerPanneauContent: any;
	drawerPanneauTitle: string;
	drawerPanneauInputs: any;
	isMobile: boolean;
	showPopover: boolean;
	traitement?: Traitement;

	@Input() confirm: (seance: StoredSeance, modifActesSeance: IModifActesSeance) => void;
	@Input() cancel: () => void;

	newlySelectedActe: Acte | null = null;
	selectedActe: Acte;
	actionToPerform: "add" | "replace" | "delete" | "" = "";

	// Contient la liste des modifications à appliquer
	modifActesSeance: IModifActesSeance = {
		actesDeleted: [],
		actesAdded: [],
		actesUpdated: []
	};
	constructor(
		private svcPanneau: PanneauService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcDevice: DeviceService,
		private svcActe: ActesService,
		private svcTraitement: TraitementService
	) {
		super();
	}

	ngOnInit() {
		this.svcDevice.isMobile$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((flag: boolean) => {
				this.isMobile = flag;
			});

		this.svcActe.selectActe$
			.subscribe((acteSelected) => {
				if (acteSelected && this.traitement?.isAld) {
					acteSelected.isAldExonerante = true;
				}
				const acteWithPrice = new Acte(acteSelected);
				acteWithPrice.etat = EEtatActe.to_be_done;
				this.newlySelectedActe = acteWithPrice;
				this.saveModif();
			});
	}
	openPanneau() {
		this.svcPanneau.open(
			this.drawerPanneauTitle,
			this.drawerPanneauContent,
			this.drawerPanneauInputs
		);
	}

	openPanneauActes(): void {
		this.svcTraitement.getTraitementANAKIN(this.seance.traitementId).pipe(
			tap((traitement: Traitement) => {
				this.traitement = traitement;
				this.closePanneauSwiperDrawable()
				this.drawerPanneauTitle = "Actes";
				this.drawerPanneauContent = PanneauRechercheActesComponent;
				this.drawerPanneauInputs = {
					ordonnance: traitement
				};
				this.openPanneau()
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}


	openConfirmDeleteActe(event: Event) {
		this.showPopover = true;
		this.svcDrawerPopover.open(
			"Confirmation de suppression",
			"30%",
			event.currentTarget,
			ConfirmationSuppressionComponent,
			{
				onConfirm: () => {
					this.closeMenu();
					this.saveModif();
				},

				onCancel: () => this.closeMenu(),
			},
			() => (this.showPopover = false)
		);
	}

	saveModif() {
		switch (this.actionToPerform) {
			case "add":
				this.modifActesSeance.actesAdded.push(this.newlySelectedActe);
				break;
			case "replace":
				this.modifActesSeance.actesUpdated.push({ src: this.selectedActe, dest: this.newlySelectedActe });
				break;
			case "delete":
				this.modifActesSeance.actesDeleted.push(this.selectedActe)
				break;
			default:
				break;
		}
		if (this.confirm)
			this.confirm(this.seance, this.modifActesSeance)
	}

	closeMenu(): void {
		this.showPopover = false;
		this.svcDrawerPopover.close();
	}

	private closePanneauSwiperDrawable() {
		if (this.isMobile) {
			this.svcDrawerPopover.close();
		} else {
			this.svcPanneau.close();
		}
	}

	addActe() {
		this.actionToPerform = "add";
		this.openPanneauActes();
	}

	replaceActe(acte: Acte) {
		this.actionToPerform = "replace";
		this.selectedActe = acte
		this.openPanneauActes();
	}

	deleteActe(event: Event, acte: Acte) {
		this.actionToPerform = "delete";
		this.selectedActe = acte;
		this.openConfirmDeleteActe(event);
	}
}
