import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { IonicModule } from '@ionic/angular';
import { DateModule } from '@osapp/components/date';
import { GalleryModule } from '@osapp/components/gallery';
import { CalaoAvatarModule } from '@osapp/modules/avatar/calao-avatar.module';
import { ConflictsModule } from '@osapp/modules/conflicts/conflicts.module';
import { GesturesModule } from '@osapp/modules/gestures/gestures.module';
import { HeaderModule } from '@osapp/modules/header/header.module';
import { ModalModule } from '@osapp/modules/modal';
import { PngIconModule } from '@osapp/modules/png-icon';
import { SelectorModule } from '@osapp/modules/selector/selector.module';
import { VirtualScrollModule } from '@osapp/modules/virtual-scroll/virtual-scroll.module';
import { PipeModule } from '@osapp/pipes/pipes.module';
import { IdlPipeModule } from '../../pipes/idlPipes.module';
import { ActesModule } from '../actes/actes.module';
import { FacturationService } from '../facturation/facturation.service';
import { OrdonnancesModule } from '../ordonnances/ordonnances.module';
import { PatientsModule } from '../patients/patients.module';
import { SeancesModule } from '../seances/seances.module';
import { ValidateActesModule } from '../traitement/validateActes/validate-actes.module';
import { ExportTourneeModalComponent } from './components/export-tournee/export-tournee-modal.component';
import { SeancePlaceModalComponent } from './components/seance-place-modal/seance-place-modal.component';
import { SeanceModalComponent } from './components/seance/seance-modal.component';
import { SelectSeanceStatusModalComponent } from './components/selects/select-seance-status-modal.component';
import { TimelinedEventComponent } from './components/tournee/timelined-event/timelined-event.component';
import { TourneePauseComponent } from './components/tournee/tournee-pause/tournee-pause.component';
import { TourneeComponent } from './components/tournee/tournee.component';
import { TourneePageComponent } from './pages/tournee/tournee-page.component';
import { TourneesPageComponent } from './pages/tournees/tournees-page.component';
import { TourneeFilterService } from './services/tournee-filter.service';
import { TourneesService } from './services/tournees.service';
import { TourneesRoutingModule } from './tournees-routing.module';

const components: Type<any>[] = [
	SeanceModalComponent,
	ExportTourneeModalComponent,
	SelectSeanceStatusModalComponent,
	SeancePlaceModalComponent
];

@NgModule({
	declarations: [
		...components,
		TimelinedEventComponent,
		TourneesPageComponent,
		TourneePageComponent,
		TourneePauseComponent,
		TourneeComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		IonicModule,
		PipeModule,
		CalaoAvatarModule,
		GalleryModule,
		IdlPipeModule,
		DateModule,
		PngIconModule,
		MatExpansionModule,
		MatTooltipModule,
		ValidateActesModule,
		TourneesRoutingModule,
		ModalModule,
		PatientsModule,
		SeancesModule,
		SelectorModule,
		HeaderModule,
		ActesModule,
		MatSnackBarModule,
		GesturesModule.forRoot({ longPress: { time: 1000 } }),
		ConflictsModule,
		OrdonnancesModule,
		VirtualScrollModule
	],
	providers: [TourneesService, FileOpener, TourneeFilterService, FacturationService]
})
export class TourneesModule { }