import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lua-loader-horizontal',
  templateUrl: './loader-horizontal.component.html',
  styleUrls: ['./loader-horizontal.component.css'],
})
export class LoaderHorizontalComponent implements OnChanges, OnDestroy {
  @Input() color = 'Raspberry';
  @Input() progressBar = false;
  @Input() onGoingProgress? = false;
  @Input() initialProgress = 0;
  @Input() targetProgress = 100;
  @Input() speed = 1;

  private intervalId: number | null = null;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialProgress'] || changes['targetProgress']) {
      this.updateProgress();
    }
    if (changes['color']) {
      this.el.nativeElement.style.setProperty(
        '--loader-color',
        `var(--${this.color})`
      );
    }
    if (changes['speed']) {
      this.el.nativeElement.style.setProperty(
        '--loader-speed',
        `${this.speed}s`
      );
    }
  }

  updateProgress() {
    const progressRatio = (this.initialProgress / this.targetProgress) * 100;
    this.el.nativeElement.style.setProperty(
      '--loader-progress',
      `${progressRatio}%`
    );
  }

  manageProgress() {
    if (
      this.progressBar &&
      this.onGoingProgress &&
      this.initialProgress < this.targetProgress
    ) {
      this.initialProgress += 1;
      this.updateProgress();
    }
  }

  ngOnInit(): void {
    if (!this.progressBar || this.onGoingProgress) {
      this.intervalId = window.setInterval(() => this.manageProgress(), 150);
    }
    this.el.nativeElement.style.setProperty(
      '--loader-color',
      `var(--${this.color})`
    );
    this.el.nativeElement.style.setProperty('--loader-speed', `${this.speed}s`);
    this.updateProgress();
  }

  ngOnDestroy(): void {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  getClasses() {
    return {
      indeterminateMode: !this.progressBar,
    };
  }
}
