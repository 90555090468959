import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { PanneauService } from '../../../services/panneau.service';

@Component({
  selector: 'di-panneau-patient-deces',
  templateUrl: './panneau-patient-deces.component.html',
  styleUrls: ['./panneau-patient-deces.component.scss'],
})
export class PanneauPatientDecesComponent implements OnInit {

  @Input()
  public patient: IPatient;

  public model: IPatient;

  public decesForm: FormGroup;

  public deces: boolean;


  constructor(private svcPatients: PatientsService, private svcPanneau: PanneauService, private fb: FormBuilder) { }

  ngOnInit() {
		this.deces = this.patient.deceased ?? true;
    this.decesForm = this.fb.group({
      deces: [this.deces],
      decesDate: [this.patient.deceasedDate || ""],
      decesHeure: [this.patient.deceasedHour || ""],
      decesCommentaire: [this.patient.deceasedComment || ""]
    });
    this.model = this.patient;
  }

  public goBack(): void {
    this.svcPanneau.close();
  }

  public toggleDeces(deceased: boolean){
    this.deces = deceased;
  }

  public handleSubmit(event: Event): void {
    event.preventDefault();
    if (this.decesForm.valid) {
      const formValues = this.decesForm.value;
      this.model = {
        ...this.model,
        deceased: this.deces,
        deceasedDate: "",
        deceasedHour: "",
        deceasedComment: ""
      }
      if(this.deces){
        this.model = {
          ...this.model,
          deceasedDate: formValues.decesDate,
          deceasedHour: formValues.decesHeure,
          deceasedComment: formValues.decesCommentaire
        }
      }
      this.svcPatients.savePatientAnakin(this.model).subscribe(() => {
        this.svcPatients.saveAdressCacheData(this.model);
        this.svcPanneau.close();
      })
    }
  }

}
