import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { EPrefix, ESortOrder, UserData } from '@osapp/model';
import { ConfigData } from '@osapp/model/config/ConfigData';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { AuthenticatedRequestOptionBuilder } from '@osapp/modules/api/models/authenticated-request-option-builder';
import { Loader } from '@osapp/modules/loading/Loader';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { LoadingService } from '@osapp/services';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { Store } from '@osapp/services/store.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { WorkspaceService } from '@osapp/services/workspace.service';
import { plainToClass } from 'class-transformer';
import { Observable, forkJoin, from, of, throwError } from 'rxjs';
import { catchError, concatMap, finalize, map, mapTo, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';
import { ChoixConventionComponent } from '../../../anakin/features/patients/components/choix-convention/choix-convention.component';
import { DrawerPopoverService } from '../../../anakin/features/shared/services/drawer-popover.service';
import { LoaderService } from '../../../anakin/features/shared/services/loader.service';
import { PanneauService } from '../../../anakin/features/shared/services/panneau.service';
import { C_PREFIX_AMC, C_PREFIX_AMCP, C_PREFIX_AMO, C_PREFIX_AMOP } from '../../../app/app.constants';
import { ConventionModalComponent } from '../components/couvertures/convention-modal/convention-modal.component';
import { AMCP } from '../model/amc-p';
import { AMOP } from '../model/amo-p';
import { ECouvertureType } from '../model/ecouverture-type.enum';
import { EGarantie } from '../model/egarantie.enum';
import { EUpdateMode } from '../model/eupdate-mode.enum';
import { IAMC } from '../model/iamc';
import { IAMCP } from '../model/iamc-p';
import { IAMO } from '../model/iamo';
import { IAMOP } from '../model/iamo-p';
import { IConvention } from '../model/iconvention';
import { ICouvertureP } from '../model/icouverture-p';
import { ISortedCouvertures } from '../model/isorted-couvertures';

@Injectable({
	providedIn: 'root'
})
export class CouverturesService {


	//#region PROPERTIES

	public static readonly C_COUVERTURES_GESTION_UNIQUE_IDS: string[] = [
		"amc_0099999997", // C2S
		"amc_0775685399", // MGEN
	];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioHttp: HttpClient,
		private readonly isvcModal: ModalService,
		private readonly isvcLoader: LoadingService,
		private readonly svcLoader : LoaderService,
		private readonly svcDrawerPopover: DrawerPopoverService,
		private readonly svcPanneau: PanneauService,
	) { }

	private get baseUrl(): string {
		return `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}workspaces/${this.isvcWorkspace.getWorkspaceNameFromId(ArrayHelper.getFirstElement(UserData.current.workspaceInfos).id)}`;
	}

	public getPatientSortedCouvertures(psPatientId: string, pbLive?: boolean): Observable<{ AMOPs: AMOP[]; AMCPs: AMCP[]; }> {
		const loAMOPDataSource: IDataSource = this.getCouvertureDataSource(psPatientId, C_PREFIX_AMOP, pbLive);
		const loAMCDataSource: IDataSource = this.getCouvertureDataSource(psPatientId, C_PREFIX_AMCP, pbLive);

		let laAMOPs: AMOP[] = [];
		let laAMCPs: AMCP[] = [];

		return this.isvcStore.get<IAMOP>(loAMOPDataSource)
			.pipe(
				tap((paResults: IAMOP[]) => laAMOPs = paResults.map((poAMOP: IAMOP) => plainToClass(AMOP, poAMOP))),
				mergeMap(() => this.isvcStore.get<IAMCP>(loAMCDataSource)),
				tap((paResults: IAMCP[]) => laAMCPs = paResults.map((poAMCP: IAMCP) => plainToClass(AMCP, poAMCP))),
				map(() => {
					return {
						AMOPs: ArrayHelper.dynamicSort(laAMOPs, "dateFin", ESortOrder.descending),
						AMCPs: ArrayHelper.dynamicSort(laAMCPs, "dateFin", ESortOrder.descending)
					};
				})
			);
	}

	public deleteAllCouvertureAMO(idPatient: string): Observable<void> {
		return this.getPatientSortedCouvertures(idPatient).pipe(
			mergeMap((sortedCouvertures) => {
				const amopsToDelete = sortedCouvertures.AMOPs;
				if (amopsToDelete.length === 0) {
					return of(undefined);
				}

				const deleteObservables: Observable<void>[] = amopsToDelete
					.filter(amop => {
						const isManual = amop.updateMode !== EUpdateMode.ADRi && amop.updateMode !== EUpdateMode.carteVitale;
						const isOutsideSameHour = !DateHelper.areDatesInSameHour(new Date(), new Date(amop.updateDate));

						return isManual || isOutsideSameHour;
					})
					.map(amop =>
						this.deleteCouverture(amop, idPatient).pipe(
							map(() => undefined)
						)
					);

				return forkJoin(deleteObservables).pipe(
					map(() => undefined)
				);
			}),
			catchError((error) => {
				return throwError(error);
			})
		);
	}


	public getPatientLastSortedCouvertures(psPatientId: string, pbLive?: boolean): Observable<ISortedCouvertures> {
		return this.getPatientSortedCouvertures(psPatientId, pbLive)
			.pipe(
				map((poCouvertures: { AMOPs: AMOP[]; AMCPs: AMCP[]; }) => {
					return {
						AMOP: ArrayHelper.getFirstElement(poCouvertures.AMOPs),
						AMCP: ArrayHelper.getFirstElement(poCouvertures.AMCPs)
					};
				})
			);
	}

	public getCouvertureDataSource(psPatientId: string, peCouverturePrefix: EPrefix, pbLive: boolean): IDataSource {
		return {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				startkey: `${peCouverturePrefix}${psPatientId}`,
				endkey: `${peCouverturePrefix}${psPatientId}${Store.C_ANYTHING_CODE_ASCII}`
			},
			live: pbLive
		};
	}

	/** Sauvegarde une couverture.
	 * @param poCouverture
	 */
	public saveCouverture(poCouverture: ICouvertureP): Observable<ICouvertureP> {
		let loader: Loader;
		return from(this.isvcLoader.create("Sauvegarde de la couverture patient ...")).pipe(
			tap(loading => {
				loader = loading;
				loader.present();
			}),
			switchMap(() => this.isvcStore.put(poCouverture)),
			switchMap(() => {
				const patientId = IdHelper.extractAllIds(poCouverture._id)[0];
				return this.getPatientLastSortedCouvertures(patientId).pipe(
					switchMap(lastSortedCouvertures => {
						const exportObservablesList = [];
						if (poCouverture._id.startsWith(EPrefix.amc)) {
							if (lastSortedCouvertures.AMOP) {
								exportObservablesList.push(
									this.exportCouverture(patientId, lastSortedCouvertures.AMOP).pipe(
										catchError(error => {
											console.error('Erreur lors de l\'exportation de l\'AMOP :', error);
											return of(null);
										})
									)
								);
							}
							exportObservablesList.push(
								this.exportCouverture(patientId, poCouverture as AMCP).pipe(
									catchError(error => {
										console.error('Erreur lors de l\'exportation de l\'AMCP :', error);
										return of(null);
									})
								)
							);
						} else {
							exportObservablesList.push(
								this.exportCouverture(patientId, poCouverture as AMOP).pipe(
									catchError(error => {
										console.error('Erreur lors de l\'exportation de l\'AMOP :', error);
										return of(null);
									})
								)
							);
							if (lastSortedCouvertures.AMCP) {
								exportObservablesList.push(
									this.exportCouverture(patientId, lastSortedCouvertures.AMCP).pipe(
										catchError(error => {
											console.error('Erreur lors de l\'exportation de l\'AMCP :', error);
											return of(null);
										})
									)
								);
							}
						}

						return from(exportObservablesList).pipe(
							concatMap(obs => obs),
							toArray()
						);
					})
				);
			}),
			finalize(() => loader?.dismiss()),
			mapTo(poCouverture)
		);
	}


	public saveCouvertureANAKIN(poCouverture: ICouvertureP, anchorPopover?: any): Observable<ICouvertureP> {
		let isAmcToUpdate: boolean = false;
		this.svcLoader.showLoader(`Sauvegarde de la couverture ${poCouverture._id.startsWith(EPrefix.amo) ? 'AMO' : 'AMC'} en cours ...`)
		return from(this.isvcStore.put(poCouverture)).pipe(
			switchMap(() => {
				const patientId = IdHelper.extractAllIds(poCouverture._id)[0];
				return this.getPatientLastSortedCouvertures(patientId).pipe(
					switchMap(lastSortedCouvertures => {
						const exportObservablesList = [];
						isAmcToUpdate = !!lastSortedCouvertures?.AMCP;
						if (poCouverture._id.startsWith(EPrefix.amc)) {
							exportObservablesList.push(
								this.exportCouvertureANAKIN(patientId, poCouverture as AMCP, anchorPopover).pipe(
									catchError(error => {
										console.error('Erreur lors de l\'exportation de l\'AMCP :', error);
										throw (error);
									})
								)
							);
						} else {
							exportObservablesList.push(
								this.exportCouvertureANAKIN(patientId, poCouverture as AMOP, anchorPopover).pipe(
									catchError(error => {
										console.error('Erreur lors de l\'exportation de l\'AMOP :', error);
										throw ({ error: { message: 'Erreur lors de l\'exportation de l\'AMO' } })
									})
								)
							);
							if (lastSortedCouvertures.AMCP) {
								//Les conventions de l'amc dépendent de l'AMO (codeAmo) c'est pourquoi on doit faire un exportAMC
								exportObservablesList.push(
									this.exportCouvertureANAKIN(patientId, lastSortedCouvertures.AMCP, anchorPopover).pipe(
										catchError(error => {
											console.error('Erreur lors de l\'exportation de l\'AMCP :', error);
											throw (error);
										})
									)
								);
							}
						}

						return from(exportObservablesList).pipe(
							concatMap(obs => obs),
							toArray()
						);
					})

				);
			}),
			mapTo(poCouverture),
			finalize(() => {
				if (!isAmcToUpdate) {
					this.svcDrawerPopover.close();
					this.svcPanneau.close();
				}
				this.svcLoader.hideLoader();
			})
		);
	}

	/** Supprime une couverture.
	 * @param poCouverture
	 */
	public deleteCouverture(poCouverture: ICouvertureP, patientId?: string): Observable<boolean> {
		const lsTypeCouverture = poCouverture._id.startsWith(EPrefix.amo) ? "amo" : "amc";
		if (patientId) {
			const params = new HttpParams().set('couvertureId', poCouverture._id);

			return this.ioHttp
				.delete<ICouvertureP>(
					`${this.baseUrl}/entities/patients/${patientId}/${lsTypeCouverture}`,
					{
						params,
						...AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions(),
					}
				)
				.pipe(
					map(() => true),
					catchError((error) => {
						console.error(`Erreur lors de la suppression de la couverture sur le serveur :`, error);
						return of(false);
					})
				);
		}
		else {
			return this.isvcStore.delete(poCouverture).pipe(
				map((poResponse) => poResponse.ok),
				catchError((error) => {
					console.error(`Erreur lors de la suppression locale de la couverture :`, error);
					return of(false);
				})
			);
		}

	}




	public getEtablissements(peCouvertureType: ECouvertureType): Observable<(IAMO | IAMC)[]> {
		const lsDatabaseIds: string[] = this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsEntries);
		const lsPrefix: string = peCouvertureType === ECouvertureType.AMOP ? C_PREFIX_AMO : C_PREFIX_AMC;
		const loDataSource: IDataSource = {
			databasesIds: lsDatabaseIds,
			viewParams: {
				include_docs: true,
				startkey: lsPrefix,
				endkey: `${lsPrefix}${Store.C_ANYTHING_CODE_ASCII}`
			}
		};

		return this.isvcStore.get<IAMO | IAMC>(loDataSource);
	}

	public getEtablissementsByIds(paIds: string[]): Observable<(IAMO | IAMC)[]> {
		const lsDatabaseIds: string[] = this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsEntries);
		const modifiedPaIds = paIds.flatMap(id => {
			if (id.startsWith("amo_")) {
				const modifiedId = id.substring(0, id.lastIndexOf('-')) + '-0000';
				return [id, modifiedId];
			} else {
				return id;
			}
		});
		const loDataSource: IDataSource = {
			databasesIds: lsDatabaseIds,
			viewParams: {
				include_docs: true,
				keys: modifiedPaIds
			}
		};

		return this.isvcStore.get<IAMO | IAMC>(loDataSource)
	}

	public getLabelCouverture(amoId: string, etablissementsById: Map<string, IAMO | IAMC>): string | undefined {
		const fullLabel = etablissementsById.get(amoId)?.label;
		if (fullLabel) {
			return fullLabel;
		} else {
			const modifiedAmoId = amoId.replace(/-\d+$/, '-0000');
			const resultat = etablissementsById.get(modifiedAmoId);
			return resultat?.label;
		}
	}

	public getEtablissementById(amoId: string, etablissementsById: Map<string, IAMO | IAMC>): IAMO | IAMC {
		const etablissement = etablissementsById.get(amoId);
		if (etablissement) {
			return etablissement;
		}
		const modifiedAmoId = amoId.replace(/-\d+$/, '-0000');
		return etablissementsById.get(modifiedAmoId);
	}

	/** Exporte une couverture vers le logiciel partenaire.
	 * @param psPatientId Id du patient.
	 * @param poCouverture Couverture à exporter.
	 */
	public exportCouverture(psPatientId: string, poCouverture: AMOP | AMCP): Observable<ICouvertureP> {
		const lsTypeCouverture: string = poCouverture._id.startsWith(EPrefix.amo) ? "amo" : "amc";
		const params = new HttpParams().set('couvertureId', poCouverture._id);

		return from(this.isvcLoader.create("Export de la couverture patient ...")).pipe(
			switchMap(loader => {
				loader.present();

				return this.ioHttp.post<ICouvertureP>(
					`${this.baseUrl}/entities/patients/${psPatientId}/${lsTypeCouverture}/export`,
					null,
					{
						params: params,
						...AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
					}
				).pipe(
					catchError(poError => {
						if (lsTypeCouverture === "amo") {
							console.error("PAT.S:: Erreur export de la couverture amo :", poError);
							this.isvcUiMessage.showMessage(
								new ShowMessageParamsPopup({
									header: `Erreur lors de l'export de la couverture amo.`,
									message: poError.error.message
								})
							);
						} else {
							if (poError.error.message.includes("ne possède aucune convention")) {
								this.isvcUiMessage.showMessage(
									new ShowMessageParamsPopup({
										header: `Erreur lors de l'export de la couverture amc.`,
										message: poError.error.message
									})
								);
							} else if (poError.error.message.includes("possède plusieurs conventions")) {
								return this.consulterConventions(psPatientId).pipe(
									tap(result => {
										const resultatArrayConv: IConvention[] = !Array.isArray(result) ? [result] : result;
										this.openModalConvention(resultatArrayConv, psPatientId);
									}),
									catchError(error => {
										console.error("Error during consultation: ", error);
										return of(undefined);
									})
								);
							}
						}
						return of(undefined);
					}),
					finalize(() => loader.dismiss())
				);
			})
		);
	}

	public exportCouvertureANAKIN(psPatientId: string, poCouverture: AMOP | AMCP, anchorPopover?: any): Observable<ICouvertureP> {
		const lsTypeCouverture: string = poCouverture._id.startsWith(EPrefix.amo) ? "amo" : "amc";
		const params = new HttpParams().set('couvertureId', poCouverture._id);
		let isError: boolean = false;
		return this.ioHttp.post<ICouvertureP>(
			`${this.baseUrl}/entities/patients/${psPatientId}/${lsTypeCouverture}/export`,
			null,
			{
				params: params,
				...AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
			}
		).pipe(
			catchError(poError => {
				isError = true;
				if (lsTypeCouverture === "amo") {
					console.error("PAT.S:: Erreur export de la couverture amo :", poError);
					throw (poError);
				} else {

					if (poError.error.message.includes("ne possède aucune convention")) {
						// pas de convention
						throw (poError);
					} else if (poError.error.message.includes("possède plusieurs conventions")) {
						return this.consulterConventions(psPatientId).pipe(
							tap(result => {
								const resultatArrayConv: IConvention[] = !Array.isArray(result) ? [result] : result;
								this.svcDrawerPopover.open("Sélectionner la convention", "50%", anchorPopover?.nativeElement, ChoixConventionComponent, {
									listConventions: resultatArrayConv,
									patientId: psPatientId,
									couvertureAMC: poCouverture
								}, null)
							}),
							catchError(error => {
								throw (error);
							})
						);
					}
					else {
						throw (poError);
					}
				}
			}),
			mergeMap((response) => {
				if (isError) {
					return of(undefined);
				}
				if (lsTypeCouverture === "amc" && !(poCouverture as AMCP).gestionUnique) {
					const couverture: AMCP = poCouverture as AMCP;
					return this.consulterConventions(psPatientId).pipe(
						tap((resultat: IConvention[]) => {
							const conventionsSelected = !Array.isArray(resultat) ? [resultat] : resultat
							const conventionSelected = ArrayHelper.getFirstElement(conventionsSelected);
							couverture.idConvention = conventionSelected.idtConvention;
							couverture.convention = conventionSelected;
							this.isvcStore.put(couverture).pipe(
								tap(() => {
									this.svcDrawerPopover.close();
									this.svcPanneau.close();
								})
							).subscribe();
						}),
						catchError(error => {
							console.error("Error during the second consultation: ", error);
							return of(undefined);
						})
					);
				} else {
					return of(response);
				}
			}),
			finalize(() => {
				if (!isError && lsTypeCouverture === "amc") {
					this.svcPanneau.close()
				}
			})
		)
	}

	public openModalConvention(convList: IConvention[], patientId: string): void {
		from(this.isvcModal.open({
			component: ConventionModalComponent,
			componentProps: {
				conventionList: convList.map(convention => {
					return {
						label: [
							convention.libelle?.trim(),
							convention.typeConvention?.trim(),
							convention.codeOrganismeSignataire?.trim(),
							convention.critereSecondaire !== undefined ? convention.critereSecondaire.trim() : "",
							convention.estTp ? "TP" : "hors TP"
						].filter(Boolean).join(" - "),
						value: convention.idtConvention
					};
				})
			}
		}))
			.subscribe(
				(convSeleted: number[] | null) => {
					if (convSeleted) {
						this.selectionnerConvention(patientId, convSeleted[0]).subscribe()
					}
				},
				error => {
					console.error('Erreur lors de l\'ouverture de la modal : ', error);
				}
			);
	}

	public consulterConventions(psPatientId: string): Observable<IConvention[]> {
		return this.ioHttp.post<IConvention[]>(
			`${this.baseUrl}/entities/patients/${psPatientId}/amc/consulterConventions`,
			null,
			AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
		)
			.pipe(
				catchError(poError => {
					console.error("PAT.S:: Erreur l'appel à consulter convention :", poError);
					this.isvcUiMessage.showMessage(
						new ShowMessageParamsPopup({ header: `Erreur dans la consultation des conventions.`, message: poError.error ? poError.error.message : poError.message })
					);
					throw (poError);
				}),
			);
	}

	public selectionnerConvention(psPatientId: string, conventionId: number): Observable<void> {
		const params = new HttpParams().set('conventionId', conventionId);
		return this.ioHttp.post<void>(
			`${this.baseUrl}/entities/patients/${psPatientId}/amc/selectionnerConvention`, null,
			{
				params: params,
				...AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
			}
		)
			.pipe(
				catchError(poError => {
					console.error("PAT.S:: Erreur lors de l'appel à selectionner convention :", poError);
					this.isvcUiMessage.showMessage(
						new ShowMessageParamsPopup({ header: `Erreur dans la selection de la convention.`, message: poError.message })
					);
					return of(undefined);
				}),
			);
	}

	public static getFilteredGarantieOptions(psAmoId: string): ISelectOption<EGarantie>[] {
		psAmoId = IdHelper.extractIdWithoutPrefix(psAmoId, C_PREFIX_AMO);
		const laOptions: ISelectOption<EGarantie>[] = [{ label: "Aucune", value: undefined }];

		switch (psAmoId.slice(0, 2)) {
			case "01":
				laOptions.push(...[
					{ label: "Non éxonéré (60%)", value: EGarantie.NONEXO },
					{ label: "FNS (80%)", value: EGarantie.TXFSV },
					{ label: "Régime local (Alsace-Moselle) (90%)", value: EGarantie.TXALMO },
					{ label: "Assuré ou bénéficiaire exonéré (100%)", value: EGarantie['100TOUT'] },

				]);
				break;

			case "02":
				laOptions.push(...[
					{ label: "Non exonérés (accidents non couverts) (60%)", value: EGarantie.NONEXOACC },
					{ label: "Autres cas non exonérés (accidents non couverts) (60%)", value: EGarantie.AUTREACC },
					{ label: "Régime local (Alsace-Moselle) (90%)", value: EGarantie.TXALMO },
				]);
				break;

			case "03":
				laOptions.push(...[
					{ label: "Non éxonéré (60%)", value: EGarantie.NONEXO },
					{ label: "Assuré ou bénéficiaire exonéré (100%)", value: EGarantie["100TOUT"] },
				]);
				break;

			case "04":
				laOptions.push(...[
					{ label: "Non éxonéré (60%)", value: EGarantie.NONEXO },
					{ label: "FNS (80%)", value: EGarantie.TXFSV },
					{ label: "Régime local (Alsace-Moselle) (90%)", value: EGarantie.TXALMO },
					{ label: "SNCF (100%)", value: EGarantie["100SNCF"] },
					{ label: "Assuré ou bénéficiaire exonéré (100%)", value: EGarantie["100TOUT"] },
				]);
				break;

			case "05":
				laOptions.push(...[
					{ label: "Non éxonéré (60%)", value: EGarantie.NONEXO },
					{ label: "FNS (80%)", value: EGarantie.TXFSV },
					{ label: "Assuré ou bénéficiaire exonéré (100%)", value: EGarantie["100TOUT"] },
				]);
				break;

			case "07":
				laOptions.push(...[
					{ label: "Assuré ou bénéficiaire exonéré (100%)", value: EGarantie["100TOUT"] },
				]);
				break;

			case "09":
				laOptions.push(...[
					{ label: "Non éxonéré (60%)", value: EGarantie.NONEXO },
					{ label: "Assuré ou bénéficiaire exonéré (100%)", value: EGarantie["100TOUT"] },
				]);
				break;
		}

		switch (psAmoId.slice(0, 1)) {
			case "1":
				laOptions.push(...[
					{ label: "Non éxonéré (60%)", value: EGarantie.NONEXO },
					{ label: "Assuré ou bénéficiaire exonéré (100%)", value: EGarantie["100TOUT"] },
				]);
				break;

			case "9":
				laOptions.push(...[
					{ label: "Non éxonéré (60%)", value: EGarantie.NONEXO },
					{ label: "FNS (80%)", value: EGarantie.TXFSV },
					{ label: "Assuré ou bénéficiaire exonéré (100%)", value: EGarantie["100TOUT"] },

				]);
				break;
		}

		return laOptions;
	}

	public getLatestActiveCouverture(couvertures: (AMOP | AMCP)[]): AMOP | AMCP | null {
		const activeCouvertures = couvertures
			.filter((couverture) => couverture.isActiveAnakin)
			.sort((a, b) => (b.dateDebut?.getTime() || 0) - (a.dateDebut?.getTime() || 0));

		if (activeCouvertures.length > 0) {
			return activeCouvertures[0];
		}

		// Find expired entities
		const expiredCouvertures = couvertures
			.filter((couverture) => couverture.dateFin && new Date(couverture.dateFin).getTime() < new Date().getTime())
			.sort((a, b) => (new Date(b.dateFin).getTime() || 0) - (new Date(a.dateFin).getTime() || 0));

		return expiredCouvertures.length > 0 ? expiredCouvertures[0] : null;
	}

	//#endregion

}
