import { Component, Input, OnInit } from '@angular/core';
import { ETimetablePattern, IAddress, IContact } from '@osapp/model';
import { Site } from '@osapp/modules/sites/models/site';
import { DateObject } from '../../../models/retrocession/DateObject';
import { Retrocession } from 'apps/idl/src/modules/patients/model/Retrocession';
import { DateHelper } from '@osapp/helpers';

@Component({
  selector: 'di-impression-retrocession',
  templateUrl: './impression-retrocession.component.html',
  styleUrls: ['./impression-retrocession.component.scss'],
})
export class ImpressionRetrocessionComponent implements OnInit {

  @Input() retrocession!: Retrocession;
  @Input() cabinet!: Site;
  @Input() remplacant!: IContact;
  @Input() journees!: { date: Date, dateObject: DateObject }[];
  @Input() nombreJournees!: number;
  @Input() totalActesMajorations!: number;
  @Input() totalIndemnites!: number;
  @Input() totalRetrocession!: number;

  public dateRetrocession: string;
  public nomCabinet: string;
  public adresseCabinet: string;
  public villeCabinet: string;
  public telephoneCabinet: string;
  public emailCabinet: string;
  public nomPrenomRemplacant: string;
  public adresseRemplacant: string;
  public finessRemplacant: string;
  public informationsCabinet: string[];
  public journeesDisplayed: {date: string; dateObject: DateObject}[];

  public MAX_CHAR_PER_LINE: number = 38;

  constructor() { }

  ngOnInit() {
    const dateFin: Date | null = this.retrocession?.dateFin ? new Date(this.retrocession.dateFin) : null;
    this.nomCabinet = this.cabinet?.name || '';
    this.adresseCabinet = this.cabinet?.street || '';
    this.villeCabinet = [this.cabinet?.zipCode, this.cabinet?.city].filter(Boolean).join(' ')
    this.telephoneCabinet = this.cabinet?.phone || '';
    this.emailCabinet = this.cabinet?.email || '';
    this.setInformationsCabinet();
    this.nomPrenomRemplacant = (this.remplacant?.lastName || '') + ' ' + (this.remplacant?.firstName || '');
    this.adresseRemplacant = this.getAddress(this.remplacant);
    this.finessRemplacant = this.remplacant?.finess || '';
    this.dateRetrocession = dateFin ? dateFin.getDate() + ' ' + DateHelper.C_MONTH_NAMES[dateFin.getMonth()] + ' ' + dateFin.getFullYear() : '';
    this.journeesDisplayed = this.journees.map((journee: {date: Date, dateObject: DateObject}) => ({
      ...journee,
      date: DateHelper.transform(journee.date, ETimetablePattern.dd_MMM_yyyy)
    }));
  }

  public getAddress(adress: IAddress): string {
    const { street, zipCode, city } = adress || {};
    const addressParts = [
      street,
      [zipCode, city].filter(Boolean).join(' ')
    ].filter(Boolean);
    return addressParts.join(', ');
  }

  // Divise les informations du cabinet sur plusieurs lignes de 'maxCharPerLine' characters
  private splitTextToFitWidth(text: string, numberOfLines: number): string[] {
    const maxCharactersPerLine = this.MAX_CHAR_PER_LINE;
    const lines: string[] = [];
    let currentLine = '';
    const words = text.split(' ');
    for (const word of words) {
      const isFirstWord = currentLine === '';
      const newLineLength = currentLine.length + (isFirstWord ? 0 : 1) + word.length;
      if (newLineLength <= maxCharactersPerLine) {
        currentLine += (isFirstWord ? '' : ' ') + word;
      } else {
        if (lines.length === numberOfLines - 1) {
          break;
        } else {
          lines.push(currentLine.trim());
          currentLine = word;
        }
      }
    }
    if (lines.length < numberOfLines) {
      const wordsToPush = words.join(' ').slice(lines.length * maxCharactersPerLine + currentLine.length);
      lines.push((currentLine + ' ' + wordsToPush).slice(0, maxCharactersPerLine).trim());
    }
    return lines;
  }

  // On affiche les informations sur 5 lignes, avec 3 lignes maximum pour l'adresse
  public setInformationsCabinet(): void {
    const addressLines = this.splitTextToFitWidth(this.adresseCabinet, 2);
    const villeLines = this.splitTextToFitWidth(this.villeCabinet, 1);
    const nonEmptyValues = [
      ...addressLines,
      ...villeLines,
      this.telephoneCabinet,
      this.emailCabinet
    ].filter(value => value !== '');
    this.informationsCabinet = [...nonEmptyValues, ...Array(5 - nonEmptyValues.length).fill('')].slice(0, 5);
  }
}