import { NgModule, Type } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { CameraModule } from '../../../modules/camera/camera.module';
import { ColorsModule } from '../../../modules/colors/colors.module';
import { DrawingModule } from '../../../modules/drawing/drawing.module';
import { SectorsModule } from '../../../modules/sectors/sectors.module';
import { SelectorModule } from '../../../modules/selector/selector.module';
import { ContactsModule } from '../../contacts/contacts.module';
import { DateModule } from '../../date/date.module';
import { FilePickerModule } from '../../filePicker/filePicker.module';
import { GalleryModule } from '../../gallery/gallery.module';
import { LikeModule } from '../../like/like.module';
import { LinkModule } from '../../link/link.module';
import { LinkedItemsListModule } from '../../linkedItemsList/linkedItemsList.module';
import { SharedComponentsModule } from '../../sharedComponents.module';
import { SlideboxModule } from '../../slidebox/slidebox.module';
import { DynHostWrapperComponent } from './customFields/dynHostWrapper.component';
import { FormDynHostWrapperComponent } from './customFields/formDynHostWrapper.component';
import { FormListDynHostWrapperComponent } from './customFields/formListDynHostWrapper.component';
import { NoSpacesDirective } from './customFields/inputs/input-no-spaces.directive';
import { MaxTwoDecimalPriceDirective } from './customFields/inputs/input-positive-price-number.directive';
import { C_FORMLY_CUSTOM } from './fields.config';

/** Module de gestion des Formulaire. */
const modules: Array<Type<any>> = [
	SharedComponentsModule,
	LinkModule,
	SlideboxModule,
	GalleryModule,
	LikeModule,
	FilePickerModule,
	ContactsModule,
	DateModule,
	LinkedItemsListModule,
	MatInputModule,
	MatChipsModule,
	CameraModule,
	ColorsModule,
	SectorsModule,
	SelectorModule,
	DrawingModule
];
const components: Array<Type<any>> = [
	FormDynHostWrapperComponent, FormListDynHostWrapperComponent, DynHostWrapperComponent
];

const directives: Array<Type<any>> = [
	NoSpacesDirective, MaxTwoDecimalPriceDirective
];

/** Module de gestion des formulaires. */
@NgModule({
	imports: modules,
	exports: [directives],
	declarations: [
		C_FORMLY_CUSTOM,
		...directives,
		...components
	]
})
export class FormModule { }