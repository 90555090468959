import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransmissionService {
	
	private deleteTransmissionAction = new Subject<void>();

	deletion$ = this.deleteTransmissionAction.asObservable();
	
	private refreshTransmissionSubject = new Subject<void>();
	refreshTransmissionList$ = this.refreshTransmissionSubject.asObservable();

	emitDeletion() {
		this.deleteTransmissionAction.next();
	}

	triggerRefreshTransmissionList() {
		this.refreshTransmissionSubject.next();
	}
}