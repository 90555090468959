import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'lua-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.css'],
})
export class PopoverComponent implements OnInit {
  @ViewChild('popoverContent', {read: ViewContainerRef, static: false})
  popoverContent!: ViewContainerRef;

  @Input() open = false;
  @Input() title = '';
  @Input() anchorElement!: HTMLElement;

  // True si on veut utiliser l'anchorElement pour se positionner
  // False si on veut l'afficher au centre de l'écran
  @Input() useAnchor = true;
  @Input() showGoBackButton? = false;
  @Input() anchorRect?: DOMRect;
  @Output() onGoBackClick = new EventEmitter<Event>();
  @Output() onPopoverClose = new EventEmitter<Event>();

  constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {}

  isReady = false;
  screenWidth: number = window.innerWidth;
  getClasses() {
    return {
      visible: this.open && this.isReady,
      hidden: !this.open || !this.isReady,
    };
  }

  ngOnInit(): void {
    if (this.open) {
      this.positionPopover();
    }
  }
  @HostListener('window:resize')
  onWindowResize() {
    this.positionPopover();
  }
  positionPopover(): void {
    const popoverElement = this.el.nativeElement.querySelector('.lua-popover');

    if (!popoverElement) return;

    // Taille du popover et de la fenêtre
    const popoverWidth = popoverElement.offsetWidth;
    const popoverHeight = popoverElement.offsetHeight;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    if (this.anchorElement && this.useAnchor && !this.anchorRect) {
      this.anchorRect = this.anchorElement.getBoundingClientRect();
    }

    let leftPosition = 0;
    let topPosition = 0;
    let useBottom = false;

    if (this.useAnchor && this.anchorRect) {
      // Calcul initial (au centre de l'ancre)
      leftPosition =
        this.anchorRect.left +
        window.scrollX +
        this.anchorRect.width / 2 -
        popoverWidth / 2;
      topPosition =
        this.anchorRect.top +
        window.scrollY +
        this.anchorRect.height / 2 -
        popoverHeight / 2;

      // Ajustements pour éviter de déborder à droite
      if (leftPosition + popoverWidth > windowWidth) {
        leftPosition = windowWidth - popoverWidth - 10;
      }

      // Ajustements pour éviter de déborder en bas
      if (
        topPosition + popoverHeight > windowHeight ||
        this.anchorRect.bottom + popoverHeight > windowHeight
      ) {
        // Si le popover dépasse en bas, on positionne avec bottom à 10px
        useBottom = true;
      }

      // Si le popover dépasse à gauche
      if (leftPosition < 0) {
        leftPosition = 10;
      }

      // Si le popover dépasse en haut
      if (!useBottom && topPosition < 0) {
        topPosition = 10;
      }
    } else {
      // Position par défaut (centre de l'écran)
      leftPosition = (windowWidth - popoverWidth) / 2;
      topPosition = (windowHeight - popoverHeight) / 2;
      useBottom = false;
    }
    // Appliquer les positions ajustées
    popoverElement.style.left = `${leftPosition}px`;

    if (useBottom) {
      popoverElement.style.bottom = '10px';
      popoverElement.style.top = '';
    } else {
      popoverElement.style.top = `${topPosition}px`;
      popoverElement.style.bottom = '';
    }
    this.isReady = true;
  }

  handleGoBack(event: Event): void {
    this.onGoBackClick.emit(event);
  }

  handlePopoverClose(event: Event): void {
    this.open = false;
    this.onPopoverClose.emit(event);
  }

  setContent(content: Type<any>, inputs: any) {
    setTimeout(() => {
      if (this.popoverContent) {
        this.popoverContent.clear();
        const componentRef = this.popoverContent.createComponent(content);
        Object.assign(componentRef.instance, inputs);
        this.cdr.detectChanges();
        this.positionPopover();
        this.isReady = true;
      }
    });
  }
}
