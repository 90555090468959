<div class="di-confirmation-impression">
	<div class="dialog-content">
		<lua-action-secondaire libelle="Avec fond Cerfa - 11390*04" color="CouleurPrimaire" [fullWidth]="true"
			[avecIcone]="false" size="medium" [isDisabled]="false" positionIcone="L"
			(click)="onWithCerfaClick()"></lua-action-secondaire>
		<lua-action-secondaire libelle="Sans fond Cerfa - 11390*04" color="CouleurPrimaire" [fullWidth]="true"
			[avecIcone]="false" size="medium" [isDisabled]="false" positionIcone="L"
			(click)="onWithOutCerfaClick()"></lua-action-secondaire>
		<lua-bouton-texte libelle="Annuler" color="CouleurPrimaire" [fullWidth]="true" [isDisabled]="false"
			endIcon="" type="button" size="medium" (click)="onCancel()"></lua-bouton-texte>
	</div>
</div>