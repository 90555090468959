import { range } from 'lodash';
import { NotImplementedError } from '../model/errors/NotImplementedError';
import { ESortOrder } from '../model/ESortOrder';
import { IIndexedArray } from '../model/IIndexedArray';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { DateHelper } from './dateHelper';
import { MapHelper } from './mapHelper';
import { NumberHelper } from './numberHelper';
import { ObjectHelper } from './objectHelper';
import { StringHelper } from './stringHelper';

export abstract class ArrayHelper {

	//#region FIELDS

	/** _index */
	private static readonly C_INDEX_SORT = "_index";

	//#endregion

	//#region METHODS

	/** Tri un tableau par ordre descendant (alphabétique, plus ancien au plus récent) selon une propriété donnée.
	 * @param paArray Tableau à trier.
	 * @param psProperty Propriété actuelle sur laquelle on trie les éléments d'un tableau,
	 * le `keyof` ne permet pas d'obtenir des propriétés sur une interface ayant des propriétés génériques du genre `[key: machin]: truc`.
	 * @param peSortOrder Indique si le tri doit être croissant ou décroissant, croissant par défaut (alphabétique, plus vieux au plus récent).
	 * @returns `NotImplementedError` si la propriété de tri est `_index`, le tableau trié sinon.
	 */
	public static dynamicSort<T>(paArray: T[], psProperty: keyof T, peSortOrder: ESortOrder = ESortOrder.ascending): T[] {
		if (psProperty !== this.C_INDEX_SORT) {

			switch (peSortOrder) {

				case ESortOrder.descending:
					return paArray.sort((poLeftItem: T, poRightItem: T) => - this.compareByProperty(poLeftItem, poRightItem, psProperty));

				case ESortOrder.ascending:
				default:
					return paArray.sort((poLeftItem: T, poRightItem: T) => this.compareByProperty(poLeftItem, poRightItem, psProperty));
			}
		}
		else
			throw new NotImplementedError();
	}

	/** Tri un tableau par ordre descendant (alphabétique) en prenant en compte plusieurs critères de tri.
	 * @param paArray Tableau à trier.
	 * @param paProperties Tableau de chaîne de caractères correspondant aux propriétés qui servent à trier le tableau.
	 * @param pbIsAscending Indique si le tri doit être croissant ou décroissant, croissant par défaut.
	 */
	public static dynamicSortMultiple<T>(paArray: Array<T>, paProperties: (keyof T)[], peSortOrder: ESortOrder = ESortOrder.ascending): T[] {
		return paArray.sort((poLeftItem: T, poRightItem: T) => {
			const lnTotalProperties: number = paProperties.length;
			let lnCurrentProperty = 0;
			let lnResult = 0;

			// Essaie d'obtenir un résultat différent de 0 (égal) en comparant les propriétés tant qu'il y en a.
			while (lnResult === 0 && lnCurrentProperty < lnTotalProperties) {
				switch (peSortOrder) {
					case ESortOrder.descending:
						lnResult = - this.compareByProperty(poLeftItem, poRightItem, paProperties[lnCurrentProperty]);
						break;

					case ESortOrder.ascending:
						lnResult = this.compareByProperty(poLeftItem, poRightItem, paProperties[lnCurrentProperty]);
						break;
					default:
						break;
				}

				++lnCurrentProperty;
			}

			return lnResult;
		});
	}

	/** Récupère le premier élément d'un tableau, `undefined` si le tableau est vide.
	 * @param paArray Tableau dont il faut récupérer le premier élément.
	 */
	public static getFirstElement<T>(paArray: T[]): T;
	/** Récupère le premier élément d'un tableau, `undefined` si le tableau est vide.
	 * @param paArray Tableau dont il faut récupérer le premier élément.
	 */
	public static getFirstElement<T>(paArray: ReadonlyArray<T>): T;
	public static getFirstElement<T>(paArray: T[] | ReadonlyArray<T>): T {
		return this.hasElements(paArray) ? paArray[0] : undefined;
	}

	/** Récupère le dernier élément d'un tableau, `undefined` si aucun élément ou si ce n'est pas un tableau.
	 * @param paArray Tableau dont il faut récupérer le dernier élément.
	 */
	public static getLastElement<T>(paArray: T[]): T;
	/** Récupère le dernier élément d'un tableau, `undefined` si aucun élément ou si ce n'est pas un tableau.
	 * @param paArray Tableau dont il faut récupérer le dernier élément.
	 */
	public static getLastElement<T>(paArray: ReadonlyArray<T>): T;
	public static getLastElement<T>(paArray: T[] | ReadonlyArray<T>): T {
		return this.hasElements(paArray) ? paArray[paArray.length - 1] : undefined;
	}

	/** Déplace un élément du tableau vers un nouvel index, en déplaçant si besoin les éléments entre l'ancien et le nouvel index
	 * et retourne un booléen indiquant si l'opération a réussi ou non.
	 * @param paArray Tableau qui contient l'élément à déplacer.
	 * @param pnFrom Index courant de l'élément à déplacer.
	 * @param pnTo Nouvel index de l'élément après déplacement.
	 */
	public static moveElement<T>(paArray: Array<T>, pnFrom: number, pnTo: number): boolean {
		// Si les index sont corrects (nombres non négatifs, nombres plus petits que la longueur du tableau).
		if (NumberHelper.isValidPositive(pnFrom) && NumberHelper.isValidPositive(pnTo) && paArray.length > pnFrom && paArray.length > pnTo) {
			const loTmp: T = paArray[pnFrom];

			if (pnFrom < pnTo) {
				for (let i = pnFrom; i < pnTo; ++i) {
					paArray[i] = paArray[i + 1];
				}
			}
			else if (pnFrom > pnTo) {
				for (let i = pnFrom; i > pnTo; --i) {
					paArray[i] = paArray[i - 1];
				}
			}

			paArray[pnTo] = loTmp;
			return true;
		}
		else // Sinon, on ne peut pas bouger l'élément.
			return false;
	}

	/** Supprime un élément spécifique du tableau et le retourne si celui-ci a été supprimé, retourne `undefined` si l'élément n'a pas été trouvé.
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param poElementToRemove Objet qu'il faut supprimer du tableau.
	 */
	public static removeElement<T>(paArray: Array<T>, poElementToRemove: T): T {
		let loRemovedElement: T;

		const lnIndex: number = paArray.indexOf(poElementToRemove);
		if (lnIndex !== -1)
			loRemovedElement = this.getFirstElement(paArray.splice(lnIndex, 1));

		return loRemovedElement;
	}

	/** Supprime un élément spécifique du tableau à l'aide d'une fonction et le retourne si celui-ci a été supprimé, retourne `undefined` si l'élément n'a pas été trouvé.
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param pfFinder Fonction permettant de trouver l'objet à supprimer.
	 */
	public static removeElementByFinder<T>(paArray: Array<T>, pfFinder: (poItem: T) => boolean): T {
		let loRemovedElement: T;
		const lnIndex: number = paArray.findIndex(pfFinder);

		if (lnIndex !== -1)
			loRemovedElement = this.getFirstElement(paArray.splice(lnIndex, 1));

		return loRemovedElement;
	}

	/** Supprime le dernier élément du tableau qui satisfait une condition spécifique et le retourne si celui-ci a été supprimé,
	 * retourne `undefined` si l'élément n'a pas été trouvé.
	 * @param paData Tableau contenant l'élément à supprimer.
	 * @param pfFinder Fonction permettant de trouver l'objet à supprimer.
	 */
	public static removeLastElementByFinder<T>(paData: T[], pfFinder: (poItem: T) => boolean): T {
		return this.removeElementByIndex(paData, this.findLastIndex(paData, pfFinder));
	}

	/** Supprime un élément du tableau à partir de `id` ou `_id` et le retourne si celui-ci a été supprimé, retourne `undefined` si l'id n'est pas valide ou non trouvé).
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param psIdToRemove Id de l'objet à supprimer.
	 */
	public static removeElementById<T>(paArray: Array<T>, psIdToRemove: string | number): T {
		return this.removeElementByIndex(paArray, paArray.findIndex((poItem: T) => poItem["_id"] === psIdToRemove || poItem["id"] === psIdToRemove));
	}

	/** Supprime un élément d'un tableau à partir d'un index et le retourne si celui-ci a été supprimé, retourne `undefined` si l'index n'est pas valide.
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param pnIndexToRemove Index de l'objet à supprimer.
	 */
	public static removeElementByIndex<T>(paArray: Array<T>, pnIndexToRemove: number): T {
		let loRemovedElement: T;

		if (NumberHelper.isValidPositive(pnIndexToRemove))
			loRemovedElement = this.getFirstElement(paArray.splice(pnIndexToRemove, 1));

		return loRemovedElement;
	}

	/** Supprimer un élément d'un tableau à partir d'une propriété donnée et la valeur qui identifie l'objet à supprimer, retourne l'élément supprimé ou `undefined` sinon.
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param psProperty Propriété avec laquelle on vérifie la présence de l'objet à supprimer.
	 * @param poValue Objet qu'il faut supprimer du tableau.
	 */
	public static removeElementByProperty<T>(paArray: Array<T>, psProperty: keyof T, poValue: any): T {
		let loRemovedElement: T;

		for (let lnIndex = 0; lnIndex < paArray.length; ++lnIndex) {

			if (paArray[lnIndex][psProperty] && paArray[lnIndex][psProperty] === poValue) {
				loRemovedElement = this.getFirstElement(paArray.splice(lnIndex, 1));
				break;
			}
		}

		return loRemovedElement;
	}

	/** Supprime tous les éléments du tableau qui satisfont une condition, retourne le tableau des éléments supprimés ou `undefined` si aucun élément n'a été trouvé.
	 * @param paArray Tableau contenant les éléments à supprimer.
	 * @param pfFinder Fonction permettant de trouver les éléments à supprimer.
	 */
	public static removeElementsByFinder<T>(paArray: Array<T>, pfFinder: (poItem: T) => boolean): T[] {
		const laRemovedElements: T[] = [];

		for (let lnIndex = 0; lnIndex < paArray.length; ++lnIndex) {
			let loItem: T = paArray[lnIndex];

			if (pfFinder(loItem)) {
				laRemovedElements.push(...paArray.splice(lnIndex, 1)); // Suppression de l'élément en modifiant directement la référence du tableau.
				lnIndex--; // On recule l'index de 1 après suppression
			}
		}

		return laRemovedElements;
	}

	/** Remplace un élément spécifique du tableau à l'aide d'une fonction et retourne l'élément supprimé ou
	 * `undefined` et ajoute à la fin si l'élément n'a pas été remplacé.
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param pfFinder Fonction permettant de trouver l'objet à supprimer.
	 * @param poCandidateItem Élément qu'on souhaite placer dans le tableau à la place de celui remplissant le critère de la fonction.
	 * @param pbMustPushCandidateItem Indique si on doit ajouter l'élément candidat dans le tableau s'il n'a pas été trouvé, `true` par défaut.
	 */
	public static replaceElementByFinder<T>(paArray: Array<T>, pfFinder: (poItem: T) => boolean, poCandidateItem: T, pbMustPushCandidateItem: boolean = true): T {
		let loRemovedElement: T;
		const lnIndex: number = paArray.findIndex(pfFinder);

		if (lnIndex === -1) {
			if (pbMustPushCandidateItem)
				paArray.push(poCandidateItem);
		}
		else if (paArray[lnIndex] !== poCandidateItem)
			loRemovedElement = this.getFirstElement(paArray.splice(lnIndex, 1, poCandidateItem));

		return loRemovedElement;
	}

	/** Remplace un élément d'un tableau à partir d'un index et retourne l'élement supprimé, retourne `undefined` si l'index n'est pas valide.
	 * @param paArray Tableau contenant l'élément à remplacer.
	 * @param pnIndexToReplace Index de l'objet à remplacer.
	 */
	public static replaceElementByIndex<T>(paArray: Array<T>, pnIndexToReplace: number, poCandidateItem: T): T {
		let loRemovedElement: T;

		if (NumberHelper.isValidPositive(pnIndexToReplace))
			loRemovedElement = this.getFirstElement(paArray.splice(pnIndexToReplace, 1, poCandidateItem));

		return loRemovedElement;
	}

	/** Retourne le résultat de la comparaison pour indiquer si l'objet de gauche doit être placé avant ou après celui de droite.
	 * @param poLeftItem Objet gauche à comparer.
	 * @param poRightItem Objet droit à comparer.
	 * @param psProperty Propriété qui sert à la comparaison pour le tri.
	 */
	public static compareByProperty<T>(poLeftItem: T, poRightItem: T, psProperty: keyof T): number {
		let loLeftProperty: any;
		let loRightProperty: any;

		if (poLeftItem !== undefined && poLeftItem !== null)
			loLeftProperty = poLeftItem[psProperty];

		if (poRightItem !== undefined && poRightItem !== null)
			loRightProperty = poRightItem[psProperty];

		if (typeof loLeftProperty === "number" && typeof loRightProperty === "number")
			return loLeftProperty - loRightProperty;

		else if (typeof loLeftProperty === "string" && typeof loRightProperty === "string") {
			if (NumberHelper.isStringNumber(loLeftProperty) && NumberHelper.isStringNumber(loRightProperty))
				return (+loLeftProperty) - (+loRightProperty);

			else if (DateHelper.isDate(loRightProperty) && DateHelper.isDate(loLeftProperty))
				return DateHelper.compareTwoDates(new Date(loLeftProperty), new Date(loRightProperty));

			else
				return loLeftProperty.toLowerCase().localeCompare(loRightProperty.toLowerCase());
		}

		else if (DateHelper.isDate(loRightProperty) && DateHelper.isDate(loLeftProperty))
			return DateHelper.compareTwoDates(new Date(loLeftProperty), new Date(loRightProperty));

		else if (loLeftProperty instanceof Array && loRightProperty instanceof Array)
			return loLeftProperty.join().toLowerCase().localeCompare(loRightProperty.join().toLowerCase());

		else if (typeof loLeftProperty === "boolean" && typeof loRightProperty === "boolean")
			return (+loLeftProperty) - (+loRightProperty);

		else if (!ObjectHelper.isDefined(loLeftProperty) || !ObjectHelper.isDefined(loRightProperty))
			return this.compareByExistingProperty(poLeftItem, poRightItem, psProperty);

		else
			return loLeftProperty > loRightProperty ? 1 : loLeftProperty < loRightProperty ? -1 : 0;
	}

	/** Retourne le résultat de la comparaison (si défini ou non) pour indiquer si l'objet de gauche doit être placé avant ou après celui de droite.
	 * @param poLeftItem Objet gauche à comparer.
	 * @param poRightItem Objet droit à comparer.
	 * @param psProperty Propriété qui sert à la comparaison pour le tri.
	 * @returns 0, 1 (gauche devant) ou -1 (droit devant) suivant l'existance ou non de la propriété.
	 */
	public static compareByExistingProperty<T>(poLeftItem: T, poRightItem: T, psProperty: keyof T): number {
		let loLeftProperty: any;
		let loRightProperty: any;

		if (poLeftItem !== undefined && poLeftItem !== null)
			loLeftProperty = poLeftItem[psProperty];

		if (poRightItem !== undefined && poRightItem !== null)
			loRightProperty = poRightItem[psProperty];

		if (ObjectHelper.isDefined(loLeftProperty) && !ObjectHelper.isDefined(loRightProperty))
			return 1;
		else if (ObjectHelper.isDefined(loRightProperty) && !ObjectHelper.isDefined(loLeftProperty))
			return -1;
		else
			return 0;
	}

	/** Détermine s'il existe au moins 1 élément dans la liste.
	 * Si la liste n'est pas initialisée, elle est considérée comme vide, et on retourne `false`.
	 */
	public static hasElements(paArray: any[]): boolean;
	/** Détermine s'il existe au moins 1 élément dans la liste.
	 * Si la liste n'est pas initialisée, elle est considérée comme vide, et on retourne `false`.
	 */
	public static hasElements(paArray: ReadonlyArray<any>): boolean;
	public static hasElements(paArray: any[] | ReadonlyArray<any>): boolean {
		return !!paArray && paArray.length > 0;
	}

	/** Permet de comparer 2 tableaux.
	 * @param paArrayA Premier tableau.
	 * @param paArrayB Deuxième tableau.
	 * @param pfPredicate Fonction de comparaison des éléments, par défaut c'est une comparaison simple (itemA === itemB).
	 */
	public static areArraysEqual<T, U = T>(paArrayA: T[], paArrayB: U[], pfPredicate?: (poItemA: T, poItemB: U) => boolean): boolean;
	/** Permet de comparer 2 tableaux.
	 * @param paArrayA Premier tableau.
	 * @param paArrayB Deuxième tableau.
	 * @param pfPredicate Fonction de comparaison des éléments, par défaut c'est une comparaison simple (itemA === itemB).
	 */
	public static areArraysEqual<T, U = T>(paArrayA: ReadonlyArray<T>, paArrayB: ReadonlyArray<U>, pfPredicate?: (poItemA: T, poItemB: U) => boolean): boolean;
	public static areArraysEqual<T, U = T>(paArrayA: T[] | ReadonlyArray<T>, paArrayB: U[] | ReadonlyArray<U>, pfPredicate?: (poItemA: T, poItemB: U) => boolean): boolean {
		if (!pfPredicate)
			pfPredicate = (poItemA: T, poItemB: U) => poItemA === poItemB as unknown as T;

		return (paArrayA as any[] === paArrayB as any[]) ||
			(
				paArrayA && paArrayB && paArrayA.length === paArrayB.length &&
				paArrayA.every((poItemA: T) => paArrayB.some((poItemB: U) => pfPredicate(poItemA, poItemB))) &&
				paArrayB.every((poItemB: U) => paArrayA.some((poItemA: T) => pfPredicate(poItemA, poItemB)))
			);
	}

	/** Retourne `true` si les deux tableaux sont strictement identiques (mêmes longueurs et mêmes éléments au même index), `false` sinon.
	 * @param paDataA Tableau A qu'il faut comparer au tableau B.
	 * @param paDataB Tableau B qu'il faut comparer au tableau A.
	 * @param pfPredicate Fonction d'égalité de deux éléments, `true` si les éléments sont égaux, `false` sinon ; par défaut égalité simple `a === b`.
	 */
	public static areArraysStrictEqual<T>(paDataA: T[], paDataB: T[], pfPredicate?: (poItemA: T, poItemB: T) => boolean): boolean;
	/** Retourne `true` si les deux tableaux sont strictement identiques (mêmes longueurs et mêmes éléments au même index), `false` sinon.
	 * @param paDataA Tableau A qu'il faut comparer au tableau B.
	 * @param paDataB Tableau B qu'il faut comparer au tableau A.
	 * @param pfPredicate Fonction d'égalité de deux éléments, `true` si les éléments sont égaux, `false` sinon ; par défaut égalité simple `a === b`.
	 */
	public static areArraysStrictEqual<T>(paDataA: ReadonlyArray<T>, paDataB: ReadonlyArray<T>, pfPredicate?: (poItemA: T, poItemB: T) => boolean): boolean;
	public static areArraysStrictEqual<T>(paDataA: T[] | ReadonlyArray<T>, paDataB: T[] | ReadonlyArray<T>, pfPredicate?: (poItemA: T, poItemB: T) => boolean): boolean {
		if (!pfPredicate)
			pfPredicate = (poItemA: T, poItemB: T) => poItemA === poItemB;

		if (!paDataA && !paDataB) // Les deux tableaux ne sont pas définis, on les considère égaux.
			return true;

		else if (paDataA && paDataB) { // Les deux tableaux sont définis, il faut vérifier les éléments de chacun.
			if (paDataA.length !== paDataB.length) // Les deux tableaux ont une taille différentes, ils sont différents.
				return false;
			else {
				for (let lnIndex = 0; lnIndex < paDataA.length; ++lnIndex) {
					const loItemA: T = paDataA[lnIndex];
					const loItemB: T = paDataB[lnIndex];

					// Si l'un des deux éléments est défini mais pas l'autre ou que les deux éléments ne sont pas identiques, tableaux différents.
					if (loItemA && !loItemB || !loItemA && loItemB || !pfPredicate(loItemA, loItemB))
						return false;
				}

				return true; // Tous les éléments sont identiques, tableaux identiques.
			}
		}

		else // L'un des deux tableaux est défini mais pas l'autre, ils sont différents.
			return false;
	}

	/** Permet de comparer 2 tableaux provenant de la base de données.
	 * @param paArrayA Premier tableau.
	 * @param paArrayB Deuxième tableau.
	 */
	public static areArraysFromDatabaseEqual<T extends IStoreDocument>(paArrayA: T[], paArrayB: T[]): boolean;
	/** Permet de comparer 2 tableaux provenant de la base de données.
	 * @param paArrayA Premier tableau.
	 * @param paArrayB Deuxième tableau.
	 */
	public static areArraysFromDatabaseEqual<T extends IStoreDocument>(paArrayA: ReadonlyArray<T>, paArrayB: ReadonlyArray<T>): boolean;
	public static areArraysFromDatabaseEqual<T extends IStoreDocument>(paArrayA: T[] | ReadonlyArray<T>, paArrayB: T[] | ReadonlyArray<T>): boolean {
		return ArrayHelper.areArraysEqual(paArrayA, paArrayB, (poItemA: T, poItemB: T) => poItemA._id === poItemB._id && poItemA._rev === poItemB._rev);
	}

	/** Verifie si les valeurs du tableau sont toutes vides.
	 * @param paValues Tableau de valeurs de n'importe quel type.
	 */
	public static areAllValuesEmpty<T>(paValues?: T[]): boolean;
	/** Verifie si les valeurs du tableau sont toutes vides.
	 * @param paValues Tableau de valeurs de n'importe quel type.
	 */
	public static areAllValuesEmpty<T>(paValues?: ReadonlyArray<T>): boolean;
	public static areAllValuesEmpty<T>(paValues?: T[] | ReadonlyArray<T>): boolean {

		if (!this.hasElements(paValues))
			return true;
		else {
			return paValues.every((poValue) => {

				if (typeof poValue === "boolean")
					return false;

				else if (!poValue || (poValue instanceof Array && !ArrayHelper.hasElements(poValue)))
					return true;

				else if (typeof poValue === "string")
					return StringHelper.isBlank(poValue);

				else if (typeof poValue === "object")
					return Object.keys(poValue).length === 0;

				else
					return false;
			});
		}
	}

	/** Applique la dernière révision aux éléments du tableau à partir d'un tableau à jour.
	 * @param paMineDocuments Tableau des documents dont il faut mettre à jour les révisions.
	 * @param paStoredDocuments Tableau source des révisions des documents (documents à jour).
	 * @returns Tableau des documents qui ont été mis à jour.
	 */
	public static applyLastRevisions<T extends IStoreDocument>(paMineDocuments: T[], paStoredDocuments: T[]): T[] {
		const laChangedDocuments: T[] = [];

		paStoredDocuments.forEach((poStoredDocument: T) => {
			const loMyDocument: T = paMineDocuments.find((poMyDocument: T) => poMyDocument._id === poStoredDocument._id);

			if (loMyDocument._rev !== poStoredDocument._rev) {
				loMyDocument._rev = poStoredDocument._rev;
				laChangedDocuments.push(loMyDocument);
			}
		});

		return laChangedDocuments;
	}

	/** Aplanit un tableau de tableaux en un unique tableau regroupant tous les éléments de chaque tableau et le retourne,
	 * `undefined` si pas de tableau  correct en entrée.
	 * @param paArrayOfArrays Tableau (qui peut contenir des tableaux) qu'il faut aplanir (transformer en un seul tableau d'éléments).
	 */
	public static flat<T>(paArrayOfArrays: Array<T | T[]>): T[];
	/** Aplanit un tableau de tableaux en un unique tableau regroupant tous les éléments de chaque tableau et le retourne,
	 * `undefined` si pas de tableau  correct en entrée.
	 * @param paArrayOfArrays Tableau de tableaux qu'il faut aplanir (transformer en un seul tableau d'éléments).
	 */
	public static flat<T>(paArrayOfArrays: ReadonlyArray<T | T[] | ReadonlyArray<T>>): T[];
	public static flat<T>(paArrayOfArrays: Array<T | T[]> | ReadonlyArray<T | T[] | ReadonlyArray<T>>): T[] {
		if (!paArrayOfArrays)
			return undefined;
		else
			return [].concat(...paArrayOfArrays); // Équivalent à : `return paArrayOfArrays.reduce((poPrevious: T[], poCurrent: T[]) => poPrevious.concat(poCurrent), []);`
	}

	/** Retourne un tableau d'objets uniques à partir d'un tableau d'objets (supprime les valeurs `undefined` et `null`).
	 * @param paArray Tableau dont il faut récupérer les objets de manière unique.
	 */
	public static unique<T extends IStoreDocument | string | number | boolean>(paArray: T[]): T[];
	/** Retourne un tableau d'objets uniques à partir d'un tableau d'objets readonly (supprime les valeurs `undefined` et `null`).
	 * @param paArray Tableau dont il faut récupérer les objets de manière unique.
	 */
	public static unique<T extends IStoreDocument | string | number | boolean>(paArray: Array<keyof T>): Array<keyof T>;
	/** Retourne un tableau d'objets uniques à partir d'un tableau d'objets readonly (supprime les valeurs `undefined` et `null`).
	 * @param paArray Tableau readonly dont il faut récupérer les objets de manière unique.
	 */
	public static unique<T extends IStoreDocument | string | number | boolean>(paArray: ReadonlyArray<T>): T[];
	/** Retourne un tableau d'objets uniques à partir d'un tableau d'objets (supprime les valeurs `undefined` et `null`).
	 * @param paArray Tableau dont il faut récupérer les objets de manière unique.
	 * @param pfGetKey Fonction permettant de récupérer une clé unique associé à un objet du tableau.
	 */
	public static unique<T>(paArray: T[], pfGetKey: (poItem: T) => string): T[];
	/** Retourne un tableau d'objets uniques à partir d'un tableau d'objets (supprime les valeurs `undefined` et `null`).
	 * @param paArray Tableau dont il faut récupérer les objets de manière unique.
	 * @param pfGetKey Fonction permettant de récupérer une clé unique associé à un objet du tableau.
	 */
	public static unique<T>(paArray: Array<keyof T>, pfGetKey: (poItem: T) => string): Array<keyof T>;
	/** Retourne un tableau d'objets uniques à partir d'un tableau d'objets readonly (supprime les valeurs `undefined` et `null`).
	 * @param paArray Tableau dont il faut récupérer les objets de manière unique.
	 * @param pfGetKey Fonction permettant de récupérer une clé unique associé à un objet du tableau.
	 */
	public static unique<T>(paArray: ReadonlyArray<T>, pfGetKey: (poItem: T) => string): T[]
	public static unique<T>(paArray: T[] | ReadonlyArray<T>, pfGetKey?: (poItem: T) => string): T[] {
		if (!this.hasElements(paArray))
			return [];
		else if (typeof this.getFirstElement(paArray) === "object") {
			const loMap: Map<string, T> = new Map();

			if (!pfGetKey) // Si on manipule un tableau de `IStoreDocument`.
				pfGetKey = (poItem: T) => (poItem as any as IStoreDocument)?._id; // On définit la méthode d'obtention de clé pour n'avoir qu'un chemin d'obtention.

			paArray.forEach((poItem: T) => {
				if (poItem !== undefined && poItem !== null) {
					const lsKey: string = pfGetKey(poItem);
					if (!loMap.has(lsKey))
						loMap.set(lsKey, poItem);
				}
			});

			return MapHelper.valuesToArray(loMap);
		}
		else
			return Array.from(new Set(paArray)).filter((poItem: T) => poItem !== undefined && poItem !== null);
	}

	/** Vide le tableau et retourne les éléments supprimés.
	 * @param paArray
	 */
	public static clear<T>(paArray: T[]): T[] {
		return this.hasElements(paArray) ? paArray.splice(0, paArray.length) : [];
	}

	/** Permet de récupérer les membres qui sont présents dans `paArrayA` et pas dans `paArrayB`.
	 * @param paArrayA Tableau dont on veut récupérer les éléments non présents dans l'autre.
	 * @param paArrayB Tableau avec lequel différencier le premier.
	 * @param pfPredicate Fonction qui défini l'égalité entre 2 membres.
	 */
	public static getDifferences<T>(paArrayA: T[], paArrayB?: T[], pfPredicate?: (poItemA: T, poItemB: T) => boolean): T[];
	/** Permet de récupérer les membres qui sont présents dans `paArrayA` et pas dans `paArrayB`.
	 * @param paArrayA Tableau dont on veut récupérer les éléments non présents dans l'autre.
	 * @param paArrayB Tableau avec lequel différencier le premier.
	 * @param pfPredicate Fonction qui défini l'égalité entre 2 membres.
	 */
	public static getDifferences<T>(paArrayA: ReadonlyArray<T>, paArrayB?: ReadonlyArray<T>, pfPredicate?: (poItemA: T, poItemB: T) => boolean): T[];
	public static getDifferences<T>(paArrayA: T[] | ReadonlyArray<T>, paArrayB?: T[] | ReadonlyArray<T>, pfPredicate?: (poItemA: T, poItemB: T) => boolean): T[] {
		if (!pfPredicate)
			pfPredicate = (poItemA: T, poItemB: T) => poItemA === poItemB;

		return paArrayA.filter((poItemA: T) => !paArrayB?.some((poItemB: T) => pfPredicate(poItemA, poItemB)));
	}

	/** Permet de récupérer les membres de `paArrayA` qui sont aussi présents dans `paArrayB`.
	 * @param paArrayA
	 * @param paArrayB
	 * @param pfPredicate Fonction qui définie l'égalité entre 2 membres.
	 */
	public static intersection<T, U = T>(paArrayA: T[], paArrayB: U[], pfPredicate?: (poItemA: T, poItemB: U) => boolean): T[];
	/** Permet de récupérer les membres de `paArrayA` qui sont aussi présents dans `paArrayB`.
	 * @param paArrayA
	 * @param paArrayB
	 * @param pfPredicate Fonction qui définie l'égalité entre 2 membres.
	 */
	public static intersection<T, U = T>(paArrayA: ReadonlyArray<T>, paArrayB: ReadonlyArray<U>, pfPredicate?: (poItemA: T, poItemB: U) => boolean): T[];
	public static intersection<T, U = T>(paArrayA: T[] | ReadonlyArray<T>, paArrayB: U[] | ReadonlyArray<U>, pfPredicate?: (poItemA: T, poItemB: U) => boolean): T[] {
		if (!this.hasElements(paArrayA) || !this.hasElements(paArrayB))
			return [];

		if (!pfPredicate)
			pfPredicate = (poItemA: T, poItemB: U) => poItemA === poItemB as any as T;

		return paArrayA.filter((poItemA: T) => paArrayB.some((poItemB: U) => pfPredicate(poItemA, poItemB)));
	}

	public static getType<T>(paArray: T[] | ReadonlyArray<T>): string {
		if (!ArrayHelper.hasElements(paArray))
			return "void";

		const lsFirstType: string = typeof ArrayHelper.getFirstElement(paArray);
		if (paArray.every((poValue: T) => typeof poValue === lsFirstType))
			return lsFirstType;
		else
			return "multiple";
	}

	/** Ajoute un élément dans un tableau s'il n'est pas déjà présent.
	 * @param paArray Tableau dans lequel ajouter un élément si non présent.
	 * @param poElement Élément à ajouter au tableau s'il n'est pas déjà présent.
	 * @param pfIsPresent Prédicat permettant de tester si l'élément est déjà présent (est égal à un autre) ou non, pas besoin pour des types primitifs.
	 * @returns Le nombre d'éléments dans le tableau.
	 */
	public static pushIfNotPresent<T>(paArray: T[] | ReadonlyArray<T>, poElement: T, pfIsPresent?: (poItem: T) => boolean): number {
		if (!pfIsPresent)
			pfIsPresent = (poItem: T) => poItem === poElement;

		if (!paArray.some(pfIsPresent))
			(paArray as T[]).push(poElement);

		return paArray.length;
	}

	/** Groupe les membres d'un tableau en fonction du selecteur passé en paramètre.
	 * @param paArray
	 * @param pfKeySelector
	 * @param pbToObject
	 */
	public static groupBy<K, V>(paArray: V[], pfKeySelector: (poItem: V, pnIndex?: number) => K, pbToObject: true): IIndexedArray<V[]>;
	/** Groupe les membres d'un tableau en fonction du selecteur passé en paramètre.
	 * @param paArray
	 * @param pfKeySelector
	 * @param pbToObject
	 */
	public static groupBy<K, V>(paArray: V[], pfKeySelector: (poItem: V, pnIndex?: number) => K, pbToObject: false): Map<K, V[]>;
	/** Groupe les membres d'un tableau en fonction du selecteur passé en paramètre.
	 * @param paArray
	 * @param pfKeySelector
	 */
	public static groupBy<K, V>(paArray: V[], pfKeySelector: (poItem: V, pnIndex?: number) => K): Map<K, V[]>;
	public static groupBy<K, V>(paArray: V[], pfKeySelector: (poItem: V, pnIndex?: number) => K, pbToObject?: boolean): Map<K, V[]> | IIndexedArray<V[]> {
		const loMap = new Map<K, V[]>();
		const laKeys: K[] = paArray.map((poValue: V, pnIndex: number) => pfKeySelector(poValue, pnIndex));

		laKeys.forEach((poKey: K, pnIndex: number) => {
			let laResults: V[] = loMap.get(poKey);

			if (!ArrayHelper.hasElements(laResults))
				laResults = [];

			laResults.push(paArray[pnIndex]);
			loMap.set(poKey, laResults);
		});

		return pbToObject ? MapHelper.mapToObject(loMap) : loMap;
	}

	/** Groupe les membres d'un tableau par valeur unique en fonction du selecteur passé en paramètre.
	 * @param paArray
	 * @param pfKeySelector
	 */
	public static groupByUnique<K, V>(paArray: V[], pfKeySelector: (poItem: V, pnIndex?: number) => K): Map<K, V> {
		const loMap = new Map<K, V>();
		const laKeys: K[] = paArray.map((poValue: V, pnIndex: number) => pfKeySelector(poValue, pnIndex));

		laKeys.forEach((poKey: K, pnIndex: number) => {
			loMap.set(poKey, paArray[pnIndex]);
		});

		return loMap;
	}

	/** Filtre un tableau afin de récupérer uniquement ses éléments valides.
	 * @param paData Tableau dont il faut récupérer les valeurs valides.
	 * @param pfIsValid Fonction pour identifier si un élément est valide, vérifie juste que l'élément n'est pas `NaN` ou `undefined` si non renseigné.
	 */
	public static getValidValues<T = string | number | boolean>(paData: T[], pfIsValid?: (poItem: T) => boolean): T[];
	/** Filtre un tableau afin de récupérer uniquement ses éléments valides.
	 * @param paData Tableau en lecture seule dont il faut récupérer les valeurs valides.
	 * @param pfIsValid Fonction pour identifier si un élément est valide, vérifie juste que l'élément n'est pas `NaN` ou `undefined` si non renseigné.
	 */
	public static getValidValues<T = string | number | boolean>(paData: ReadonlyArray<T>, pfIsValid?: (poItem: T) => boolean): ReadonlyArray<T>;
	/** Filtre un tableau afin de récupérer uniquement ses éléments valides.
	 * @param paData Tableau dont il faut récupérer les valeurs valides.
	 * @param pfIsValid Fonction pour identifier si un élément est valide, vérifie juste que l'élément est initialisé si non renseigné.
	 */
	public static getValidValues<T>(paData: T[], pfIsValid?: (poItem: T) => boolean): T[];
	/** Filtre un tableau afin de récupérer uniquement ses éléments valides.
	 * @param paData Tableau en lecture seule dont il faut récupérer les valeurs valides.
	 * @param pfIsValid Fonction pour identifier si un élément est valide, vérifie juste que l'élément est initialisé si non renseigné.
	 */
	public static getValidValues<T>(paData: ReadonlyArray<T>, pfIsValid?: (poItem: T) => boolean): ReadonlyArray<T>;
	public static getValidValues<T>(paData: T[] | ReadonlyArray<T>, pfIsValid?: (poItem: T) => boolean): T[] | ReadonlyArray<T> {
		if (!pfIsValid) {
			pfIsValid = (poItem: T) => {
				if (typeof poItem === "number")
					return !isNaN(poItem);
				else if (typeof poItem === "string")
					return !StringHelper.isBlank(poItem);
				else
					return poItem !== undefined && poItem !== null;
			};
		}

		return paData.filter((poItem: T) => pfIsValid(poItem));
	}

	/** Récupère tous les éléments d'un tableau dans un intervalle défini (valeurs incluses).
	 * @param paData Tableau dont il faut récupérer une partie des éléments.
	 * @param pnStartIndex Index à partir duquel commencer la section à récupérer, `0` par défaut.
	 * @param pnEndIndex Index de fin qui termine la section à récupérer, `fin du tableau` par défaut.
	 */
	public static getSection<T>(paData: T[], pnStartIndex?: number, pnEndIndex?: number): T[];
	/** Récupère tous les éléments d'un tableau dans un intervalle défini (valeurs incluses).
	 * @param paData Tableau dont il faut récupérer une partie des éléments.
	 * @param pnStartIndex Index à partir duquel commencer la section à récupérer, `0` par défaut.
	 * @param pnEndIndex Index de fin qui termine la section à récupérer, `fin du tableau` par défaut.
	 */
	public static getSection<T>(paData: ReadonlyArray<T>, pnStartIndex?: number, pnEndIndex?: number): ReadonlyArray<T>;
	public static getSection<T>(paData: T[] | ReadonlyArray<T>, pnStartIndex: number = 0, pnEndIndex: number = paData.length - 1): T[] | ReadonlyArray<T> {
		return paData.slice(pnStartIndex, pnEndIndex + 1);
	}

	/** Comme le `Array.findIndex` mais en partant de la fin du tableau.
	 * @param paData Tableau à partir duquel trouver l'index du dernier élément éligible.
	 * @param pfFinder Fonction qui doit retourner `true` s'il s'agit de l'élément recherché, `false` sinon.
	 * @returns
	 * - -1 si aucun élément correspondant trouvé.
	 * - l'index de l'élément recherché.
	 */
	public static findLastIndex<T>(paData: T[] | ReadonlyArray<T>, pfFinder: (poItem: T) => boolean): number {
		for (let lnIndex = paData.length - 1; lnIndex >= 0; --lnIndex) {
			if (pfFinder(paData[lnIndex]))
				return lnIndex;
		}

		return -1;
	}

	/** Permet de regrouper les éléments par lots dont la taille est définie par `pnBatchSize`.
	 * @param paArray Tableau à partir duquel regrouper les éléments.
	 * @param pnBatchSize Taille des groupes d'éléments.
	 */
	public static unflat<T>(paArray: T[], pnBatchSize: number): T[][] {
		const lnBatchSize: number = NumberHelper.isValidStrictPositive(pnBatchSize) ? pnBatchSize : 1; // 1 est la valeur de batch par défaut.

		return range(0, paArray.length, lnBatchSize).map((_, pnIndex: number) => {
			const pnBatchStartIndex = pnIndex * lnBatchSize;
			const pnBatchEndIndex = (pnIndex + 1) * lnBatchSize;
			const laItemIndexes: number[] = range(pnBatchStartIndex, pnBatchEndIndex < paArray.length ? pnBatchEndIndex : paArray.length);

			return laItemIndexes.map((pnItemIndex: number) => paArray[pnItemIndex]);
		});
	}

	/** Supprime un certain nombre d'éléments d'un tableau à partir d'un index précis et retourne les éléments supprimés.
	 * @param paData Tableau dont il faut supprimer une partie des éléments.
	 * @param pnStartIndex Index à partir duquel supprimer les éléments.
	 * @param pnCount Nombre d'éléments à supprimer, jusqu'à la fin du tableau si non renseigné.
	 */
	public static removeSection<T>(paData: T[], pnStartIndex: number, pnCount?: number): T[] {
		// Il faut faire en deux temps parce que dnas le cas où on passe 'undefined' ou 'null' à la méthode 'splice', il n'y a pas de suppression.
		return pnCount === null || pnCount === undefined ? paData.splice(pnStartIndex) : paData.splice(pnStartIndex, pnCount);
	}

	/** Retourne le nombre total d'éléments d'un tableau à deux dimensions.
	 * @param paArrayOfArrays Tableau de tableau dont il faut compter le nombre d'éléments total.
	 */
	public static flattedLength<T>(paArrayOfArrays: T[][]): number {
		return paArrayOfArrays?.reduce(
			(pnTotalLength: number, paCurrentValues: T[]) => paCurrentValues.length + pnTotalLength,
			0
		) ?? 0;
	}

	/** Algo de recherche dichotomique dans tableau trié.
	 * @param paArray Le tableau doit être trié
	 * @param poItem
	 */
	public static binarySearch<T>(paArray: T[], poItem: T, psSearchKey?: keyof T): boolean {
		let lnFirst = 0;
		let lnLast: number = paArray.length - 1;
		const loSearchValue: any = psSearchKey ? poItem[psSearchKey] : poItem;

		while (lnFirst <= lnLast) {
			const lnMiddle: number = Math.floor((lnFirst + lnLast) / 2);
			const loItem: T = paArray[lnMiddle];
			const loValue: any = psSearchKey ? loItem[psSearchKey] : loItem;

			if (loValue === loSearchValue)
				return true;
			else if (loSearchValue < loValue)
				lnLast = lnMiddle - 1;
			else
				lnFirst = lnMiddle + 1;
		}

		return false;
	}

	// Remplace le contenu de la target array par celui de la source array en gardant la même référence
	public static resetArray<T>(targetArray: T[], sourceArray: T[]): void {
		targetArray.splice(0, targetArray.length, ...sourceArray);
	}

	//#endregion

}
