<div class="page-ordonnances__container">
	<div class="page-ordonnances__header">
		<div class="page-ordonnances__header-titre-btn">
			<div class="page-ordonnances__titre">
				<h1>Ordonnances</h1>
				<small>{{ordoActiveCountMessage}}</small>
			</div>
			<div class="page-ordonnances__btn-add-ordonance">
				<lua-action-secondaire *ngIf="!isMobileView" [avecIcone]="true" libelle="Ordonnance" nomIcone="add"
					[fullWidth]="false" positionIcone="L" (click)="handleClickAddOrdonnance($event)"></lua-action-secondaire>
			</div>
		</div>
		<div class="page-ordonnances__filters">
			<lua-recherche [searchText]="searchedValue" label="Rechercher" (onValueChange)="searchOrdonnances($event)"
				placeholder="Rechercher">
			</lua-recherche>
			<lua-filtre-groupe [filters]="ordonnancefilters" (filterSelected)="changeFiltre($event)"
				[defaultFilter]="selectedFilter"></lua-filtre-groupe>
			<lua-filtres
				*ngIf="!isMobileView && infirmiers?.length > 1"
				[fullWidth]="false" 
				[selectedFilters]="filterInfirmiersSelected" 
				(click)="openMenuFiltreInfirmier($event)" 
				label="Infirmier"
				[color]="colorFilter">
			</lua-filtres>		
		</div>
	</div>
	<div class="page-ordonnances__body">
		<ng-container *ngIf="filteredOrdonnances.length > 0">
			<ng-container *ngFor="let ordonnance of filteredOrdonnances | slice:0:displayCount; let i = index">
				<di-carte-ordonnance [ordonnance]="ordonnance" tagColor="Info" progressBarColor="Indigo" [patient]="ordonnance.patient" buttonIconName="more_vert">
				</di-carte-ordonnance>
			</ng-container>
			<lua-action-secondaire *ngIf="displayCount < filteredOrdonnances.length" class="show-more-btn-container"
				libelle="Afficher les ordonnances suivantes" [fullWidth]="false" (click)="showMore()"></lua-action-secondaire>
		</ng-container>
		<ng-container *ngIf="filteredOrdonnances.length === 0">
			<lua-message-aucune-donnee (onClick)="handleClickAddOrdonnance($event)" [mainText]="MainTextNoResult"
				iconName="contract" [subText]="SubTextNoResult" buttonLabel="Nouvelle ordonnance"></lua-message-aucune-donnee>
		</ng-container>
	</div>
</div>
<lua-bouton-floating *ngIf="isMobileView" class="btn-ajout-contact-mobile" bottom="40px" right="40px" iconName="add"
	(click)="handleClickAddOrdonnance($event)">
</lua-bouton-floating>