import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactHelper } from '@osapp/helpers';
import { EPrefix, ETimetablePattern, IContact, IGroup, UserData } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService, GroupsService, PlatformService, Store } from '@osapp/services';
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { ITraitement } from 'apps/idl/src/model/ITraitement';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { IAccordPrealable } from 'apps/idl/src/modules/patients/model/IAccordPrealable';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { AMCP } from 'apps/idl/src/modules/patients/model/amc-p';
import { AMOP } from 'apps/idl/src/modules/patients/model/amo-p';
import { ESituation } from 'apps/idl/src/modules/patients/model/esituation.enum';
import { EUpdateMode } from 'apps/idl/src/modules/patients/model/eupdate-mode.enum';
import { IAMC } from 'apps/idl/src/modules/patients/model/iamc';
import { IAMO } from 'apps/idl/src/modules/patients/model/iamo';
import { IConvention } from 'apps/idl/src/modules/patients/model/iconvention';
import { CouverturesService } from 'apps/idl/src/modules/patients/services/couvertures.service';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { AccordPrealableService } from 'apps/idl/src/modules/traitement/slides-traitement/accordPrealable/accordPrealable.service';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription, from, iif, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, mergeMap, merge as mergeOperator, startWith, switchMap, take, takeUntil, tap, toArray, finalize } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../../../libs/osapp/src/helpers/arrayHelper';
import { DateHelper } from '../../../../../../../libs/osapp/src/helpers/dateHelper';
import { FacturationSvComponent } from '../../../features/facturation/components/facturation-sv/facturation-sv.component';
import { SelectionTitulaireComponent } from '../../../features/facturation/components/selection-titulaire/selection-titulaire.component';
import { GroupeListeSeancesComponent } from '../../../features/shared/components/groupe-liste-seances/groupe-liste-seances-component';
import { PanneauAccordPrealableComponent } from '../../../features/shared/components/panneaux/panneau-accord-prealable/panneau-accord-prealable.component';
import { PanneauIdentitePatientComponent } from '../../../features/shared/components/panneaux/panneau-identite-patient/panneau-identite-patient.component';
import { PanneauOrdonnanceComponent } from '../../../features/shared/components/panneaux/panneau-ordonnance/panneau-ordonnance.component';
import { PanneauPatientAmcComponent } from '../../../features/shared/components/panneaux/panneau-patient-amc/panneau-patient-amc.component';
import { PanneauPatientAmoComponent } from '../../../features/shared/components/panneaux/panneau-patient-amo/panneau-patient-amo.component';
import { PanneauFormContactComponent } from '../../../features/shared/components/panneaux/panneau-prescripteur/panneau-form-contact.component';
import { EControleEtat } from '../../../features/shared/enums/EControleEtat';
import { EMode } from '../../../features/shared/enums/EMode';
import { EProfile } from '../../../features/shared/enums/EProfile';
import { SSNHelper } from '../../../features/shared/helpers/ssn.helper';
import { DeviceService } from '../../../features/shared/services/device.service';
import { DrawerPopoverService } from '../../../features/shared/services/drawer-popover.service';
import { MedecinService } from '../../../features/shared/services/medecin.service';
import { PanneauService } from '../../../features/shared/services/panneau.service';
import { SeanceService } from '../../../features/shared/services/seance.service';
import { ITagSoin } from '../../../models/ITagSoin';
import { StoredSeance } from '../../../models/StoredSeance';
import { ControleFacturation } from '../../../models/controle-facturation/ControleFacturation';
import { IControleBase } from '../../../models/controle-facturation/IControleBase';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FiltreInfirmiersComponent } from '../../../features/facturation/components/filtres/filtre-infirmiers/filtre-infirmiers.component';
import { Acte } from 'apps/idl/src/model/Acte';
import { LoaderService } from '../../../features/shared/services/loader.service';
import { PanneauDeplacementComponent } from '../../../features/shared/components/panneaux/panneau-deplacement/panneau-deplacement.component';
import { Indemnite } from 'apps/idl/src/modules/traitement/model/Indemnite';
import { SnackbarService } from '../../../features/shared/services/snackbar.service';
import { FiltreEtatSeanceComponent } from '../../../features/facturation/components/filtres/filtre-etat-seance/filtre-etat-seance.component';
import { FILTRE_ETATS_SEANCE, NB_SEANCE_MAX_TARIFICATION_AUTO } from '../../../shared/seances.constants';
import { IFiltreEtatSeance } from '../../../models/seances/IFiltreEtatSeance';
import { Invoice } from 'apps/idl/src/modules/facturation/models/invoice';
import { FacturationService } from 'apps/idl/src/modules/facturation/facturation.service';
import { ETraitementState } from 'apps/idl/src/model/ETraitementState';
import { IdlPrestation } from 'apps/idl/src/modules/facturation/models/idl-prestation';
import { SeancesGeneratorService } from '../../../features/shared/services/seances-generator.service';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';

@Component({
  selector: 'di-controle-facturation',
  templateUrl: './controle-facturation.component.html',
  styleUrls: ['./controle-facturation.component.scss'],
})
export class ControleFacturationPage extends DestroyableComponentBase implements OnInit {
  public isMobile: boolean = false;
  /**
   * Liste de toutes les séances du traitement
  */
  public seances: StoredSeance[] = [];

  // Liste des séances affichées selon les critères (date de facturation + infirmier(s) sélectionné(s))
  public seancesDisplayed: StoredSeance[] = [];

  // Liste des séances facturables (ne contient aucune séance future)
  public seancesFacturables: StoredSeance[] = [];

  // Liste des séances facturables filtrées par les infirmiers sélectionnés
  public seancesFacturablesInfirmiers: StoredSeance[] = [];

  public idOrdonnance: string = "";
  public ordonnance: ITraitement; //v2 : Traitement = Ordonnance
  public patient: IPatient;
  private isFacturable: boolean = false;
  public isFacturable$ = new BehaviorSubject<boolean>(this.isFacturable);
  public showSelectionTitulaire: boolean = false;
  public infirmiers: IContact[];
  public convention: IConvention;
  public etablissementsById: Map<string, IAMC | IAMO> = new Map();
  public controlesFacturation: ControleFacturation = new ControleFacturation();
  public dateFacturation: Date = new Date();
  public facturationStart: boolean = false;
  public nomPatientFormatted: string;
  public adressePatientFormatted: string;
  public libelleBtnDeplacement: string = "Saisir";
  public filtreForm: FormGroup;

  /**Gestion tarification à la volée */
  public factureTarifiee: Invoice;
  public prestations: IdlPrestation[] = [];
  public showWarningTarification: boolean = false;


  private filterInfirmiersSelected: string[] = [];
  public filterInfirmiersSelected$ = new BehaviorSubject<string[]>(this.filterInfirmiersSelected);

  private colorFilter: string = "Indigo";
  public colorFilter$ = new BehaviorSubject<string>(this.colorFilter);
  public idsInfirmiersSelected: string[] = [];

  public etatsSeancesSelected: EStatusSeance[] = [];
  public filterEtatsSelected: string[] = [];
  public etatsSeance: IFiltreEtatSeance[] = FILTRE_ETATS_SEANCE;

  public situations: Record<ESituation, string> = {
    [ESituation.assuranceMaladie]: "Assurance maladie",
    [ESituation.accidentTravail]: "Accident du travail",
    [ESituation.maternite]: "Maternité",
    [ESituation.preventionMaladie]: "Prévention maladie",
    [ESituation.soinsMedicauxGratuits]: "Soins médicaux gratuits",
  };

  public remplacantTag: ITagSoin = {
    label: "Remplaçant",
    color: "CouleurPrimaire",
    mode: EMode.filled,
  };

  private startNewTarification = new Subject<void>();
  startNewTarification$ = this.startNewTarification.asObservable();

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;


  // Défini les conditions d'erreur du patient avec un ordre de priorité
  private erreursPatient = [
    { condition: (patient: IPatient) => !patient.birthDate, message: "Date de naissance non renseignée" },
    // { condition: (patient: IPatient) => !patient.carteVitale, message: "Carte vitale non lue" },
    //TODO: à voir avec les analystes pour les conditions à ajouter
  ];

  private erreursDateOrdonnance = [
    { condition: (ordonnance: ITraitement) => !ordonnance.prescriptionDate, message: "Date de l'ordonnance non renseignée", etat: EControleEtat.ERROR },
    //TODO: à voir avec les analystes pour les conditions à ajouter
  ];
  private erreursScanOrdonnance = [
    { condition: (ordonnance: ITraitement) => ordonnance.documents?.length === 0, message: "Aucun scan de l'ordonnance", etat: EControleEtat.WARNING },
    //TODO: à voir avec les analystes pour les conditions à ajouter
  ];

  private erreursAccordPrealable = [
    { condition: (ordonnance: ITraitement) => this.accordPrealable?.resultat !== true, message: "Demande d'accord préalable non validée", etat: EControleEtat.ERROR },
  ];

  private erreursPrescripteur = [
    { condition: (prescripteur: IContact) => prescripteur === undefined, message: "Prescripteur non renseigné", etat: EControleEtat.ERROR }
  ];

  private erreursNumeroPrescripteur = [
    { condition: (prescripteur: IContact) => prescripteur && !prescripteur.finess, message: "N° du prescripteur non renseigné", etat: EControleEtat.ERROR }
  ];

  private erreursAmo = [
    { condition: (couvAmo: AMOP) => !couvAmo, message: "Régime obligatoire non renseigné", etat: EControleEtat.ERROR },
  ];

  private erreursAmoNir = [
    { condition: (couvAmo: AMOP) => !couvAmo || !couvAmo.nir, message: "Numéro de sécurité social non renseigné", etat: EControleEtat.ERROR },
    //TODO : il faut mettre l'algo qui vérifie si le NIR est correct
    { condition: (couvAmo: AMOP) => couvAmo.nir?.length < 13, message: "Numéro de sécurité social incorrect", etat: EControleEtat.ERROR },
  ];

  private erreursAmoCouv = [
    // { condition: (couvAmo: AMOP) => !couvAmo || (!couvAmo.dateDebut && !couvAmo.dateFin), message: "Dates de couverture non renseignées", etat: EControleEtat.ERROR },
    // { condition: (couvAmo: AMOP) => !couvAmo.dateDebut, message: "Date de début non renseignée", etat: EControleEtat.WARNING },
    // { condition: (couvAmo: AMOP) => !couvAmo.dateFin, message: "Date de fin non renseignée", etat: EControleEtat.WARNING },
    { condition: (couvAmo: AMOP) => !couvAmo.isActiveAnakin, message: "Les couvertures ne sont plus valides", etat: EControleEtat.ERROR },
  ];

  private erreursAmoMode = [
    { condition: (couvAmo: AMOP) => !couvAmo || !couvAmo.updateMode || couvAmo.updateMode === EUpdateMode.manual, message: "Aucune lecture carte vitale ou ADRi", etat: EControleEtat.WARNING },
  ];

  private erreursAmc = [
    { condition: (couvAmc: AMCP) => !couvAmc, message: "Mutuelle non renseignée", etat: EControleEtat.ERROR },
    { condition: (couvAmc: AMCP) => (couvAmc.gestionUnique && !couvAmc.mutnum) || (!couvAmc.gestionUnique && !couvAmc.amcId), message: "N° de télétransmission non renseigné", etat: EControleEtat.ERROR },
  ];

  private erreursAmcCouv = [
    { condition: (couvAmc: AMCP) => !couvAmc || (!couvAmc.dateDebut && !couvAmc.dateFin), message: "Dates de couverture non renseignées", etat: EControleEtat.ERROR },
    { condition: (couvAmc: AMCP) => !couvAmc.isActive, message: "Date de fin dépassée", etat: EControleEtat.ERROR },
    { condition: (couvAmc: AMCP) => !couvAmc.dateFin, message: "Date de fin non renseignée", etat: EControleEtat.WARNING },
    { condition: (couvAmc: AMCP) => !couvAmc.gestionUnique && !couvAmc.idConvention, message: "Convention non sélectionnée", etat: EControleEtat.ERROR },
  ];

  private erreursAmcConvention = [
    { condition: (couvAmc: AMCP) => !couvAmc.gestionUnique && !couvAmc.idConvention, message: "Convention non sélectionnée", etat: EControleEtat.ERROR },
  ];

  private erreursInfirmier = [
    // { condition: (infirmier: IContact) => !infirmier.codeCpsSaved, message: "Carte CPS non enregistrée", etat: EControleEtat.ERROR },
  ];

  private erreurDateSituation = [
    { condition: (couvAmo: AMOP) => couvAmo.situation !== ESituation.assuranceMaladie && !DateHelper.isDate(couvAmo.situationDate), message: "Date de situation non renseignée", etat: EControleEtat.ERROR },
  ];

  private erreursSoinsAFacturer = [
    { condition: (seances: StoredSeance[]) => !ArrayHelper.hasElements(seances), message: "Aucune séance à facturer", etat: EControleEtat.ERROR },
    { condition: (seances: StoredSeance[]) => seances.some(x => x.status === EStatusSeance.to_be_done || x.status === EStatusSeance.canceled), message: "Aucune séance réalisée", etat: EControleEtat.WARNING },
    { condition: (seances: StoredSeance[]) => !seances.some(x => x.status === EStatusSeance.done || x.status === EStatusSeance.to_be_done || x.status === EStatusSeance.canceled), message: "Aucune séance à facturer", etat: EControleEtat.ERROR },
    //TODO : A voir si on garde ici le cas ou il y a des séances annulées ou non réalisées
    // { condition: (seances: StoredSeance[]) => seances.some(x => x.status === EStatusSeance.canceled || x.status === EStatusSeance.to_be_done), message: "Il y a des séances non réalisées ou annulées", etat: EControleEtat.WARNING },
  ];

  private selectMedecinSubscription: Subscription | null = null;
  private selectMedecinRunning$ = new Subject<boolean>();

  private accordPrealable: IAccordPrealable;

  private checkAccordPrealable: boolean = false;
  private amoCouverture: IAMO;
  private amcCouverture: IAMC;
  private amcPatient: AMCP;
  private currentContactId: string;

  public targetSeanceId: string = "";

  constructor(
    private svcDevice: DeviceService,
    private svcTraitement: TraitementService,
    private svcPatient: PatientsService,
    private router: Router,
    private route: ActivatedRoute,
    private svcSeance: SeanceService,
    private svcContact: ContactsService,
    private svcGroupe: GroupsService,
    private readonly svcCouvertures: CouverturesService,
    private svcPanneau: PanneauService,
    private svcDrawerPopover: DrawerPopoverService,
    private svcAccordPrealable: AccordPrealableService,
    private svcMedecin: MedecinService,
    private fb: FormBuilder,
    private svcLoader: LoaderService,
    private svcSnackbar: SnackbarService,
    private readonly svcFacturation: FacturationService,
    private svcStore: Store,
    private psvcPlatform: PlatformService,
    private svcSeanceGenerator: SeancesGeneratorService,
    private svcPrestation: PrestationService,
  ) {
    super();
  }

  ngOnInit() {
    this.svcDevice.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flag: boolean) => {
        this.isMobile = flag;
      });

    this.getDateFacturationFromRoute();
    this.initFiltreForm();

    this.currentContactId = UserData.current._id.replace(EPrefix.user, EPrefix.contact);
    if (!this.isMobile) {
      this.idsInfirmiersSelected.push(this.currentContactId);
    }

    this.idOrdonnance = this.route.snapshot.paramMap.get('id')!;
    if (this.idOrdonnance) {
      this.getOrdonnance().pipe(
        tap(() => this.initEtatsSeanceSelected()),
        switchMap(() => this.refreshSeancesList()),
        tap(() => {
          this.checkShowSelectionTitulaire();
          if (this.isMobile) {
            this.idsInfirmiersSelected = ArrayHelper.unique(
              this.seancesDisplayed.map(seance => seance.infirmierId)
            );
          }
          this.getInfirmiersControles(this.infirmiers);
          this.checkIsFacturable();
          this.verifierEtatBtnDeplacement();
          this.svcLoader.hideLoader();
        }),
        switchMap(() => this.tarificationProcess())
      ).subscribe();
    }


    this.svcPanneau.closePanel$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(300),
      switchMap(() =>
        // Si un prescripteur a été sélectionné, alors on attend que l'ordonnance soit mis à jour avant de refresh les contrôles
        this.selectMedecinRunning$.pipe(
          startWith(undefined),
          distinctUntilChanged(),
          switchMap((running: boolean | undefined) => {
            if (running === undefined || !running) {
              this.refreshControleFacturation();
              this.selectMedecinRunning$.complete();
            }
            return of(true);
          }),
          switchMap(() => {
            if (this.isMobile) {
              return this.refreshSeancesList();
            }
            return of(true);
          }),
          tap(() => this.svcLoader.hideLoader()),
          switchMap(() => this.tarificationProcess()),
        )
      )
    ).subscribe();

    this.svcSeance.refreshSeanceList$.pipe(
      takeUntil(this.destroyed$),
      switchMap(() => this.refreshSeancesList()),
      tap(() => {
        this.checkIsFacturable();
        this.checkShowSelectionTitulaire();
        this.svcLoader.hideLoader();
      }),
      switchMap(() => this.tarificationProcess())
    ).subscribe();

    this.startNewTarification$.pipe(
      takeUntil(this.destroyed$),
      switchMap(() => this.tarificationProcess())
    ).subscribe();

  }

  private tarificationProcess(): Observable<Invoice> {
    return this.isMobile ? of(null) : this.tarification().pipe(finalize(() => {
      this.deleteFacturesTarifiees();
    }));
  }

  private deleteFacturesTarifiees() {
    this.svcFacturation.annulerFacturesEtPrestaTarifiees();
  }

  private initEtatsSeanceSelected() {
    this.etatsSeancesSelected = [EStatusSeance.done, EStatusSeance.to_be_done];
    this.getFilterEtatsSelected();
  }

  private getFilterEtatsSelected() {
    this.filterEtatsSelected = this.etatsSeance.filter(x => this.etatsSeancesSelected.includes(x.status)).map(x => x.label);
  }

  private initFiltreForm() {

    this.filtreForm = this.fb.group({
      dateFacturation: [(this.dateFacturation && DateHelper.isDate(this.dateFacturation)) ? new Date(this.dateFacturation) : new Date(), []],
    });
  }

  public refreshControleFacturation() {
    this.controlesFacturation.initControle();
    this.getOrdonnance().subscribe(() => {
      this.checkIsFacturable();
      this.checkShowSelectionTitulaire();
      this.verifierEtatBtnDeplacement();
      this.svcLoader.hideLoader();
    });
  }

  // Modifie le tiers payant de l'AMO active tu patient
  toggleTiersPayantAMO = (value: boolean) => {
    if (this.patient && this.patient.AMO && this.patient.AMO.length > 0 && this.patient.AMO[0]) {
      this.patient.AMO[0].tp = value;
    }
  }

  // Modifie le tiers payant de l'AMC active tu patient
  toggleTiersPayantAMC = (value: boolean) => {
    if (this.patient && this.patient.AMC && this.patient.AMC.length > 0 && this.patient.AMC[0]) {
      this.patient.AMC[0].tp = value;
    }
  }

  onClickCancel() {
    this.router.navigate(["facturation"]);
  }

  facturer(event?: Event) {
    if (this.showSelectionTitulaire) {
      this.openSelectionTitulaire(event);
    } else {
      this.openPanneauFacturation();
    }
  }

  private getDateFacturationFromRoute() {
    const state = this.router.getCurrentNavigation()?.extras.state as { dateFacturation: Date; };
    if (state?.dateFacturation) {
      // this.dateFacturation = new Date(state.dateFacturation);
      this.dateFacturation = state.dateFacturation;
    } else {
      this.dateFacturation = history.state.dateFacturation || new Date();
    }
  }

  private changeFacturationStart = () => {
    this.facturationStart = false;
  }

  private openPanneauFacturation = (titulaireSelected?: IContact) => {
    this.facturationStart = true;
    if (this.isMobile) {
      this.svcPanneau.close();
      this.svcDrawerPopover.open("Facturation Sesam-Vitale",
        "700px",
        null,
        FacturationSvComponent,
        {
          seancesAFacturer: this.seancesFacturablesInfirmiers,
          seances: this.seances,
          ordonnance: this.ordonnance,
          patient: this.patient,
          couvertureAMO: this.amoCouverture,
          couvertureAMC: this.amcCouverture,
          couvertureAMCPatient: this.amcPatient,
          titulaireSelected: titulaireSelected
        },
        this.changeFacturationStart)
    } else {
      this.svcPanneau.open("Facturation Sesam-Vitale", FacturationSvComponent, {
        seancesAFacturer: this.seancesFacturablesInfirmiers,
        seances: this.seances,
        ordonnance: this.ordonnance,
        patient: this.patient,
        couvertureAMO: this.amoCouverture,
        couvertureAMC: this.amcCouverture,
        couvertureAMCPatient: this.amcPatient,
        titulaireSelected: titulaireSelected
      });
    }
  }

  private openSelectionTitulaire(event: Event) {
    this.svcPanneau.close();
    this.svcDrawerPopover.open("Sélectionnez le titulaire",
      "auto",
      event.currentTarget,
      SelectionTitulaireComponent,
      {
        onFacturer: this.openPanneauFacturation,
        infirmiers: this.infirmiers.filter(x => !x.profiles || x.profiles.length === 0) //On retire les remplacants
      })
  }

  private getOrdonnance(): Observable<{}> {
    this.svcLoader.showLoader("Chargement de l'ordonnance et du patient...");
    this.facturationStart = false;
    //TODO : à changer en getITraitementANAKIN si on ne récupère pas les documents dans le type Traitement
    return this.svcTraitement.getTraitementANAKIN(this.idOrdonnance).pipe(
      tap((ordo: ITraitement) => this.ordonnance = ordo),
      mergeMap(() => {
        const patientId = Traitement.extractPatientId(this.idOrdonnance);
        return this.getPatient(patientId);
      }),
      tap(() => this.checkAccordPrealable = this.ordonnance.actes.some(acte => acte.isPriorAgreement)),
      switchMap(() => {
        if (!this.checkAccordPrealable)
          return of(null)

        return this.svcAccordPrealable.get(this.ordonnance._id).pipe(
          tap((accord: IAccordPrealable) => {
            this.accordPrealable = accord;
          }),
          catchError(() => {
            return of(null)
          })
        )
      }),
      tap(() => { this.getOrdonnanceControles(this.ordonnance) }),
      mergeMap(() => this.svcContact.getSiteContactsAnakin([], EPrefix.contact, true, true)),
      map((contacts: IContact[]) => {
        return contacts.map((contact: IContact) => {
          if (!contact.isDoctor && contact.finess)
            contact.isDoctor = true;
          return contact;
        });
      }),
      tap((contacts: IContact[]) => {
        this.infirmiers = contacts.filter(contact => contact.userId);
      }),
      switchMap(() => this.svcGroupe.getContactsGroups(this.infirmiers.map((infi: IContact) => infi._id))),
      tap((infiGroups: Map<string, IGroup[]>) => {
        this.setProfilInfirmier(infiGroups);
      }),
      takeUntil(this.destroyed$)
    )
  }

  private setProfilInfirmier(infiGroups: Map<string, IGroup[]>) {
    this.infirmiers = this.infirmiers.map((inf: IContact) => {
      const groups = infiGroups.get(inf._id) || [];

      if (groups.some(group => group._id === 'grp_remplacant')) {
        //On insert le profile remplacant seulement s'il n'existe pas déjà
        inf.profiles = ArrayHelper.hasElements(inf.profiles)
          ? Array.from(new Set([...inf.profiles, EProfile.remplacant]))
          : [EProfile.remplacant];
      }

      return inf;
    });
  }

  private getPatientControles(patient: IPatient) {
    let controlePatient: IControleBase = {} as IControleBase;

    // Trouve la première erreur applicable
    const premiereErreur = this.erreursPatient.find(erreur => erreur.condition(patient));

    // Défini l'état : s'il y a une erreur, met l'état à ERROR, sinon à OK
    controlePatient.etat = premiereErreur ? EControleEtat.ERROR : EControleEtat.OK;
    const fullLastName = this.svcPatient.getFullNamePatient(this.patient);
    this.nomPatientFormatted = [
      fullLastName.toUpperCase(),
      patient.firstName
        ? patient.firstName.charAt(0).toUpperCase() + patient.firstName.slice(1).toLowerCase()
        : ''
    ].filter(Boolean).join(' ');
    controlePatient.libellePrincipal = this.nomPatientFormatted;
    controlePatient.isBold = true;
    this.adressePatientFormatted = [patient.street, patient.city].filter(Boolean).join(', ');
    controlePatient.libelleSecondaire = this.adressePatientFormatted;

    // Défini le libellé d'erreur basé sur la première erreur trouvée
    controlePatient.libelleErreur = premiereErreur ? premiereErreur.message : "";
    controlePatient.onClick = this.openPanneauIdentitePatient;

    this.controlesFacturation.patient = controlePatient;

  }

  private openPanneauSecuriteSociale = () => {
    const panneauTitle = "Assurance maladie obligatoire";
    const panneauContent = PanneauPatientAmoComponent;
    const panneauInputs = {
      patient: this.patient,
      ...(this.patient.AMO[0] && { couverture: this.patient.AMO[0] })
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  public openPanneauMutuelle = () => {
    const panneauTitle = "Mutuelle";
    const panneauContent = PanneauPatientAmcComponent;
    const panneauInputs = {
      patient: this.patient,
      ...(this.patient.AMC[0] && { couverture: this.patient.AMC[0] })
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  private openPanneauIdentitePatient = () => {
    const panneauTitle = "Identification du patient";
    const panneauContent = PanneauIdentitePatientComponent;
    const panneauInputs = {
      patient: this.patient
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  private ajouterControleOrdonnance(
    erreurs: { condition: (ordonnance: ITraitement) => boolean, etat: EControleEtat, message: string }[],
    ordonnance: ITraitement,
    libelle: string,
    onClick?: () => void
  ) {
    let controle: IControleBase = {} as IControleBase;

    // Trouve la première erreur applicable
    const premiereErreur = erreurs.find(erreur => erreur.condition(ordonnance));

    // Défini l'état : s'il y a une erreur, met l'état à ERROR, sinon à OK
    controle.etat = premiereErreur?.etat ?? EControleEtat.OK;

    // Défini le libellé principal en fonction de l'état
    controle.libellePrincipal = controle.etat !== EControleEtat.ERROR ? libelle : "";

    // Défini le libellé d'erreur basé sur la première erreur trouvée
    controle.libelleErreur = premiereErreur ? premiereErreur.message : "";
    controle.onClick = onClick ?? this.openPanneauOrdonnance;

    // Ajoute le contrôle à la facturation
    this.controlesFacturation.ordonnance.push(controle);
  }


  private getOrdonnanceControles(ordonnance: ITraitement) {
    //Prescripteur
    of(ordonnance.prescripteurContactId).pipe(
      switchMap((prescripteurContactId) =>
        // Si prescripteurContactId est défini alors on fait le getContact
        iif(
          () => prescripteurContactId !== undefined,
          this.svcContact.getContact(prescripteurContactId),
          of(undefined)
        )
      ),
      tap((prescripteur) => {
        this.getOrdonnancePrescripteurControles(prescripteur);
        // Contrôle pour la date de l'ordonnance
        const libellePrincipalDate = ["Du", DateHelper.transform(ordonnance.prescriptionDate, ETimetablePattern.dd_MM_yyyy_slash)].filter(Boolean).join(' ');
        this.ajouterControleOrdonnance(this.erreursDateOrdonnance, ordonnance, libellePrincipalDate);

        // Contrôle pour le scan de l'ordonnance
        this.ajouterControleOrdonnance(this.erreursScanOrdonnance, ordonnance, "Scan de l'ordonnance (SCOR)");

        // Contrôle pour l'accord préalable de l'ordonnance
        if (this.checkAccordPrealable) {
          this.ajouterControleOrdonnance(this.erreursAccordPrealable, ordonnance, "Accord préalable", this.openPanneauAccordPrealable);
        }
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  private getOrdonnancePrescripteurControles(prescripteur: IContact) {
    let controlePresc: IControleBase = {} as IControleBase;
    const premiereErreur = this.erreursPrescripteur.find(erreur => erreur.condition(prescripteur));
    // Défini l'état : s'il y a une erreur, met l'état à ERROR, sinon à OK
    controlePresc.etat = premiereErreur?.etat ?? EControleEtat.OK;
    controlePresc.libellePrincipal = [prescripteur?.isDoctor ? "Dr" : "", ContactHelper.getFormattedLastName(prescripteur?.lastName), ContactHelper.getFormattedFirstName(prescripteur?.firstName)].filter(Boolean).join(' ');
    controlePresc.isBold = true;
    controlePresc.libelleSecondaire = prescripteur?.rpps ? `RPPS : ${prescripteur.rpps}` : ''
    // Défini le libellé d'erreur basé sur la première erreur trouvée
    controlePresc.libelleErreur = premiereErreur ? premiereErreur.message : "";
    controlePresc.onClick = this.openPanneauOrdonnance;
    this.controlesFacturation.ordonnance.push(controlePresc);

    // Si le prescripteur est renseigné, on ajoute le contrôle sur le numéro de prescripteur
    if (!premiereErreur) {
      this.ajouterControlePrescripteur(
        this.erreursNumeroPrescripteur,
        prescripteur,
        `Numéro de prescripteur`,
        () => this.openContactPrescripteur(prescripteur)
      )
    }

  }

  private openContactPrescripteur(prescripteur: IContact) {
    const drawerPanneauTitle = "Prescripteur";
    const drawerPanneauContent = PanneauFormContactComponent;
    const drawerPanneauInputs = {
      contact: prescripteur
    };
    this.svcPanneau.open(
      drawerPanneauTitle,
      drawerPanneauContent,
      drawerPanneauInputs
    );
  }

  private ajouterControlePrescripteur(
    erreurs: { condition: (Prescripteur: IContact) => boolean, etat: EControleEtat, message: string }[],
    prescripteur: IContact,
    libelle: string,
    onClick?: () => void
  ) {
    let controle: IControleBase = {} as IControleBase;

    // Trouve la première erreur applicable
    const premiereErreur = erreurs.find(erreur => erreur.condition(prescripteur));

    // Défini l'état : s'il y a une erreur, met l'état à ERROR, sinon à OK
    controle.etat = premiereErreur?.etat ?? EControleEtat.OK;

    // Défini le libellé principal en fonction de l'état
    controle.libellePrincipal = controle.etat !== EControleEtat.ERROR ? libelle : "";
    controle.libelleSecondaire = controle.etat !== EControleEtat.ERROR ? `Finess : ${prescripteur.finess}` : "";
    // Défini le libellé d'erreur basé sur la première erreur trouvée
    controle.libelleErreur = premiereErreur ? premiereErreur.message : "";
    controle.onClick = onClick ?? this.openPanneauOrdonnance;

    // Ajoute le contrôle à la facturation
    this.controlesFacturation.ordonnance.push(controle);
  }

  private openPanneauOrdonnance = () => {
    const panneauTitle = "Ordonnance";
    const panneauContent = PanneauOrdonnanceComponent;
    const panneauInputs = {
      ordonnance: this.ordonnance
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );

    if (this.selectMedecinSubscription) {
      this.selectMedecinSubscription.unsubscribe();
    }

    this.selectMedecinSubscription = this.svcMedecin.selectMedecin$.pipe(
      take(1),
      takeUntil(this.destroyed$)
    ).subscribe((medecinId: string) => {
      this.selectMedecinRunning$.next(true);
      this.svcContact.getContact(medecinId).pipe(
        take(1),
        switchMap((medecin: IContact) => {
          this.ordonnance.prescripteurContactId = medecin._id;
          const ordonnanceObject = Traitement.createFromData(this.ordonnance);
          return this.svcTraitement.saveTraitementANAKIN(ordonnanceObject).pipe(
            tap((ordonnance: ITraitement) => {
              this.ordonnance = ordonnance;
            }),
            takeUntil(this.destroyed$)
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe(() => {
        this.selectMedecinRunning$.next(false);
      });
    });
  }

  private openPanneauAccordPrealable = () => {
    const panneauTitle = "Accord préalable";
    const panneauContent = PanneauAccordPrealableComponent;
    const panneauInputs = {
      ordonnance: this.ordonnance,
      accord: this.accordPrealable
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  private getAmoControles(patient: IPatient) {
    const amoP: AMOP = ArrayHelper.hasElements(patient.AMO) ? patient.AMO.find(x => x.isActiveAnakin) ?? patient.AMO[0] : null;

    //Si on a pas d'amo on grise tous les controles
    let controleTP: IControleBase = {} as IControleBase;
    let controleAmo: IControleBase = {} as IControleBase;
    let controleAmoNir: IControleBase = {} as IControleBase;
    let controleAmoCouv: IControleBase = {} as IControleBase;
    let controleAmoMode: IControleBase = {} as IControleBase;
    let controleAmoDateSitu: IControleBase = {} as IControleBase;

    if (!amoP) {
      controleAmo.etat = EControleEtat.DISABLED;
      controleAmo.libelleErreur = "Régime obligatoire";

      controleAmoNir.etat = EControleEtat.DISABLED;
      controleAmoNir.libelleErreur = "Numéro de sécurité sociale";

      controleAmoCouv.etat = EControleEtat.DISABLED;
      controleAmoCouv.libelleErreur = "Période de droits";

      controleAmoMode.etat = EControleEtat.DISABLED;
      controleAmoMode.libelleErreur = "Contrôle ADRI ou carte vitale";

      controleTP.isDisabled = true;

      this.amoCouverture = null;
    } else {
      const premiereErreurAmo = this.erreursAmo.find(erreur => erreur.condition(amoP));
      const libelleAmo = amoP ? this.svcCouvertures.getLabelCouverture(amoP.amoId, this.etablissementsById) : "";
      this.amoCouverture = amoP ? this.svcCouvertures.getEtablissementById(amoP.amoId, this.etablissementsById) as IAMO : null;
      controleAmo.etat = premiereErreurAmo?.etat ?? EControleEtat.OK;
      controleAmo.libellePrincipal = controleAmo.etat !== EControleEtat.ERROR ? libelleAmo : "";
      controleAmo.isBold = true;
      controleAmo.libelleSecondaire = controleAmo.etat !== EControleEtat.ERROR ? amoP.amoId.replace("amo_", "").replace(/-/g, "") : "";
      controleAmo.libelleErreur = premiereErreurAmo ? premiereErreurAmo.message : "";

      const premiereErreurAmoNir = this.erreursAmoNir.find(erreur => erreur.condition(amoP));
      const libelleAmoNir = amoP ? SSNHelper.formatSSN(amoP.nir) : "";
      controleAmoNir.etat = premiereErreurAmoNir?.etat ?? EControleEtat.OK;
      controleAmoNir.libellePrincipal = libelleAmoNir;
      controleAmoNir.libelleErreur = premiereErreurAmoNir ? premiereErreurAmoNir.message : "";

      const premiereErreurAmoCouv = this.erreursAmoCouv.find(erreur => erreur.condition(amoP));
      const libelleAmoCouv = amoP ? [amoP.dateDebut ? "Du" : "", DateHelper.transform(amoP.dateDebut, ETimetablePattern.dd_MM_yyyy_slash), amoP.dateDebut ? (amoP.dateFin ? "au" : "") : (amoP.dateFin ? "jusqu'au" : ""), DateHelper.transform(amoP.dateFin, ETimetablePattern.dd_MM_yyyy_slash)].filter(Boolean).join(' ') : "";
      if (!amoP.dateDebut && !amoP.dateFin) {
        controleAmoCouv.etat = EControleEtat.DISABLED
        controleAmoCouv.libelleErreur = "Période de droits";
      } else {
        controleAmoCouv.libellePrincipal = libelleAmoCouv;
        controleAmoCouv.etat = premiereErreurAmoCouv?.etat ?? EControleEtat.OK;
        controleAmoCouv.libelleErreur = premiereErreurAmoCouv ? premiereErreurAmoCouv.message : "";
      }

      if (amoP.situation !== ESituation.assuranceMaladie) {
        const premiereErreurAmoDateSitu = this.erreurDateSituation.find(erreur => erreur.condition(amoP));
        const libelleAmoDateSitu = this.situations[amoP.situation];
        const libelleSecAmoDateSitu = amoP ? [amoP.situationDate ? DateHelper.transform(amoP.situationDate, ETimetablePattern.dd_MM_yyyy_slash) : ""].filter(Boolean).join(' ') : "";
        controleAmoDateSitu.isBold = true;
        controleAmoDateSitu.etat = premiereErreurAmoDateSitu?.etat ?? EControleEtat.OK;
        controleAmoDateSitu.libelleSecondaire = controleAmoDateSitu.etat !== EControleEtat.ERROR ? libelleSecAmoDateSitu : "";
        controleAmoDateSitu.libellePrincipal = libelleAmoDateSitu;
        controleAmoDateSitu.libelleErreur = premiereErreurAmoDateSitu ? premiereErreurAmoDateSitu.message : "";
      }

      const premiereErreurAmoMode = this.erreursAmoMode.find(erreur => erreur.condition(amoP));
      const libelleAmoMode = "Contrôle ADRI ou carte vitale";
      controleAmoMode.etat = premiereErreurAmoMode?.etat ?? EControleEtat.OK;
      controleAmoMode.libellePrincipal = controleAmo.etat !== EControleEtat.ERROR ? libelleAmoMode : "";
      controleAmoMode.libelleErreur = premiereErreurAmoMode ? premiereErreurAmoMode.message : "";

      controleTP.onToggleSwitch = this.toggleTiersPayantAMO
    }

    controleTP.isSwitchOnly = true;
    controleTP.isChecked = amoP?.tp || false;
    controleTP.libellePrincipal = "Tiers payant";
    this.controlesFacturation.amo.push(controleTP);

    controleAmo.onClick = this.openPanneauSecuriteSociale;
    controleAmoNir.onClick = this.openPanneauSecuriteSociale;
    controleAmoCouv.onClick = this.openPanneauSecuriteSociale;
    controleAmoMode.onClick = this.openPanneauSecuriteSociale;
    this.controlesFacturation.amo.push(controleAmo);
    this.controlesFacturation.amo.push(controleAmoNir);
    this.controlesFacturation.amo.push(controleAmoCouv);
    this.controlesFacturation.amo.push(controleAmoMode);

    if (amoP?.situation !== ESituation.assuranceMaladie) {
      controleAmoDateSitu.onClick = this.openPanneauSecuriteSociale;
      this.controlesFacturation.amo.push(controleAmoDateSitu);
    }

  }

  private getAmcControles(patient: IPatient) {
    const amcP: AMCP = ArrayHelper.hasElements(patient.AMC) ? patient.AMC.find(x => x.isActive) ?? patient.AMC[0] : null;

    let controleTP: IControleBase = {} as IControleBase;
    let controleAmc: IControleBase = {} as IControleBase;
    let controleAmcCouv: IControleBase = {} as IControleBase;
    let controleAmcMode: IControleBase = {} as IControleBase;
    if (!amcP) {
      controleAmc.etat = EControleEtat.DISABLED;
      controleAmc.libelleErreur = "Tiers payeur mutuelle";

      controleAmcCouv.etat = EControleEtat.DISABLED;
      controleAmcCouv.libelleErreur = "Période  de droits";

      controleAmcMode.etat = EControleEtat.DISABLED;
      controleAmcMode.libelleErreur = "Convention de télétransmission";

      controleTP.isDisabled = true;

      this.amcPatient = null;
      this.amcCouverture = null;
    } else {
      const premiereErreurAmc = this.erreursAmc.find(erreur => erreur.condition(amcP));
      const libelleAmc = amcP ? this.svcCouvertures.getLabelCouverture(amcP.amcId, this.etablissementsById) : "";
      this.amcPatient = amcP ?? null;
      this.amcCouverture = amcP ? this.svcCouvertures.getEtablissementById(amcP.amcId, this.etablissementsById) as IAMC : null;
      controleAmc.etat = premiereErreurAmc?.etat ?? EControleEtat.OK;
      controleAmc.libellePrincipal = controleAmc.etat !== EControleEtat.ERROR ? libelleAmc : "";
      controleAmc.isBold = true;
      controleAmc.libelleSecondaire = controleAmc.etat !== EControleEtat.ERROR ? amcP.gestionUnique ? `${amcP.amcId.replace("amc_", "")} - GU : ${amcP.mutnum}` : amcP.amcId.replace("amc_", "") : "";
      controleAmc.libelleErreur = premiereErreurAmc ? premiereErreurAmc.message : "";

      const premiereErreurAmcCouv = this.erreursAmcCouv.find(erreur => erreur.condition(amcP));
      const libelleAmcCouv = amcP ? [amcP.dateDebut ? "Du" : "", DateHelper.transform(amcP.dateDebut, ETimetablePattern.dd_MM_yyyy_slash), amcP.dateDebut ? (amcP.dateFin ? "au" : "") : (amcP.dateFin ? "jusqu'au" : ""), DateHelper.transform(amcP.dateFin, ETimetablePattern.dd_MM_yyyy_slash)].filter(Boolean).join(' ') : "";
      controleAmcCouv.etat = premiereErreurAmcCouv?.etat ?? EControleEtat.OK;
      controleAmcCouv.libellePrincipal = libelleAmcCouv;
      controleAmcCouv.libelleErreur = premiereErreurAmcCouv ? premiereErreurAmcCouv.message : "";

      if (!amcP.gestionUnique) {
        const premiereErreurAmcConv = this.erreursAmcConvention.find(erreur => erreur.condition(amcP));
        const libelleAmcMode = !amcP.convention ? "Convention sélectionnée" : [amcP.convention.libelle, amcP.convention.estTp ? "(TP)" : ""].filter(Boolean).join(" ");
        const libelleSecAmcMode = !amcP.convention ? "" : [amcP.convention.typeConvention, amcP.convention.codeOganismeComplementaire, amcP.convention.critereSecondaire].filter(Boolean).join(" - ");
        controleAmcMode.etat = premiereErreurAmcConv?.etat ?? EControleEtat.OK;
        controleAmcMode.libellePrincipal = controleAmc.etat !== EControleEtat.ERROR ? libelleAmcMode : "";
        controleAmcMode.isBold = true;
        controleAmcMode.libelleSecondaire = controleAmc.etat !== EControleEtat.ERROR ? libelleSecAmcMode : "";
        controleAmcMode.libelleErreur = premiereErreurAmcConv ? premiereErreurAmcConv.message : "";
      }

      controleTP.onToggleSwitch = this.toggleTiersPayantAMC
    }
    controleTP.isSwitchOnly = true;
    controleTP.isChecked = amcP?.tp || false;
    controleTP.libellePrincipal = "Tiers payant";

    controleAmc.onClick = this.openPanneauMutuelle
    controleAmcCouv.onClick = this.openPanneauMutuelle
    controleAmcMode.onClick = this.openPanneauMutuelle
    this.controlesFacturation.amc.push(controleTP);
    this.controlesFacturation.amc.push(controleAmc);
    this.controlesFacturation.amc.push(controleAmcCouv);
    if (amcP && !amcP.gestionUnique) {
      this.controlesFacturation.amc.push(controleAmcMode);
    }
  }

  private getSoinsAFacturerControles(seances: StoredSeance[]) {
    this.controlesFacturation.soinsAFacturer = [];
    //On affiche ce bloc seulement en mobile
    if (!this.isMobile) return;
    let controleDateSeance: IControleBase = {} as IControleBase;
    controleDateSeance.etat = EControleEtat.OK;
    controleDateSeance.iconEnd = "";
    controleDateSeance.libellePrincipal = ["jusqu'au", DateHelper.transform(this.dateFacturation, ETimetablePattern.dd_MM_yyyy_slash)].join(" ");
    this.controlesFacturation.soinsAFacturer.push(controleDateSeance);

    let controleSeances: IControleBase = {} as IControleBase;
    const nbSeancesAFacturer = seances.filter(x => x.status === EStatusSeance.done).length;
    const nbSeancesAnnuleesOuNonRealisees = seances.filter(x => x.status === EStatusSeance.canceled || x.status === EStatusSeance.to_be_done).length;
    const aucuneSeances = nbSeancesAFacturer === 0 && nbSeancesAFacturer === 0;
    const premiereErreurSeances = this.erreursSoinsAFacturer.find(erreur => erreur.condition(seances));
    const libelleSeance = [nbSeancesAFacturer, nbSeancesAFacturer > 1 ? "séances" : "séance", "à facturer"].join(" ");
    const libelleSeanceAnnulee = [nbSeancesAnnuleesOuNonRealisees, nbSeancesAnnuleesOuNonRealisees > 1 ? "séances non réalisées ou annulées" : "séance non réalisée ou annulée"].join(" ");
    controleSeances.iconEnd = "chevron_right";
    controleSeances.etat = premiereErreurSeances?.etat ?? (nbSeancesAnnuleesOuNonRealisees ? EControleEtat.WARNING : EControleEtat.OK);
    controleSeances.libellePrincipal = controleSeances.etat !== EControleEtat.ERROR ? libelleSeance : "";
    controleSeances.isBold = true;
    controleSeances.libelleSecondaire = controleSeances.etat !== EControleEtat.ERROR ? "voir le détail" : "";
    controleSeances.libelleErreur = premiereErreurSeances && controleSeances.etat === EControleEtat.WARNING ? libelleSeanceAnnulee : (premiereErreurSeances && controleSeances.etat === EControleEtat.ERROR ? premiereErreurSeances.message : "");
    controleSeances.onClick = this.openPanneauSoinsAFacturer;
    this.controlesFacturation.soinsAFacturer.push(controleSeances);
  }

  public openPanneauSoinsAFacturer = () => {
    if (this.isMobile) {
      const drawerPanneauTitle = "Séances à facturer";
      const drawerPanneauContent = GroupeListeSeancesComponent;
      const drawerPanneauInputs = {
        seances: this.seancesDisplayed,
        ordonnance: this.ordonnance,
        patient: this.patient,
        facturationStart: this.facturationStart,
        isFacturable$: this.isFacturable$,
        amoCouverture: this.amoCouverture,
        amcCouverture: this.amcCouverture,
        amcPatient: this.amcPatient,
        showSelectionTitulaire: this.showSelectionTitulaire,
        infirmiers: this.infirmiers,
        onShowPanneauFacturation: this.openPanneauFacturation,
        nomPatientFormatted: this.nomPatientFormatted,
        adressePatientFormatted: this.adressePatientFormatted,
        libelleBtnDeplacement: this.libelleBtnDeplacement,
        openPanneauDeplacement: this.openPanneauDeplacement,
        filtreForm: this.filtreForm,
        onDateFacturationChange: this.onDateFacturationChange,
        filterInfirmiersSelected$: this.filterInfirmiersSelected$,
        openMenuFiltreInfirmier: this.openMenuFiltreInfirmier,
        colorFilter$: this.colorFilter$,
        factureTarifiee: this.factureTarifiee,
        showWarningTarification: this.showWarningTarification
      };
      this.svcPanneau.open(
        drawerPanneauTitle,
        drawerPanneauContent,
        drawerPanneauInputs
      );
    }
    else {
      this.scrollContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  // Ajoute ou retire les séances de l'infirmier ciblé de la liste des séances à facturer
  toggleInfirmier = (value: boolean, id: string) => {
    if (!value) {
      const seancesDisplayedFiltered: StoredSeance[] = this.seancesDisplayed.filter((seance: StoredSeance) => seance.infirmierId !== id);
      ArrayHelper.resetArray(this.seancesDisplayed, seancesDisplayedFiltered);
      const seancesFacturablesFiltered: StoredSeance[] = this.seancesFacturablesInfirmiers.filter((seance: StoredSeance) => seance.infirmierId !== id)
      ArrayHelper.resetArray(this.seancesFacturablesInfirmiers, seancesFacturablesFiltered);
      this.idsInfirmiersSelected = this.idsInfirmiersSelected.filter((idInfirmier: string) => idInfirmier !== id);
    } else {
      const seancesDisplayedFiltered: StoredSeance[] = [...this.seancesDisplayed, ...this.seancesFacturables.filter((seance: StoredSeance) => seance.infirmierId === id)];
      ArrayHelper.resetArray(this.seancesDisplayed, seancesDisplayedFiltered);
      const seancesFacturablesFiltered: StoredSeance[] = [...this.seancesFacturablesInfirmiers, ...this.seancesFacturables.filter((seance: StoredSeance) => seance.infirmierId === id)];
      ArrayHelper.resetArray(this.seancesFacturablesInfirmiers, seancesFacturablesFiltered);
      this.idsInfirmiersSelected.push(id);
    }
    this.controlesFacturation.infirmiers.find(x => x.id === id).isChecked = value;
    this.getSoinsAFacturerControles(this.seancesDisplayed);
    this.checkIsFacturable();
    this.getSelectedInfirmiers();
  }

  private getInfirmiersControles(infirmiers: IContact[]) {
    this.getSelectedInfirmiers();
    if (!this.isMobile) {
      return;
    }

    if (ArrayHelper.hasElements(infirmiers)) {
      let controlesInf: IControleBase[] = [];
      infirmiers.forEach((inf) => {
        let controleInf: IControleBase = {} as IControleBase;
        controleInf.libellePrincipal = [inf.firstName, inf.lastName?.toUpperCase()].filter(Boolean).join(" ");
        controleInf.isSwitch = true;
        controleInf.id = inf._id;
        //On ajoute le tag remplacant
        if (inf.profiles?.some(x => x === EProfile.remplacant)) {
          controleInf.tag = this.remplacantTag;
        }
        if (this.idsInfirmiersSelected.includes(inf._id)) {
          //TODO a décommenter lorsque la fonctionnalité de cps sur l'infirmier sera dev
          const premiereErreurInf = this.erreursInfirmier.find(erreur => erreur.condition(inf));
          controleInf.etat = premiereErreurInf?.etat ?? EControleEtat.OK;
          controleInf.isChecked = true;
          controleInf.onToggleSwitch = (isChecked: boolean) => this.toggleInfirmier(isChecked, inf._id);
        } else {
          if (this.seancesFacturables.some(seance => seance.infirmierId === inf._id)) {
            controleInf.etat = EControleEtat.OK;
            controleInf.isChecked = false;
            controleInf.onToggleSwitch = (isChecked: boolean) => this.toggleInfirmier(isChecked, inf._id);
          } else {
            controleInf.etat = EControleEtat.DISABLED;
            controleInf.isDisabled = true;
            controleInf.isChecked = false;
            controleInf.libelleSecondaire = 'Aucune séance'
          }
        }
        controlesInf.push(controleInf);

      });
      this.controlesFacturation.infirmiers = controlesInf;
    }
  }



  private getPatient(patientId: string) {
    return this.svcPatient.getPatient(patientId).pipe(
      tap((patient: IPatient) => {
        this.patient = patient;
        this.getPatientControles(patient);
      }),
      switchMap(() => this.svcCouvertures.getPatientSortedCouvertures(this.patient._id, false)),
      switchMap((poCouvertures: { AMOPs: AMOP[]; AMCPs: AMCP[] }) => {

        // AMO active du patient
        const activeAmo: AMOP = this.svcCouvertures.getLatestActiveCouverture(poCouvertures.AMOPs) as AMOP;
        this.patient.AMO = activeAmo ? [activeAmo] : [];

        // AMC active du patient
        const activeAmc: AMCP = this.svcCouvertures.getLatestActiveCouverture(poCouvertures.AMCPs) as AMCP;
        this.patient.AMC = activeAmc ? [activeAmc] : [];

        const idsCouv: string[] = []
        if (activeAmo) {
          idsCouv.push(activeAmo.amoId)
          activeAmo.nir = this.patient.socialNumber ?? undefined
        }
        if (activeAmc)
          idsCouv.push(activeAmc.amcId)

        //Récupération des établissements liés aux AMO et AMC du patient
        return this.svcCouvertures.getEtablissementsByIds(idsCouv)
      }),
      tap((etablissements: (IAMC | IAMO)[]) => {
        etablissements.forEach((etablissement: IAMC | IAMO) => this.etablissementsById.set(etablissement._id, etablissement));
      }),
      tap(() => {
        this.getAmoControles(this.patient);
        this.getAmcControles(this.patient);
      }),
      takeUntil(this.destroyed$),
      catchError(error => {
        console.error('Erreur lors de la récupération du patient et de ses couvertures ', error);
        return of(null);
      })
    );
  }

  private checkIsFacturable() {
    //TODO : ajouter des exceptions en fonction des retours, attente NIE
    this.isFacturable = this.seancesDisplayed.some(x => x.status === EStatusSeance.done)
      && !(this.controlesFacturation.patient?.etat === EControleEtat.ERROR
        || this.controlesFacturation.ordonnance.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.amo.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.amc.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.infirmiers.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.soinsAFacturer.some(x => x.etat === EControleEtat.ERROR))
    this.isFacturable$.next(this.isFacturable);
  }

  private checkShowSelectionTitulaire() {
    //On garde le comportement actuel sur mobile
    if (this.isMobile) {
      //On récupère les ids des remplacants qui sont sélectionnés
      const infRemplacantsIds: string[] = this.controlesFacturation.infirmiers.filter(x => x.etat === EControleEtat.OK && x.tag && x.isChecked).map(x => x.id);

      //On affiche la pop de sélection d'un titulaire uniquement si l'on a séances à facturer avec des remplacants et qu'on a au moins un titulaire et que l'on n'est pas le titulaire
      this.showSelectionTitulaire = ArrayHelper.hasElements(this.controlesFacturation.infirmiers)
        && this.controlesFacturation.infirmiers.some(x => x.etat === EControleEtat.OK && x.tag && x.isChecked) //Permet de vérifier si j'ai un remplacant de coché dans le controle "Infirmier"      
        && infRemplacantsIds.includes(this.currentContactId)//Permet de vérifier si je suis un remplacant
        //Vérifie que le remplacant à des séances à facturer 
        && this.seancesFacturablesInfirmiers.filter((seance) => seance.status === EStatusSeance.done).some(x => infRemplacantsIds.includes(x.infirmierId));
    } else {
      //En revanche sur desktop on regarde juste si l'utilisateur connecté est un remplacant 
      const currentInfi: IContact = this.infirmiers.find(x => x._id === this.currentContactId);
      //Si je suis remplacant, on n'affiche la pop
      this.showSelectionTitulaire = !!currentInfi?.profiles?.includes(EProfile.remplacant);
    }
  }

  public getTargetSeance(): void {
    // Permet de recevoir la startDate de la séance qui vient d'être mise à jour / supprimée
    this.svcSeance.seanceUpdated$.pipe(
      mergeOperator(of(null)),
      take(1)
    ).subscribe((seanceStartDate: Date | null) => {
      let targetSeanceStartDate: Date;
      if (seanceStartDate) {
        targetSeanceStartDate = new Date(seanceStartDate);
        this.svcSeance.clearSeanceUpdated();
      }
      if (!targetSeanceStartDate || !this.seances?.length) return;
      let seanceFound: boolean = false;
      let index: number = 0;
      while (!seanceFound && index < this.seances.length) {
        let seance: StoredSeance = this.seances[index];
        index++;
        this.targetSeanceId = seance._id;
        seanceFound = new Date(seance.startDate) >= targetSeanceStartDate;
      }
    });
  }

  public openPanneauDeplacement = (event: Event) => {
    const panneauTitle = "Déplacement";
    const panneauContent = PanneauDeplacementComponent;
    const panneauInputs = {
      patient: this.patient,
      dateFacturationFiltree: this.dateFacturation,
      seance: ArrayHelper.getFirstElement(this.seancesDisplayed),
      onSaveDeplacement: this.handleDeplacementSeanceValidee
    };

    if (this.isMobile) {
      this.svcDrawerPopover.open(panneauTitle,
        "90%",
        null,
        panneauContent,
        panneauInputs)
    } else {
      this.svcPanneau.open(panneauTitle, panneauContent, panneauInputs);
    }
  }

  private handleDeplacementSeanceValidee = (indemnitesSelected: Indemnite[]) => {
    this.appliquerDeplacementSeanceValidee(indemnitesSelected);
    this.verifierEtatBtnDeplacement();
    this.isMobile ? this.svcDrawerPopover.close() : this.svcPanneau.close();
  }

  public appliquerDeplacementSeanceValidee(indemnitesASave: Indemnite[]) {
    const seancesAMaj: StoredSeance[] = this.seancesDisplayed.filter(x => x.status === EStatusSeance.done);
    if (ArrayHelper.hasElements(seancesAMaj)) {
      seancesAMaj.forEach(x => x.indemnites = indemnitesASave);
      this.svcSeance.updateSeances(seancesAMaj).subscribe(() => {
        this.svcSnackbar.showToast("success", "bottom center", "Les indemnités de déplacement ont été appliquées avec succès", null, null, null, "6000");
      });
    } else {
      this.svcSnackbar.showToast("success", "bottom center", "Les indemnités de déplacement (IK) ont été enregistrées", null, null, null, "6000");
    }
  }

  private verifierEtatBtnDeplacement() {
    if (this.isMobile) return;

    this.libelleBtnDeplacement = this.patient?.lastIkUpdate?.type
      ? `${this.patient.lastIkUpdate.type}${this.patient.lastIkUpdate.distance ? ` (${this.patient.lastIkUpdate.distance} km)` : ""}`
      : "Saisir";
  }

  public onDateFacturationChange = (newDate: string) => {
    const newDateFormated = new Date(newDate);
    this.dateFacturation = newDateFormated;
    this.filtreForm.setValue({
      dateFacturation: newDateFormated,
    });
    this.applyFilter();
  }

  private applyFilter(tarification: boolean = true) {
    ArrayHelper.resetArray(this.seancesDisplayed, this.seancesFacturables);

    if (DateHelper.isDate(this.dateFacturation)) {
      this.filterSeancesByDate();
    }

    if (this.idsInfirmiersSelected?.length > 0) {
      this.filterSeancesByInfirmier();
    }

    this.filterSeancesByEtat();

    if (tarification) {
      this.startNewTarification.next();
    }
  }

  private filterSeancesByDate() {
    const dateFacturationAComparer: Date = DateHelper.fillDay(this.dateFacturation);
    const seancesAAfficher = this.seancesDisplayed.filter((seance: StoredSeance) => DateHelper.compareTwoDates(seance.startDate, dateFacturationAComparer) <= 0);
    ArrayHelper.resetArray(this.seancesDisplayed, seancesAAfficher);
    ArrayHelper.resetArray(this.seancesFacturablesInfirmiers, seancesAAfficher);
  }

  private filterSeancesByInfirmier() {
    const seancesAAfficher = this.seancesDisplayed.filter((seance: StoredSeance) => this.idsInfirmiersSelected.includes(seance.infirmierId));
    ArrayHelper.resetArray(this.seancesDisplayed, seancesAAfficher);
    ArrayHelper.resetArray(this.seancesFacturablesInfirmiers, seancesAAfficher);
  }

  private filterSeancesByEtat() {
    const seancesAAfficher = this.seancesDisplayed.filter((seance: StoredSeance) => this.etatsSeancesSelected.includes(seance.status));
    ArrayHelper.resetArray(this.seancesDisplayed, seancesAAfficher);
    ArrayHelper.resetArray(this.seancesFacturablesInfirmiers, seancesAAfficher);
  }

  public openMenuFiltreInfirmier = (event: Event) => {
    this.svcDrawerPopover.open(
      "",
      this.isMobile ? "250px" : "",
      event.currentTarget,
      FiltreInfirmiersComponent,
      {
        infirmiers: this.infirmiers,
        idsInfirmiersSelected: this.idsInfirmiersSelected,
        onFilter: this.selectionNewInfirmiers,
        libelleBtn: "Filtrer les infirmiers"
      }
    );
  }

  public selectionNewInfirmiers = (idsInfirmiers: string[]): void => {
    this.idsInfirmiersSelected = idsInfirmiers;
    this.getSelectedInfirmiers();
    this.svcDrawerPopover.close();
    this.applyFilter();
    this.checkIsFacturable();
  }


  private getSelectedInfirmiers() {
    const newFilterInfirmierSelected = this.infirmiers
      .filter(x => this.idsInfirmiersSelected.includes(x._id))
      .map(x => {
        if (this.idsInfirmiersSelected.length === 1) {
          this.colorFilter = x.avatarCouleur ?? "Indigo";
          this.colorFilter$.next(this.colorFilter);
        }
        const firstLetter = x.firstName?.trimStart().charAt(0).toUpperCase() || "";
        return `${firstLetter}${x.firstName?.slice(1) || ""} ${x.lastName.toUpperCase()}`;
      });
    ArrayHelper.resetArray(this.filterInfirmiersSelected, newFilterInfirmierSelected);
    this.filterInfirmiersSelected$.next(this.filterInfirmiersSelected);
  }

  public openMenuFiltreEtat = (event: Event) => {
    this.svcDrawerPopover.open(
      "",
      "",
      event.currentTarget,
      FiltreEtatSeanceComponent,
      {
        etatsSelected: this.etatsSeancesSelected,
        onFilter: this.selectionNewEtatsSeance
      }
    );
  }

  public selectionNewEtatsSeance = (etatsSeance: EStatusSeance[]): void => {
    this.etatsSeancesSelected = etatsSeance;
    this.svcDrawerPopover.close();
    this.applyFilter();
    this.getFilterEtatsSelected();
    this.checkIsFacturable();
  }

  public refreshSeancesList(): Observable<StoredSeance[]> {
    this.svcLoader.showLoader("Récupération des séances à facturer...")
    return this.svcSeance.selectSeancesByTraitement(this.ordonnance._id)
      .pipe(
        tap((seances: StoredSeance[]) => {
          this.seances = seances;
          this.seances.forEach((seance: StoredSeance) => {
            seance.actes = seance.actes.map((acte: Acte) => new Acte(acte));
            seance.infirmier = this.infirmiers.find((infirmier: IContact) => infirmier._id === seance.infirmierId);
          });
          const today = new Date();
          const startDate = DateHelper.resetDay(this.ordonnance.prescriptionDate);

          // Contient toutes les séances facturables (aucune séance future)
          this.seancesFacturables = seances.filter((seance: StoredSeance) =>
            DateHelper.isBetweenTwoDates(seance.startDate, startDate, today)
          );

          this.applyFilter(false);
          this.getInfirmiersControles(this.infirmiers);
          this.getSoinsAFacturerControles(this.seancesDisplayed);
          this.getTargetSeance();
        })
      )
  }

  public tarification(): Observable<Invoice | null> {
    this.factureTarifiee = null;
    this.prestations = [];

    const traitement: Traitement = Traitement.createFromData(this.ordonnance);
    const seancesValidees = this.seancesDisplayed.filter(x => x.status === EStatusSeance.done);

    if (!ArrayHelper.hasElements(seancesValidees)) {
      return of(null);
    }
    if (seancesValidees.length > NB_SEANCE_MAX_TARIFICATION_AUTO) {
      this.showWarningTarification = true;
      return of(null);
    }
    //On masque l'alerte qui indique qu'on a trop de séances et qu'on ne peut pas tarifier
    this.showWarningTarification = false;

    const seancesRestantes = this.seances.filter(seance =>
      !seancesValidees.some(validee => validee._id === seance._id) &&
      ![EStatusSeance.canceled, EStatusSeance.inProgress, EStatusSeance.completed].includes(seance.status)
    );

    return this.generatePrestationsOptimise(seancesValidees, traitement, seancesRestantes).pipe(
      switchMap(() => this.createAndSaveInvoice()),
      switchMap(invoice => invoice ? this.tarificationApi(invoice) : of(null)),
      tap(invoice => {
        this.factureTarifiee = invoice;
      }),
      catchError(error => {
        return of(null);
      })
    );
  }

  private generatePrestationsOptimise(
    seances: StoredSeance[],
    traitement: Traitement,
    seancesRestantes: StoredSeance[]
  ): Observable<IdlPrestation[]> {
    let prestationsToSave: IdlPrestation[] = [];

    return from(seances).pipe(
      mergeMap(seance => this.svcSeanceGenerator.createAndSavePrestationOptimise(seance, traitement, prestationsToSave)),
      tap(presta => this.prestations.push(presta)),
      mergeMap(presta =>
        seancesRestantes.length > 0
          ? of(presta)
          : this.svcTraitement.modifierStateTraitement(traitement, ETraitementState.termine).pipe(map(() => presta))
      ),
      toArray(), // Regroupe toutes les prestations en un tableau
      switchMap(prestations => {
        if (prestationsToSave.length > 0) {
          return this.svcPrestation.savePrestations(prestationsToSave).pipe(map(() => prestations));
        }
        return of(prestations);
      })
    );
  }

  private createAndSaveInvoice(): Observable<Invoice | null> {
    if (!ArrayHelper.hasElements(this.prestations)) return of(null);

    const invoice = this.svcFacturation.createInvoiceFromPrestations(
      this.patient,
      this.prestations,
      this.ordonnance,
      this.amoCouverture,
      this.amcCouverture,
      this.amcPatient,
      this.showSelectionTitulaire ? this.infirmiers.find(x => !x.profiles || x.profiles.length === 0) : null,
      true
    );

    const amc = this.patient.AMC?.find(x => x.isActive);
    this.svcFacturation.fillInvoiceSTSAndGU(invoice, amc);

    return this.svcFacturation.saveInvoices([invoice]).pipe(
      switchMap(() => this.psvcPlatform.isMobileApp
        ? this.svcStore.replicateSingleDocument(invoice._id)
        : of(true)
      ),
      map(() => invoice)
    );
  }

  private tarificationApi(invoice: Invoice): Observable<Invoice> {
    return from(this.svcFacturation.forwardActionAnakin(this.patient._id, invoice, true)).pipe(
      switchMap(reponseInvoice =>
        reponseInvoice
          ? of(reponseInvoice)
          : from(this.svcFacturation.getInvoice(invoice._id).toPromise())
      ),
      catchError(error => {
        console.error('Erreur lors de la tarification:', error);
        return EMPTY;
      })
    );
  }
}
